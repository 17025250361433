<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Zone Management</div>
  <div class="px-5 py-4 minHeight80vh bg-white m-4 font-14">
    <div class="row">
      <div class="col-12">
        <div class="pb-4 font-14 pt-1">Edit Zone</div>
      </div>
    </div>
    <div class="mb-3">
      <form [formGroup]="addZoneForm">
        <div class="row medium font-12 pb-3">
          <div class="col-3">
            <div class="medium pb-1">Zone Name *</div>
            <div class="mb-3">
              <input type="text" placeholder="Enter Zone Name" type="text"
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser" formControlName="zoneName" />
              <div *ngIf="
                    addZoneForm.controls['zoneName']?.invalid && addZoneForm.controls['zoneName']?.dirty
                  ">
                <span class="error" *ngIf="addZoneForm.controls['zoneName'].errors?.['required']">Zone name is
                  required</span>
                <span class="error" *ngIf="addZoneForm.controls['zoneName'].errors?.['pattern']">Zone name can only be
                  in text</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row medium font-14">
          <div class="col-12">
            <span class="bold subHeading px-3 pt-2 pb-3">Address</span>
          </div>
        </div>
        <div class="contentBelowSubHeading p-3">
          <div class="row medium font-12">
            <div class="col">
              <div class="medium pb-1">Address Line 1 </div>
              <div class="mb-3">
                <input type="text" placeholder="Enter Address" class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="addressLine1" />
                <div *ngIf="
                      addZoneForm.controls['addressLine1']?.invalid &&
                      addZoneForm.controls['addressLine1']?.dirty
                    ">
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">Address Line 2</div>
              <div class="mb-3">
                <input type="text" placeholder="Enter Address" class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="addressLine2" />
                <div *ngIf="
                      addZoneForm.controls['addressLine2']?.invalid &&
                      addZoneForm.controls['addressLine2']?.dirty
                    ">
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">State *</div>
              <div class="mb-3">
                <select
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser"
                formControlName="state"
              >
                <option value="" disabled>Select State</option>
                <option
                  *ngFor="let state of stateList"
                  value="{{ state.stateName }}"
                  >{{ state.stateName }}</option
                >
              </select>
                <div *ngIf="
                      addZoneForm.controls['state']?.invalid && addZoneForm.controls['state']?.dirty
                    ">
                    <span class="error" *ngIf="addZoneForm.controls['state'].errors && addZoneForm.controls['state'].errors['required']"
                    >State is required</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row medium font-12">
            <div class="col">
              <div class="medium pb-1">City/District</div>
              <div class="mb-3">
                <input type="text" placeholder="Enter City or District"
                  class="w-100 light height-40 px-3 inputBoxAddUser" formControlName="district" />
                <div *ngIf="
                      addZoneForm.controls['district']?.invalid &&
                      addZoneForm.controls['district']?.dirty
                    ">
                  <span class="error" *ngIf="addZoneForm.controls['district'].errors?.['pattern']">District name can
                    only be in text</span>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">Location</div>
              <div class="mb-3">
                <input type="text" placeholder="Enter Location" class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="location" />
                <div *ngIf="
                      addZoneForm.controls['location']?.invalid &&
                      addZoneForm.controls['location']?.dirty
                    ">
                  <span class="error" *ngIf="addZoneForm.controls['location'].errors?.['pattern']">Location can only be
                    in text</span>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">Pincode</div>
              <div class="mb-3">
                <input type="text" placeholder="Enter Pincode" class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="pincode" />
                <div *ngIf="
                      addZoneForm.controls['pincode']?.invalid && addZoneForm.controls['pincode']?.dirty
                    ">
                  <span class="error" *ngIf="addZoneForm.controls['pincode'].errors?.['pattern']">Pincode should be in
                    number</span>
                  <span class="error" *ngIf="
                        !addZoneForm.controls['pincode'].errors?.['pattern'] &&
                        !addZoneForm.controls['pincode'].errors?.['required'] &&
                        addZoneForm.controls['pincode'].errors?.['maxlength']
                      ">Invalid pincode.
                  </span>
                  <span class="error" *ngIf="
                        !addZoneForm.controls['pincode'].errors?.['pattern'] &&
                        !addZoneForm.controls['pincode'].errors?.['required'] &&
                        addZoneForm.controls['pincode'].errors?.['maxlength']
                      ">Invalid pincode.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row medium font-14 pt-4">
          <div class="col-6">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Contact Person</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12">
                <div class="col">
                  <div class="medium pb-1">Contact Name </div>
                  <div class="mb-3">
                    <input type="text" placeholder="Enter Contact Name"
                      class="w-100 light height-40 px-3 inputBoxAddUser" formControlName="contactName" />
                    <div *ngIf="
                          addZoneForm.controls['contactName']?.invalid &&
                          addZoneForm.controls['contactName']?.dirty
                        ">
                      <span class="error" *ngIf="
                            addZoneForm.controls['contactName'].errors?.['minlength'] &&
                            !addZoneForm.controls['contactName'].errors?.['pattern']
                          ">Contact name is too short</span>
                      <span class="error" *ngIf="addZoneForm.controls['contactName'].errors?.['maxlength']">Contact name
                        is too long</span>
                      <span class="error" *ngIf="addZoneForm.controls['contactName'].errors?.['pattern']">Contact name
                        can only be in text</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Contact Number </div>
                  <div class="mb-3">
                    <input type="text" placeholder="Enter Contact Person"
                      class="w-100 light height-40 px-3 inputBoxAddUser" formControlName="contactNumber" />
                    <div *ngIf="
                          addZoneForm.controls['contactNumber']?.invalid &&
                          addZoneForm.controls['contactNumber']?.dirty
                        ">
                      <span class="error" *ngIf="
                            addZoneForm.controls['contactNumber'].errors?.['maxlength'] &&
                            !addZoneForm.controls['contactNumber'].errors?.['pattern']
                          ">Please enter a valid contact number</span>
                      <span class="error" *ngIf="
                            addZoneForm.controls['contactNumber'].errors?.['minlength'] &&
                            !addZoneForm.controls['contactNumber'].errors?.['pattern']
                          ">Invalid mobile number.</span>
                      <span class="error" *ngIf="addZoneForm.controls['contactNumber'].errors?.['pattern']">Invalid
                        mobile number.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row medium font-12">
                <div class="col">
                  <div class="medium pb-1">Daily Wages *</div>
                  <div class="mb-3">
                    <input type="text" placeholder="Enter Unskilled " class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="unskilled" />
                    <div *ngIf="
                          addZoneForm.controls['unskilled']?.invalid &&
                          addZoneForm.controls['unskilled']?.dirty
                        ">
                      <span class="error" *ngIf="addZoneForm.controls['unskilled'].errors?.['required']">Unskilled is
                        required</span>
                      <span class="error" *ngIf="addZoneForm.controls['unskilled'].errors?.['pattern']">Please enter a
                        valid number.</span>
                      <span class="error" *ngIf="
                            !addZoneForm.controls['unskilled'].errors?.['pattern'] &&
                            !addZoneForm.controls['unskilled'].errors?.['required'] &&
                            addZoneForm.controls['unskilled'].errors?.['minlength']
                          ">Semi skilled is too short.</span>
                      <span class="error" *ngIf="
                            !addZoneForm.controls['unskilled'].errors?.['pattern'] &&
                            !addZoneForm.controls['unskilled'].errors?.['required'] &&
                            addZoneForm.controls['unskilled'].errors?.['maxlength']
                          ">Semi skilled is too long.</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1 transparentText">*</div>
                  <div class="mb-3">
                    <input type="text" placeholder="Enter Semi Skilled"
                      class="w-100 light height-40 px-3 inputBoxAddUser" formControlName="semiSkilled" />
                    <div *ngIf="
                          addZoneForm.controls['semiSkilled']?.invalid &&
                          addZoneForm.controls['semiSkilled']?.dirty
                        ">
                      <span class="error" *ngIf="addZoneForm.controls['semiSkilled'].errors?.['required']">Semi skilled
                        is required</span>
                      <span class="error" *ngIf="addZoneForm.controls['semiSkilled'].errors?.['pattern']">Please enter a
                        valid number.</span>
                      <span class="error" *ngIf="
                            !addZoneForm.controls['semiSkilled'].errors?.['pattern'] &&
                            !addZoneForm.controls['semiSkilled'].errors?.['required'] &&
                            addZoneForm.controls['semiSkilled'].errors?.['minlength']
                          ">Semi skilled is too short.</span>
                      <span class="error" *ngIf="
                            !addZoneForm.controls['semiSkilled'].errors?.['pattern'] &&
                            !addZoneForm.controls['semiSkilled'].errors?.['required'] &&
                            addZoneForm.controls['semiSkilled'].errors?.['maxlength']
                          ">Semi skilled is too long.</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1 transparentText">*</div>
                  <div class="mb-3">
                    <input type="text" placeholder="Enter Skilled" class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="skilled" />
                    <div *ngIf="
                          addZoneForm.controls['skilled']?.invalid &&
                          addZoneForm.controls['skilled']?.dirty
                        ">
                      <span class="error" *ngIf="addZoneForm.controls['skilled'].errors?.['required']">Skilled is
                        required</span>
                      <span class="error" *ngIf="addZoneForm.controls['skilled'].errors?.['pattern']">Please enter a
                        valid number.</span>
                      <span class="error" *ngIf="
                            !addZoneForm.controls['skilled'].errors?.['pattern'] &&
                            !addZoneForm.controls['skilled'].errors?.['required'] &&
                            addZoneForm.controls['skilled'].errors?.['minlength']
                          ">Semi skilled is too short.</span>
                      <span class="error" *ngIf="
                            !addZoneForm.controls['skilled'].errors?.['pattern'] &&
                            !addZoneForm.controls['skilled'].errors?.['required'] &&
                            addZoneForm.controls['skilled'].errors?.['maxlength']
                          ">Semi skilled is too long.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Earnings and Deductions</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12 pb-1">
                <div class="col-12">
                  <div class="medium pb-1">Additions</div>
                </div>
                <div class="col-12 light">
                  <label class="radio  float-left pr-3">
                    <input type="checkbox" name="hra" formControlName="isHra" />
                    <span>HRA</span>
                  </label>

                  <label class="radio  float-left pr-3">
                    <input type="checkbox" name="conveyance" formControlName="isConveyance" />
                    <span>Conveyance</span>
                  </label>

                  <label class="radio  float-left pr-3">
                    <input type="checkbox" name="vhr" formControlName="isVhr" />
                    <span>VHR</span>
                  </label>


                  <label class="radio float-left pr-3">
                    <input type="checkbox" name="shoe" formControlName="isShoeAllowance" />
                    <span>Shoe</span>
                  </label>

                </div>
              </div>

              <div class="row medium font-12">
                <div class="col-12">

                </div>
                <div class="col-12 light mb-2">
                  <div class="row align-items-center">

                    <div class="col-8">
                      <div class="medium pb-1 pt-4">Deductions</div>
                      <label class="radio  float-left pr-3">
                        <input type="checkbox" name="epf" formControlName="isEpf" />
                        <span>EPF</span>
                      </label>

                      <label class="radio  float-left pr-3">
                        <input type="checkbox" name="esi" formControlName="isEsi" />
                        <span>ESI</span>
                      </label>

                      <label class="radio  float-left pr-3">
                        <input type="checkbox" name="pt" formControlName="isPt" />
                        <span>Professional Tax</span>
                      </label>
                    </div>
                    <div class="col-4 mt-3">
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row medium font-14">
          <div class="col-12">
            <div class="text-right pt-5">
              <button class="font-14 border-0 mr-5 submitBtn darkGreyBG text-white pointer" (click)="onCancel()">
                Cancel</button><button class="font-14 border-0 submitBtn redBG text-white pointer" type="submit"
                (click)="onClickSubmit()">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
