<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Payroll</div>


  <div class="px-5 py-4 minHeight80vh bg-white m-4 font-12">
    <div class="mb-3">
      <div style="padding-left: 0.9rem!important;" class="row pb-3">
        <div class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-5 p-0">
          <div class="pb-2 font-16 pt-1 medium">Bonus Report</div>
        </div>
      </div>
      <form [formGroup]="bonusReportForm"  (ngSubmit)="onSubmitBonusReportForm()">
        <div class="row medium font-12 pb-3">
          <div class="col-4">
            <div class="medium pb-1">From Date *</div>
            <div class="mb-3">
              <input type="text" bsDatepicker [maxDate]="bonusReportForm.controls['toDate'].value" formControlName="fromDate" placeholder="Select Date"
              type="text" class="w-100 light height-40 px-3 inputBoxAddUser font-12"
              [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY'}">
                <div class="error "
                *ngIf="bonusReportForm.get('fromDate')?.invalid && bonusReportForm.get('fromDate')?.dirty">
                <span *ngIf="bonusReportForm.get('fromDate')?.hasError('required')">From date is required</span>
              </div>
            </div>
          </div>
        </div>
          <div class="row medium font-12 pb-3">
          <div class="col-4">
            <div class="medium pb-1">To Date *</div>
            <div class="mb-3">
              <input type="text" bsDatepicker [minDate]="bonusReportForm.controls['fromDate'].value" formControlName="toDate" placeholder="Select Date"
              type="text" class="w-100 light height-40 px-3 inputBoxAddUser font-12"
              [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY'}">
                <div class="error "
                *ngIf="bonusReportForm.get('toDate')?.invalid && bonusReportForm.get('toDate')?.dirty">
                <span *ngIf="bonusReportForm.get('toDate')?.hasError('required')">To date is required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row medium font-12 pb-3">
          <div class="col-4">
            <div class="medium pb-1">Site </div>
            <div class="mb-3">
              <select

              class="w-100 light height-40 px-3 inputBoxAddUser font-12"
              formControlName="site"
            >
              <option value="" selected>Select Site</option>
              <option
                class="pointer"
                *ngFor="let item of allSites; let i = index"
                [value]="item.siteName"
              >
                {{ item.siteName }}
              </option>
            </select>
                <div class="error "
                *ngIf="bonusReportForm.get('site')?.invalid && bonusReportForm.get('site')?.dirty">
                <span *ngIf="bonusReportForm.get('site')?.hasError('required')
                && bonusReportForm.get('zone')?.hasError('required')">Please select site or zone</span>
              </div>
            </div>
          </div>
          <div style="padding-top: 1.9rem!important;"class="col-1 medium text-center">or</div>
          <div class="col-4">
            <div class="medium pb-1">Zone </div>
            <div class="mb-3">
              <ng-multiselect-dropdown [disabled]="bonusReportForm.controls['zone'].disabled" [placeholder]="'Select Zone'" [data]="zoneList"
              [settings]="multipleZonesSettings" formControlName="zone">
            </ng-multiselect-dropdown>
              </div>
          </div>
          <div class="col pt-4 text-right">
          <button type="button" (click)="onClickCancel()"
          class="font-12 border-0  submitBtn darkGreyBG text-white pointer">Clear Filter</button>
        </div>
        </div>





        <div class="row medium font-12">
          <div class="col-12">
            <div class="text-right ">
                <button
                type="submit"
                class="font-12 border-0 submitBtn redBG text-white pointer">Submit</button>
            </div>
          </div>
        </div>
        <div class="row medium font-14 pr-5 pt-4">
          <div *ngIf="downloadStatus" class="col-12 text-right">
            <img (click)="onDownloadBonusReport()" class="width-40 pointer" alt="download"
            title="Download Bonus report"
            src="../../../assets/img/download.png" />
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
