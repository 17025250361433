<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Purchase Order & Invoice</div>
  <div class="px-5 py-4 minHeight80vh bg-white m-4 font-14">
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="row">
          <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="pb-2 font-14 pt-2">Purchase Orders</div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!orderStage">
      <form [formGroup]="purchaseGenerateForm" (ngSubmit)="onSubmitPurchaseGenerateForm(purchaseGenerateForm.value)">
        <div class="row medium font-12 pt-3 pb-2">
          <div class="col-3">
            <div class="medium py-1">Select Vendor</div>
            <div class="">
              <select formControlName="vendorId" class="font-12 w-100 light height-40 px-3 inputBoxAddUser">
                <option value="" selected>Vendor Name</option>
                <option *ngFor="let cust of allVendorData" value="{{cust.vendorId}}">{{cust.vendorName}}</option>
              </select>
              <div class="error "
                *ngIf="purchaseGenerateForm.controls['vendorId'].invalid && purchaseGenerateForm.controls['vendorId'].dirty">
                <span *ngIf="purchaseGenerateForm.controls['vendorId'].errors && purchaseGenerateForm.controls['vendorId'].errors['required']">Vendor Name is Required</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-3 pb-2">
          <div class="col-3"><button type="submit"
              class="font-12 border-0 submitBtn redBG text-white pointer">Generate</button></div>
        </div>
      </form>
    </div>
    <div *ngIf="orderStage">
      <form [formGroup]="purchaseOrderFormGroup" (ngSubmit)="onSubmitPurchaseOrderForm(purchaseOrderFormGroup.value)">
        <div class="my-3">
          <div class="row medium font-12 pb-3">
            <div class="col">
              <div class="medium pb-1">PO Number *</div>
              <div class="mb-3">
                <input pInputText type="text" formControlName="purchaseOrderNumber" placeholder="Enter PO Number"
                  type="text" class="font-12 w-100 light height-40 px-3 inputBoxAddUser">

                <div class="error "
                  *ngIf="purchaseOrderFormGroup.controls['purchaseOrderNumber'].invalid && purchaseOrderFormGroup.controls['purchaseOrderNumber'].dirty">
                  <span *ngIf="purchaseOrderFormGroup.controls['purchaseOrderNumber'].errors && purchaseOrderFormGroup.controls['purchaseOrderNumber'].errors['required']">PO Number
                    Required</span>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">PO Date *</div>
              <div class="mb-3">
                <input bsDatepicker formControlName="purchaseOrderDate" type="text" placeholder="Enter PO Date"
                  type="text" class="font-12 w-100 light height-40 px-3 inputBoxAddUser">

                <div class="error "
                  *ngIf="purchaseOrderFormGroup.controls['purchaseOrderDate'].invalid && purchaseOrderFormGroup.controls['purchaseOrderDate'].dirty">
                  <span *ngIf="purchaseOrderFormGroup.controls['purchaseOrderDate'].errors && purchaseOrderFormGroup.controls['purchaseOrderDate'].errors['required']">PO Date
                    Required</span>
                  <span *ngIf="purchaseOrderFormGroup.controls['purchaseOrderDate'].errors && purchaseOrderFormGroup.controls['purchaseOrderDate'].errors['dateValidator']">PO Date
                      invalid</span>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">Reference Number *</div>
              <div class="mb-3">
                <input pInputText formControlName="referenceNumber" placeholder="Enter Reference Number" type="text"
                  class="font-12 w-100 light height-40 px-3 inputBoxAddUser">

                <div class="error "
                  *ngIf="purchaseOrderFormGroup.controls['referenceNumber'].invalid && purchaseOrderFormGroup.controls['referenceNumber'].dirty">
                  <span *ngIf="purchaseOrderFormGroup.controls['referenceNumber'].errors && purchaseOrderFormGroup.controls['referenceNumber'].errors['required']">Reference Number
                    Required</span> </div>
              </div>
            </div>
          </div>
          <div class="row medium font-12 pb-3">
            <div class="col">
              <div class="medium pb-1">PO Description *</div>
              <div class="mb-3">
                <input pInputText formControlName="purchaseOrderDescription" placeholder="Enter PO Description" type="text"
                  class="font-12 w-100 light height-40 px-3 inputBoxAddUser">
                <div class="error "
                  *ngIf="purchaseOrderFormGroup.controls['purchaseOrderDescription'].invalid && purchaseOrderFormGroup.controls['purchaseOrderDescription'].dirty">
                  <span *ngIf="purchaseOrderFormGroup.controls['purchaseOrderDescription'].errors && purchaseOrderFormGroup.controls['purchaseOrderDescription'].errors['required']">PO Description
                    Required</span> </div>
              </div>
            </div>
          </div>
          <div class="row font-12">
            <div class="col-6">
              <div class="bold py-2">Vendor Details</div>
              <div class="row darkGreyFont">
                <div class="col-4">Name</div>
                <div class="col-8 text-uppercase">{{this.selectedVendorData.vendorName}}</div>
              </div>
              <div class="row darkGreyFont">
                <div class="col-4">GSTIN</div>
                <div class="col-8">{{this.selectedVendorData.gstin}}</div>
              </div>
              <div class="row darkGreyFont">
                <div class="col-4">Address</div>
                <div class="col-8 text-uppercase">{{this.selectedVendorData?.address.addressLine1}},
                  {{this.selectedVendorData?.address.addressLine2}},
                  {{this.selectedVendorData?.address.city}}, {{this.selectedVendorData?.address.state}}, {{this.selectedVendorData?.address.pinCode}}</div>
              </div>
            </div>
            <div class="col-6">
              <div class="bold py-2">Customer Details</div>
              <div class="row darkGreyFont">
                <div class="col-4">Name</div>
                <div class="col-8 text-uppercase">{{this.selectedCustomerData?.tenantName}}</div>
              </div>
              <div class="row darkGreyFont">
                <div class="col-4">GSTIN</div>
                <div class="col-8">{{this.selectedCustomerData?.gstin}}</div>
              </div>
              <div class="row darkGreyFont">
                <div class="col-4">Address</div>
                <div class="col-8 text-uppercase">{{this.selectedCustomerData?.address.addressLine1}},
                  {{this.selectedCustomerData?.address.addressLine2}},
                  {{this.selectedCustomerData?.address.city}}, {{this.selectedCustomerData?.address.state}}, {{this.selectedCustomerData?.address.pinCode}}</div>
              </div>
            </div>
          </div>
          <div class="row medium font-12 pt-4">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <span class="bold subHeading px-3 pt-2 pb-3">Ship to</span>
                </div>
              </div>
              <div class="contentBelowSubHeading p-3">
                <div class="row medium font-12">
                  <div class="col">
                    <div class="medium pb-1">Address</div>
                    <div class="mb-3">
                      <input pInputText formControlName="shipToAddress" type="text" placeholder="Enter Address"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                    </div>
                    <div class="error "
                      *ngIf="purchaseOrderFormGroup.controls['shipToAddress'].invalid && purchaseOrderFormGroup.controls['shipToAddress'].dirty">
                      <span *ngIf="purchaseOrderFormGroup.controls['shipToAddress'].errors && purchaseOrderFormGroup.controls['shipToAddress'].errors['required']">Address is
                        Required</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="medium pb-1">City</div>
                    <div class="mb-3">
                      <input pInputText formControlName="shipToCity" type="text" placeholder="Enter City Name"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                    </div>
                    <div class="error "
                      *ngIf="purchaseOrderFormGroup.controls['shipToCity'].invalid && purchaseOrderFormGroup.controls['shipToCity'].dirty">
                      <span *ngIf="purchaseOrderFormGroup.controls['shipToCity'].errors && purchaseOrderFormGroup.controls['shipToCity'].errors['required']">City is
                        Required</span>
                    </div>
                  </div>

                  <div class="col">
                    <div class="medium pb-1">State</div>
                    <div class="mb-3">
                      <input pInputText formControlName="shipToState" type="text" placeholder="Enter State Name"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['shipToState'].invalid && purchaseOrderFormGroup.controls['shipToState'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['shipToState'].errors && purchaseOrderFormGroup.controls['shipToState'].errors['required']">State is
                          Required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="medium pb-1">Country</div>
                    <div class="mb-3">
                      <input pInputText formControlName="shipToCountry" type="text" placeholder="Enter Country Name"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['shipToCountry'].invalid && purchaseOrderFormGroup.controls['shipToCountry'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['shipToCountry'].errors && purchaseOrderFormGroup.controls['shipToCountry'].errors['required']">Country is
                          Required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="medium pb-1">Pincode *</div>
                    <div class="mb-3">
                      <input
                        placeholder="Enter Pincode"
                        class="w-100 light height-40 px-3 inputBoxAddUser"
                        formControlName="shipToPincode"
                      />
                      <div
                        *ngIf="
                        purchaseOrderFormGroup.controls['shipToPincode'].invalid && purchaseOrderFormGroup.controls['shipToPincode'].dirty
                        "

                      >
                        <span class="error" *ngIf="purchaseOrderFormGroup.controls['shipToPincode'].errors && purchaseOrderFormGroup.controls['shipToPincode'].errors['required']"
                          >Pincode is required</span
                        >
                        <span class="error" *ngIf="purchaseOrderFormGroup.controls['shipToPincode'].errors && purchaseOrderFormGroup.controls['shipToPincode'].errors['pattern']"
                          >Pincode can only be in numbers</span
                        >
                        <span
                          class="error"
                          *ngIf="purchaseOrderFormGroup.controls['shipToPincode'].errors &&
                            !purchaseOrderFormGroup.controls['shipToPincode'].errors['pattern'] &&
                            !purchaseOrderFormGroup.controls['shipToPincode'].errors['required'] &&
                            purchaseOrderFormGroup.controls['shipToPincode'].errors['minlength']
                          "
                          >Invalid pincode.</span
                        >
                        <span
                          class="error"
                          *ngIf="purchaseOrderFormGroup.controls['shipToPincode'].errors &&
                            !purchaseOrderFormGroup.controls['shipToPincode'].errors['pattern'] &&
                            !purchaseOrderFormGroup.controls['shipToPincode'].errors['required'] &&
                            purchaseOrderFormGroup.controls['shipToPincode'].errors['maxlength']
                          "
                          >Invalid pincode.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row medium font-12 pt-4">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <span class="bold subHeading px-3 pt-2 pb-3">Bill to</span>
                </div>
              </div>
              <div class="contentBelowSubHeading p-3">
                <div class="row medium font-12">
                  <div class="col">
                    <div class="medium pb-1">Address</div>
                    <div class="mb-3">
                      <input pInputText formControlName="billToAddress" type="text" placeholder="Enter Address"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['billToAddress'].invalid && purchaseOrderFormGroup.controls['billToAddress'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['billToAddress'].errors && purchaseOrderFormGroup.controls['billToAddress'].errors['required']">Address is
                          Required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="medium pb-1">City</div>
                    <div class="mb-3">
                      <input pInputText formControlName="billToCity" type="text" placeholder="Enter City Name"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['billToCity'].invalid && purchaseOrderFormGroup.controls['billToCity'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['billToCity'].errors && purchaseOrderFormGroup.controls['billToCity'].errors['required']">City is
                          Required</span>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <div class="medium pb-1">State</div>
                    <div class="mb-3">
                      <input pInputText formControlName="billToState" type="text" placeholder="Enter State Name"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['billToState'].invalid && purchaseOrderFormGroup.controls['billToState'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['billToState'].errors && purchaseOrderFormGroup.controls['billToState'].errors['required']"> State is
                          Required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="medium pb-1">Country</div>
                    <div class="mb-3">
                      <input pInputText formControlName="billToCountry" type="text" placeholder="Enter Country Name"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['billToCountry'].invalid && purchaseOrderFormGroup.controls['billToCountry'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['billToCountry'].errors && purchaseOrderFormGroup.controls['billToCountry'].errors['required']"> Country is
                          Required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="medium pb-1">Pincode *</div>
                    <div class="mb-3">
                      <input
                        placeholder="Enter Pincode"
                        class="w-100 light height-40 px-3 inputBoxAddUser"
                        formControlName="billToPincode"
                      />
                      <div
                        *ngIf="
                        purchaseOrderFormGroup.controls['billToPincode'].invalid && purchaseOrderFormGroup.controls['billToPincode'].dirty
                        "

                      >
                        <span class="error" *ngIf="purchaseOrderFormGroup.controls['billToPincode'].errors && purchaseOrderFormGroup.controls['billToPincode'].errors['required']"
                          >Pincode is required</span
                        >
                        <span class="error" *ngIf="purchaseOrderFormGroup.controls['billToPincode'].errors && purchaseOrderFormGroup.controls['billToPincode'].errors['pattern']"
                          >Pincode can only be in numbers</span
                        >
                        <span
                          class="error"
                          *ngIf="
                            purchaseOrderFormGroup.controls['billToPincode'].errors &&
                            !purchaseOrderFormGroup.controls['billToPincode'].errors['pattern'] &&
                            !purchaseOrderFormGroup.controls['billToPincode'].errors['required'] &&
                            purchaseOrderFormGroup.controls['billToPincode'].errors['minlength']
                          "
                          >Invalid pincode.</span
                        >
                        <span
                          class="error"
                          *ngIf="
                            purchaseOrderFormGroup.controls['billToPincode'].errors &&
                            !purchaseOrderFormGroup.controls['billToPincode'].errors['pattern'] &&
                            !purchaseOrderFormGroup.controls['billToPincode'].errors['required'] &&
                            purchaseOrderFormGroup.controls['billToPincode'].errors['maxlength']
                          "
                          >Invalid pincode.</span
                        >
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="row medium font-12 pt-4">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <span class="bold subHeading px-3 pt-2 pb-3">Payment Terms</span>
                </div>
              </div>
              <div class="contentBelowSubHeading p-3">
                <div class="row medium font-12">
                  <div class="col">
                    <div class="medium pb-1">Open Credit</div>
                    <div class="mb-3">
                      <input pInputText formControlName="openCredit" type="number" placeholder="Enter No of Days"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                    </div>
                  </div>
                  <div class="col">
                    <div class="medium pb-1">Price Basis</div>
                    <div class="mb-3">
                      <input pInputText formControlName="priceBasis" type="text" placeholder="Enter Price"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['priceBasis'].invalid && purchaseOrderFormGroup.controls['priceBasis'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['priceBasis'].errors && purchaseOrderFormGroup.controls['priceBasis'].errors['required']">Price Basis is
                          Required</span>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <div class="medium pb-1">Before Delivery</div>
                    <div class="mb-3">
                      <input pInputText formControlName="beforeDelivery" type="text" placeholder="Enter before delivery"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['beforeDelivery'].invalid && purchaseOrderFormGroup.controls['beforeDelivery'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['beforeDelivery'].errors && purchaseOrderFormGroup.controls['beforeDelivery'].errors['required']">Before Delivery
                          is Required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="medium pb-1">After Delivery</div>
                    <div class="mb-3">
                      <input pInputText formControlName="afterDelivery" type="text" placeholder="Enter after delivery"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['afterDelivery'].invalid && purchaseOrderFormGroup.controls['afterDelivery'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['afterDelivery'].errors && purchaseOrderFormGroup.controls['afterDelivery'].errors['required']">After Delivery
                         is Required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="medium pb-1">Currency *</div>
                    <div class="mb-3">
                      <input pInputText formControlName="currency" type="text" placeholder="Enter Currency"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['currency'].invalid && purchaseOrderFormGroup.controls['currency'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['currency'].errors && purchaseOrderFormGroup.controls['currency'].errors['required']">Currency is
                          Required</span>
                        <span class="error" *ngIf="purchaseOrderFormGroup.controls['currency'].errors && purchaseOrderFormGroup.controls['currency'].errors['currency']"
                          >Currency can only be in numbers</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row medium font-12">
                  <div class="col">
                    <div class="medium pb-1">RFA Number</div>
                    <div class="mb-3">
                      <input pInputText formControlName="rfaNumber" type="text" placeholder="Enter RFA Number"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['rfaNumber'].invalid && purchaseOrderFormGroup.controls['rfaNumber'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['rfaNumber'].errors && purchaseOrderFormGroup.controls['rfaNumber'].errors['required']">RFA Number is
                          Required</span>
                          <span class="error" *ngIf="purchaseOrderFormGroup.controls['rfaNumber'].errors && purchaseOrderFormGroup.controls['rfaNumber'].errors['pattern']"
                          >RFA Number can only be in numbers</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="medium pb-1">RFA Date </div>
                    <div class="mb-3">
                      <input bsDatepicker pInputText formControlName="rfaDate" type="text" placeholder="Enter DD/MM/YY"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['rfaDate'].invalid && purchaseOrderFormGroup.controls['rfaDate'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['rfaDate'].errors && purchaseOrderFormGroup.controls['rfaDate'].errors['required']">RFA Date is
                          Required</span>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <div class="medium pb-1">Freight Terms </div>
                    <div class="mb-3">
                      <input pInputText formControlName="frieghtTerms" type="text" placeholder="Enter"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['frieghtTerms'].invalid && purchaseOrderFormGroup.controls['frieghtTerms'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['frieghtTerms'].errors && purchaseOrderFormGroup.controls['frieghtTerms'].errors['required']">Freight Terms are
                          Required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="medium pb-1">Freight Carrier</div>
                    <div class="mb-3">
                      <input pInputText formControlName="frieghtCarrier" type="text" placeholder="Enter"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['frieghtCarrier'].invalid && purchaseOrderFormGroup.controls['frieghtCarrier'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['frieghtCarrier'].errors && purchaseOrderFormGroup.controls['frieghtCarrier'].errors['required']">Freight Carrier
                          is Required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="font-12 light">
            <div class="row text-white bg-darkGrey py-1 mx-0 mb-2 mt-4">
              <div class="col-1"></div>
              <div class="col-1">Sl No.</div>
              <div class="col-3">Description *</div>
              <div class="col">UAM *</div>
              <div class="col">HSN / SAC Code *</div>
              <div class="col">Quantity *</div>
              <div class="col">Unit Price*</div>
              <div class="col">Amount *</div>
              <div class="col-1"></div>
            </div>

            <div class="row mx-0 py-2">
              <div formArrayName="invoices" *ngFor="let a of invoicesFormArray.controls; let i = index" class="col-11">
                <div [formGroupName]="i" class="row">
                  <div class="col-1">
                    <button class="transparent-bg border-0 p-0 m-0" *ngIf=" i >= 1" (click)="onRemoveFormGroup(i)"><img
                        title="Remove" alt="remove" src="../../assets/img/delete.svg"
                        class="height-30 pointer p-1"></button>
                  </div>
                  <div class="col-1 py-1">{{i+1}}</div>
                  <div class="col-3">
                    <textarea pInputTextarea formControlName="description"
                      class="w-100 light height-30 px-3 py-1 inputBoxAddUser font-12"></textarea>
                    <div class="error "
                      *ngIf="invoicesFormArray.get([i,'description'])?.invalid && invoicesFormArray.get([i,'description'])?.dirty">
                      <span *ngIf="invoicesFormArray.get([i,'description'])?.errors && invoicesFormArray.get([i,'description'])?.errors?.['required']">Description is
                        Required</span>
                    </div>
                  </div>
                  <div class="col"><input pInputText formControlName="uam" type="text" placeholder=""
                      class="w-100 light height-30 px-3 inputBoxAddUser font-12">
                    <div class="error "
                      *ngIf="invoicesFormArray.get([i,'uam'])?.invalid && invoicesFormArray.get([i,'uam'])?.dirty">
                      <span *ngIf="invoicesFormArray.get([i,'uam'])?.errors && invoicesFormArray.get([i,'uam'])?.errors?.['required']">UAM is Required</span>
                    </div>
                  </div>
                  <div class="col"><input pInputText formControlName="hsnCode" type="text" placeholder=""
                      class="w-100 light height-30 px-3 inputBoxAddUser font-12">
                    <div class="error "
                      *ngIf="invoicesFormArray.get([i,'hsnCode'])?.invalid && invoicesFormArray.get([i,'hsnCode'])?.dirty">
                      <span *ngIf="invoicesFormArray.get([i,'hsnCode'])?.errors && invoicesFormArray.get([i,'hsnCode'])?.errors?.['required']">HSN / SAC Code is
                        Required</span>
                    </div>
                  </div>
                  <div class="col"><input (input)="onQuantityChange($event.target,i)" pInputText formControlName="quantity" type="number" placeholder=""
                      class="w-100 light height-30 px-3 inputBoxAddUser font-12">
                    <div class="error "
                      *ngIf="invoicesFormArray.get([i,'quantity'])?.invalid && invoicesFormArray.get([i,'quantity'])?.dirty">
                      <span *ngIf="invoicesFormArray.get([i,'quantity'])?.errors && invoicesFormArray.get([i,'quantity'])?.errors?.['required']">Quantity is
                        Required</span>
                        <span *ngIf="invoicesFormArray.get([i,'quantity'])?.errors && invoicesFormArray.get([i,'quantity'])?.errors?.['pattern']"
                        >Quantity is invalid</span
                      >
                    </div>
                  </div>
                  <div class="col"><input (input)="onUnitRateChange($event.target,i)" pInputText formControlName="unitRate" type="number" placeholder=""
                      class="w-100 light height-30 px-3 inputBoxAddUser font-12">
                    <div class="error "
                      *ngIf="invoicesFormArray.get([i,'unitRate'])?.invalid && invoicesFormArray.get([i,'unitRate'])?.dirty">
                      <span *ngIf="invoicesFormArray.get([i,'unitRate'])?.errors && invoicesFormArray.get([i,'unitRate'])?.errors?.['required']">Unit Price is
                        Required</span>
                        <span *ngIf="invoicesFormArray.get([i,'unitRate'])?.hasError('pattern') && invoicesFormArray.get([i,'unitRate'])?.errors?.['pattern']"> Unit Price is invalid</span
                      >
                    </div>
                  </div>
                  <div class="col">
                    {{amount[i]}}
                  </div>
                </div>
              </div>
              <div class="col-1">
                <img (click)="addNewInvoice()" alt="ADD" src="../../assets/img/plus.svg" class="height-30 pointer">
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-9">
              <div class="bold pt-5">Total Amount in Words</div>
              <div class="medium darkGreyFont py-2 text-capital">{{amountInWords}}</div>
            </div>
            <div class="col-3 font-12 my-3">
              <div class="row py-1">
                <div class="col-6">Sub Total</div>
                <div class="col-6">{{subTotal}}</div>
              </div>
              <div class="row py-1">
                <div class="col-6">IGST 18%</div>
                <div class="col-6">
                  {{igst}}
                      </div>
              </div>
              <div class="row py-1">
                <div class="col-6">SGST 9%</div>
                <div class="col-6">
                  {{sgst}}
                  </div>

              </div>
              <div class="row py-1">
                <div class="col-6">CGST 9%</div>
                <div class="col-6">
                  {{cgst}}
                </div>
              </div>
              <div class="row py-1 pb-3">
                <div class="col-6">CESS </div>
                <div class="col-6"> <input  (blur)="calculateTotal()" pInputText formControlName="cess" type="number" placeholder=""
                      class="w-100 light inputBoxPurchase px-1 font-12">  <div class="text-danger font-9 pl-3" *ngIf="purchaseOrderFormGroup.controls['cess'].invalid && purchaseOrderFormGroup.controls['cess'].dirty">
                        <span  *ngIf="purchaseOrderFormGroup.controls['cess'].errors && purchaseOrderFormGroup.controls['cess'].errors['required']">
                          Required
                          </span>

                        </div></div>
              </div>
              <div class="row py-1">
                <div class="col-6">Total</div>
                <div class="col-6">{{totalAmount}}</div>
              </div>
            </div>
          </div>

          <hr>

          <div class="row medium font-12 pb-3">
            <div class="col-4">
              <div class="medium pb-1">Document / Reports Required from Supplier</div>
              <div class="mb-3">
                <input formControlName="documentsOrReportsNeeded"
                  class="font-12 w-100 light height-40 px-3 inputBoxAddUser font-12">
                <div class="error "
                  *ngIf="purchaseOrderFormGroup.controls['documentsOrReportsNeeded'].invalid && purchaseOrderFormGroup.controls['documentsOrReportsNeeded'].dirty">
                  <span *ngIf="purchaseOrderFormGroup.controls['documentsOrReportsNeeded'].errors && purchaseOrderFormGroup.controls['documentsOrReportsNeeded'].errors['required']">Document /
                    Reports are Required</span>
                </div>
              </div>
            </div>
            <div class="col-8">
              <div class="medium pb-1">Commercial Terms & Conditions </div>
              <div class="mb-3">
                <input pInputText formControlName="termsAndConditions" type="text" placeholder="Enter Commercial Terms & Conditions" type="text"
                  class="font-12 w-100 light height-40 px-3 inputBoxAddUser font-12">
              </div>
            </div>
          </div>

          <div class="row medium font-12 py-2">
            <div class="col-8">
              <div class="contentBelowSubHeading p-3 borderTopLeft10">
                <div class="row medium font-12">
                  <div class="col">
                    <div class="medium pb-1">Project Number *</div>
                    <div class="mb-3">
                      <input pInputText formControlName="projectNumber" type="text" placeholder="Enter Project Number"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['projectNumber'].invalid && purchaseOrderFormGroup.controls['projectNumber'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['projectNumber'].errors && purchaseOrderFormGroup.controls['projectNumber'].errors['required']">Project Number is
                          Required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="medium pb-1">Delivery Date *</div>
                    <div class="mb-3">
                      <input bsDatepicker pInputText formControlName="deliveryDate" type="text"
                        placeholder="Enter Delivery Date" class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['deliveryDate'].invalid && purchaseOrderFormGroup.controls['deliveryDate'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['deliveryDate'].errors && purchaseOrderFormGroup.controls['deliveryDate'].errors['required']">Delivery Date is
                          Required</span>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <div class="medium pb-1">Shipping Quantity *</div>
                    <div class="mb-3">
                      <input pInputText formControlName="shippingQuantity" type="text"
                        placeholder="Enter Shipping Quantity"
                        class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                      <div class="error "
                        *ngIf="purchaseOrderFormGroup.controls['shippingQuantity'].invalid && purchaseOrderFormGroup.controls['shippingQuantity'].dirty">
                        <span *ngIf="purchaseOrderFormGroup.controls['shippingQuantity'].errors && purchaseOrderFormGroup.controls['shippingQuantity'].errors['required']">Shipping
                          Quantity is Required</span>
                          <span class="error" *ngIf="purchaseOrderFormGroup.controls['shippingQuantity'].errors && purchaseOrderFormGroup.controls['shippingQuantity'].errors['pattern']"
                          >Shipping Quantity can only be in numbers</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row medium font-12">
            <div class="col-12">
              <div class="text-right pt-5"><button type="button" (click)="onClickCancel()"
                  class="font-12 border-0 mr-5 submitBtn darkGreyBG text-white pointer">Cancel</button><button
                  type="submit" class="font-12 border-0 submitBtn redBG text-white pointer">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>


  </div>
</div>
