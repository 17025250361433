import { DataService } from './../../services/data.service';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, ActivationEnd   } from '@angular/router';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [],
})
export class MenuComponent implements OnInit {
  classApplied = true;
  currentLink = '';
  welcomeMessage: string = '';
  submenu = false;
  submenum = false;
  accounts = false;
  pageUrl: any;
  pageQueryParams: any;
  innerWidth: number = 0;

  toggleClass() {
    this.classApplied = !this.classApplied;
  }
  constructor(private router: Router, public dataService: DataService, public auth: AuthService) {
   this.dataService.getFromLocalStorage();
  }
  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd  ) {
        this.pageUrl = event.snapshot.url[0].path;
        const queryParams : any= event.snapshot.queryParams
        this.pageQueryParams = queryParams.type;
      }
    });
  }

  isMenuActive(methodName: string) {
    return this.currentLink === methodName;
  }
  onCallMethod(methodName: string) {
    this.currentLink = methodName;
    switch (methodName) {
      case 'onClickDashBoard': {
        this.router.navigate(['/dashboard']);
        break;
      }
      case 'onClickPayroll': {
        this.router.navigate([]);
        break;
      }

      case 'onClickMaster': {
        this.router.navigate(['/statutory']);
        break;
      }
      case 'onClickSites': {
        this.router.navigate(['/manage-site']);
        break;
      }
      case 'onClickEmployees': {
        this.router.navigate(['/list-employee']);
        break;
      }
      case 'onClickPayslip': {
        this.router.navigate(['/payslip']);
        break;
      }

      case 'onClickPO&Invoicing': {
        this.router.navigate(['/vendor-invoice']);
        break;
      }
      case 'onClickAccounts': {
        break;
      }
      case 'onClickQuotation': {
        this.router.navigate([]);
        break;
      }
      case 'onClickSiteExpenceManagement': {
        break;
      }
      case 'onClickReports&Analytics': {
        break;
      }
    }
  }
  onClickAdminDashboard() {
    this.router.navigate(['/admin-dashboard']);
  }
  onClickLogout() {
    this.auth.forceLogout();
    this.router.navigate(['/login']);
  }
  clickAccount() {
    this.accounts = !this.accounts;
  }
  clickInvoice() {
  this.submenu = !this.submenu;
}
clickPayroll() {
  this.submenum = !this.submenum;
}
convertString(userName: string) {
  let newStatus = '';
  return newStatus = userName.replace(/[&\/\\#, +()$~%.'":*?<>{}_]/g, ' ');
}
@HostListener('window:resize', ['$event'])
onResize(event: any) {
  this.classApplied =window.innerWidth >= 768? true: false;
 }
}


