<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Purchase Order & Invoice</div>
  <div class="px-lg-5 py-5 px-4 minHeight80vh bg-white m-lg-3 font-14">
    <form [formGroup]="searchForm" (ngSubmit)="onSearchInvoiceData()">
      <div class="row">
        <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 invoiceTab">
          <div class="row">
            <div class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 p-lg-0">
              <div class="pb-2 font-14 pt-2">Invoice List</div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-3">
            </div>
            <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div class="pb-4 font-16 position-relative">
                <input type="text" placeholder="Search with Invoice Number" formControlName="keyword"
                  class="manageUserSelectBox w-100 height-30 pl-5 pr-3 font-12 light darkGreyFont pointer" />
                <img alt="search" src="../../assets/img/magnifying-glass.svg" class="position-absolute searchIcon" />
              </div>
            </div>
            <div *ngIf="dataService.adminStatus1 === true"
              class="col-3 col-xl-1 col-lg-1 col-md-2 col-sm-3 p-0 addIcon">
              <img (click)="onClickAddInvoice()" alt="add" src="../../assets/img/plus.svg"
                class="addIcon w-100 pointer " />
            </div>
          </div>
        </div>
      </div>
    </form>
    <form [formGroup]="invoiceListForm">
      <div class="pb-4 font-12">
        <div class="pb-5">

          <div class="d-none d-sm-none d-md-none d-lg-block d-xl-block">
            <div class="row medium py-3">
              <div class="col-2">Invoice Number</div>
              <div class="col-2">Invoice Date</div>
              <div class="col-1">Edit</div>
              <div class="col-1">View</div>
              <div class="col-2">Amount Received Date</div>
              <div class="col-2"></div>
            </div>
            <div formArrayName="invoices" *ngFor="let a of invoicesFormArray.controls; let i = index">
              <div [formGroupName]="i" class="row py-2 light">
                <div class="col-2 pointer">{{allInvoiceData[i].invoiceNumber}}</div>
                <div class="col-2"> {{allInvoiceData[i].invoiceDate | date: 'dd/MM/yyyy'}}
                </div>
                <div class="col-1">
                  <img alt="Edit" src="../../assets/img/pencil.svg" class="width-20 pointer"
                    (click)="editinvoiceList(allInvoiceData[i].invoiceId)" />
                </div>
                <div class="col-1 pointer">
                  <a [href]="openPdf(allInvoiceData[i])" target="_blank" class="text-dark">
                    <img alt="Download Pdf" title="invoice Pdf" src="../../assets/img/pdf.svg"
                      class="searchIcon mr-3" />
                  </a>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <input type="text" bsDatepicker formControlName="amountReceivedDate" placeholder="Select Date"
                      type="text" class="font-12 bold w-100 light height-30 px-3 inputBoxAddUser"
                      [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY'}">
                    <div class="error "
                      *ngIf="invoicesFormArray.get([i,'amountReceivedDate'])?.invalid && invoicesFormArray.get([i,'amountReceivedDate'])?.dirty">
                      <span *ngIf="invoicesFormArray.get([i,'amountReceivedDate'])?.hasError('required')">Select
                        Date</span>
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <button class="font-12 border-0 saveBtn redBG text-white light pointer"
                    (click)="onSubmitInvoiceDate(allInvoiceData[i].invoiceId,i)">Save</button>
                </div>
              </div>
            </div>
          </div>

          <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12" formArrayName="invoices"
                *ngFor="let a of invoicesFormArray.controls; let i = index">
                <div class="cardStyle my-3" [formGroupName]="i">
                  <div class="font-14 bold text-dark py-2">
                    <div class="font-12 text-grey light pb-1">Invoice Number :</div>
                    {{allInvoiceData[i].invoiceNumber}}
                  </div>
                  <div class="font-14 bold text-dark py-2">
                    <div class="font-12 text-grey light pb-1">Invoice Date :</div>
                    {{allInvoiceData[i].invoiceDate | date: 'dd/MM/yyyy'}}
                  </div>
                  <div class="font-14 bold text-dark py-2">
                    <div class="font-12 text-grey light pb-1">Amount Received Date :</div> <input type="text"
                      bsDatepicker formControlName="amountReceivedDate" placeholder="Select Date" type="text"
                      class="font-14 bold w-100 light height-30 px-3 inputBoxAddUser"
                      [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY'}">
                    <div class="error "
                      *ngIf="invoicesFormArray.get([i,'amountReceivedDate'])?.invalid && invoicesFormArray.get([i,'amountReceivedDate'])?.dirty">
                      <span *ngIf="invoicesFormArray.get([i,'amountReceivedDate'])?.hasError('required')">Select
                        Date</span>
                    </div>
                  </div>
                  <div class="row pt-4">
                    <div class="col my-1"> <a [href]="openPdf(allInvoiceData[i])" target="_blank" class="text-dark">
                        <img alt="Download Pdf" title="invoice Pdf" src="../../assets/img/pdf.svg"
                          class="searchIcon mr-3" />
                      </a></div>
                    <div class="col my-1" *ngIf="dataService.adminStatus1 === true">
                      <img alt="Edit" src="../../assets/img/pencil.svg" class="width-20 pointer"
                        (click)="editinvoiceList(allInvoiceData[i].invoiceId)" />
                    </div>
                    <div class="col text-right">
                      <button class="font-12 border-0 saveBtn redBG text-white light pointer"
                        (click)="onSubmitInvoiceDate(allInvoiceData[i].invoiceId,i)">Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="this.pageSize < this.totalElements">
        <pagination [totalItems]="totalElements" previousText="&laquo;" nextText="&raquo;" [itemsPerPage]="50"
          [maxSize]="10" ({ngModel})="currentPage" (pageChanged)="pageChanged($event)"></pagination>
      </div>
    </form>
  </div>
</div>
