import { NgModule } from '@angular/core';
import { MenuComponent } from './menu/menu.component';


@NgModule({
  declarations: [],
  imports: [
  ],
  exports: []
})
export class MenuModule { }
