import { Component, OnInit, ViewChild, ViewChildren, AfterViewInit, AfterViewChecked } from '@angular/core';
import { VendorService } from '../services/vendor.service';
import { CustomerService } from '../services/customer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-vendor-invoice',
  templateUrl: './vendor-invoice.component.html',
  styleUrls: ['./vendor-invoice.component.scss'],
})
export class VendorInvoiceComponent implements OnInit , AfterViewInit  {
  @ViewChild('tabs') tabs: TabsetComponent | undefined;
  allVendorData: any;
  allCustomerData: any;
  vendorSearchData: string = '';
  customerSearchData: string = '';
  orginalAllCustomerData: any;
  orginalAllVendorData: any;
  constructor(private route: ActivatedRoute, private router: Router, private vendorService: VendorService, private customerService: CustomerService) {}
  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((el: any) => {
      if (el.type === '1' ) {
       setTimeout( () => {
        this.tabs ? this.tabs.tabs[0].active = true : '';
       });
      } else {
        setTimeout( () => {
          this.tabs ? this.tabs.tabs[1].active = true : '';
      });
      }
    });
  }
  ngOnInit() {

    this.customerService.getCustomer().subscribe(ele => {
      this.allCustomerData = ele.response.content;
      this.orginalAllCustomerData = ele.response.content;
    });
    this.vendorService.getVendors().subscribe(ele => {
      this.allVendorData = ele.response.content;
      this.orginalAllVendorData = ele.response.content;
    });
  }
  onSearchVendorData(keyword:string) {
    if (keyword.length >= 3) {
      this.vendorService.searchVendor(keyword).subscribe(ele => {
        this.allVendorData = ele.response.content;
      });
    } else if (keyword.length === 0) {
      this.allVendorData = this.orginalAllVendorData;
    }
  }
  onSearchCustomerData(keyword: string) {
    if (keyword.length >= 3) {
      this.customerService.searchCustomer(keyword).subscribe(ele => {
        this.allCustomerData = ele.response.content;
      });
    } else if (keyword.length === 0) {
      this.allCustomerData = this.orginalAllCustomerData;
    }
  }
  onClickAddVendor() {
    this.router.navigate(['/add-vendor']);
  }
  onClickAddCustomer() {
    this.router.navigate(['/add-customer']);
  }
  onClickEditCustomer(id: string) {
    this.router.navigate(['/add-customer'], { queryParams: { id } });
  }
  onClickVendor(id: string) {
    this.router.navigate(['/add-vendor'], { queryParams: { id } });

  }
}
