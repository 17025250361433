import { Injectable } from '@angular/core';
import { RestService } from '../core/rest.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VendorService {
  constructor(public restService: RestService) {}

  searchVendor(name: string): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/vendors/search/' + name, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getVendors(): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/vendors', null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getVendorsbycustomerId(customerId: string): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/invoices/generate?customerId=' + customerId, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getVendorById(id: string): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/vendors/' + id, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  addVendors(data:any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/vendors', data).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  editVendors(id: string, data:any): Observable<any> {
    return this.restService.put(Environment.UrlPath + '/vendors/' + id, data).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  genarateInvoices(data:any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/invoices/excelUpload', data, false, true).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
}
