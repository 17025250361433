import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AmountService } from '../services/amount.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { saveAs } from 'file-saver';
import { ValidateAllFormFields } from '../utils/validateform';
import { DataService } from '../services/data.service';
import { Environment } from 'src/environments/environment';
;
@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {
  id;
  modalRef!: BsModalRef;
  employeeDetails: any;
  searchByDateForm!: FormGroup;
  rejectRemarkForm!: FormGroup;
  range: any = [];
  month: any;
  year: any;
  selectedMonth: any;
  allTransaction: any;
  employeeId: any;
  transactionId: any;
  transactionIndex: any;
  transactionStatus: any;
  remarks: any;
  dashboardMonth: string;
  dashboardYear: string;
  selectedYear: any;
  balanceInHand: string = '';
  totalElements = 0;
  pageSize = 50;
  currentPage = 1;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private amountService: AmountService,
              private formBuilder: FormBuilder,
              private modalService: BsModalService,
              public dataService: DataService
              ) {
    this.id = this.route.snapshot.queryParamMap.get('id');
    this.dashboardMonth = this.route.snapshot.queryParamMap.get('month') || '';
    this.dashboardYear = this.route.snapshot.queryParamMap.get('year') || '';

  }

  ngOnInit() {
    this.year = 2020;
    this.setRange();
    this.selectedYear = this.dashboardMonth ? (this.range.filter((res: any) => res.name === Number(this.dashboardYear)))[0].id : new Date().getFullYear();
    this.selectedMonth = this.dashboardMonth ? (this.month.filter((res: any) => res.name === this.dashboardMonth))[0].id : new Date().getMonth() + 1;
    this.getEmployeeDetails();
    this.initAccountDetailForm();
    this.searchByDateForm.valueChanges.subscribe(() => {
      this.onSearch();
    });
    this.rejectRemarkForm = this.formBuilder.group({
      remarks: [''],
    });
    this.remarks = this.rejectRemarkForm.get('remarks');
  }
  get initIndex() {
    let index = 0;
    index = Number((this.pageSize * (this.currentPage - 1 )));
    return index;
  }
  setRange() {
    this.month = [
      { id: 1, name: 'January' },
      { id: 2, name: 'February' },
      { id: 3, name: 'March' },
      { id: 4, name: 'April' },
      { id: 5, name: 'May' },
      { id: 6, name: 'June' },
      { id: 7, name: 'July' },
      { id: 8, name: 'August' },
      { id: 9, name: 'September' },
      { id: 10, name: 'October' },
      { id: 11, name: 'November' },
      { id: 12, name: 'December' },
    ];
    for (let i = 0; i < 21; i++) {
      this.range.push({
        id: this.year + i,
        name: this.year + i,
      });
    }
  }
  initAccountDetailForm() {
    this.searchByDateForm = this.formBuilder.group({
      year: [this.selectedYear, Validators.compose([Validators.required])],
      month: [this.selectedMonth, Validators.compose([Validators.required])],
    });
  }
  getEmployeeDetails() {
    this.amountService.getSupervisorDetails(this.id).subscribe(res => {
      this.employeeDetails = res.response;
      this.employeeId = this.employeeDetails.employeeId;
      this.onSearch();
    });

  }
 onSearch() {
  let selectedMonth;
  if (this.searchByDateForm.value.month !== '') {
    selectedMonth = this.month[this.searchByDateForm.value.month - 1].name;
  } else {
    selectedMonth = '';
  }
  const data = {
    employeeId: this.employeeDetails.employeeId,
    month: selectedMonth,
    year: this.searchByDateForm.value.year,
    pageNumber: this.currentPage,
    pageSize: this.pageSize
  };
  this.getBalanceInHandById(selectedMonth, this.searchByDateForm.value.year);
  this.amountService.supervisorTransaction(data).subscribe(res => {
    this.totalElements = res.response.totalElements
    this.allTransaction = res.response.content;
  });
 }
 getBalanceInHandById(selectedMonth: any, year: any) {
  this.amountService.getBalanceInHand(this.employeeDetails.employeeId, selectedMonth, year).subscribe((res => {
    this.balanceInHand = res.response;
  }));
 }
 onClickAddTransaction() {
  this.router.navigate(['add-amount'], {queryParams: { id: this.id , employeeId: this.employeeId} });
 }
 onTransactionStatus(status: any, id: any, index: any, template: any) {
  this.transactionStatus = status;
  this.transactionId = id;
  this.transactionIndex = index;
  if (status === 'REJECTED') {
    this.remarks.setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(500)]);
    this.modalRef = this.modalService.show(template);
  } else {
    this.remarks.setValidators([]);
    this.onConfirmStatusChange();
}
  this.remarks.updateValueAndValidity();
}
onConfirmStatusChange() {
  const rejectionRemarks = this.rejectRemarkForm.value.remarks;
  if (this.rejectRemarkForm.valid) {
  const request = {
    transactionStatus: this.transactionStatus,
    transactionId: this.transactionId,
    rejectionRemarks
  };
  this.amountService.changeTransactionStatus(request).subscribe((res) => {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.balanceInHand = res.response.balance;
    this.rejectRemarkForm.reset();
    this.allTransaction[this.transactionIndex] = res.response;
    });
} else {
  ValidateAllFormFields(this.rejectRemarkForm);
}
}
onRemoveRemarks() {
  this.modalRef.hide();
  this.rejectRemarkForm.reset();
}
downloadImage(data: any) {
  this.amountService.downloadTransactionImage(data).subscribe((ele) => {
    if (ele.type === 'image/jpeg') {
      window.open( Environment.UrlPath + '/transactions/download/' + data);
    }});
   }
   downloadTransactionHistory() {
    let fileName: any;
    const month = this.month[this.searchByDateForm.value.month - 1].name;
    const year = this.searchByDateForm.value.year;
    fileName = `TransactionHistory-${this.employeeId}-${month}-${year}.xlsx`;
    this.amountService.downloadtransactionHistory(this.employeeId, month, year).subscribe(res =>   {
      if (res) {
        const file = new File(
            [res],
            fileName,
            { type: 'application/vnd.ms.excel' }
          );
        saveAs(file);
  }
});
   }
   pageChanged(event: any) {
    this.currentPage = event.page;
    this.onSearch();
  }
}
