import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup, FormArray } from '@angular/forms';
import { ValidateAllFormFields } from '../utils/validateform';
import { CustomerService } from '../services/customer.service';
import { PurchaseService } from '../services/purchase.service';
import { VendorService } from '../services/vendor.service';
import { Router, ActivatedRoute } from '@angular/router';
var writtenNumber = require('written-number');
@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  invoiceStage = false;
  invoiceGenerateForm!: FormGroup;
  invoiceUploadForm!: FormGroup;
  invoiceForm!: FormGroup;
  allCustomerData: any;
  allVendorData: any;
  invoiceNumber: any;
  vendorId: string = '';
  customerId: string = '';
  selectedCustomerData: any;
  selectedVendorData: any;
  selectedPurchaseData: any;
  isPreview = false;
  subTotal:any;
  totalAmount:any;
  amountInWords: any;
  amount :any[]= [];
  quantityChange :any[] = [];
  unitRateChange :any[] = [];
  cgst = '0';
  igst = '0';
  sgst = '0';
  file: File|null = null;
  fileForm:any;
  showFiletypeError: boolean = false;
  constructor(private purchaseService: PurchaseService, private customerService: CustomerService , private vendorService: VendorService, private router: Router , private route: ActivatedRoute, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.getCustomerList();
    this.initGenerateForm();
    this.invoiceUploadForm = this.formBuilder.group({
      file: ['', Validators.compose([Validators.required])],
    });
  }
  calculateTotal() {
    if ( this.allVendorData.address.state === this.selectedCustomerData.address.state) {
      this.sgst = Number((this.subTotal * 9) / 100).toFixed(0);
      this.cgst = Number((this.subTotal * 9) / 100).toFixed(0);
    } else {
      this.igst = Number((this.subTotal * 18) / 100).toFixed(0);
    }
    const cess = this.invoiceForm.controls['cess'].value;
    this.totalAmount = (Number(this.igst) + Number(this.sgst) + Number(this.cgst) + Number(cess) + Number(this.subTotal)).toFixed(0);
    this.amountInWords = writtenNumber(this.totalAmount);
  }
  amountChanges() {
    for (let i = 0; i < this.quantityChange.length; i++) {
        this.amount[i] = (this.quantityChange[i] * this.unitRateChange[i]).toFixed(2);
      }
    this.subTotal = 0;
    this.amount.forEach((element) => {
      this.subTotal = (Number(element) + Number(this.subTotal)).toFixed(2);
    });
    this.calculateTotal();
}
onQuantityChange(event: any,index: number) {
  this.quantityChange[index] = event.value;
  if(this.unitRateChange.length === this.quantityChange.length) {
    this.amountChanges();
  }
}
onUnitRateChange(event: any,index: number) {
  this.unitRateChange[index] = event.value;
  if (this.unitRateChange.length === this.quantityChange.length ) {
    this.amountChanges();
  }
}
  getCustomerList() {
    this.customerService.getCustomer().subscribe((ele) => {
      this.allCustomerData = ele.response.content;

    });
  }
  getVendorList(customerId: any) {
    this.vendorService.getVendorsbycustomerId(customerId).subscribe((ele) => {

    this.invoiceNumber = ele.response.invoiceNumber;
    this.allVendorData = ele.response.vendorId;

    });
  }
  initGenerateForm() {
    this.invoiceForm = this.formBuilder.group({
      invoiceDate: new FormControl(null, [
        Validators.required,
      ]),
      InvoicePoNumber: new FormControl(null, [
        Validators.required
      ]),
      InvoiceReferanceNumber: new FormControl(null, [
        Validators.required
      ]),
      InvoicePurchaseOrderDate:  new FormControl(null, [
        Validators.required
      ]),
      cess: new FormControl(0, [
      ]),
      shipToAddress: new FormControl(null, []),
      shipToCity: new FormControl(null, []),
      shipToState: new FormControl(null, []),
      shipToCountry: new FormControl(null, [
      ]),
      invoices: new FormArray([]),
      shipToPincode: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^\\d+$'),
          Validators.minLength(6),
          Validators.maxLength(6),
        ]),
      ],
    });
    this.invoiceGenerateForm = this.formBuilder.group({
      customerId: new FormControl('', [
        Validators.required,
      ]),
    });
    this.addNewInvoice();
  }
  onSubmitInvoiceGenerateForm(value: any) {
    if (this.invoiceGenerateForm.valid) {
      this.selectedCustomerData = this.allCustomerData.find((ele: any) => ele.customerId === value.customerId);
      this.customerId = value.customerId;
      this.getVendorList(this.customerId);
      this.invoiceStage = true;
    } else {
      ValidateAllFormFields(this.invoiceGenerateForm);
    }
  }
  onSubmitInvoiceForm(value: any) {
    if (this.invoiceForm.valid) {
      for (let index = 0; index < this.amount.length; index++) {
        value.invoices[index].amount = this.amount[index];
     }
      const request = {
        invoiceNumber: this.invoiceNumber,
        purchaseOrderNumber : value.InvoicePoNumber,
        referenceNumber : value.InvoiceReferanceNumber,
        invoiceDate : value.invoiceDate,
        customerId: this.customerId,
        shipToAddress: value.shipToAddress ,
        purchaseOrderDate: value.InvoicePurchaseOrderDate ,
        shipToCity: value.shipToCity ,
        shipToState: value.shipToState ,
        shipToCountry: value.shipToCountry ,
        shipToPincode: value.shipToPincode ,
        totalAmountInWords: this.amountInWords ,
        subTotal: this.subTotal ,
        igst: this.igst ,
        sgst: this.sgst ,
        cgst: this.cgst ,
        cess: value.cess ,
        grandTotal: this.totalAmount ,
        itemSpecification: value.invoices
        };
      this.purchaseService.submitInvoice(request).subscribe((ele) => {
       if (ele.status.code === 0) {
          this.router.navigate(['invoice-list']);
          }
  });
} else {
  ValidateAllFormFields(this.invoiceForm);
}
  }
  get totalInWords() {
return writtenNumber(this.selectedPurchaseData.grandTotal);
   }

  get invoicesFormArray() {
    return this.invoiceForm.controls['invoices'] as FormArray;
  }
  onClickCancel() {
    this.router.navigate(['invoice-list']);
  }
  onRemoveFormGroup(i: any) {
    const invoices = this.invoiceForm.controls['invoices'] as FormArray;
    this.quantityChange.splice(i, 1);
    this.unitRateChange.splice(i, 1);
    this.amount = [];
    this.amountChanges();
    invoices.removeAt(i);
    }
  addNewInvoice() {
    const isInvoicesFormArray = this.invoiceForm.controls['invoices'].valid;
    if (isInvoicesFormArray) {
    const invoices = this.invoiceForm.controls['invoices'] as FormArray;
    invoices.push(this.formBuilder.group({
      description: new FormControl(null, [
        Validators.required
      ]),
      uam: new FormControl(null, [
        Validators.required
      ]),
      hsnCode: new FormControl(null, [
        Validators.required
      ]),
      quantity: ['', [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      unitRate: ['', [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      amount: new FormControl(null, []),
    }));
  } else {
    ValidateAllFormFields(this.invoiceForm);
  }
}
deleteForm() {
  this.file = null;
  delete this.fileForm;
  this.fileForm = new FormData();
}
onClear() {
  this.deleteForm();
  this.invoiceUploadForm.reset();
  this.showFiletypeError = false;
}
onSelectedFile(event: any) {
  this.deleteForm();
  if (event.target.files && event.target.files[0]) {
    const  filedata = event.target.files[0];
    if (filedata.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || filedata.type === 'application/vnd.ms-excel') {
      this.showFiletypeError = false;
      this.file = event.target.files[0];
      this.fileForm.append('file', this.file, this.file ?  this.file.name : '');
    } else {
      this.showFiletypeError = true;
    }
  }
}
onUploadInvoice() {
  if (this.invoiceUploadForm.valid && !this.showFiletypeError) {
    this.vendorService.genarateInvoices(this.fileForm).subscribe(res => {
      if (res) {
        if (res.status.code === 0) {
          this.onClear();
          this.invoiceUploadForm.reset();
        }
      }
    });
  } else {
    ValidateAllFormFields(this.invoiceUploadForm);
  }
}
}
