<div class="loginBackground">
  <div class="container">
    <div class="row">
      <div class="col-xs-8 col-lg-8 col-md-6 col-sm-4 col-12"></div>
      <div class="col-xs-4 col-lg-4 col-md-6 col-sm-8 col-12">
        <div class="loginPad loginForm">
          <div class="text-center"><img alt="logo" src="../../assets/img/login-logo.png" class="loginLogo my-3"></div>
          <div class="py-4 px-5 borderRdius10">
            <div class="text-white pt-1 pb-3 text-center bold font-18">Sign in</div>
            <div>
              <form [formGroup]="loginform" (ngSubmit)="onClickLogin()">
                <div class="text-info small"><sup class="font-red"></sup>
                  <div class="row">
                    <div class="col-md-12">
                      <input class="form-control w-100 bg-white my-2" placeholder="Email" formControlName="email" type="text">
                      <div class="error text-white"
                      *ngIf="loginform.controls['email'].invalid && loginform.controls['email'].dirty">
                      <span *ngIf="loginform.controls['email'].errors && loginform.controls['email'].errors['required']">Please enter your email-id</span>
                      <span
                        *ngIf="loginform.controls['email'].errors && loginform.controls['email'].errors['email'] && !loginform.controls['email'].errors['maxlength'] && !loginform.controls['email'].errors['required']">Please
                        enter your email address in format : Yourname@example.com</span>
                    </div>
                    </div>
                    <div class="col-md-12 my-2">
                      <input placeholder="Password"  maxlength="50" type="password" class="form-control w-100 bg-white my-2"
                        name="password" formControlName="password">
                        <div class="error text-white"
                        *ngIf="loginform.controls['password'].invalid &&loginform.controls['password'].dirty">
                        <span *ngIf="loginform.controls['password'].errors && loginform.controls['password'].errors['required']">Please enter the password</span>
                        <span *ngIf="loginform.controls['password'].errors && loginform.controls['password'].errors['minlength']">Password needs to be atleast 5
                          characters long</span>
                        <span *ngIf="loginform.controls['password'].errors && loginform.controls['password'].errors['maxlength']">Password is too long.</span>
                      </div>
                    </div>
                    <div class="text-center w-100 pb-4 pt-3">
                      <a  (click)="openModal(template)" class="font-12 text-white light pointer">Forgot Password
                        ?</a>
                    </div>
                  </div>
                </div>

              <div class="row">
                <div class="col-md-12 text-center">
                  <button class="border-0 px-4 py-1 borderRdius5 bg-black text-white bold pointer" type="submit">Sign
                    in</button> </div>
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!--Forgot Password Modal-->

<ng-template #template>
  <form [formGroup]="modelForm">
    <div class="modal-header">
      <h4 class="modal-title pull-left medium">Forgot Password</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12 font-20">
          <input class="form-control w-100 bg-white mt-3 font-14 light py-2" type="text"
            placeholder="Enter your email ID" formControlName="emailAddress">

            <div
                *ngIf="
                modelForm.controls['emailAddress'].invalid &&
                modelForm.controls['emailAddress'].dirty
                "
              >
                <span class="error" *ngIf="modelForm.controls['emailAddress'].errors && modelForm.controls['emailAddress'].errors['required']"
                  >Official Email address is required</span
                >
                <span
                  class="error"
                  *ngIf="
                  modelForm.controls['emailAddress'].errors &&
                  modelForm.controls['emailAddress'].errors['email'] &&
                  !modelForm.controls['emailAddress'].errors['required']
                  "
                  >Please enter your email address in format <br />
                  for example: Yourname@example.com</span
                >
              </div>

        </div>
        <div class="col-md-12 text-center">
          <button class="border-0 px-4 py-1 borderRdius5 bg-black text-white medium my-4 pointer" type="submit"
            (click)="onClickResetPassword()">Reset my password</button> </div>
      </div>
    </div>
  </form>
</ng-template>
