<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Purchase Order & Invoice</div>
  <div class="px-5 py-4 minHeight80vh bg-white m-4 font-14">
    <div *ngIf="!invoiceStage">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 invoiceTab">
        <tabset #tabs type="pills">
          <tab heading="Invoice Generate">
            <form [formGroup]="invoiceGenerateForm" (ngSubmit)="onSubmitInvoiceGenerateForm(invoiceGenerateForm.value)">
              <div class="row medium font-12 pt-5 pb-2">
                <div class="col-3">
                  <div class="medium py-1">Select Customer</div>
                  <div class="">
                    <select formControlName="customerId" class="font-12 w-100 light height-40 px-3 inputBoxAddUser">
                      <option value="" selected disabled>Customer Name</option>
                      <option *ngFor="let cust of allCustomerData" value="{{cust.customerId}}">{{cust.companyName}}</option>
                    </select>
                    <div class="error "
                      *ngIf="invoiceGenerateForm.get('customerId')?.invalid && invoiceGenerateForm.get('customerId')?.dirty">
                      <span *ngIf="invoiceGenerateForm.get('customerId')?.hasError('required')">Required</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pt-3 pb-2">
                <div class="col-3"><button type="submit"
                    class="font-12 border-0 submitBtn redBG text-white pointer">Generate</button></div>
              </div>
            </form>
          </tab>
          <tab heading="Bulk Invoice Generate">
            <form [formGroup]="invoiceUploadForm">
            <div class="row w-100">
              <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1 col-12"></div>
              <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 col-12">
                <div class="text-center uploadBox my-5 px-5">
                  <h3 class="font-24 regular pt-5 pb-4">Upload Your File</h3>
                  <div class="font-12 light pb-3">Only Excel Files :)</div>
                  <div>
                    <img
                      alt="Excel Only"
                      src="../../assets/img/1excel-icon.png"
                      class="width-60"
                    />
                  </div>
                  <div class="my-4 pointer">
                    <label for="uploadexcel" class="excelUpload w-100 py-4 pointer"
                      >Drag a file here or&nbsp;<span class="orangeFont">Browse</span>&nbsp;for
                      a file to upload</label
                    >
                    <input
                      type="file"
                      class="pointer"
                      id="uploadexcel"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      formControlName="file"
                      (change)="onSelectedFile($event)"
                    />
                    <div *ngIf="this.file">
                      <span>{{ file.name }}</span>
                    </div>
                    <div
                      class=""
                      *ngIf="
                      invoiceUploadForm.controls['file'].invalid &&
                      invoiceUploadForm.controls['file'].dirty
                      "
                    >
                      <span class="error" *ngIf="invoiceUploadForm.controls['file'].errors?.['required']"
                        >Please select file.</span
                      >
                    </div>
                    <div>
                    <span class="error" *ngIf="showFiletypeError === true"
                        >Please upload valid format file.</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <button
                        class="font-14 border-0 submitBtn darkGreyBG text-white mb-5 pointer"
                        type="button"
                        (click)="onClear()"
                      >
                        Cancel
                      </button>
                    </div>
                    <div class="col-6">
                      <button
                        class="font-14 border-0 submitBtn redBG text-white mb-5 pointer"
                        (click)="onUploadInvoice()"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1 col-12"></div>
            </div>
          </form>
          </tab>
        </tabset>
      </div>


    </div>
    <div *ngIf="invoiceStage">
      <div class="my-3">
          <form [formGroup]="invoiceForm" >
            <div class="row medium font-12 pb-3">
              <div class="col">
                <div class="medium pb-1">Invoice Number *</div>
                  <div class="mb-3 darkGreyFont">
                    {{invoiceNumber}}
                  </div>
          </div>
          <div class="col">
            <div class="medium pb-1">Invoice Date *</div>
            <div class="mb-3">
              <input type="text" bsDatepicker  formControlName="invoiceDate"  placeholder="Enter Invoice Date" type="text"
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser"[bsConfig]="{ dateInputFormat: 'DD/MM/YYYY'}">
                <div class="error "
                *ngIf="invoiceForm.get('invoiceDate')?.invalid && invoiceForm.get('invoiceDate')?.dirty">
                <span *ngIf="invoiceForm.get('invoiceDate')?.hasError('required')"> Invoice Date is Required</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="medium pb-1">PO Number *</div>
            <div class="mb-3">
              <input pInputText formControlName="InvoicePoNumber" placeholder="Enter PO Number" type="text"
                  class="font-12 w-100 light height-40 px-3 inputBoxAddUser">
                <div class="error "
                  *ngIf="invoiceForm.get('InvoicePoNumber')?.invalid && invoiceForm.get('InvoicePoNumber')?.dirty">
                  <span *ngIf="invoiceForm.get('InvoicePoNumber')?.hasError('required')">PO Number
                    Required</span> </div>
              </div>
          </div>
          <div class="col">
            <div class="medium pb-1">PO Date *</div>
            <div class="mb-3">
              <input bsDatepicker formControlName="InvoicePurchaseOrderDate" type="text" placeholder="Enter PO Date"
              type="text" class="font-12 w-100 light height-40 px-3 inputBoxAddUser" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY'}">

            <div class="error "
              *ngIf="invoiceForm.get('InvoicePurchaseOrderDate')?.invalid && invoiceForm.get('InvoicePurchaseOrderDate')?.dirty">
              <span *ngIf="invoiceForm.get('InvoicePurchaseOrderDate')?.hasError('required')">PO Date
                Required</span>
            </div>
            </div>
          </div>

          <div class="col">
            <div class="medium pb-1">Reference Number *</div>
            <div class="mb-3">
              <input pInputText formControlName="InvoiceReferanceNumber" placeholder="Enter Reference Number" type="text"
                  class="font-12 w-100 light height-40 px-3 inputBoxAddUser">
                <div class="error "
                  *ngIf="invoiceForm.get('InvoiceReferanceNumber')?.invalid && invoiceForm.get('InvoiceReferanceNumber')?.dirty">
                  <span *ngIf="invoiceForm.get('InvoiceReferanceNumber')?.hasError('required')">Reference number is required</span> </div>
              </div>
          </div>


        </div>

        <div class="row font-12">
          <div class="col-4">
            <div class="bold py-2">Vendor Details</div>
            <div class="row darkGreyFont">
              <div class="col-4">Name</div>
              <div class="col-8 text-uppercase">{{allVendorData?.tenantName}}</div>
            </div>
            <div class="row darkGreyFont">
              <div class="col-4">GSTIN</div>
              <div class="col-8">{{allVendorData?.gstin}}</div>
            </div>
            <div class="row darkGreyFont">
              <div class="col-4">Address</div>
              <div class="col-8 text-uppercase">
                {{allVendorData?.address.addressLine1}},
                {{allVendorData?.address.addressLine2}},
                {{allVendorData?.address.city}},
                {{allVendorData?.address.state}}  {{allVendorData?.address.pinCode}}
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="bold py-2">Customer Details</div>
            <div class="row darkGreyFont">
              <div class="col-4">Name</div>
              <div class="col-8 text-uppercase">{{selectedCustomerData.companyName}}</div>
            </div>
            <div class="row darkGreyFont">
              <div class="col-4">GSTIN</div>
              <div class="col-8">{{selectedCustomerData.gstin}}</div>
            </div>
            <div class="row darkGreyFont">
              <div class="col-4">Address</div>
              <div class="col-8 text-uppercase">{{selectedCustomerData.address.addressLine1}},
                  {{selectedCustomerData.address.addressLine2}},
                  {{selectedCustomerData.address.city}},
                  {{selectedCustomerData.address.state}}  {{selectedCustomerData.address.pinCode}}</div>
            </div>
          </div>
        </div>
        <div class="row medium font-12 pt-4">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Ship to</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12">
                <div class="col">
                  <div class="medium pb-1">Address</div>
                  <div class="mb-3">
                    <input pInputText formControlName="shipToAddress" type="text" placeholder="Enter Address"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                  </div>
                  <div class="error "
                    *ngIf="invoiceForm.get('shipToAddress')?.invalid && invoiceForm.get('shipToAddress')?.dirty">
                    <span *ngIf="invoiceForm.get('shipToAddress')?.hasError('required')">Address is
                      Required</span>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">City</div>
                  <div class="mb-3">
                    <input pInputText formControlName="shipToCity" type="text" placeholder="Enter City Name"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                  </div>
                  <div class="error "
                    *ngIf="invoiceForm.get('shipToCity')?.invalid && invoiceForm.get('shipToCity')?.dirty">
                    <span *ngIf="invoiceForm.get('shipToCity')?.hasError('required')">City is
                      Required</span>
                  </div>
                </div>

                <div class="col">
                  <div class="medium pb-1">State</div>
                  <div class="mb-3">
                    <input pInputText formControlName="shipToState" type="text" placeholder="Enter State Name"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                    <div class="error "
                      *ngIf="invoiceForm.get('shipToState')?.invalid && invoiceForm.get('shipToState')?.dirty">
                      <span *ngIf="invoiceForm.get('shipToState')?.hasError('required')">State is
                        Required</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Country</div>
                  <div class="mb-3">
                    <input pInputText formControlName="shipToCountry" type="text" placeholder="Enter Country Name"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                    <div class="error "
                      *ngIf="invoiceForm.get('shipToCountry')?.invalid && invoiceForm.get('shipToCountry')?.dirty">
                      <span *ngIf="invoiceForm.get('shipToCountry')?.hasError('required')">Country is
                        Required</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Pincode *</div>
                  <div class="mb-3">
                    <input
                      placeholder="Enter Pincode"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="shipToPincode"
                    />
                    <div
                      *ngIf="
                      invoiceForm.get('shipToPincode')?.invalid && invoiceForm.get('shipToPincode')?.dirty
                      "

                    >
                      <span class="error" *ngIf="invoiceForm.get('shipToPincode')?.hasError('required')"
                        >Pincode is required</span
                      >
                      <span class="error" *ngIf="invoiceForm.get('shipToPincode')?.hasError('pattern')"
                        >Pincode can only be in numbers</span
                      >
                      <span
                        class="error"
                        *ngIf="
                          !invoiceForm.get('shipToPincode')?.hasError('pattern') &&
                          !invoiceForm.get('shipToPincode')?.hasError('required') &&
                          invoiceForm.get('shipToPincode')?.hasError('minlength')
                        "
                        >Invalid pincode.</span
                      >
                      <span
                        class="error"
                        *ngIf="
                          !invoiceForm.get('shipToPincode')?.hasError('pattern') &&
                          !invoiceForm.get('shipToPincode')?.hasError('required') &&
                          invoiceForm.get('shipToPincode')?.hasError('maxlength')
                        "
                        >Invalid pincode.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="font-12 light">
          <div class="row text-white bg-darkGrey py-1 mx-0 mb-2 mt-4">
            <div class="col-1"></div>
            <div class="col-1">Sl No.</div>
            <div class="col-3">Description *</div>
            <div class="col">UAM *</div>
            <div class="col">HSN / SAC Code *</div>
            <div class="col">Quantity *</div>
            <div class="col">Unit Price*</div>
            <div class="col">Amount *</div>
            <div class="col-1"></div>
          </div>

          <div class="row mx-0 py-2">
            <div formArrayName="invoices" *ngFor="let a of invoicesFormArray.controls; let i = index" class="col-11">
              <div [formGroupName]="i" class="row">
                <div class="col-1">
                  <button class="transparent-bg border-0 p-0 m-0" *ngIf=" i >= 1" (click)="onRemoveFormGroup(i)"><img
                      title="Remove" alt="remove" src="../../assets/img/delete.svg"
                      class="height-30 pointer p-1">
                  </button>
                </div>
                <div class="col-1 py-1">{{i+1}}</div>
                <div class="col-3">
                  <textarea pInputTextarea formControlName="description"
                    class="w-100 light height-30 px-3 py-1 inputBoxAddUser font-12">
                  </textarea>
                  <div class="error"
                    *ngIf="invoicesFormArray.get([i,'description'])?.invalid && invoicesFormArray.get([i,'description'])?.dirty">
                    <span *ngIf="invoicesFormArray.get([i,'description'])?.hasError('required')">Description is
                      Required</span>
                  </div>
                </div>
                <div class="col"><input pInputText formControlName="uam" type="text" placeholder=""
                    class="w-100 light height-30 px-3 inputBoxAddUser font-12">
                  <div class="error "
                    *ngIf="invoicesFormArray.get([i,'uam'])?.invalid && invoicesFormArray.get([i,'uam'])?.dirty">
                    <span *ngIf="invoicesFormArray.get([i,'uam'])?.hasError('required')">UAM is Required</span>
                  </div>
                </div>
                <div class="col"><input pInputText formControlName="hsnCode" type="text" placeholder=""
                    class="w-100 light height-30 px-3 inputBoxAddUser font-12">
                  <div class="error "
                    *ngIf="invoicesFormArray.get([i,'hsnCode'])?.invalid && invoicesFormArray.get([i,'hsnCode'])?.dirty">
                    <span *ngIf="invoicesFormArray.get([i,'hsnCode'])?.hasError('required')">HSN / SAC Code is
                      Required</span>
                  </div>
                </div>
                <div class="col"><input (input)="onQuantityChange($event.target,i)" pInputText formControlName="quantity" type="number" placeholder=""
                  class="w-100 light height-30 px-3 inputBoxAddUser font-12">
                <div class="error "
                  *ngIf="invoicesFormArray.get([i,'quantity'])?.invalid && invoicesFormArray.get([i,'quantity'])?.dirty">
                  <span *ngIf="invoicesFormArray.get([i,'quantity'])?.hasError('required')">Quantity is
                    Required</span>
                    <span *ngIf="invoicesFormArray.get([i,'quantity'])?.hasError('pattern')"
                    >Quantity is invalid</span
                  >
                </div>
              </div>
              <div class="col"><input (input)="onUnitRateChange($event.target,i)" pInputText formControlName="unitRate" type="number" placeholder=""
                  class="w-100 light height-30 px-3 inputBoxAddUser font-12">
                <div class="error "
                  *ngIf="invoicesFormArray.get([i,'unitRate'])?.invalid && invoicesFormArray.get([i,'unitRate'])?.dirty">
                  <span *ngIf="invoicesFormArray.get([i,'unitRate'])?.hasError('required')">Unit Price is
                    Required</span>
                    <span *ngIf="invoicesFormArray.get([i,'unitRate'])?.hasError('pattern')"
                    >Unit Price is invalid</span
                  >
                </div>
              </div>
              <div class="col">
                {{amount[i]}}
              </div>
              </div>
            </div>
            <div class="col-1">
              <img (click)="addNewInvoice()" alt="ADD" src="../../assets/img/plus.svg" class="height-30 pointer">
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-9">
            <div class="bold pt-5">Total Amount in Words</div>
            <div class="medium darkGreyFont py-2 text-capital">{{amountInWords}}</div>
          </div>
          <div class="col-3 font-12 my-3">
            <div class="row py-1">
              <div class="col-6">Sub Total</div>
              <div class="col-6">{{subTotal}}</div>
            </div>
            <div class="row py-1">
              <div class="col-6">IGST 18%</div>
              <div class="col-6"> {{igst}}

                    </div>
            </div>
            <div class="row py-1">
              <div class="col-6">SGST 9%</div>
              <div class="col-6"> {{sgst}}
                    </div>

            </div>
            <div class="row py-1">
              <div class="col-6">CGST 9%</div>
              <div class="col-6"> {{cgst}}
                    </div>
            </div>
            <div class="row py-1 pb-3">
              <div class="col-6">CESS</div>
              <div class="col-6"> <input  (blur)="calculateTotal()" pInputText formControlName="cess" type="number" placeholder=""
                    class="w-100 light inputBoxPurchase px-1 font-12">
                  </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Total</div>
              <div class="col-6"> {{totalAmount}}</div>
            </div>
          </div>
        </div>


        <hr>

        <div class="row medium pb-3">
          <div class="col-4">
            <div class="medium pb-1">Company's Bank Details :</div>
            <div class="mb-3 regular">
              <div class="row darkGreyFont font-12">
                <div class="col-6">Bank Name :</div>
                <div class="col-6">{{allVendorData?.accountDetails?.bankId.bankName}}</div>
              </div>
              <div class="row darkGreyFont font-12">
                <div class="col-6">Account Number :</div>
                <div class="col-6">{{allVendorData?.accountDetails?.accountNumber}}</div>
              </div>
              <div class="row darkGreyFont font-12">
                <div class="col-6">IFSC Code :</div>
                <div class="col-6">{{allVendorData?.accountDetails?.ifscCode}}</div>
              </div>
              <div class="row darkGreyFont font-12">
                <div class="col-6">Branch :</div>
                <div class="col-6">{{allVendorData?.accountDetails?.branchName}}</div>
              </div>
              <div class="row darkGreyFont font-12">
                <div class="col-6">PAN :</div>
                <div class="col-6">{{allVendorData?.pan}}</div>
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class="medium pb-1">Note</div>
            <div class="mb-3 darkGreyFont font-12 regular">
              <div class="mb-3">
              <label>Certified that the particular's given above are true & correct & the amount indicated represents the price actually charged & that there is no flow of additional consideration directly or indirectly from the buyer.</label>
                </div>
            </div>
          </div>
        </div>

        <div *ngIf="!isPreview" class="row medium font-12">
          <div class="col-12">
            <div class="text-right pt-5"><button type="button"
              (click)="onClickCancel()"  class="font-12 border-0 mr-5 submitBtn darkGreyBG text-white pointer">Cancel</button><button
                (click)="onSubmitInvoiceForm(invoiceForm.value)" class="font-12 border-0 submitBtn redBG text-white pointer">Submit</button>
            </div>
          </div>
        </div>
        <div *ngIf="isPreview" class="row medium font-12">
          <div class="col-12">
            <div class="text-right pt-5"><button type="button"
              (click)="onClickCancel()"  class="font-12 border-0 mr-5 submitBtn darkGreyBG text-white pointer">Close</button>
              </div>
          </div>
        </div>
        </form>
      </div>
    </div>



