<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Purchase Order and Invoice</div>
  <div class="px-5 py-5 minHeight80vh bg-white m-4 font-14">
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 invoiceTab">
        <tabset #tabs type="pills">
          <tab heading="Vendor">
            <div class="row">
              <div class="col-xl-4 col-lg-5 col-md-6">
                <div class="my-4 font-16 position-relative">
                  <input  (ngModelChange)="onSearchVendorData($event)" [(ngModel)]="vendorSearchData" type="text" placeholder="Search with Vendor Name"
                    class="manageUserSelectBox w-100 height-30 pl-5 pr-3 font-12 light darkGreyFont pointer">
                  <img alt="search" src="../../assets/img/magnifying-glass.svg" class="position-absolute searchIcon">
                </div>
              </div>
              <div  class="col-xl-8 col-lg-7 col-md-6 text-right">
                <img (click)="onClickAddVendor()" alt="add" src="../../assets/img/plus.svg" class="addIcon w-100 pointer my-4">
              </div>
            </div>
            <div class="pb-4 font-12">
              <div class="pb-5">
                <div class="row medium py-3">
                  <div class="col-2">Vendor Name</div>
                  <div class="col-2">Address</div>
                  <div class="col-2">GSTIN</div>
                  <div class="col-2">CIN</div>
                  <div class="col-2">PAN</div>
                  <div class="col-1">Contact Number</div>
                  <div class="col-1">Edit</div>
                </div>
                <div *ngFor="let vendor of allVendorData" class="row py-2 light">

                  <div  class="col-2">{{vendor.vendorName}}</div>
                  <div class="col-2">{{vendor.address.addressLine1}}, {{vendor.address.addressLine2}}, {{vendor.address.city}},{{vendor.address.state}}
                  </div>
                  <div class="col-2">{{vendor.gstin}}
                  </div>
                  <div class="col-2">{{vendor.cin}}</div>
                  <div class="col-2">{{vendor.pan}}</div>
                  <div class="col-1">{{vendor.mobileNumber}}</div>
                  <div class="col-1 pointer"><img alt="Edit" src="../../assets/img/pencil.svg" class="width-20 pointer"
                    (click)="onClickVendor(vendor.vendorId)"/></div>

                </div>
              </div>
            </div>
          </tab>



          <tab heading="Customer">
            <div class="row">
              <div class="col-xl-4 col-lg-5 col-md-6">
                <div class="my-4 font-16 position-relative">
                  <input   (ngModelChange)="onSearchCustomerData($event)" [(ngModel)]="customerSearchData"  type="text" placeholder="Search with Customer Name"
                    class="manageUserSelectBox w-100 height-30 pl-5 pr-3 font-12 light darkGreyFont pointer">
                  <img alt="search" src="../../assets/img/magnifying-glass.svg" class="position-absolute searchIcon">
                </div>
              </div>
              <div class="col-xl-8 col-lg-7 col-md-6 text-right">
                <img (click)="onClickAddCustomer()" alt="add" src="../../assets/img/plus.svg" class="addIcon w-100 pointer my-4">
              </div>
            </div>
            <div class="pb-4 font-12">
              <div class="pb-5">
                <div class="row medium py-3">
                  <div class="col-2">Customer Name</div>
                  <div class="col-2">Address</div>
                  <div class="col-2">GSTIN</div>
                  <div class="col-2">CIN</div>
                  <div class="col-2">PAN</div>
                  <div class="col-1">Contact Number</div>
                  <div class="col-1">Edit</div>
                </div>
                <div  *ngFor="let cust of allCustomerData" class="row py-2 light">

                  <div  class="col-2">{{cust.companyName}}</div>
                  <div class="col-2">{{cust.address.addressLine1}}, {{cust.address.addressLine2}}, {{cust.address.city}},{{cust.address.state}}
                  </div>
                  <div class="col-2">{{cust.gstin}}
                  </div>
                  <div class="col-2">{{cust.cin}}</div>
                  <div class="col-2">{{cust.pan}}</div>
                  <div class="col-1">{{cust.mobileNumber}}</div>
                  <div class="col-1 pointer"><img alt="Edit" src="../../assets/img/pencil.svg" class="width-20 pointer"
                    (click)="onClickEditCustomer(cust.customerId)"/></div>
                </div>
              </div>
            </div>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</div>
