import { Component, OnInit } from '@angular/core';
import { PurchaseService } from '../services/purchase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { VendorService } from '../services/vendor.service';
import { CustomerService } from '../services/customer.service';
import { Environment } from 'src/environments/environment';
;
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ValidateAllFormFields } from '../utils/validateform';
import { debounceTime } from 'rxjs/operators';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {
  orginalAllInvoiceData: any;
  allInvoiceData: any;
  invoiceid: any;
  searchForm :any;
  totalElements = 0;
  currentPage = 1;
  pageSize = 50;
  invoiceListForm!: FormGroup;
  constructor(private purchaseService: PurchaseService,
              private route: ActivatedRoute,
              private router: Router,
              private vendorService: VendorService,
              private customerService: CustomerService,
              private formBuilder: FormBuilder,
              public dataService: DataService) {}

  ngOnInit() {
    this.initInvoiceListForm();
    this.getAllOrders();
    this.onSearchForm();
    this.onSearchInvoiceData();
  }
  pageChanged(event: any) {
    this.currentPage = event.page;
    this.getAllOrders();
  }
  getAllOrders() {
    this.allInvoiceData = [];
    this.orginalAllInvoiceData = null;
    const con = this.invoiceListForm.controls['invoices'] as FormArray;
    const arrlength = con.length;
    for (let i = 0; i <= arrlength; i++) {
       con.removeAt(0);
        }
    this.purchaseService.getAllInvoicesOrders(this.currentPage, this.pageSize).subscribe(ele => {
      this.allInvoiceData = ele.response.content;
      this.totalElements = ele.response.totalElements;
      this.orginalAllInvoiceData = ele.response.content;
      this.allInvoiceData.forEach((element: any) => {
        this.invoiceItems(element);
      });
    });
  }
  onSearchForm() {
    this.searchForm = this.formBuilder.group({
      keyword: new FormControl('')
    });
  }
  initInvoiceListForm() {
    this.invoiceListForm = this.formBuilder.group({
      invoices: new FormArray([]),
    });
  }
  onClickAddInvoice() {
    this.router.navigate(['/invoice']);
  }
  onClickInvoice(data: any) {
    this.router.navigate(['/invoice'], {queryParams: {id: data.invoiceId} });

  }
  invoiceItems(data: any) {
    const ivoices = this.invoicesFormArray;
    ivoices.push(this.formBuilder.group({
      amountReceivedDate: new FormControl(data.paymentStatus === 'RECEIVED' ? new Date(data.paymentRecievedDate) : '',[Validators.required])
    }))
  }
  get invoicesFormArray() {
    return this.invoiceListForm.controls['invoices'] as FormArray;
  }
  onSearchInvoiceData() {
    this.searchForm.controls.keyword.valueChanges.pipe(debounceTime(200)).subscribe((value: any) => {
      if (value.length >= 1 ) {
        this.purchaseService.searchInvoiceOrders(value).subscribe(ele => {
          if (ele.status.code === 0) {
              this.allInvoiceData = ele.response.content;
              this.totalElements = 0;
              this.currentPage = 1;
              const con = this.invoiceListForm.controls['invoices'] as FormArray;
              const arrlength = con.length;
              for (let i = 0; i <= arrlength; i++) {
              con.removeAt(0);
              }
              this.allInvoiceData.forEach((element: any) => {
                this.invoiceItems(element);
              });
            }
          });
        } else {
          this.currentPage = 1;
          this.getAllOrders();
        }
    });
  }
  editinvoiceList(id: any) {
    this.router.navigate(['/edit-invoice'], {queryParams: {id}});
  }
  downloadInvoicePdf(data: any) {
    this.purchaseService.getInvoiceOrderPdf(data.invoiceId).subscribe(res => {
      window.open('/resources/' + data.purchaseOrderNumber + '.pdf', '_blank');
    });
  }
  openPdf(data: any) {
    return Environment.UrlPath + '/invoices/pdfDownload/' + data.invoiceId;
  }
  onSubmitInvoiceDate(id: any, index: any) {
    if (this.invoicesFormArray.controls[index].valid) {
      const request = {
        paymentRecievedDate: this.invoicesFormArray.value[index].amountReceivedDate
      }
      this.purchaseService.submitEditInvoice(request, id).subscribe((ele) => {
        if (ele.status.code === 0) {
           this.router.navigate(['invoice-list']);
           }
   });
    } else {
      ValidateAllFormFields(this.invoicesFormArray.controls[index] as FormGroup)
    }
  }
}
