
<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Add Employee</div>
  <div class="px-5 py-4 minHeight80vh bg-white m-4 font-14">
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <button  class="font-14 border-0 submitBtn darkGreyBG text-white mb-5 pointer" (click)="gotoback()">Back</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 payslipTab">

            <div>
              <form [formGroup]="uploadEmployeeForm" (ngSubmit)="onSubmitedForm()">
                <div class="row w-100">
                  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12">


                  </div>
                  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12">


                  </div>
                </div>
                <div class="row w-100">
                  <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1 col-12"></div>
                  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 col-12">
                    <div class="text-center uploadBox my-5 px-5">
                      <h3 class="font-24 regular pt-5 pb-4">Upload Your File</h3>
                      <div class="font-12 light pb-3">Only Excel Files :)</div>
                      <div>
                        <img
                          alt="Excel Only"
                          src="../../assets/img/1excel-icon.png"
                          class="width-60"
                        />
                      </div>
                      <div class="my-4 pointer">
                        <label for="uploadexcel" class="excelUpload w-100 py-4 pointer"
                          >Drag a file here or&nbsp;<span class="orangeFont">Browse</span>&nbsp;for
                          a file to upload</label
                        >
                        <input
                          type="file"
                          class="pointer"
                          id="uploadexcel"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          formControlName="file"
                          (change)="onSelectedFile($event)"
                        />
                        <div *ngIf="this.file">
                          <span>{{ file.name }}</span>
                        </div>
                        <div
                          class=""
                          *ngIf="
                            uploadEmployeeForm.controls['file'].invalid &&
                            uploadEmployeeForm.controls['file'].dirty
                          "
                        >
                          <span class="error" *ngIf="uploadEmployeeForm.controls['file'].errors?.['required']"
                            >Please select file.</span
                          >
                        </div>
                        <div>
                          <span class="error" *ngIf="showFiletypeError === true"
                              >Please upload valid format file.</span
                            >
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <button
                            class="font-14 border-0 submitBtn darkGreyBG text-white mb-5 pointer"
                            type="button"
                            (click)="onClear()"
                          >
                            Cancel
                          </button>
                        </div>
                        <div class="col-6">
                          <button
                            class="font-14 border-0 submitBtn redBG text-white mb-5 pointer"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1 col-12"></div>
                </div>
              </form>
            </div>



      </div>
    </div>
  </div>
</div>
