<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Purchase Order & Invoice</div>
  <div class="px-5 py-4 minHeight80vh bg-white m-4 font-14">
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="row">
          <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="pb-2 font-14 pt-2">Invoice</div>
          </div>
        </div>
      </div>
    </div>
      <div class="my-3">
          <form [formGroup]="invoiceForm" >
            <div class="row medium font-12 pb-3">
              <div class="col">
                <div class="medium pb-1">Invoice Number *</div>
                  <div class="mb-3 darkGreyFont">
                    {{allInvoiceData?.invoiceNumber}}
                  </div>
          </div>
          <div class="col">
            <div class="medium pb-1">Invoice Date *</div>
            <div class="mb-3">
              <input type="text" bsDatepicker  formControlName="invoiceDate"  placeholder="Enter the date" type="text"
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser"  [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY'}">
                <div class="error "
                *ngIf="invoiceForm.get('invoiceDate')?.invalid && invoiceForm.get('invoiceDate')?.dirty">
                <span *ngIf="invoiceForm.get('invoiceDate')?.hasError('required')"> Invoice Date is Required</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="medium pb-1">PO Number *</div>
            <div class="mb-3">
              <input pInputText formControlName="purchaseOrderNumber" placeholder="Enter PO Number" type="text"
                  class="font-12 w-100 light height-40 px-3 inputBoxAddUser">
                <div class="error "
                  *ngIf="invoiceForm.get('purchaseOrderNumber')?.invalid && invoiceForm.get('purchaseOrderNumber')?.dirty">
                  <span *ngIf="invoiceForm.get('purchaseOrderNumber')?.hasError('required')">PO Number
                    Required</span> </div>
              </div>
          </div>
          <div class="col">
            <div class="medium pb-1">PO Date *</div>
            <div class="mb-3">
              <input bsDatepicker formControlName="invoicePurchaseOrderDate" type="text" placeholder="Enter PO Date"
              type="text" class="font-12 w-100 light height-40 px-3 inputBoxAddUser" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY'}">

            <div class="error "
              *ngIf="invoiceForm.get('invoicePurchaseOrderDate')?.invalid && invoiceForm.get('invoicePurchaseOrderDate')?.dirty">
              <span *ngIf="invoiceForm.get('invoicePurchaseOrderDate')?.hasError('required')">PO Date
                Required</span>
            </div>
            </div>
          </div>
          <div class="col">
            <div class="medium pb-1">Reference Number *</div>
            <div class="mb-3">
              <input pInputText formControlName="InvoiceReferanceNumber" placeholder="Enter Reference Number" type="text"
                  class="font-12 w-100 light height-40 px-3 inputBoxAddUser">
                <div class="error "
                  *ngIf="invoiceForm.get('InvoiceReferanceNumber')?.invalid && invoiceForm.get('InvoiceReferanceNumber')?.dirty">
                  <span *ngIf="invoiceForm.get('InvoiceReferanceNumber')?.hasError('required')">Reference Number
                    Required</span> </div>
              </div>
          </div>
        </div>

        <div class="row font-12">
          <div class="col-4">
            <div class="bold py-2">Vendor Details</div>
            <div class="row darkGreyFont">
              <div class="col-4">Name</div>
              <div class="col-8 text-uppercase">{{allInvoiceData?.vendor.tenantName}}</div>
            </div>
            <div class="row darkGreyFont">
              <div class="col-4">GSTN</div>
              <div class="col-8">{{allInvoiceData?.vendor.gstin}}</div>
            </div>
            <div class="row darkGreyFont">
              <div class="col-4">Address</div>
              <div class="col-8 text-uppercase">
                {{allInvoiceData?.vendor.address.addressLine1}},
                {{allInvoiceData?.vendor.address.addressLine2}},
                {{allInvoiceData?.vendor.address.city}},
                {{allInvoiceData?.vendor.address.state}}, {{allInvoiceData?.vendor.address.pinCode}}
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="bold py-2">Customer Details</div>
            <div class="row darkGreyFont">
              <div class="col-4">Name</div>
              <div class="col-8 text-uppercase">{{allInvoiceData?.customer.companyName}}</div>
            </div>
            <div class="row darkGreyFont">
              <div class="col-4">GSTN</div>
              <div class="col-8">{{allInvoiceData?.customer.gstin}}</div>
            </div>
            <div class="row darkGreyFont">
              <div class="col-4">Address</div>
              <div class="col-8 text-uppercase">{{allInvoiceData?.customer.address.addressLine1}},
                  {{allInvoiceData?.customer.address.addressLine2}},
                  {{allInvoiceData?.customer.address.city}},
                  {{allInvoiceData?.customer.address.state}}, {{allInvoiceData?.customer.address.pinCode}}</div>
            </div>
          </div>
        </div>
        <div class="row medium font-12 pt-4">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Ship to</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12">
                <div class="col">
                  <div class="medium pb-1">Address</div>
                  <div class="mb-3">
                    <input pInputText formControlName="shipToAddress" type="text" placeholder="Enter Address"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                  </div>
                  <div class="error "
                    *ngIf="invoiceForm.get('shipToAddress')?.invalid && invoiceForm.get('shipToAddress')?.dirty">
                    <span *ngIf="invoiceForm.get('shipToAddress')?.hasError('required')">Address is
                      Required</span>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">City</div>
                  <div class="mb-3">
                    <input pInputText formControlName="shipToCity" type="text" placeholder="Enter City Name"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                  </div>
                  <div class="error "
                    *ngIf="invoiceForm.get('shipToCity')?.invalid && invoiceForm.get('shipToCity')?.dirty">
                    <span *ngIf="invoiceForm.get('shipToCity')?.hasError('required')">City is
                      Required</span>
                  </div>
                </div>

                <div class="col">
                  <div class="medium pb-1">State</div>
                  <div class="mb-3">
                    <input pInputText formControlName="shipToState" type="text" placeholder="Enter State Name"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                    <div class="error "
                      *ngIf="invoiceForm.get('shipToState')?.invalid && invoiceForm.get('shipToState')?.dirty">
                      <span *ngIf="invoiceForm.get('shipToState')?.hasError('required')">State is
                        Required</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Country</div>
                  <div class="mb-3">
                    <input pInputText formControlName="shipToCountry" type="text" placeholder="Enter Country Name"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">

                    <div class="error "
                      *ngIf="invoiceForm.get('shipToCountry')?.invalid && invoiceForm.get('shipToCountry')?.dirty">
                      <span *ngIf="invoiceForm.get('shipToCountry')?.hasError('required')">Country is
                        Required</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Pincode *</div>
                  <div class="mb-3">
                    <input
                      placeholder="Enter Pincode"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="shipToPincode"
                    />
                    <div
                      *ngIf="
                      invoiceForm.get('shipToPincode')?.invalid && invoiceForm.get('shipToPincode')?.dirty
                      "

                    >
                      <span class="error" *ngIf="invoiceForm.get('shipToPincode')?.hasError('required')"
                        >Pincode is required</span
                      >
                      <span class="error" *ngIf="invoiceForm.get('shipToPincode')?.hasError('pattern')"
                        >Pincode can only be in numbers</span
                      >
                      <span
                        class="error"
                        *ngIf="
                          !invoiceForm.get('shipToPincode')?.hasError('pattern') &&
                          !invoiceForm.get('shipToPincode')?.hasError('required') &&
                          invoiceForm.get('shipToPincode')?.hasError('minlength')
                        "
                        >Invalid pincode.</span
                      >
                      <span
                        class="error"
                        *ngIf="
                          !invoiceForm.get('shipToPincode')?.hasError('pattern') &&
                          !invoiceForm.get('shipToPincode')?.hasError('required') &&
                          invoiceForm.get('shipToPincode')?.hasError('maxlength')
                        "
                        >Invalid pincode.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="font-12 light">
          <div class="row text-white bg-darkGrey py-1 mx-0 mb-2 mt-4">
            <div class="col-1"></div>
            <div class="col-1">Sl No.</div>
              <div class="col-3">Description</div>
              <div class="col">UAM</div>
              <div class="col">HSN / SAC Code</div>
              <div class="col">Quantity</div>
              <div class="col">Unit Price</div>
              <div class="col">Amount</div>
              <div class="col-1"></div>
          </div>

          <div class="row mx-0 py-2">
            <div formArrayName="invoices" *ngFor="let a of invoicesFormArray.controls; let i = index" class="col-11">
              <div [formGroupName]="i" class="row">
                <div class="col-1">
                  <button class="transparent-bg border-0 p-0 m-0" *ngIf=" i >= 1" (click)="onRemoveFormGroup(i)"><img
                      title="Remove" alt="remove" src="../../assets/img/delete.svg"
                      class="height-30 pointer p-1">
                  </button>
                </div>
                <div class="col-1 py-1">{{i+1}}</div>
                <div class="col-3">
                  <textarea pInputTextarea formControlName="description"
                    class="w-100 light height-30 px-3 py-1 inputBoxAddUser font-12">
                  </textarea>
                  <div class="error"
                    *ngIf="invoicesFormArray.get([i,'description'])?.invalid && invoicesFormArray.get([i,'description'])?.dirty">
                    <span *ngIf="invoicesFormArray.get([i,'description'])?.hasError('required')">Description is
                      Required</span>
                  </div>
                </div>
                <div class="col"><input pInputText formControlName="uam" type="text" placeholder=""
                    class="w-100 light height-30 px-3 inputBoxAddUser font-12">
                  <div class="error "
                    *ngIf="invoicesFormArray.get([i,'uam'])?.invalid && invoicesFormArray.get([i,'uam'])?.dirty">
                    <span *ngIf="invoicesFormArray.get([i,'uam'])?.hasError('required')">UAM is Required</span>
                  </div>
                </div>
                <div class="col"><input pInputText formControlName="hsnCode" type="text" placeholder=""
                    class="w-100 light height-30 px-3 inputBoxAddUser font-12">
                  <div class="error "
                    *ngIf="invoicesFormArray.get([i,'hsnCode'])?.invalid && invoicesFormArray.get([i,'hsnCode'])?.dirty">
                    <span *ngIf="invoicesFormArray.get([i,'hsnCode'])?.hasError('required')">HSN / SAC Code is
                      Required</span>
                  </div>
                </div>
                <div class="col"><input  pInputText formControlName="quantity" type="number" placeholder=""
                  class="w-100 light height-30 px-3 inputBoxAddUser font-12">
                <div class="error "
                  *ngIf="invoicesFormArray.get([i,'quantity'])?.invalid && invoicesFormArray.get([i,'quantity'])?.dirty">
                  <span *ngIf="invoicesFormArray.get([i,'quantity'])?.hasError('required')">Quantity is
                    Required</span>
                    <span *ngIf="invoicesFormArray.get([i,'quantity'])?.hasError('pattern')"
                    >Quantity is invalid</span
                  >
                </div>
              </div>
              <div class="col"><input pInputText formControlName="unitRate" type="number" placeholder=""
                  class="w-100 light height-30 px-3 inputBoxAddUser font-12">
                <div class="error "
                  *ngIf="invoicesFormArray.get([i,'unitRate'])?.invalid && invoicesFormArray.get([i,'unitRate'])?.dirty">
                  <span *ngIf="invoicesFormArray.get([i,'unitRate'])?.hasError('required')">Unit Price is
                    Required</span>
                    <span *ngIf="invoicesFormArray.get([i,'unitRate'])?.hasError('pattern')"
                    >Unit Price is invalid</span
                  >
                </div>
              </div>
              <div class="col">
                  {{invoicesFormArray.get([i,'amount'])?.value}}
              </div>
              </div>
            </div>
            <div class="col-1">
              <img (click)="addNewInvoice()" alt="ADD" src="../../assets/img/plus.svg" class="height-30 pointer">
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-9">
            <div class="bold pt-5">Total Amount in Words</div>
            <div class="medium darkGreyFont py-2 text-capital">{{amountInWords}}</div>
          </div>
          <div class="col-3 font-12 my-3">
            <div class="row py-1">
              <div class="col-6">Sub Total</div>
              <div class="col-6"> {{subTotal}}</div>
            </div>
            <div class="row py-1">
              <div class="col-6">IGST 18%</div>
              <div class="col-6"> {{igst}}

                    </div>
            </div>
            <div class="row py-1">
              <div class="col-6">SGST 9%</div>
              <div class="col-6"> {{sgst}}

                    </div>

            </div>
            <div class="row py-1">
              <div class="col-6">CGST 9%</div>
              <div class="col-6"> {{cgst}}
            </div>
            </div>
            <div class="row py-1 pb-3">
              <div class="col-6">CESS</div>
              <div class="col-6"> <input  (blur)="calculateTotal()" pInputText formControlName="cess" type="number" placeholder=""
                    class="w-100 light inputBoxPurchase px-1 font-12"> </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Total</div>
              <div class="col-6"> {{totalAmount}}</div>
            </div>
          </div>
        </div>


        <hr>

        <div class="row medium pb-3">
          <div class="col-4">
            <div class="medium pb-1">Company's Bank Details :</div>
            <div class="mb-3 regular">
              <div class="row darkGreyFont font-12">
                <div class="col-6">Bank Name :</div>
                <div class="col-6">{{allInvoiceData?.vendor.accountDetails?.bankId.bankName}}</div>
              </div>
              <div class="row darkGreyFont font-12">
                <div class="col-6">Account Number :</div>
                <div class="col-6">{{allInvoiceData?.vendor.accountDetails?.accountNumber}}</div>
              </div>
              <div class="row darkGreyFont font-12">
                <div class="col-6">IFSC Code :</div>
                <div class="col-6">{{allInvoiceData?.vendor.accountDetails?.ifscCode}}</div>
              </div>
              <div class="row darkGreyFont font-12">
                <div class="col-6">Branch :</div>
                <div class="col-6">{{allInvoiceData?.vendor.accountDetails?.branchName}}</div>
              </div>
              <div class="row darkGreyFont font-12">
                <div class="col-6">PAN :</div>
                <div class="col-6">{{allInvoiceData?.vendor.pan}}</div>
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class="medium pb-1">Note</div>
            <div class="mb-3 darkGreyFont font-12 regular">
              <div class="mb-3">
                <label>Certified that the particular's given above are true & correct & the amount indicated represents the price actually charged & that there is no flow of additional consideration directly or indirectly from the buyer.</label>
                </div>
            </div>
          </div>
        </div>

          <div class="col-12">
            <div class="text-right pt-5"><button type="button"
              (click)="onClickCancel()"  class="font-12 border-0 mr-5 submitBtn darkGreyBG text-white pointer">Cancel</button><button
                (click)="onSubmitInvoiceForm(invoiceForm.value)" class="font-12 border-0 submitBtn redBG text-white pointer">Submit</button>
            </div>
          </div>
        </form>
      </div>




