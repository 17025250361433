import { Injectable } from '@angular/core';
import { RestService } from '../core/rest.service';
import { Observable, throwError } from 'rxjs';
import { Environment } from 'src/environments/environment';
;
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {
  constructor(public restService: RestService) {}

  submitPurchaseOrder(data:any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/purchaseOrders', data).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  submitInvoice(data:any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/invoices', data, false).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  submitEditInvoice(data:any, id:any): Observable<any> {
    return this.restService.put(Environment.UrlPath + '/invoices/' + id, data, false).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getInvoice(year:any, month:any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/dashboard/invoices?year=' + year + '&month=' + month, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getPurchaseOrders(customerId: string, vendorId: string): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/purchaseOrders', {vendorId, customerId}, false).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getAllPurchaseOrders(pageNumber: number, pageSize:number): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/purchaseOrders/all?pageNumber=' + pageNumber + '&pageSize=' + pageSize, null, false).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getAllInvoicesOrders(pageNumber:number, pageSize:number): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/invoices/search?pageNumber=' + pageNumber + '&pageSize=' + pageSize, null, false).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  searchInvoiceOrders(name:any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/invoices/search?keyword=' + name, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getInvoiceById(id:any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/invoices?customerId=' + id, null, false).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  editInvoiceList(id:any) : Observable<any> {
    return this.restService.get(Environment.UrlPath + '/invoices/' + id, null, false).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  searchPurchaseOrders(name: string): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/purchaseOrders/search?keyword=' + name, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  searchInvoice(data?:any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/invoices/search' , data, false).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  downloadDashboardData(data:any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/invoices/excelDownload' , data, false, true).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  onCancelPurchaseOrder(purchaseid: string): Observable<any> {
    return this.restService.put(Environment.UrlPath + '/purchaseOrders/' + purchaseid, false).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getpurchaseOrderPdf(purchaseOrderId: string): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/purchaseOrders/pdfDownload/' + purchaseOrderId, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getInvoiceOrderPdf(invoiceId: string): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/purchaseOrders/pdfDownload/' + invoiceId, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }

  }



