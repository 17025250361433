import { UserService } from './services/user.service';
import { DataService } from './services/data.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private router: Router,
    private dataService: DataService,
    private userService: UserService,
    private location: Location
  ) {}
  ngOnInit() {
    if (this.location.path().startsWith('/set-password')) {
      return;
    }

    if (localStorage.getItem('userId') === null) {
      this.auth.forceLogout();
    } else {
      this.dataService.getFromLocalStorage();
      this.userService.getUserById(this.dataService.userId).subscribe(
        (res) => {
          this.dataService.adminStatus(res.response.user.userId);
          this.dataService.userData = res.response;
          this.dataService.saveUserInLocalStorage(res.response);
        },
        (err) => {
          this.auth.forceLogout();
        }
      );
      this.router.url === ''?this.router.navigate(['/admin-dashboard']): '';
    }
    window.addEventListener('storage', (event) => {
      if (localStorage.getItem('userId') === null) {
        this.auth.forceLogout();
      }
    });
  }
}
