import { CustomMessageService } from './message.service';
import { MessageService } from 'primeng/api';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Message } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit, OnDestroy {
  msgs: Message[] = [];
  subscription!: Subscription;
  constructor(private messagingService: CustomMessageService , private messageService: MessageService) { }
  ngOnInit() {
    this.subscribeToNotifications();
  }

  subscribeToNotifications() {
    this.subscription = this.messagingService.notificationChange
    .subscribe(notification => {
    this.messageService.clear();
    this.messageService.add(notification);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
