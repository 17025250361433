<header *ngIf="auth.isLoggedIn()">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 text-right"></div>
      <div class="col-xl-6 col-lg-6 col-md-3 col-sm-12 col-6"></div>
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 text-right">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-right font-14">
            <div class="row position-relative">
              <div class="col-3 text-left">
                <a (click)="toggleClass()" class="d-block d-sm-block d-md-none d-lg-none">
                  <img alt="Menu" src="../../../assets/img/menu-ico.svg" style="width: 25px;">
                </a>
              </div>
              <div class="col-3 col-md-3 text-right">
                <div class="position-absolute rounded-circle activeUser"></div>
                <img alt="dashboard" src="../../../assets/img/dummy-user-img.png" class="userImg rounded-circle" />
              </div>

              <div class="col-6 text-left text-sm-left text-md-right my-1 darkGreyFont">
                Welcome {{ convertString(dataService.userName) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<section class="main">
  <aside [class.keepWidth]="classApplied" *ngIf="auth.isLoggedIn()">
    <ul class="light">
      <li class="logoBorderBottom">
        <a (click)="toggleClass()">
          <span><img alt="Menu" src="../../../assets/img/logo.png" class="logoSize" /><img alt="Menu"
              src="../../../assets/img/menu-back.png" class="menuIcon pull-right  mt-1 ml-4" /></span>
        </a>
      </li>
      <li (click)="toggleClass()" class="d-block d-sm-block d-md-none d-lg-none" routerLink="/admin-dashboard"
        [ngClass]="{
        menuActive:
          pageUrl === 'admin-dashboard' || 
          (pageUrl === 'payslip' && dataService.adminStatus1 === false) || 
          (pageUrl === 'account-details' && dataService.adminStatus1 === false) || 
          (pageUrl === 'purchase-orders' && dataService.adminStatus1 === false) || 
          (pageUrl === 'invoice-list' && dataService.adminStatus1 === false)
            ? 'true'
            : ''
      }">
        <a class="font-11">
          <span><img alt="Payroll" src="../../../assets/img/dashboard-menu.png" class="menuIcon" /></span>
          Dashboard
        </a>
      </li>
      <li class="d-none d-sm-none d-md-block d-lg-block" routerLink="/admin-dashboard" [ngClass]="{
      menuActive:
        pageUrl === 'admin-dashboard' || 
        (pageUrl === 'payslip' && dataService.adminStatus1 === false) || 
        (pageUrl === 'account-details' && dataService.adminStatus1 === false) || 
        (pageUrl === 'purchase-orders' && dataService.adminStatus1 === false) || 
        (pageUrl === 'invoice-list' && dataService.adminStatus1 === false)
          ? 'true'
          : ''
    }">
        <a class="font-11 w-100">
          <span><img alt="Payroll" src="../../../assets/img/dashboard-menu.png" class="menuIcon" /></span>
          Dashboard
        </a>
      </li>
      <li *ngIf="dataService.adminStatus1" class="has-submenu">
        <a (click)="clickPayroll()" routerLinkActive="" class="w-100">
          <span><img alt="Payroll" src="../../../assets/img/bill.png" class="menuIcon" /></span>
          PAYROLL
          <span class="float-right margin-top-8 plusIco"><img alt="Sub-Menu" src="../../../assets/img/menuadd.svg" class="submenuIcon" /></span>
        </a>
        <ul class="submenu" [ngClass]="submenum ? 'd-block' : 'd-none'">
          <li routerLink="/statutory" [ngClass]="{
              menuActive:
                pageUrl === 'add-zone' ||
                pageUrl === 'statutory' ||
                pageUrl === 'edit-statutory' ||
                pageUrl === '/statutory'
                  ? 'true'
                  : ''
            }">
            <a class="font-11">
              <span><img alt="Payroll" src="../../../assets/img/master.png" class="menuIcon" /></span>
              MASTER
            </a>
          </li>
          <li routerLink="/manage-site" [ngClass]="{
              menuActive:
                pageUrl === 'add-site' || pageUrl === 'manage-site' || pageUrl === 'edit-site'
                  ? 'true'
                  : ''
            }">
            <a class="font-11">
              <span><img alt="Payroll" src="../../../assets/img/worksite.png" class="menuIcon" /></span>
              SITES
            </a>
          </li>
          <li routerLink="/list-employee" [ngClass]="{
              menuActive:
                pageUrl === 'add-employee' ||
                pageUrl === 'list-employee' ||
                pageUrl === 'employee-upload' ||
                pageUrl === 'edit-user'
                  ? 'true'
                  : ''
            }">
            <a class="font-11">
              <span><img alt="Payroll" src="../../../assets/img/employee.png" class="menuIcon" /></span>
              EMPLOYEES
            </a>
          </li>
          <li routerLink="/payslip" routerLinkActive="menuActive">
            <a class="font-11">
              <span><img alt="Payroll" src="../../../assets/img/money.png" class="menuIcon" /></span>
              PAYSLIP
            </a>
          </li>
          <li routerLink="/reports" routerLinkActive="menuActive">
            <a class="font-11">
              <span><img alt="Payroll" src="../../../assets/img/report.png" class="menuIcon" /></span>
              Reports
            </a>
          </li>
          <li routerLink="/bonus-report" routerLinkActive="menuActive">
            <a class="font-11">
              <span><img alt="Payroll" src="../../../assets/img/money.png" class="menuIcon" /></span>
              BONUS REPORT
            </a>
          </li>
        </ul>
      </li>

      <li *ngIf="dataService.adminStatus1" class="has-submenu">
        <a (click)="clickInvoice()" routerLinkActive="" class="w-100">
          <span><img alt="master" src="../../../assets/img/invoice.png" class="menuIcon" /></span> PO
          & INVOICING
          <span class="float-right margin-top-8 plusIco"><img alt="Sub-Menu" src="../../../assets/img/menuadd.svg" class="submenuIcon" /></span>
        </a>
        <ul class="submenu" [ngClass]="submenu ? 'd-block' : 'd-none'">


          <li routerLink="/vendor-invoice" [queryParams]="{ type: 1 }" [ngClass]="{
              menuActive:
                pageUrl === 'add-vendor' ||
                (pageQueryParams === '1' &&
                pageUrl === 'vendor-invoice')
                  ? 'true'
                  : ''
            }">
            <a class="font-11">
              <span><img alt="Payroll" src="../../../assets/img/vendor.png" class="menuIcon" /></span>
              Vendor
            </a>
          </li>
          <li routerLink="/vendor-invoice" [queryParams]="{ type: 2 }" [ngClass]="{
              menuActive:
                pageUrl === 'add-customer' ||
                (pageQueryParams === '2' && pageUrl === 'vendor-invoice')
                  ? 'true'
                  : ''
            }">
            <a class="font-11">
              <span><img alt="Payroll" src="../../../assets/img/customer.png" class="menuIcon" /></span>
              Customer
            </a>
          </li>
          <li routerLink="/purchase-orders" [ngClass]="{
            menuActive:
              pageUrl === 'purchase-orders' || 
              pageUrl === 'purchase' 
                ? 'true'
                : ''
          }">
            <a class="font-11">
              <span><img alt="Payroll" src="../../../assets/img/purchase-order.png" class="menuIcon" /></span>
              Purchase Order List</a>
          </li>
          <li routerLink="/invoice-list" [ngClass]="{
            menuActive:
              pageUrl === 'invoice-list' || 
              pageUrl === 'invoice' ||
              pageUrl === 'edit-invoice'
                ? 'true'
                : ''
          }">
            <a class="font-11">
              <span><img alt="Payroll" src="../../../assets/img/invoiceList.png" class="menuIcon" /></span>
              Invoice List
            </a>
          </li>
          <li routerLink="/dashboard" [ngClass]="{
            menuActive:
              pageUrl === 'dashboard'
                ? 'true'
                : ''
          }">
            <a class="font-11">
              <span><img alt="Dashboard" src="../../../assets/img/receipt.png" class="menuIcon" /></span>
              Invoice Reports
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="dataService.adminStatus1" class="has-submenu">
        <a (click)="clickAccount()" routerLinkActive="" class="w-100">
          <span><img alt="master" src="../../../assets/img/login-menu.png" class="menuIcon" /></span> Accounts
          <span class="float-right margin-top-8 plusIco"><img alt="Sub-Menu" src="../../../assets/img/menuadd.svg" class="submenuIcon" /></span>
        </a>
        <ul class="submenu" [ngClass]="accounts ? 'd-block' : 'd-none'">
          <li routerLink="/manage-supervisor" [ngClass]="{
              menuActive:
                pageUrl === 'manage-supervisor' ||
                pageUrl === 'add-supervisor' ||
                pageUrl === 'account-details' ||
                pageUrl === 'add-amount' 
                  ? 'true'
                  : ''
            }">
            <a class="font-11">
              <span><img alt="Payroll" src="../../../assets/img/supervisor.png" class="menuIcon" /></span>
              Supervisor
            </a>
          </li>
        </ul>
      <li (click)="onClickLogout()">
        <a>
          <span><img alt="LOGOUT" src="../../../assets/img/logout.png" class="menuIcon" /></span>
          LOGOUT
        </a>
      </li>
    </ul>
  </aside>






  <article [class.reduceWidth]="classApplied">
    <router-outlet></router-outlet>
  </article>
</section>