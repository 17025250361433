import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe as AngularTitleCasePipe } from '@angular/common';

@Pipe({
  name: 'customTitleCase'
})
export class CustomTitleCasePipe implements PipeTransform {


  angularTitleCase = new AngularTitleCasePipe();
  transform(value: any): any {
    if (typeof value === 'string') {

      //replacing if any special characters exists
      value = value.replace(/[&\/\\# +()$~%.'":*?<>{}_]/g, ' ');

      // if the value we have to transform is a simple string
      return this.angularTitleCase.transform(value);
    } 
    return '';
  }
}

