<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Payroll</div>
  <div class="px-5 py-4 minHeight80vh bg-white m-4 font-14">
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="pb-4 font-16 pt-1 medium">Master</div>
      </div>
      <div class="col-12 col-xl-10 col-lg-10 col-md-12 col-sm-12">
        <tabset type="pills">
          <tab heading="Zone" (selectTab)="onClickZones($event)">
            <div>
              <form>
                <div class="pt-4 pb-4 font-12">
                  <div class="pb-5">
                    <div class="row medium">
                      <div class="col-12 text-right">
                        <img
                          alt="Add"
                          src="../../assets/img/plus.svg"
                          class="width-30 pointer"
                          (click)="onclickPlusImage()"
                        />
                      </div>
                    </div>
                    <div class="row medium">
                      <div class="col-10">
                        <div class="row font-14 py-3">
                          <div class="col">Zone Name</div>
                          <div class="col text-center">Unskilled</div>
                          <div class="col text-center">Semi Skilled</div>
                          <div class="col text-center">Skilled</div>
                        </div>
                      </div>
                      <div class="col-2"></div>
                    </div>
                    <div class="row py-2 light" *ngFor="let zone of this.zoneData; let i = index">
                      <div class="col-10">
                        <div class="row">
                          <div class="col my-1 font-14">{{ zone.zoneName }}</div>
                          <div class="col text-center">
                            <input
                              type="text"
                              name="unskilled"
                              class="w-100 addInput px-2"
                              value="{{ zone.unSkilledWage }}"
                            />
                          </div>
                          <div class="col text-center">
                            <input
                              type="text"
                              name="Semiskilled"
                              class="w-100 addInput px-2"
                              value="{{ zone.semiSkilledWage }}"
                            />
                          </div>
                          <div class="col text-center">
                            <input
                              type="text"
                              name="skilled"
                              class="w-100 addInput px-2"
                              value="{{ zone.skilledWage }}"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-2">
                        <img alt="Edit" src="../../assets/img/pencil.svg" class="width-20 pointer" (click)="editStatutory(zone.zoneId)"/>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div *ngIf="this.pageSize < this.totalElements">
              <pagination
              [totalItems]="totalElements"
              previousText="&laquo;"
              nextText="&raquo;"
              [itemsPerPage]="50"
              [maxSize]="10"

              (pageChanged)="pageChanged($event)"
              ></pagination>
              </div>
          </tab>

          <tab heading="Statutory" (selectTab)="onClickStatutory($event)">
            <div>
              <form [formGroup]="masterStatutoryForm">
                <div class="pt-5 pb-4">
                  <div class="row">
                    <div class="font-14 medium col-12">Additions</div>
                  </div>
                  <div class="row">
                    <div class="font-14 light col-12 col-xl-5 col-lg-6 col-md-6 col-sm-6 my-4">
                      <div class="row py-2">
                        <div class="font-12 light col-5 my-1">HRA</div>
                        <div class="font-12 light col-7">
                          <input
                            type="text"
                            name="hra"
                            class="w-80 addInput px-2"
                            formControlName="hra"
                          />&nbsp;&nbsp;%
                          <div
                            *ngIf="
                              masterStatutoryForm.controls['hra'].invalid &&
                              masterStatutoryForm.controls['hra'].dirty
                            "
                          >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['hra'].errors && masterStatutoryForm.controls['hra'].errors['required']"
                              >HRA is required</span
                            >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['hra'].errors && !masterStatutoryForm.controls['hra'].errors['max'] && !masterStatutoryForm.controls['hra'].errors['pattern'] && masterStatutoryForm.controls['hra'].errors['maxlength']"
                              >HRA is too long.</span
                            >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['hra'].errors && !masterStatutoryForm.controls['hra'].errors['max'] && !masterStatutoryForm.controls['hra'].errors['pattern'] && masterStatutoryForm.controls['hra'].errors['minlength']"
                              >HRA is too short.</span
                            >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['hra'].errors && masterStatutoryForm.controls['hra'].errors['pattern']"
                              >Please enter the HRA correctly.</span
                            >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['hra'].errors &&
                                !masterStatutoryForm.controls['hra'].errors['pattern'] &&
                                masterStatutoryForm.controls['hra'].errors['max']
                              "
                              >Please enter the HRA correctly.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row py-2">
                        <div class="font-12 light col-5 my-1">Conveyance</div>
                        <div class="font-12 light col-7">
                          <input
                            type="text"
                            name="conveyance"
                            class="w-80 addInput px-2"
                            formControlName="conveyance"
                          />
                          <div
                            *ngIf="
                              masterStatutoryForm.controls['conveyance'].invalid &&
                              masterStatutoryForm.controls['conveyance'].dirty
                            "
                          >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['conveyance'].errors && masterStatutoryForm.controls['conveyance'].errors['required']"
                              >Conveyance is required</span
                            >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['conveyance'].errors && masterStatutoryForm.controls['conveyance'].errors['pattern']"
                              >Please enter a valid number.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="font-14 light col-12 col-xl-5 col-lg-6 col-md-6 col-sm-6 my-4">
                      <div class="row py-2">
                        <div class="font-12 light col-5 my-1">Shoes</div>
                        <div class="font-12 light col-7">
                          <input
                            type="text"
                            name="shoes"
                            class="w-80 addInput px-2"
                            formControlName="shoes"
                          />

                          <div
                            *ngIf="
                              masterStatutoryForm.controls['shoes'].invalid &&
                              masterStatutoryForm.controls['shoes'].dirty
                            "
                          >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['shoes'].errors && masterStatutoryForm.controls['shoes'].errors['required']"
                              >Shoes is required</span
                            >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['shoes'].errors && masterStatutoryForm.controls['shoes'].errors['pattern']"
                              >Please enter a valid number.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row py-2">
                        <div class="font-12 light col-5 my-1">VHR</div>
                        <div class="font-12 light col-7">
                          <input
                            type="text"
                            name="vhr"
                            class="w-80 addInput px-2"
                            formControlName="vhr"
                          />
                          <div
                            *ngIf="
                              masterStatutoryForm.controls['vhr'].invalid &&
                              masterStatutoryForm.controls['vhr'].dirty
                            "
                          >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['vhr'].errors && masterStatutoryForm.controls['vhr'].errors['required']"
                              >VHR is required</span
                            >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['vhr'].errors && masterStatutoryForm.controls['vhr'].errors['pattern']"
                              >Please enter a valid number.</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="pb-5">
                  <div class="row">
                    <div class="font-14 medium col-12">Deductions</div>
                  </div>
                  <div class="row">
                    <div class="font-14 light col-12 col-xl-5 col-lg-6 col-md-6 col-sm-6 my-4">
                      <div class="row py-2">
                        <div class="font-12 light col-5 my-1">EPF</div>
                        <div class="font-12 light col-7">
                          <input
                            type="text"
                            name="epf"
                            class="w-80 addInput px-2"
                            formControlName="epf"
                          />&nbsp;&nbsp;%
                          <div
                            *ngIf="
                              masterStatutoryForm.controls['epf'].invalid &&
                              masterStatutoryForm.controls['epf'].dirty
                            "
                          >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['epf'].errors && masterStatutoryForm.controls['epf'].errors['required']"
                              >EPF is required</span
                            >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['epf'].errors && !masterStatutoryForm.controls['epf'].errors['max'] && !masterStatutoryForm.controls['epf'].errors['pattern'] && masterStatutoryForm.controls['epf'].errors['maxlength']"
                              >EPF should not exceed more than 5 digits.</span
                            >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['epf'].errors && masterStatutoryForm.controls['epf'].errors['pattern']"
                              >Please enter the EPF correctly.</span
                            >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['epf'].errors &&
                                !masterStatutoryForm.controls['epf'].errors['pattern'] &&
                                masterStatutoryForm.controls['epf'].errors['max']
                              "
                              >Please enter the EPF correctly.</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="font-14 light col-12 col-xl-5 col-lg-6 col-md-6 col-sm-6 my-4">
                      <div class="row py-2">
                        <div class="font-12 light col-5 my-1">ESI</div>
                        <div class="font-12 light col-7">
                          <input
                            type="text"
                            name="esi"
                            class="w-80 addInput px-2"
                            formControlName="esi"
                          />&nbsp;&nbsp;%

                          <div
                            *ngIf="
                              masterStatutoryForm.controls['esi'].invalid &&
                              masterStatutoryForm.controls['esi'].dirty
                            "
                          >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['esi'].errors && masterStatutoryForm.controls['esi'].errors['required']"
                              >ESI is required</span
                            >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['esi'].errors && !masterStatutoryForm.controls['esi'].errors['pattern'] && !masterStatutoryForm.controls['esi'].errors['max'] && masterStatutoryForm.controls['esi'].errors['maxlength']"
                              >ESI should not exceed more than 5 digits.</span
                            >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['esi'].errors && masterStatutoryForm.controls['esi'].errors['pattern']"
                              >Please enter the ESI correctly.</span
                            >
                            <span
                              class="error"
                              *ngIf="
                              masterStatutoryForm.controls['esi'].errors&&
                                !masterStatutoryForm.controls['esi'].errors['pattern'] &&
                                masterStatutoryForm.controls['esi'].errors['max']
                              "
                              >Please enter the ESI correctly.</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="font-14 light col-12 col-xl-5 col-lg-6 col-md-6 col-sm-6">
                      <div class="row py-2">
                        <div class="font-12 light col-5 my-1">EPF wage ceiling</div>
                        <div class="font-12 light col-7">
                          <input
                            type="text"
                            name="epfCealingAmount"
                            class="w-80 addInput px-2"
                            formControlName="epfCealingAmount"
                          />
                          <div
                            *ngIf="
                              masterStatutoryForm.controls['epfCealingAmount'].invalid &&
                              masterStatutoryForm.controls['epfCealingAmount'].dirty
                            "
                          >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['epfCealingAmount'].errors && masterStatutoryForm.controls['epfCealingAmount'].errors['required']"
                              >EPF wage ceiling is required</span
                            >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['epfCealingAmount'].errors && masterStatutoryForm.controls['epfCealingAmount'].errors['pattern']"
                              >Please enter the EPF wage ceiling correctly.</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="font-14 light col-12 col-xl-5 col-lg-6 col-md-6 col-sm-6">
                      <div class="row py-2">
                        <div class="font-12 light col-5 my-1">ESI wage ceiling</div>
                        <div class="font-12 light col-7">
                          <input
                            type="text"
                            name="esiCealingAmount"
                            class="w-80 addInput px-2"
                            formControlName="esiCealingAmount"
                          />
                          <div
                            *ngIf="
                              masterStatutoryForm.controls['esiCealingAmount'].invalid &&
                              masterStatutoryForm.controls['esiCealingAmount'].dirty
                            "
                          >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['esiCealingAmount'].errors && masterStatutoryForm.controls['esiCealingAmount'].errors['required']"
                              >ESI wage ceiling is required</span
                            >
                            <span
                              class="error"
                              *ngIf="masterStatutoryForm.controls['esiCealingAmount'].errors && masterStatutoryForm.controls['esiCealingAmount'].errors['pattern']"
                              >Please enter the ESI wage ceiling correctly.</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-right">
                  <button
                    class="font-14 border-0 mr-5 submitBtn darkGreyBG text-white pointer"
                    (click)="onCancel()"
                  >
                    Cancel</button
                  ><button
                    class="font-14 border-0 mr-4 submitBtn redBG text-white pointer"
                    type="submit"
                    (click)="onSubmitStatutory()"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</div>
