import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { ValidateAllFormFields } from '../utils/validateform';
import { MasterService } from '../services/master.service';
import { Router } from '@angular/router';
import { CustomValidators } from '../validators/custom.validator';

@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.scss'],
})
export class AddSiteComponent implements OnInit {
  showSelected: boolean;
  public addSiteForm!: FormGroup;
  allZones: any;
  showSite = false;
  showZone = false;
  allSite: any;
  validatorType = false;
  constructor(
    private formBuilder: FormBuilder,
    private masterService: MasterService,
    private router: Router
  ) {
    this.onInitForm();
    this.showSelected = false;
  }

  ngOnInit() {
    this.addSiteForm = this.formBuilder.group({
      siteName: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9 s]*$')]),
      ],
      employerName: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z s]*$')]),
      ],
      addressLine1: ['', Validators.compose([Validators.required])],
      addressLine2: ['', Validators.compose([Validators.required])],
      state: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z s]*$')])],
      district: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z s]*$')]),
      ],
      location: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z s]*$')]),
      ],
      pincode: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^\\d+$'),
          Validators.minLength(6),
          Validators.maxLength(6),
        ]),
      ],
      contactName: [
        '',
        Validators.compose([
          Validators.pattern('^[a-zA-Z s]*$'),
          Validators.maxLength(30),
          Validators.minLength(3),
        ]),
      ],
      contactNumber: [
        '',
        Validators.compose([
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern('^\\d+$'),
        ]),
      ],
      leaveWage:
        [
          '',
        [
          Validators.pattern('^[0-9][0-9]*([.][0-9]{1,3}|)$'),
          Validators.maxLength(5),
          CustomValidators.max,
        ],
        ],
        bonusPercent:
        [
          '',
        [
          Validators.pattern('^[0-9][0-9]*([.][0-9]{1,3}|)$'),
          CustomValidators.max,
        ],
        ],
      zone: ['', Validators.compose([Validators.required])],
      unskilled: ['', Validators.compose([Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')])],
      semiSkilled: ['', Validators.compose([Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')])],
      skilled: ['', Validators.compose([Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')])],
      isHra: [false],
      isConveyance: [false],
      isVhr: [false],
      isShoeAllowance: [false],
      isEpf: [false],
      isEsi: [false],
      isPt: [false],
    });
  }
  onClickAddSite() {
    this.validType();
    if (this.addSiteForm.valid && !this.validatorType) {
      const data = {
        siteName: this.addSiteForm.value.siteName,
        employerName: this.addSiteForm.value.employerName,
        addressLine1: this.addSiteForm.value.addressLine1,
        addressLine2: this.addSiteForm.value.addressLine2,
        state: this.addSiteForm.value.state,
        city: this.addSiteForm.value.district,
        location: this.addSiteForm.value.location,
        pincode: this.addSiteForm.value.pincode,
        contactPersonName: this.addSiteForm.value.contactName,
        contactPersonNumber: this.addSiteForm.value.contactNumber,
        zoneId: this.addSiteForm.value.zone,
        leaveWage: this.addSiteForm.value.leaveWage,
        bonusPercent: this.addSiteForm.value.leaveWage,
        unSkilledWage: this.addSiteForm.value.unskilled,
        semiSkilledWage: this.addSiteForm.value.semiSkilled,
        skilledWage: this.addSiteForm.value.skilled,
        isHra: this.addSiteForm.value.isHra,
        isConveyance: this.addSiteForm.value.isConveyance,
        isVhr: this.addSiteForm.value.isVhr,
        isShoeAllowance: this.addSiteForm.value.isShoeAllowance,
        isEpf: this.addSiteForm.value.isEpf,
        isEsi: this.addSiteForm.value.isEsi,
        isPt: this.addSiteForm.value.isPt,
      };
      this.masterService.onCreateSite(data).subscribe((res: any) => {
        this.addSiteForm.reset();
        this.router.navigate(['/manage-site']);
      });
    } else {
      ValidateAllFormFields(this.addSiteForm);
    }
  }
  ToggleButton() {
    this.showSelected = !this.showSelected;
  }
  onInitForm() {
    this.masterService.getAllZone(1, 1000).subscribe((res: any) => {
      const site = {
        zoneId: 'Site',
        zoneName: 'Site',
      };
      this.allZones = res.response.content;
      this.allZones = this.allZones.concat(site);
    });
  }

  onCancel() {
    this.addSiteForm.reset();
    this.router.navigate(['/manage-site']);
  }

  selectSite() {
    this.showSite = true;
  }
  hideSite() {
    this.showSite = false;
  }
  selectDropdown(event: any) {
    const type = event.target.value;

    if (type === 'Site') {
      this.showSite = true;
      this.showZone = false;
    } else {
      this.showZone = true;
      this.showSite = false;
    }
  }

  validType() {
    if (this.addSiteForm.value.zone !== 'Site' || this.addSiteForm.value.zone === '') {
      this.validatorType = false;
    } else {
      if (
        this.addSiteForm.value.zone === 'Site' &&
        (this.addSiteForm.value.unskilled === '' ||
          this.addSiteForm.value.skilled === '' ||
          this.addSiteForm.value.semiSkilled === '')
      ) {
        this.validatorType = true;
      } else {
        this.validatorType = false;
      }
    }
  }
}
