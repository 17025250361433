<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Purchase Order & Invoice</div>
  <div class="px-lg-5 py-5 px-4 minHeight80vh bg-white m-lg-3 font-14">
    <form [formGroup]="searchForm" (ngSubmit)="onSearchPurchaseData()">
      <div class="row">
        <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="row">
            <div class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 p-lg-0">
              <div class="pb-2 font-14 pt-2">Purchase Orders</div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-3">
            </div>
            <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div class="pb-4 font-16 position-relative">
                <input type="text" placeholder="Search with PO Number" formControlName="keyword"
                  class="manageUserSelectBox w-100 height-30 pl-5 pr-3 font-12 light darkGreyFont pointer" />
                <img alt="search" src="../../assets/img/magnifying-glass.svg" class="position-absolute searchIcon" />
              </div>
            </div>
            <div *ngIf="dataService.adminStatus1 === true"
              class="col-3 col-xl-1 col-lg-1 col-md-2 col-sm-3 p-lg-0 addIcon">
              <img (click)="onClickAddPurchaseOrder()" alt="add" src="../../assets/img/plus.svg"
                class="addIcon w-100 pointer " />
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="pb-4 font-12">
      <div class="pb-5">
        <div class="d-none d-sm-none d-md-none d-lg-block d-xl-block">
          <div class="row medium py-3">
            <div class="col-1">PO Number</div>
            <div class="col-3">Customer Name</div>
            <div class="col-3">Vendor Name</div>
            <div class="col-2">Project Number</div>
            <div class="col-1">Grand Total</div>
            <div class="col-1">Delete</div>
            <div class="col-1">View</div>
          </div>
          <div *ngFor="let order of allPurchaseData" class="row py-2 light">
            <div class="col-1">{{ order.purchaseOrderNumber }}</div>
            <div class="col-3">
              {{ order.customer.tenantName }}, {{ order.customer.address.addressLine1 }},
              {{ order.customer.address.addressLine2 }}, {{ order.customer.address.city }}, {{
                order.customer.address.state
              }}, {{order.customer.address.pinCode}}
            </div>
            <div class="col-3">
              {{ order.vendor.vendorName }} , {{ order.vendor.address.addressLine1 }},
              {{ order.vendor.address.addressLine2 }}, {{ order.vendor.address.city }}, {{
                order.vendor.address.state
              }}, {{order.vendor.address.pinCode}}
            </div>
            <div class="col-2">{{ order.projectNumber }}</div>
            <div class="col-1">{{ order.grandTotal }}</div>
            <div class="col-1">
              <button (click)="confirmationOfDelete(order,confirmation)" class="font-11 border-0 p-0 m-0 pointer">
                <img alt="delete" src="../../assets/img/delete.svg" class="deleteIcon" />
              </button>
            </div>
            <div class="col-1">
              <a [href]="openPdf(order)" target="_blank" class="text-dark">
                <img alt="Download Pdf" title="Purchase Order Pdf" src="../../assets/img/pdf.svg"
                  class="searchIcon mr-3" />
              </a>
            </div>
          </div>
        </div>
        <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none">
          <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12" *ngFor="let order of allPurchaseData">
              <div class="cardStyle my-3">
                <div class="font-14 bold text-dark py-2">
                  <div class="font-12 text-grey light pb-1">
                    PO Number :</div> {{ order.purchaseOrderNumber }}
                </div>
                <div class="font-14 bold text-dark py-2">
                  <div class="font-12 text-grey light pb-1">Customer Name :</div> {{ order.customer.tenantName }},
                  {{ order.customer.address.addressLine1 }},
                  {{ order.customer.address.addressLine2 }}, {{ order.customer.address.city }}, {{
                  order.customer.address.state
                }}, {{order.customer.address.pinCode}}
                </div>
                <div class="font-14 bold text-dark py-2">
                  <div class="font-12 text-grey light pb-1">Vendor Name :</div> {{ order.vendor.vendorName }} ,
                  {{ order.vendor.address.addressLine1 }},
                  {{ order.vendor.address.addressLine2 }}, {{ order.vendor.address.city }}, {{
                  order.vendor.address.state
                }}, {{order.vendor.address.pinCode}}
                </div>
                <div class="font-14 bold text-dark py-2">
                  <div class="font-12 text-grey light pb-1">Project Number :</div> {{ order.projectNumber }}
                </div>
                <div class="font-14 bold text-dark py-2">
                  <div class="font-12 text-grey light pb-1">Grand Total :</div> {{ order.grandTotal }}
                </div>
                <div class="row pt-3">
                  <div class="col">
                    <a [href]="openPdf(order)" target="_blank" class="text-dark">
                      <img alt="Download Pdf" title="Purchase Order Pdf" src="../../assets/img/pdf.svg"
                        class="searchIcon mr-3" />
                    </a>
                  </div>
                  <div class="col text-right"> <button (click)="confirmationOfDelete(order,confirmation)"
                      class="font-11 border-0 p-0 m-0 pointer">
                      <img alt="delete" src="../../assets/img/delete.svg" class="deleteIcon" />
                    </button></div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <div *ngIf="this.pageSize < this.totalElements">
      <pagination [totalItems]="totalElements" previousText="&laquo;" nextText="&raquo;" [itemsPerPage]="50"
        [maxSize]="10" ({ngModel})="currentPage" (pageChanged)="pageChanged($event)"></pagination>
    </div>
    <ng-template #confirmation>
      <div class="modal-body text-center">
        <p>Do you want to Delete?</p>
        <button type="button" class="btn btn-primary" (click)="onClickCancel()">Yes</button>
        <button type="button" class="btn btn-default" (click)="decline()">No</button>
      </div>
    </ng-template>
  </div>
</div>