import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidateAllFormFields } from '../utils/validateform';
import { CustomerService } from '../services/customer.service';
import { VendorService } from '../services/vendor.service';
var writtenNumber = require('written-number');
import { PurchaseService } from '../services/purchase.service';
import { CustomValidators } from '../validators/custom.validator';
@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {
purchaseOrderFormGroup!: FormGroup;
purchaseGenerateForm!: FormGroup;
  orderStage = false;
  vendorId: string = '';
  customerId: string = '';
  allCustomerData: any ; // @TODO need data
  allVendorData: any;
  selectedCustomerData: any;
  selectedVendorData: any;
  subTotal: any;
  totalAmount: any;
  amountInWords: any;
  amount :any[]= [];
  quantityChange :any[] = [];
  unitRateChange :any[] = [];
  cgst = '0';
  igst = '0';
  sgst = '0';
  constructor(private purchaseService: PurchaseService,
              private customerService: CustomerService ,
              private vendorService: VendorService,
              private router: Router ,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.getVendorList();
    this.initGenerateForm();
    this.initOrderForm();
  }
  amountChanges() {
    for (let i = 0; i < this.quantityChange.length; i++) {
        this.amount[i] = (this.quantityChange[i] * this.unitRateChange[i]).toFixed(2);
      }
    this.subTotal = 0;
    this.amount.forEach((element) => {
      this.subTotal = (Number(element) + Number(this.subTotal)).toFixed(2);
    });
    this.calculateTotal();
}
  onQuantityChange(event: any, index: any) {
    this.quantityChange[index] = event.value;
    if ( this.unitRateChange.length === this.quantityChange.length ) {
      this.amountChanges();
    }
  }
  onUnitRateChange(event: any, index: number) {
    this.unitRateChange[index] = event.value;
    if ( this.unitRateChange.length === this.quantityChange.length ) {
      this.amountChanges();
    }
  }
  calculateTotal() {
    if ( this.selectedVendorData.address.state === this.selectedCustomerData.address.state) {
      this.sgst = Number((this.subTotal * 9) / 100).toFixed(0);
      this.cgst = Number((this.subTotal * 9) / 100).toFixed(0);
    } else {
      this.igst = Number((this.subTotal * 18) / 100).toFixed(0);
    }
    const cess = this.purchaseOrderFormGroup.controls['cess'].value;
    this.totalAmount = (Number(this.igst) + Number(this.sgst) + Number(this.cgst) + Number(cess) + Number(this.subTotal)).toFixed(0);
    this.amountInWords = writtenNumber(this.totalAmount);
  }
  getCustomerList(vendorId: any) {
    this.customerService.getCustomerbyVendorId(vendorId).subscribe((ele) => {
      this.selectedCustomerData = ele.response.customerId;
    });
  }
  getVendorList() {
    this.vendorService.getVendors().subscribe((ele) => {
      this.allVendorData = ele.response.content;
    });
  }
  initGenerateForm() {
    this.purchaseGenerateForm = this.formBuilder.group({
      vendorId: new FormControl('', [
        Validators.required,
      ])
    });
  }
  onClickCancel() {
    this.router.navigate(['purchase-orders']);
  }
  initOrderForm() {
    this.purchaseOrderFormGroup = this.formBuilder.group({
      purchaseOrderNumber: new FormControl(null, [
        Validators.required,


      ]),
      purchaseOrderDate: new FormControl('',
      Validators.compose([
        Validators.required,
      ]), ),
      referenceNumber: new FormControl(null, [
        Validators.required,


      ]),
      purchaseOrderDescription: new FormControl(null, [
        Validators.required,
      ]),
      shipToAddress: new FormControl(null, [


      ]),
      shipToCity: new FormControl(null, [


      ]),
      shipToState: new FormControl(null, [


      ]),
      shipToCountry: new FormControl(null, [


      ]),
      shipToPincode: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^\\d+$'),
          Validators.minLength(6),
          Validators.maxLength(6),
        ]),
      ],
      billToAddress: new FormControl(null, [


      ]),
      billToCity: new FormControl(null, [


      ]),
      billToState: new FormControl(null, [


      ]),
      billToCountry: new FormControl(null, [


      ]),
      billToPincode: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^\\d+$'),
          Validators.minLength(6),
          Validators.maxLength(6),
        ]),
      ],
      priceBasis: new FormControl(null, [
      ]),
      openCredit: new FormControl(null, [
      ]),
      beforeDelivery: new FormControl(null, [
      ]),
      afterDelivery: new FormControl(null, [


      ]),
      currency:['', [Validators.required]],
      rfaNumber: new FormControl(null, [
      ]),
      rfaDate: new FormControl(null, [
      ]),
      frieghtTerms: new FormControl(null, [


      ]),
      frieghtCarrier: new FormControl(null, [

      ]),
      documentsOrReportsNeeded: new FormControl(null, [
      ]),
      termsAndConditions: new FormControl(null, []),
      projectNumber: new FormControl(null, [
        Validators.required
      ]),
      invoices: new FormArray([]),
      deliveryDate: new FormControl(null, [
        Validators.required
      ]),
      shippingQuantity: ['',[Validators.required, Validators.pattern('^\\d+$')]],
      cess: new FormControl(null, []),
    });
    this.addNewInvoice();
  }
  addNewInvoice() {
    const isInvoicesFormArray = this.purchaseOrderFormGroup.controls['invoices'].valid;
    if (isInvoicesFormArray) {
      const invoices = this.purchaseOrderFormGroup.controls['invoices'] as FormArray;
      invoices.push(this.formBuilder.group({
      description: new FormControl(null, [
        Validators.required
      ]),
      uam: new FormControl(null, [
        Validators.required
      ]),
      hsnCode: new FormControl(null, [
        Validators.required
      ]),
      quantity: [
        '',[Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')],
      ],
      unitRate: [
        '',[Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      amount: new FormControl(null, []),
    }));
  } else {
    ValidateAllFormFields(this.purchaseOrderFormGroup);
  }
  }
  onSubmitPurchaseGenerateForm(value: any) {
if (this.purchaseGenerateForm.valid) {
  this.selectedVendorData = this.allVendorData.find((ele:any) => ele.vendorId === value.vendorId);
  this.vendorId = value.vendorId;
  this.getCustomerList(this.vendorId);
  this.orderStage = true;
} else {
  ValidateAllFormFields(this.purchaseGenerateForm);
}
  }
  onSubmitPurchaseOrderForm(value: any) {
    if (this.purchaseOrderFormGroup.valid) {
      for (let index = 0; index < this.amount.length; index++) {
        value.invoices[index].amount = this.amount[index];
     }
      const request = {
      purchaseOrderNumber : value.purchaseOrderNumber,
      purchaseOrderDate: value.purchaseOrderDate ,
      referenceNumber: value.referenceNumber ,
      purchaseOrderDescription: value.purchaseOrderDescription,
      vendorId: this.vendorId ,
      shipToAddress: value.shipToAddress ,
      shipToCity: value.shipToCity ,
      shipToState: value.shipToState ,
      shipToCountry: value.shipToCountry ,
      shipToPincode: value.shipToPincode ,
      billToAddress: value.billToAddress ,
      billToCity: value.billToCity ,
      billToState: value.billToState ,
      billToCountry: value.billToCountry ,
      billToPincode: value.billToPincode ,
      priceBasis: value.priceBasis ,
      openCredit: value.openCredit ,
      beforeDelivery: value.beforeDelivery ,
      afterDelivery: value.afterDelivery ,
      currency: value.currency ,
      rfaNumber: value.rfaNumber ,
      totalAmountInWords: this.amountInWords ,
      rfaDate: value.rfaDate ,
      frieghtTerms: value.frieghtTerms ,
      frieghtCarrier: value.frieghtCarrier ,
      subTotal: this.subTotal ,
      igst: this.igst ,
      sgst: this.sgst ,
      cgst: this.cgst ,
      cess: value.cess ,
      grandTotal: this.totalAmount ,
      documentsOrReportsNeeded: value.documentsOrReportsNeeded ,
      termsAndConditions: value.termsAndConditions ,
      itemSpecification: value.invoices,
      projectNumber: value.projectNumber ,
      deliveryDate: value.deliveryDate ,
      shippingQuantity: value.shippingQuantity,
     };
      this.purchaseService.submitPurchaseOrder(request).subscribe(ele => {
      this.router.navigate(['/purchase-orders']);
    });
    } else {
      ValidateAllFormFields(this.purchaseOrderFormGroup);
    }
  }
  get invoicesFormArray() {
    return this.purchaseOrderFormGroup.controls['invoices'] as FormArray;
  }
  onRemoveFormGroup(i: number) {
    const invoices = this.purchaseOrderFormGroup.controls['invoices'] as FormArray;
    this.quantityChange.splice(i, 1);
    this.unitRateChange.splice(i, 1);
    this.amount = [];
    this.amountChanges();
    invoices.removeAt(i);
    }

}
