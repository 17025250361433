<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Accounts</div>
  <div class="px-lg-5 py-5 px-4 minHeight80vh bg-white m-lg-3 font-14">
    <div class="row pb-2">
      <div class="col-9 col-xl-10 col-lg-9 col-md-8 col-sm-9">Account History</div>
      <div class="col-3 col-xl-2 col-lg-3 col-md-4 col-sm-3  text-right" *ngIf="dataService.adminStatus1">
        <img (click)="onClickAddTransaction()" alt="add" src="../../assets/img/plus.svg" title="Add Amount"
          class="addIcon w-100 pointer" />
      </div>

    </div>

    <div class="row font-14 py-1">
      <div style="max-width: 135px;" class="col-12 col-xl-2 col-lg-2 col-md-5 col-sm-6 light">Employee Name:</div>
      <div class="col-12 col-xl-2 col-lg-4 col-md-7 col-sm-6 medium">{{employeeDetails?.user.firstName}}</div>

      <div style="max-width: 135px;" class="col-12 col-xl-2 col-lg-2 col-md-5 col-sm-6 light">Employee ID:</div>
      <div class="col-12 col-xl-2 col-lg-4 col-md-7 col-sm-6 medium"> {{employeeDetails?.employeeId}}</div>
      <div style="max-width: 135px;" class="col-12 col-xl-2 col-lg-2 col-md-5 col-sm-6 light">Balance in hand :</div>
      <div class="col-12 col-xl-2 col-lg-4 col-md-7 col-sm-6 medium">&#x20B9;{{balanceInHand}}</div>
    </div>
    <form [formGroup]="searchByDateForm">
      <div class="row py-3">
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-5">
          <select class="w-100 height-30 px-3 font-12 medium darkGreyFont pointer" formControlName="year">
            <option value="" selected>Select Year</option>
            <option class="pointer" *ngFor="let year of range; let i = index" [value]="year.id">
              {{ year.name }}
            </option>
          </select>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-5">
          <select class="w-100 height-30 px-3 font-12 medium darkGreyFont pointer" formControlName="month">
            <option value="" selected>Select Month</option>
            <option class="pointer" *ngFor="let month of month; let i = index" [value]="month.id">
              {{ month.name }}
            </option>
          </select>
        </div>
        <div class="col-xl-6 col-lg-3 col-md-4 col-sm-6 col-2 text-right">
          <img (click)="downloadTransactionHistory()" class="width-30 pointer" alt="download" tooltip="Download"
            src="../../../assets/img/download.png" />
        </div>
      </div>
    </form>

    <div class="pb-5 font-12">

      <div class="d-none d-sm-none d-md-none d-lg-block d-xl-block">
        <div class="row medium py-3">
          <div style="max-width: 70px;" class="col">Sl. No.</div>
          <div style="max-width: 100px;" class="col">Date</div>
          <div style="max-width: 120px;" class="col">Type</div>
          <div style="max-width: 120px;" class="col">Category</div>
          <div class="col">Description</div>
          <div style="max-width: 100px;" class="col">Amount</div>
          <div class="col">Status</div>
          <div style="max-width: 70px;" class="col">File</div>
        </div>
        <div *ngFor="let transaction of allTransaction; let i = index" class="row py-2 light">
          <div style="max-width: 70px;" class="col">{{i+1+initIndex}}</div>
          <div style="max-width: 100px;" class="col">{{transaction.transactionDate | date:"dd/MM/yyyy"}}</div>
          <div style="max-width: 120px;" class="col">{{transaction.transactionType}}</div>
          <div style="max-width: 120px;" class="col">{{transaction.category | transform }}</div>
          <div class="col">{{transaction.remarks}}</div>
          <div style="max-width: 100px;" class="col">&#x20B9;{{transaction.amount}}</div>
          <div class="col">
            <div class="row" *ngIf="transaction.transactionType !== 'CREDITED'">
              <div class="col-6" *ngIf="transaction.transactionStatus === 'SUBMITTED'">
                <button type="button" class="orangeBG font-12 px-2 w-100 py-1 border-0 text-white pointer"
                  style="margin-top: -5px;"
                  (click)="onTransactionStatus('APPROVED',transaction.transactionId,i,rejectRemarkModel)">Approve</button>
              </div>
              <div class="col-6" *ngIf="transaction.transactionStatus === 'SUBMITTED'">
                <button type="button" class="darkGreyBG font-12 px-2 w-100 py-1 border-0 text-white pointer"
                  style="margin-top: -5px;"
                  (click)="onTransactionStatus('REJECTED',transaction.transactionId,i,rejectRemarkModel)">Reject</button>
              </div>
              <div class="col-6" *ngIf="transaction.transactionStatus === 'APPROVED'">
                Approved
              </div>
              <div class="col-6" *ngIf="transaction.transactionStatus === 'REJECTED'">
                Rejected
              </div>
            </div>
          </div>
          <div style="max-width: 70px;" class="col">
            <div *ngIf="transaction.transactionType !== 'CREDITED' && transaction.fileName">
              <img (click)="downloadImage(transaction.transactionId)" style="margin-top: -5px;" class="width-30 pointer"
                alt="download" src="../../assets/img/file.svg" title="View File" />
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12"
            *ngFor="let transaction of allTransaction; let i = index">
            <div class="cardStyle my-3">
              <div class="font-14 bold text-dark py-2">
                <div class="font-12 text-grey light pb-1">Date :</div>
                {{transaction.transactionDate | date:"dd/MM/yyyy"}}
              </div>
              <div class="font-14 bold text-dark py-2">
                <div class="font-12 text-grey light pb-1">Type :</div>
                {{transaction.transactionType}}
              </div>
              <div class="font-14 bold text-dark py-2">
                <div class="font-12 text-grey light pb-1">Category :</div>{{transaction.category | transform }}
              </div>
              <div class="font-14 bold text-dark py-2">
                <div class="font-12 text-grey light pb-1">Description :</div>{{transaction.remarks}}
              </div>

              <div class="font-14 bold text-dark py-2">
                <div class="font-12 text-grey light pb-1">Amount :</div>{{transaction.amount}}
              </div>
              <div class="row pt-4" *ngIf=" transaction.transactionType !=='CREDITED'">
                <div class=" col-9 col-sm-9">
                  <div class="row">
                    <div class="col-6" *ngIf="transaction.transactionStatus === 'SUBMITTED'">
                      <button type="button" class="font-12 border-0 saveBtn redBG text-white light pointer"
                        (click)="onTransactionStatus('APPROVED',transaction.transactionId,i,rejectRemarkModel)">Approve</button>
                    </div>
                    <div class="col-6" *ngIf="transaction.transactionStatus === 'SUBMITTED'">
                      <button type="button" class="darkGreyBG font-12 border-0 saveBtn text-white light pointer"
                        (click)="onTransactionStatus('REJECTED',transaction.transactionId,i,rejectRemarkModel)">Reject</button>
                    </div>

                    <div class="col-6 bold font-14 my-1" *ngIf="transaction.transactionStatus === 'APPROVED'">
                      Approved
                    </div>
                    <div class="col-6 bold font-14 my-1" *ngIf="transaction.transactionStatus === 'REJECTED'">
                      Rejected
                    </div>
                  </div>
                </div>
                <div class="col-3 text-right">
                  <div *ngIf="transaction.transactionType !== 'CREDITED' && transaction.fileName">
                    <img (click)="downloadImage(transaction.transactionId)" class="width-20 pointer my-1" alt="download"
                      src="../../assets/img/file.svg" title="View File" />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center row w-100 py-4" style="padding: 50%;font-size: 12px;">
    <pagination [totalItems]="totalElements" previousText="&laquo;" nextText="&raquo;" [itemsPerPage]="pageSize"
      (pageChanged)="pageChanged($event)"></pagination>
  </div>
  </div>
  <ng-template #rejectRemarkModel>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Remarks</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="rejectRemarkForm">
        <div class="form-group">
          <label class=" font-12">Remarks</label>
          <textarea pInputTextarea formControlName="remarks" placeholder="Enter Remarks"
            class="form-control"></textarea>
          <div class="error " *ngIf="rejectRemarkForm.get('remarks')?.invalid && rejectRemarkForm.get('remarks')?.dirty">
            <span *ngIf="rejectRemarkForm.get('remarks')?.hasError('required')">Remarks
              Required</span>
            <span
              *ngIf="!rejectRemarkForm.get('remarks')?.hasError('required') && rejectRemarkForm.get('remarks')?.hasError('minlength')">Remarks
              should be atleast 5 characters !</span>
            <span
              *ngIf="!rejectRemarkForm.get('remarks')?.hasError('required') && rejectRemarkForm.get('remarks')?.hasError('maxlength')">Remarks
              should not be more than 500 characters !</span>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button (click)="onRemoveRemarks()" type="button"
        class="font-14 border-0 mr-6 submitBtn darkGreyBG text-white pointer">
        Cancel
      </button>
      <button type="button" class="font-14 border-0 submitBtn redBG text-white pointer"
        (click)="onConfirmStatusChange()">
        Submit
      </button>
    </div>
  </ng-template>
