import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MasterService } from '../services/master.service';
import { EmployeeService } from './../services/employee.service';
import { CustomMessageService } from '../core/message/message.service';
import { ValidateAllFormFields } from '../utils/validateform';
import * as moment from 'moment';
@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.scss'],
})
export class EditEmployeeComponent implements OnInit {
  id: any;
  addEmployeeForm!: FormGroup;
  allSites: any[] = [];
  employeeCategory: any;
  da: any;
  otherAllowance: any;
  ppeAllowance: any;
  foodAllowance: any;
  basic: any;
  incomeTax: any;
  employeeData: any;
  showSalaryDetailsForPermanent = true;
  employeeId: any;
  Designations: any[] = [];
  BankNames: any[] = [];
  dateOfBirth :any;
  constructor(
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private masterService: MasterService,
    private router: Router,
    private messagingService: CustomMessageService,
    private employeeService: EmployeeService,
  ) {
    this.id = this.route.snapshot.queryParamMap.get('id');
  }

  ngOnInit() {
    this.initForm();
    this.getAllDesignation();
    this.getAllBanks();
    this.addEmployeeForm = this.formBuilder.group({
      employeeName: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z s]*$')]),
      ],
      employeeDesignation: [
        '',
        Validators.compose([Validators.required]),
      ],
      employeeId: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9 s]*$')]),
      ],
      employeeType: ['', Validators.compose([Validators.required])],
      employeeCategory: [''],
      contactNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^\\d+$'),
          Validators.minLength(10),
          Validators.maxLength(10),
        ]),
      ],
      site: ['', Validators.compose([Validators.required])],
      dateOfBirth: ['', Validators.compose([Validators.required])],
      uan: [
        '',
        Validators.compose([
          Validators.pattern('^[a-zA-Z0-9 ]*$'),
          Validators.maxLength(12),
        ]),
      ],
      bloodGroup: [''],
      address: [''],
      gender: ['', Validators.compose([Validators.required])],
      city: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z s]*$')])],
      state: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z s]*$')])],
      country: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z s]*$')])],
      pincode: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^\\d+$'),
          Validators.minLength(6),
          Validators.maxLength(6),
        ]),
      ],
      mobileAllowance: ['', Validators.compose([Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')])],
      basic: [''],
      da: [''],
      otherAllowance: [''],
      ppeAllowance: [''],
      foodAllowance: [''],
      esiNo: [''],
      incomeTax: [''],
      accountNumber: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
      ],
      accountName: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]),
      ],
      bankName: [
        '',
        Validators.compose([Validators.required]),
      ],
      branchName: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]),
      ],
      ifscCode: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]),
      ],
      emergencyContactName: [
        '',
        Validators.compose([Validators.pattern('^[a-zA-Z s]*$')]),
      ],
      emergencyContactNumber: [
        '',
        Validators.compose([
          Validators.pattern('^\\d+$'),
          Validators.minLength(10),
          Validators.maxLength(10),
        ]),
      ],
      emergencyContactRelation: [
        '',
        Validators.compose([Validators.pattern('^[a-zA-Z0-9 ]*$')]),
      ],
      epf: [''],
      esi: [''],
      aadhar: ['', [Validators.pattern('^[2-9]{1}[0-9]{11}$')]],
      dateOfJoining: ['']
    });
    this.employeeCategory = this.addEmployeeForm.get('employeeCategory');
    this.incomeTax = this.addEmployeeForm.get('incomeTax');
    this.basic = this.addEmployeeForm.get('basic');
    this.da = this.addEmployeeForm.get('da');
    this.otherAllowance = this.addEmployeeForm.get('otherAllowance');
    this.ppeAllowance = this.addEmployeeForm.get('ppeAllowance');
    this.foodAllowance = this.addEmployeeForm.get('foodAllowance');
    this.incomeTax = this.addEmployeeForm.get('incomeTax');
    this.getData();
  }

  get18YearsFromToday() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    return new Date(year - 18, month, day);
  }

  cancelRegistration() {
    this.router.navigate(['/list-employee']);
  }
  initForm() {
    this.masterService.getAllSites(1, 1000).subscribe(res => {
      this.allSites = res.response.content.map((el: any) => ({
        label: el.siteName,
        value: el.siteId}));
    });
  }
  getAllDesignation() {
    this.masterService.getAllEmployeeDesignation().subscribe(res => {
      this.Designations = res.response;
    });
  }
  getAllBanks() {
    this.masterService.getAllBanks().subscribe(res => {
      this.BankNames = res.response;
    });
  }
  actOnEmpType() {
    if (this.addEmployeeForm.value.employeeType === 'PERMANENT') {
      this.showSalaryDetailsForPermanent = true;
      this.employeeCategory.setValidators('');
      this.basic.setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]));
      this.da.setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]));
      this.otherAllowance.setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]));
      this.ppeAllowance.setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]));
      this.foodAllowance.setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]));
      this.incomeTax.setValidators(Validators.compose([Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]));
    } else {
      this.showSalaryDetailsForPermanent = false;
      this.employeeCategory.setValidators([Validators.required]);
      this.basic.setValidators(Validators.compose([Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]));
      this.da.setValidators(Validators.compose([Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]));
      this.otherAllowance.setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]));
      this.ppeAllowance.setValidators(Validators.compose([Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]));
      this.foodAllowance.setValidators(Validators.compose([Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]));
      this.incomeTax.setValidators('');
    }
    this.employeeCategory.updateValueAndValidity();
    this.basic.updateValueAndValidity();
    this.da.updateValueAndValidity();
    this.otherAllowance.updateValueAndValidity();
    this.ppeAllowance.updateValueAndValidity();
    this.foodAllowance.updateValueAndValidity();
    this.incomeTax.updateValueAndValidity();
  }
  registerEmployee() {
    // let data;
    let bloodGroupId;
    let employeeCategoryId;
    if (this.addEmployeeForm.valid) {
      if (this.addEmployeeForm.value.bloodGroup !== '') {
        bloodGroupId = this.addEmployeeForm.value.bloodGroup;
      }
      if (this.addEmployeeForm.value.employeeCategory !== '') {
        employeeCategoryId = this.addEmployeeForm.value.employeeCategory;
      }
      const data = {
          employeeName: this.addEmployeeForm.value.employeeName,
          designation: this.addEmployeeForm.value.employeeDesignation,
          employeeId: this.addEmployeeForm.value.employeeId,
          employeeType: this.addEmployeeForm.value.employeeType,
          employeeCategory: employeeCategoryId,
          contactNumber: this.addEmployeeForm.value.contactNumber,
          site: this.addEmployeeForm.value.site,
          dateOfBirth: moment(this.addEmployeeForm.value.dateOfBirth).format('DD/MM/YYYY'),
          uan: this.addEmployeeForm.value.uan,
          address: this.addEmployeeForm.value.address,
          city: this.addEmployeeForm.value.city,
          state: this.addEmployeeForm.value.state,
          country: this.addEmployeeForm.value.country,
          pincode: this.addEmployeeForm.value.pincode,
          bloodGroup: bloodGroupId,
          basic: this.addEmployeeForm.value.basic,
          da: this.addEmployeeForm.value.da,
          isEpf: this.addEmployeeForm.value.epf,
          isEsi: this.addEmployeeForm.value.esi,
          otherAllowance: this.addEmployeeForm.value.otherAllowance,
          ppeAllowance: this.addEmployeeForm.value.ppeAllowance,
          foodAllowance: this.addEmployeeForm.value.foodAllowance,
          mobileAllowance: this.addEmployeeForm.value.mobileAllowance,
          incomeTax: this.addEmployeeForm.value.incomeTax,
          esiNo: this.addEmployeeForm.value.esiNo,
          gender: this.addEmployeeForm.value.gender,
          accountNumber: this.addEmployeeForm.value.accountNumber,
          accountName: this.addEmployeeForm.value.accountName,
          bankName: this.addEmployeeForm.value.bankName,
          branchName: this.addEmployeeForm.value.branchName,
          ifscCode: this.addEmployeeForm.value.ifscCode,
          emergencyContactName: this.addEmployeeForm.value.emergencyContactName,
          emergencyContactNumber: this.addEmployeeForm.value.emergencyContactNumber,
        emergencyContactRelation: this.addEmployeeForm.value.emergencyContactRelation,
        aadhar: this.addEmployeeForm.value.aadhar,
        dateOfJoining: this.addEmployeeForm.value.dateOfJoining ? moment(this.addEmployeeForm.value.dateOfJoining).format('DD/MM/YYYY'): '',
        };
      this.employeeService.editEmployee(data, this.employeeId).subscribe(res => {
        if (res.status.code === 0) {
          this.router.navigate(['/list-employee']);
        } else {
          this.messagingService.notify('error', 'Error', res.status.message);
        }
      });
    } else {
      ValidateAllFormFields(this.addEmployeeForm);
    }
  }
  getData() {
    this.employeeService.getEmployee(this.id).subscribe((res: any) => {
      this.employeeData = res.response;
      this.employeeId = res.response.employeeId;
      if (this.employeeData.salaryDetails === undefined) {
        this.addEmployeeForm.patchValue({
          basic: '',
          da: '',
          otherAllowance: '',
          ppeAllowance: '',
          foodAllowance: '',
          incomeTax: '',
        });
      } else {
        this.addEmployeeForm.patchValue({
          basic: this.employeeData.salaryDetails.basic,
          da: this.employeeData.salaryDetails.da,
          otherAllowance: this.employeeData.salaryDetails.otherAllowance,
          ppeAllowance: this.employeeData.salaryDetails.ppeAllowance,
          incomeTax: this.employeeData.salaryDetails.incomeTax,
          foodAllowance: this.employeeData.salaryDetails.foodAllowance,
        });
      }
      this.addEmployeeForm.patchValue({
        epf: this.employeeData.epf,
        esi: this.employeeData.esi,
        bloodGroup: this.employeeData.user.bloodGroup === undefined ? '' : this.employeeData.user.bloodGroup,
        employeeCategory: this.employeeData.employeeCategory === undefined ? '' : this.employeeData.employeeCategory,
        employeeName: this.employeeData.user.firstName,
        employeeDesignation: this.employeeData.designationId.designationId,
        employeeType: this.employeeData.employeeType,
        contactNumber: this.employeeData.user.mobileNumber,
        site: this.employeeData.site.siteId,
        employeeId: this.employeeData.employeeId,
        dateOfBirth: new Date(this.employeeData.user.dateOfBirth),
        uan: this.employeeData.uan,
        address: this.employeeData.address.addressLine1,
        city: this.employeeData.address.city,
        state: this.employeeData.address.state,
        country: this.employeeData.address.country,
        pincode: this.employeeData.address.pinCode,
        esiNo: this.employeeData.esiNo,
        gender: this.employeeData.user.gender,
        mobileAllowance: this.employeeData.mobileAllowance,
        accountNumber: this.employeeData.account.accountNumber,
        accountName: this.employeeData.account.accountName,
        bankName: this.employeeData.account.bankId.bankId,
        branchName: this.employeeData.account.branchName,
        ifscCode: this.employeeData.account.ifscCode,
        emergencyContactName: this.employeeData.emergencyContactName,
        emergencyContactNumber: this.employeeData.emergencyContactNumber,
        emergencyContactRelation: this.employeeData.emergencyContactRelation,
        aadhar: this.employeeData.aadhar,
        dateOfJoining: this.employeeData.dateOfJoining? new Date(this.employeeData.dateOfJoining): '',
      });
      if (this.employeeData.employeeType === 'PERMANENT') {
        this.showSalaryDetailsForPermanent = true;
        this.employeeCategory.setValidators('');
      } else {
        this.showSalaryDetailsForPermanent = false;
        this.employeeCategory.setValidators([Validators.required]);
      }
      this.employeeCategory.updateValueAndValidity();
    });
  }
}
