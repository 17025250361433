import { ReportService } from './../services/report.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { saveAs } from 'file-saver';

import { MasterService } from '../services/master.service';
import { UserService } from '../services/user.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  bobReportForm!: FormGroup;
  otherBankReportForm!: FormGroup;
  year: any;
  selectedMonth: any;
  selectedYear: any;
  bobData: any[] = [];
  otherBankData: any[] = [];
  month: any;
  range: any[]= [];
  bobSelectsite: any;
  bobAllowanceSelectSite: any;
  otherBankSelectsite: any;
  otherAllowanceSelectSite: any;
  allSites: any;
  multipleSitesSettings: IDropdownSettings = {};
  multipleZonesSettings: IDropdownSettings = {};
  multipleSite = [];
  zoneList: any;
  bobSite: any;
  bobZone: any;
  otherBankSite: any;
  otherBankZone: any;
  currentPage = 1;
  pageSize = 1000;

  constructor(private formBuilder: FormBuilder, private userService: UserService, private reportService: ReportService, private masterService: MasterService) {}
  ngOnInit() {
    this.year = 2020;
    this.selectedYear =  new Date().getFullYear();
    this.selectedMonth = new Date().getMonth() + 1;
    this.setRage();
    this.onBobReportForm();
    this.onOtherBankReportForm();
    this.getAllSites();
    this.getZone();
    this.bobReportTab();
    this.bobReportForm.valueChanges.subscribe(() => {
        this.bobReportTab();
      });
    this.otherBankReportForm.valueChanges.subscribe(() => {
        this.otherBankReportTab();
      });
  }
  getAllSites() {
    this.masterService.getAllSites(this.currentPage, this.pageSize).subscribe(res => {
      this.allSites = res.response.content;
    });
    this.multipleSitesSettings = {
        singleSelection: false,
        idField: 'siteId',
        textField: 'siteName',
        allowSearchFilter: true,
        itemsShowLimit: 1
      };
  }
  getZone() {
    this.userService.getZone().subscribe(res => {
      if (res.status.code === 0) {
        this.zoneList = res.response.content;
      }
    });
    this.multipleZonesSettings = {
      singleSelection: false,
      idField: 'zoneId',
      textField: 'zoneName',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
  }
  bobReportTab() {
    if (this.bobReportForm.value.multipleSites === undefined ||
        this.bobReportForm.value.multipleSites === '') {
      this.bobSite = '';
    } else {
      this.bobSite = this.bobReportForm.value.multipleSites;
      this.bobSite = (this.bobSite.map((ele: any) => (ele.siteId))).toString();
    }
    if (this.bobReportForm.value.multipleZones === undefined ||
      this.bobReportForm.value.multipleZones === '') {
        this.bobZone = '';
    } else {
      this.bobZone = this.bobReportForm.value.multipleZones;
      this.bobZone = (this.bobZone.map((ele: any) => (ele.zoneId))).toString();
    }
    const request = {
    month: this.month[this.bobReportForm.value.month - 1].name,
    year: this.bobReportForm.value.year,
    siteId: this.bobSite,
    zones: this.bobZone
  };
    this.reportService
       .getBobReport(this.bobReportForm.value.reportType , request)
       .subscribe(res => {
         if (res) {
           if (res.status.code === 0) {
             this.bobData = res.response;
           }
         }
       });
   }
  otherBankReportTab() {
    if (this.otherBankReportForm.value.multipleSites === undefined ||
        this.otherBankReportForm.value.multipleSites === '') {
      this.otherBankSite = '';
    } else {
      this.otherBankSite = this.otherBankReportForm.value.multipleSites;
      this.otherBankSite = (this.otherBankSite.map((ele: any) => (ele.siteId))).toString();
    }
    if (this.otherBankReportForm.value.multipleZones === undefined ||
      this.otherBankReportForm.value.multipleZones === '') {
        this.otherBankZone = '';
    } else {
      this.otherBankZone = this.otherBankReportForm.value.multipleZones;
      this.otherBankZone = (this.otherBankZone.map((ele: any) => (ele.zoneId))).toString();
    }
    const request = {
      month:  this.month[this.otherBankReportForm.value.month - 1].name,
      year: this.otherBankReportForm.value.year,
      siteId:  this.otherBankSite,
      zones: this.otherBankZone
    };
    this.reportService
        .getOtherBankReport(this.otherBankReportForm.value.reportType, request)
        .subscribe(res => {
          if (res) {
            if (res.status.code === 0) {
              this.otherBankData = res.response;
            }
          }
        });
  }
  onBobReportForm() {
    this.bobReportForm = this.formBuilder.group({
      year: [this.selectedYear, Validators.compose([Validators.required])],
      month: [this.selectedMonth, Validators.compose([Validators.required])],
      reportType: ['bobCombinedReport'],
      multipleSites: [''],
      multipleZones: ['']
    });
  }
  onOtherBankReportForm() {
    this.otherBankReportForm = this.formBuilder.group({
      year: [this.selectedYear, Validators.compose([Validators.required])],
      month: [this.selectedMonth, Validators.compose([Validators.required])],
      multipleSites: [''],
      multipleZones: [''],
      reportType: ['combinedReport'],
    });
  }
  setRage() {
    this.month = [
      { id: 1, name: 'January' },
      { id: 2, name: 'February' },
      { id: 3, name: 'March' },
      { id: 4, name: 'April' },
      { id: 5, name: 'May' },
      { id: 6, name: 'June' },
      { id: 7, name: 'July' },
      { id: 8, name: 'August' },
      { id: 9, name: 'September' },
      { id: 10, name: 'October' },
      { id: 11, name: 'November' },
      { id: 12, name: 'December' },
    ];
    for (let i = 0; i < 21; i++) {
      this.range.push({
        id: this.year + i,
        name: this.year + i,
      });
    }
  }
  downloadBOBReport() {
    const data = {
      month: this.month[this.bobReportForm.value.month - 1].name,
      year: this.bobReportForm.value.year,
      siteId:  this.bobSite,
      zones: this.bobZone
    };
    let fileName: any;
    switch (this.bobReportForm.value.reportType) {
      case 'bobCombinedReport':
          fileName =  `BOBCombinedReport-${this.month[this.bobReportForm.value.month - 1].name}-${this.bobReportForm.value.year}.xlsx`;
          break;
    case 'bobBasicPayReport':
      fileName = `BobBasicPayReport-${this.month[this.bobReportForm.value.month - 1].name}-${this.bobReportForm.value.year}.xlsx`;
      break;
      case 'bobOtherAllowanceReport':
        fileName = `BobOtherAllowanceReport-${this.month[this.bobReportForm.value.month - 1].name}-${this.bobReportForm.value.year}.xlsx`;
        break;
        default:
        break;
    }
    this.reportService
      .getBobReportDownload(this.bobReportForm.value.reportType, data)
      .subscribe(res => {
              if (res) {
                const file = new File(
                  [res],
                  fileName
                );
                saveAs(file);
              }
            });
  }

  downloadOtherBankReport() {
    const data = {
      month:  this.month[this.otherBankReportForm.value.month - 1].name,
      year: this.otherBankReportForm.value.year,
      siteId: this.otherBankSite,
      zones: this.otherBankZone
    };
    let fileName: any;
    switch (this.otherBankReportForm.value.reportType) {
      case 'combinedReport':
        fileName = `OtherBankCombinedReport-${this.month[this.otherBankReportForm.value.month - 1].name}-${this.otherBankReportForm.value.year}.xlsx`;
        break;
    case 'basicPayReport':
      fileName = `OtherBankBasicPayReport-${this.month[this.otherBankReportForm.value.month - 1].name}-${this.otherBankReportForm.value.year}.xlsx`;
      break;
      case 'otherAllowanceReport':
        fileName = `OtherBankAllowanceReport-${this.month[this.otherBankReportForm.value.month - 1].name}-${this.otherBankReportForm.value.year}.xlsx`;
        break;
        default:
        break;
    }
    this.reportService
      .getOtherReportDownload(this.otherBankReportForm.value.reportType, data)
      .subscribe(res =>   {
        if (res) {
            const file = new File(
              [res],
              fileName,
              { type: 'application/vnd.ms.excel' }
            );
            saveAs(file);
          }
  });
}
   get otherBankAmountDisplayname() {
    if (this.otherBankReportForm.value.reportType === 'combinedReport') {
      return 'NET';
    } else if (this.otherBankReportForm.value.reportType === 'basicPayReport') {
      return 'Basic';
     } else if ( this.otherBankReportForm.value.reportType === 'otherAllowanceReport') {
       return 'Other Allowance';
    }
    return ''
}
 get bobAmountDisplayName() {
  if (this.bobReportForm.value.reportType === 'bobCombinedReport') {
    return 'NET';
  } else if (this.bobReportForm.value.reportType === 'bobBasicPayReport') {
    return 'Basic';
   } else if ( this.bobReportForm.value.reportType === 'bobOtherAllowanceReport') {
     return 'Other Allowance';
  }
  return ''
}
}
