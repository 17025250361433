<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Purchase Order & Invoice</div>
  <div class="px-5 py-4 minHeight80vh bg-white m-4 font-12">
    <div class="row">
      <div class="col-12">
        <div class="pb-4 font-14 pt-1">Add Vendor</div>
      </div>
    </div>
    <div class="mb-3">
      <form [formGroup]="vendorFormGroup">
        <div class="row medium font-12 pb-3">
          <div class="col">
            <div class="medium pb-1">Vendor Name *</div>
            <div class="mb-3">
              <input formControlName="vendorName"  type="text" placeholder="Enter Vendor Name" type="text"
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser">
                <div class="error "
                *ngIf="vendorFormGroup.controls['vendorName'].invalid && vendorFormGroup.controls['vendorName'].dirty">
                <span *ngIf="vendorFormGroup.controls['vendorName'].errors && vendorFormGroup.controls['vendorName'].errors['required']">Vendor Name is Required</span>
                <span *ngIf="vendorFormGroup.controls['vendorName'].errors && !vendorFormGroup.controls['vendorName'].errors['required'] && vendorFormGroup.controls['vendorName'].errors['minlength']">Vendor Name too short</span>
                <span *ngIf="vendorFormGroup.controls['vendorName'].errors && !vendorFormGroup.controls['vendorName'].errors['required'] && vendorFormGroup.controls['vendorName'].errors['maxlength']">Vendor Name too long</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="medium pb-1">GSTIN</div>
            <div class="mb-3">
              <input pInputText formControlName="gstin"  type="text" placeholder="Enter GSTIN" type="text"
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser">
                <div class="error"
                *ngIf="vendorFormGroup.controls['gstin'].invalid && vendorFormGroup.controls['gstin'].dirty">
                <span *ngIf="vendorFormGroup.controls['gstin'].errors && vendorFormGroup.controls['gstin'].errors['maxlength']">GSTIN is Invalid !</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="medium pb-1">CIN</div>
            <div class="mb-3">
              <input pInputText formControlName="cin"  type="text" placeholder="Enter CIN" type="text"
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser">
                <div class="error "
                *ngIf="vendorFormGroup.controls['cin'].invalid && vendorFormGroup.controls['cin'].dirty">
                <span *ngIf="vendorFormGroup.controls['cin'].errors && !vendorFormGroup.controls['cin'].errors['required'] && vendorFormGroup.controls['cin'].errors['minlength']">CIN is Invalid !</span>
                <span *ngIf="vendorFormGroup.controls['cin'].errors && !vendorFormGroup.controls['cin'].errors['required'] && vendorFormGroup.controls['cin'].errors['maxlength']">CIN is Invalid !</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="medium pb-1">PAN </div>
            <div class="mb-3">
              <input pInputText formControlName="pan"  type="text" placeholder="Enter PAN Number" type="text"
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser">
                <div class="error "
                *ngIf="vendorFormGroup.controls['pan'].invalid && vendorFormGroup.controls['pan'].dirty">
                <span *ngIf="vendorFormGroup.controls['pan'].errors && !vendorFormGroup.controls['pan'].errors['required'] && vendorFormGroup.controls['pan'].errors['minlength']">PAN Number is Invalid !</span>
                <span *ngIf="vendorFormGroup.controls['pan'].errors && !vendorFormGroup.controls['pan'].errors['required'] && vendorFormGroup.controls['pan'].errors['maxlength']">PAN Number is Invalid !</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row medium font-12 pt-4">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Address</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12">
                <div class="col">
                  <div class="medium pb-1">Address Line 1 *</div>
                  <div class="mb-3">
                    <input pInputText formControlName="addressLine1"  type="text" placeholder="Address Line 1" class="w-100 light height-40 px-3 inputBoxAddUser font-12 ">
                    <div class="error "
                    *ngIf="vendorFormGroup.controls['addressLine1'].invalid && vendorFormGroup.controls['addressLine1'].dirty">
                    <span *ngIf="vendorFormGroup.controls['addressLine1'].errors && vendorFormGroup.controls['addressLine1'].errors['required']">Address is Required</span>
                    <span *ngIf="vendorFormGroup.controls['addressLine1'].errors && !vendorFormGroup.controls['addressLine1'].errors['required'] && vendorFormGroup.controls['addressLine1'].errors['minlength']">Address is Invalid !</span>
                    <span *ngIf="vendorFormGroup.controls['addressLine1'].errors && !vendorFormGroup.controls['addressLine1'].errors['required'] && vendorFormGroup.controls['addressLine1'].errors['maxlength']">Address is Invalid !</span>
                  </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Address Line 2 *</div>
                  <div class="mb-3">
                    <input pInputText formControlName="addressLine2"  type="text" placeholder="Address Line 2" class="w-100 light height-40 px-3 inputBoxAddUser font-12 ">
                    <div class="error "
                    *ngIf="vendorFormGroup.controls['addressLine2'].invalid && vendorFormGroup.controls['addressLine2'].dirty">
                    <span *ngIf="vendorFormGroup.controls['addressLine2'].errors && vendorFormGroup.controls['addressLine2'].errors['required']">Address is Required</span>
                    <span *ngIf="vendorFormGroup.controls['addressLine2'].errors && !vendorFormGroup.controls['addressLine2'].errors['required'] && vendorFormGroup.controls['addressLine2'].errors['minlength']">Address is Invalid !</span>
                    <span *ngIf="vendorFormGroup.controls['addressLine2'].errors && !vendorFormGroup.controls['addressLine2'].errors['required'] && vendorFormGroup.controls['addressLine2'].errors['maxlength']">Address is Invalid !</span>
                  </div>
                </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">State *</div>
                  <div class="mb-3">
                    <select
                    class="w-100 light height-40 px-3 inputBoxAddUser font-12"
                     formControlName="state"
                   >
                   <option value="" selected>Select State</option>
                   <option *ngFor="let state of stateNames" [value]="state.stateName">
                     {{state.gstCode}} - {{state.stateName}}
                   </option>
                   </select>
                     <div class="error "
                     *ngIf="vendorFormGroup.controls['state'].invalid && vendorFormGroup.controls['state'].dirty">
                     <span *ngIf="vendorFormGroup.controls['state'].errors && vendorFormGroup.controls['state'].errors['required']">State is Required</span>
                   </div>
                  </div>
                </div>
              </div>

              <div class="row medium font-12">
                <div class="col">
                  <div class="medium pb-1">City/District *</div>
                  <div class="mb-3">
                    <input pInputText formControlName="city"  type="text" placeholder="Enter City/District"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12 ">
                      <div class="error "
                      *ngIf="vendorFormGroup.controls['city'].invalid && vendorFormGroup.controls['city'].dirty">
                      <span *ngIf="vendorFormGroup.controls['city'].errors && vendorFormGroup.controls['city'].errors['required']">City is Required</span>
                      <span *ngIf="vendorFormGroup.controls['city'].errors && !vendorFormGroup.controls['city'].errors['required'] && vendorFormGroup.controls['city'].errors['minlength']">City is Invalid !</span>
                      <span *ngIf="vendorFormGroup.controls['city'].errors && !vendorFormGroup.controls['city'].errors['required'] && vendorFormGroup.controls['city'].errors['maxlength']">City is Invalid !</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Location *</div>
                  <div class="mb-3">
                    <input pInputText formControlName="location"  type="text" placeholder="Enter Location" class="w-100 light height-40 px-3 inputBoxAddUser font-12 ">
                    <div class="error "
                    *ngIf="vendorFormGroup.controls['location'].invalid && vendorFormGroup.controls['location'].dirty">
                    <span *ngIf="vendorFormGroup.controls['location'].errors && vendorFormGroup.controls['location'].errors['required']">Location is Required</span>
                    <span *ngIf="vendorFormGroup.controls['location'].errors && !vendorFormGroup.controls['location'].errors['required'] && vendorFormGroup.controls['location'].errors['minlength']">Location is Invalid !</span>
                    <span *ngIf="vendorFormGroup.controls['location'].errors && !vendorFormGroup.controls['location'].errors['required'] && vendorFormGroup.controls['location'].errors['maxlength']">Location is Invalid !</span>
                  </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Pincode *</div>
                  <div class="mb-3">
                    <input pInputText formControlName="pincode"  type="text" placeholder="Enter Pincode" class="w-100 light height-40 px-3 inputBoxAddUser font-12 ">
                    <div class="error "
                    *ngIf="vendorFormGroup.controls['pincode'].invalid && vendorFormGroup.controls['pincode'].dirty">
                    <span *ngIf="vendorFormGroup.controls['pincode'].errors && vendorFormGroup.controls['pincode'].errors['required']">Pincode is Required</span>
                    <span class="error" *ngIf="vendorFormGroup.controls['pincode'].errors && vendorFormGroup.controls['pincode'].errors['pattern']"
                    >Pincode can only be in numbers</span
                  >
                    <span *ngIf="vendorFormGroup.controls['pincode'].errors &&
                    !vendorFormGroup.controls['pincode'].errors['pattern'] &&
                    !vendorFormGroup.controls['pincode'].errors['required'] &&
                    vendorFormGroup.controls['pincode'].errors['minlength']
                  ">Pincode is Invalid !</span>
                    <span *ngIf="vendorFormGroup.controls['pincode'].errors &&
                    !vendorFormGroup.controls['pincode'].errors['pattern'] &&
                    !vendorFormGroup.controls['pincode'].errors['required'] &&
                    vendorFormGroup.controls['pincode'].errors['maxlength']
                  ">Pincode is Invalid !</span>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row medium font-12 pt-4">
          <div class="col-7">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Bank Details</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12">
                <div class="col">
                  <div class="medium pb-1">Account Number *</div>
                  <div class="mb-3">
                    <input pInputText formControlName="accountNumber"  type="text" placeholder="Enter Account Number"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                      <div class="error "
                      *ngIf="vendorFormGroup.controls['accountNumber'].invalid && vendorFormGroup.controls['accountNumber'].dirty">
                      <span *ngIf="vendorFormGroup.controls['accountNumber'].errors && vendorFormGroup.controls['accountNumber'].errors['required']">Account Number is Required</span>
                      <span
                      class="error"
                      *ngIf="vendorFormGroup.controls['accountNumber'].errors && vendorFormGroup.controls['accountNumber'].errors['pattern']"
                      >Account Number can only be numbers</span
                    >
                      <span *ngIf="vendorFormGroup.controls['accountNumber'].errors && !vendorFormGroup.controls['accountNumber'].errors['required'] && vendorFormGroup.controls['accountNumber'].errors['minlength']">Account Number is Invalid !</span>
                      <span *ngIf="vendorFormGroup.controls['accountNumber'].errors && !vendorFormGroup.controls['accountNumber'].errors['required'] && vendorFormGroup.controls['accountNumber'].errors['maxlength']">Account Number is Invalid !</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Account Holder Name *</div>
                  <div class="mb-3">
                    <input pInputText formControlName="accountName"  type="text" placeholder="Enter Name"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                      <div class="error "
                      *ngIf="vendorFormGroup.controls['accountName'].invalid && vendorFormGroup.controls['accountName'].dirty">
                      <span *ngIf="vendorFormGroup.controls['accountName'].errors && vendorFormGroup.controls['accountName'].errors['required']">Account Holder Name is Required</span>
                      <span *ngIf="vendorFormGroup.controls['accountName'].errors && !vendorFormGroup.controls['accountName'].errors['required'] && vendorFormGroup.controls['accountName'].errors['minlength']">Account Name is Invalid !</span>
                      <span *ngIf="vendorFormGroup.controls['accountName'].errors && !vendorFormGroup.controls['accountName'].errors['required'] && vendorFormGroup.controls['accountName'].errors['maxlength']">Account Name is Invalid !</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Account Type *</div>
                  <div class="mb-3">
                    <input pInputText formControlName="accountType"  type="text" placeholder="Enter Account Type"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                      <div class="error "
                      *ngIf="vendorFormGroup.controls['accountType'].invalid && vendorFormGroup.controls['accountType'].dirty">
                      <span *ngIf="vendorFormGroup.controls['accountType'].errors&& vendorFormGroup.controls['accountType'].errors['required']">Account Type is Required</span>
                      <span *ngIf="vendorFormGroup.controls['accountType'].errors&& !vendorFormGroup.controls['accountType'].errors['required'] && vendorFormGroup.controls['accountType'].errors['minlength']">Account Type Invalid !</span>
                      <span *ngIf="vendorFormGroup.controls['accountType'].errors&& !vendorFormGroup.controls['accountType'].errors['required'] && vendorFormGroup.controls['accountType'].errors['maxlength']">Account Type Invalid !</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row medium font-12">
                <div class="col">
                  <div class="medium pb-1">Bank Name *</div>
                  <div class="mb-3">
                    <select
                     class="w-100 light height-40 px-3 inputBoxAddUser font-12"
                      formControlName="bankName"
                    >
                    <option value="" selected>Select Bank Name</option>
                    <option *ngFor="let bank of BankNames" [value]="bank.bankId">{{
                      bank.bankName
                    }}</option>
                    </select>
                      <div class="error "
                      *ngIf="vendorFormGroup.controls['bankName'].invalid && vendorFormGroup.controls['bankName'].dirty">
                      <span *ngIf="vendorFormGroup.controls['bankName'].errors && vendorFormGroup.controls['bankName'].errors['required']">Bank Name is Required</span>

                    </div>
                   </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Branch Name *</div>
                  <div class="mb-3">
                    <input pInputText formControlName="branchName"  type="text" placeholder="Enter Branch Name"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                      <div class="error "
                      *ngIf="vendorFormGroup.controls['branchName'].invalid && vendorFormGroup.controls['branchName'].dirty">
                      <span *ngIf="vendorFormGroup.controls['branchName'].errors && vendorFormGroup.controls['branchName'].errors['required']">Branch Name is Required</span>
                      <span *ngIf="vendorFormGroup.controls['branchName'].errors && !vendorFormGroup.controls['branchName'].errors['required'] && vendorFormGroup.controls['branchName'].errors['minlength']">Branch Name is Invalid !</span>
                      <span *ngIf="vendorFormGroup.controls['branchName'].errors && !vendorFormGroup.controls['branchName'].errors['required'] && vendorFormGroup.controls['branchName'].errors['maxlength']">Branch Name is Invalid !</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">IFSC Code *</div>
                  <div class="mb-3">
                    <input pInputText formControlName="ifscCode"  type="text" placeholder="Enter IFSC Code" class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                    <div class="error "
                    *ngIf="vendorFormGroup.controls['ifscCode'].invalid && vendorFormGroup.controls['ifscCode'].dirty">
                    <span *ngIf="vendorFormGroup.controls['ifscCode'].errors && vendorFormGroup.controls['ifscCode'].errors['required']">IFSC Code is Required</span>
                    <span *ngIf="vendorFormGroup.controls['ifscCode'].errors && !vendorFormGroup.controls['ifscCode'].errors['required'] && vendorFormGroup.controls['ifscCode'].errors['minlength']">IFSC Code is Invalid !</span>
                    <span *ngIf="vendorFormGroup.controls['ifscCode'].errors && !vendorFormGroup.controls['ifscCode'].errors['required'] && vendorFormGroup.controls['ifscCode'].errors['maxlength']">IFSC Code is Invalid !</span>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-5">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Contact Info</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12 pb-1">
                <div class="col-6">
                  <div class="medium pb-1">Telephone Number</div>
                  <div class="mb-3">
                    <input pInputText formControlName="telephoneNumber"  type="text" placeholder="Enter Telephone Number"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                      <div class="error "
                      *ngIf="vendorFormGroup.controls['telephoneNumber'].invalid && vendorFormGroup.controls['telephoneNumber'].dirty">
                      <span *ngIf="vendorFormGroup.controls['telephoneNumber'].errors && !vendorFormGroup.controls['telephoneNumber'].errors['pattern'] && vendorFormGroup.controls['telephoneNumber'].errors['minlength']">Telephone Number Invalid !</span>
                      <span *ngIf="vendorFormGroup.controls['telephoneNumber'].errors && !vendorFormGroup.controls['telephoneNumber'].errors['pattern'] && vendorFormGroup.controls['telephoneNumber'].errors['maxlength']">Telephone Number Invalid !</span>
                      <span *ngIf="vendorFormGroup.controls['telephoneNumber'].errors && vendorFormGroup.controls['telephoneNumber'].errors['pattern']">Telephone Number consist only number, + and - !</span>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="medium pb-1">Fax Number </div>
                  <div class="mb-3">
                    <input pInputText formControlName="faxNumber"  type="text" placeholder="Enter Fax Number"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                      <div class="error "
                      *ngIf="vendorFormGroup.controls['faxNumber'].invalid && vendorFormGroup.controls['faxNumber'].dirty && vendorFormGroup.controls['faxNumber'].errors">
                      <span *ngIf="vendorFormGroup.controls['faxNumber'].errors && !vendorFormGroup.controls['faxNumber'].errors['required'] && vendorFormGroup.controls['faxNumber'].errors['minlength']">Fax Number isInvalid !</span>
                      <span *ngIf="vendorFormGroup.controls['faxNumber'].errors && !vendorFormGroup.controls['faxNumber'].errors['required'] && vendorFormGroup.controls['faxNumber'].errors['maxlength']">Fax Number isInvalid !</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row medium font-12">
                <div class="col-6">
                  <div class="medium pb-1">Mobile Number</div>
                  <div class="mb-3">
                    <input pInputText formControlName="mobileNumber"  type="text" placeholder="Enter Mobile Number"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                      <div class="error "
                      *ngIf="vendorFormGroup.controls['mobileNumber'].invalid && vendorFormGroup.controls['mobileNumber'].dirty">
                      <span *ngIf="vendorFormGroup.controls['mobileNumber'].errors && !vendorFormGroup.controls['mobileNumber'].errors['pattern'] && vendorFormGroup.controls['mobileNumber'].errors['minlength']">Mobile Number is Invalid !</span>
                      <span *ngIf="vendorFormGroup.controls['mobileNumber'].errors && !vendorFormGroup.controls['mobileNumber'].errors['pattern'] && vendorFormGroup.controls['mobileNumber'].errors['maxlength']">Mobile Number is Invalid !</span>
                      <span *ngIf="vendorFormGroup.controls['mobileNumber'].errors && vendorFormGroup.controls['mobileNumber'].errors['pattern']">Mobile Number consist only number !</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row medium font-12 pt-4">
          <div class="col-7">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Date's</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12">
                <div class="col">
                  <div class="medium pb-1">Days Pending From Billing Date *</div>
                  <div class="mb-3">
                    <input  formControlName="daysPendingFromBillingDate"  type="number" placeholder="Enter Number of Days"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                      <div class="error "
                      *ngIf="vendorFormGroup.controls['daysPendingFromBillingDate'].invalid && vendorFormGroup.controls['daysPendingFromBillingDate'].dirty">
                      <span *ngIf="vendorFormGroup.controls['daysPendingFromBillingDate'].errors && vendorFormGroup.controls['daysPendingFromBillingDate'].errors['required']">Days Pending  is Required</span>
                      <span *ngIf="vendorFormGroup.controls['daysPendingFromBillingDate'].errors && !vendorFormGroup.controls['daysPendingFromBillingDate'].errors['required'] && vendorFormGroup.controls['daysPendingFromBillingDate'].errors['minlength']">Days Pending  is Invalid !</span>
                      <span *ngIf="vendorFormGroup.controls['daysPendingFromBillingDate'].errors && !vendorFormGroup.controls['daysPendingFromBillingDate'].errors['required'] && vendorFormGroup.controls['daysPendingFromBillingDate'].errors['maxlength']">Days Pending  is Invalid !</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row medium font-12">
          <div class="col-12">
            <div class="text-right pt-5">
              <button type="button" (click)="onClickCancel()"  class="font-12 border-0 mr-5 submitBtn darkGreyBG text-white pointer">Cancel</button>
              <button type="submit"  class="font-12 border-0 submitBtn redBG text-white pointer" (click)="onSubmitVendorForm(vendorFormGroup.value)">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

