import { UserService } from './../services/user.service';
import { FormBuilder, Validators, FormGroup} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { ValidateAllFormFields } from '../utils/validateform';
import { Environment } from 'src/environments/environment';
;
import { ReportService } from '../services/report.service';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-payslip',
  templateUrl: './payslip.component.html',
  styleUrls: ['./payslip.component.scss'],
})
export class PayslipComponent implements OnInit {
  payslipForm: any;
  selectedMonth: any;
  selectedYear: any;
  year: any;
  month :any[]= [];
  range :any[]= [];
  file: File| null = null;
  fileForm: any;
  paySlipData: any;
  genarateForm!: FormGroup;
  zoneList: any;
  totalElements = 0;
  currentPage = 1;
  pageSize = 50;
  uploadFile: any;
  dashboardMonth: string;
  dashboardYear: string;
  showFiletypeError = false;
  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private reportService: ReportService,
              public route: ActivatedRoute,
              public dataService: DataService) {
    this.dashboardMonth = this.route.snapshot.queryParamMap.get('month')|| '';
    this.dashboardYear = this.route.snapshot.queryParamMap.get('year')|| '';
  }

  ngOnInit() {
    this.year = 2020;
    this.setRage();
    this.selectedYear = this.dashboardMonth ? (this.range.filter((res: any) => res.name === Number(this.dashboardYear)))[0].id : new Date().getFullYear();
    this.selectedMonth = this.dashboardMonth ? (this.month.filter((res: any) => res.name === this.dashboardMonth))[0].id : new Date().getMonth() + 1;
    this.getZone();
    this.onPaySlipForm();
    this.onGenarateForm();
    if (this.dataService.adminStatus1 === false) {
      this.onSearch();
    }
    this.onValueChange();
  }
  setRage() {
    this.month = [
      { id: 1, name: 'January' },
      { id: 2, name: 'February' },
      { id: 3, name: 'March' },
      { id: 4, name: 'April' },
      { id: 5, name: 'May' },
      { id: 6, name: 'June' },
      { id: 7, name: 'July' },
      { id: 8, name: 'August' },
      { id: 9, name: 'September' },
      { id: 10, name: 'October' },
      { id: 11, name: 'November' },
      { id: 12, name: 'December' },
    ];
    for (let i = 0; i < 21; i++) {
      this.range.push({
        id: this.year + i,
        name: this.year + i,
      });
    }
  }
  onPaySlipForm() {
    this.payslipForm = this.formBuilder.group({
      year: [this.selectedYear, Validators.compose([Validators.required])],
      month: [this.selectedMonth, Validators.compose([Validators.required])],
      file: ['', Validators.compose([Validators.required])],
    });
    this.uploadFile = this.payslipForm.get('file');
  }
  onSubmitedForm() {
    if (this.payslipForm.valid && !this.showFiletypeError) {
      const data = {
        month: this.month[this.payslipForm.value.month - 1].name,
        year: this.payslipForm.value.year,
      };
      this.fileForm.append('request', JSON.stringify(data));

      this.userService.genaratePayslip(this.fileForm).subscribe(res => {
        if (res) {
          if (res.status.code === 0) {
            this.onClear();
          }
        }
      });
    } else {
      ValidateAllFormFields(this.payslipForm);
    }
  }
  deleteForm() {
    this.file = null;
    delete this.fileForm;
    this.fileForm = new FormData();
  }
  onSelectedFile(event: any) {
    this.deleteForm();
    if (event.target.files && event.target.files[0]) {
      const  filedata = event.target.files[0];
      if (filedata.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || filedata.type === 'application/vnd.ms-excel') {
        this.showFiletypeError = false;
        this.file = event.target.files[0];
        this.fileForm.append('file', this.file, this.file ? this.file.name: '');
      } else {
        this.showFiletypeError = true;
      }
    }
  }
  onClear() {
    this.deleteForm();
    this.payslipForm.reset();
    this.showFiletypeError = false;
    this.ngOnInit();
  }
  onGenarateForm() {
    this.genarateForm = this.formBuilder.group({
      year: [this.selectedYear],
      month: [this.selectedMonth],
      zone: [''],
      keyword: [''],
    });
  }
  getZone() {
    this.userService.getZone().subscribe(res => {
      if (res.status.code === 0) {
        this.zoneList = res.response.content;
      }
    });
  }
  onValueChange() {
    this.genarateForm.controls['keyword'].valueChanges.pipe(debounceTime(200)).subscribe((res) => {
      const keyword = (res as string).length;
      if (keyword === 0 || keyword > 2) {
        this.onSearch();
      }
    });
  }
  onSearch() {
    this.paySlipData = null;
    let selectedMonth;
    if (this.genarateForm.value.month !== '') {
      selectedMonth = this.month[this.genarateForm.value.month - 1].name;
    } else {
      selectedMonth = '';
    }
    const data = {
      keyword: this.genarateForm.value.keyword,
      zone: this.genarateForm.value.zone,
      month: selectedMonth,
      year: this.genarateForm.value.year,
    };
    this.userService.payslipSearch(data, this.currentPage, this.pageSize).subscribe(res => {
        if (res.status.code === 0) {
          this.paySlipData = res.response.content;
          this.totalElements = res.response.totalElements;
        }
      });
  }
  openPdf(item?: any) {
    return Environment.UrlPath + '/payroll/download/' + item;
  }
  pageChanged(event: any) {
    this.currentPage = event.page;
    this.onSearch();
  }

  downloadAllPayslip() {
    let fileName = '';
    const data = {
      year: this.genarateForm.value.year ,
      month: this.month[this.genarateForm.value.month - 1].name,
      zone: this.genarateForm.value.zone,
      keyword: this.genarateForm.value.keyword
    };
    fileName = `Payslip-${this.month[this.genarateForm.value.month - 1].name}-${this.genarateForm.value.year}  ${this.genarateForm.value.zone ? '-' + this.genarateForm.value.zone  : '' }.xlsx`;
    this.reportService
    .downloadPayslip(data)
    .subscribe(res =>   {
      if (res) {
        const file = new File(
            [res],
            fileName,
            { type: 'application/vnd.ms.excel' }
          );
        saveAs(file);
  }
});
  }
  downloadAllPayslipByZone() {
    let fileName = '';
    const data = {
      year: this.genarateForm.value.year ,
      month: this.month[this.genarateForm.value.month - 1].name,
      zone: this.genarateForm.value.zone,
      keyword: this.genarateForm.value.keyword
    };
    fileName = `Payslip-${this.month[this.genarateForm.value.month - 1].name}-${this.genarateForm.value.year}  ${this.genarateForm.value.zone ? '-' + this.genarateForm.value.zone  : ''}.zip`;
    this.reportService
    .downloadPayslipPDFByZones(data)
    .subscribe(res =>   {
      if (res) {
        const file = new File(
          [res],
          fileName,
          { type: 'application/zip' }
        );
      saveAs(file);
  }
});
  }
}
