<div class="container-fluid">
  <div class="font-16 pt-4 px-4 pb-2">Accounts</div>
  <div class="px-5 py-5 minHeight80vh bg-white m-4 font-14">
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 invoiceTab">
        <div class="row">
          <div class="col-12 col-xl-6 col-lg-3 col-md-3 col-sm-3 text-left"> Add Supervisor</div>
          <div class="col-12 col-xl-6 col-lg-9 col-md-9 col-sm-9 text-right">
            <button type="submit" (click)="onCancel()"
              class="font-14 border-0 addSupervisorBtn darkGreyBG text-white pointer  mr-3">
              Back</button>

            <button type="submit" (click)="onSubmitSupervisor()"
              class="font-14 border-0 addSupervisorBtn orangeBG text-white pointer">
              Add as Supervisor</button>
          </div>
        </div>
         <form [formGroup]="searchForm" (ngSubmit)="searchEmployee()">
          <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-6">
              <div class="my-4 font-16 position-relative">
                <input type="text" placeholder="Search with Employee ID / Name" formControlName="keyword"
                  class="manageUserSelectBox w-100 height-30 pl-5 pr-3 font-12 light darkGreyFont pointer" />
                <img alt="search" src="../../assets/img/magnifying-glass.svg" class="position-absolute searchIcon">
              </div>
            </div>
          </div>
        </form>
        <form [formGroup]="supervisorForm">
          <div class="py-2">Total Records : {{totalElements}}</div>
          <div class="row pb-5 font-14">
            <div class="col-10">
              <div class="row medium py-3">
                <div style="max-width: 100px;" class="col">Select</div>
                <div class="col">Employee Name</div>
                <div style="max-width: 200px;" class="col">Employee ID</div>
                <div class="col">Email</div>
              </div>
              <div class="">
                <div formArrayName="supervisor" *ngFor="let a of supervisorFormArray.controls; let i = index" class="">
                  <div [formGroupName]="i" class="row py-2 light">
                    <div style="max-width: 100px;" class="col my-1 font-14"><label class="radio"><input type="checkbox"
                          formControlName="checkbox">
                        <span class="text-transparent">1</span>
                      </label>
                    </div>
                    <div class="col my-1 font-14">{{supervisorFormArray.get([i,'employeeName'])?.value}}
                    </div>
                    <div style="max-width: 200px;" class="col my-1 font-14">
                      {{supervisorFormArray.get([i,'employeeId'])?.value}}</div>
                    <div class="col my-1">
                      <input type="email" formControlName="emailId" placeholder="Enter Employee email id"
                        class="w-100 addInput px-2 font-12" id="email{{i}}">
                      <div class="error"
                        *ngIf="supervisorFormArray.get([i,'emailId'])?.invalid && supervisorFormArray.get([i,'emailId'])?.dirty">
                        <span *ngIf="supervisorFormArray.get([i,'emailId'])?.hasError('required')">Email Id is
                          Required</span>
                        <span *ngIf="
                        !supervisorFormArray.get([i,'emailId'])?.hasError('required') &&
                        supervisorFormArray.get([i,'emailId'])?.hasError('email')">Email Id is not valid</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="this.pageSize < this.totalElements">
            <pagination [totalItems]="totalElements" previousText="&laquo;" nextText="&raquo;" [itemsPerPage]="50"
            [maxSize]="10" ({ngModel})="currentPage"(pageChanged)="pageChanged($event)"></pagination>
            </div>
        </form>
      </div>
    </div>
  </div>
