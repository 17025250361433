import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { PurchaseService } from '../services/purchase.service';
import { BsModalService, BsModalRef  } from 'ngx-bootstrap/modal';
import { Environment } from 'src/environments/environment';
;
import { FormBuilder, FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { DataService } from '../services/data.service';


@Component({
  selector: 'app-purchase-order-list',
  templateUrl: './purchase-order-list.component.html',
  styleUrls: ['./purchase-order-list.component.scss']
})
export class PurchaseOrderListComponent implements OnInit {
  allPurchaseData: any[] | null = [];
  orginalAllPurchaseData: any;
  vendorSearchData: string = '';
  purchaseId: any;
  modalRef!: BsModalRef;
  message: string = '';
  searchForm: any;
  totalElements = 0;
  currentPage = 1;
  pageSize = 50;
  constructor(private purchaseService: PurchaseService,
              private router: Router,
              private modalService: BsModalService,
              private formBuilder: FormBuilder,
              public dataService: DataService
              ) {}

  ngOnInit() {
    this.getAllOrders();
    this.onSearchForm();
    this.onSearchPurchaseData();
  }
  getAllOrders() {
    this.allPurchaseData = null;
    this.orginalAllPurchaseData = null;
    this.purchaseService.getAllPurchaseOrders(this.currentPage, this.pageSize).subscribe(ele => {
    this.allPurchaseData = ele.response.content;
    this.totalElements = ele.response.totalElements;
    this.orginalAllPurchaseData = ele.response.content;

    });
  }
  onSearchForm() {
    this.searchForm = this.formBuilder.group({
      keyword: new FormControl('')
    });
  }
  pageChanged(event: any) {
    this.currentPage = event.page;
    this.getAllOrders();
  }
  onClickAddPurchaseOrder() {
    this.router.navigate(['/purchase']);
  }
  onSearchPurchaseData() {
    this.searchForm.controls.keyword.valueChanges.pipe(debounceTime(200)).subscribe((value: any) => {
      this.allPurchaseData = [];
      if (value.length >= 1 ) {
          this.purchaseService.searchPurchaseOrders(value).subscribe(ele => {
            if (ele.status.code === 0) {
              this.currentPage = 1;
              this.totalElements = 0;
              this.allPurchaseData = ele.response.content;
            }
          });
        } else {
          this.getAllOrders();
        }
    });
  }
  onClickCancel(order?: any) {
    this.purchaseService.onCancelPurchaseOrder(this.purchaseId).subscribe(ele => {
      this.allPurchaseData = [];
      this.getAllOrders();
    });
    this.modalRef.hide();

  }
  confirmationOfDelete(order: any, confirmation: any) {
    this.purchaseId = order.purchaseId;
    this.modalRef = this.modalService.show(confirmation, {class: 'modal-sm'});
  }
  decline(): void {
    this.modalRef.hide();
  }
  openPdf(data: any) {
    return Environment.UrlPath + '/purchaseOrders/pdfDownload/' + data.purchaseId;
  }
  }

