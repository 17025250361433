import { UserService } from './user.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private userService: UserService) {}
  userId: any;
  userName: any;
  userData: any;
  designation: any;
  acessToken: any;
  csrfToken: any;
  userRole: any;
  adminStatus1 = true;
  roleNav = {
    SUPER_ADMIN: [
      { method: 'onClickDashBoard', src: 'dashboard.png', data: 'DashBoard' },
      { method: 'onClickPayroll', src: 'manage-user.png', data: 'Payroll' },
      { method: 'onClickMaster', src: 'leads.png', data: 'Master' },
      { method: 'onClickSites', src: 'target.png', data: 'Sites' },
      { method: 'onClickEmployees', src: 'quotation.png', data: 'Employees' },
      { method: 'onClickPayslip', src: 'lead-pool.png', data: 'Payslip' },
      { method: 'onClickPO&Invoicing', src: 'leads.png', data: 'PO & Invoicing' },
      { method: 'onClickAccounts', src: 'target.png', data: 'Accounts' },
      { method: 'onClickQuotation', src: 'quotation.png', data: 'Quotation' },
      {
        method: 'onClickSiteExpenceManagement',
        src: 'lead-pool.png',
        data: 'On Site Expence Management',
      },
      { method: 'onClickReports&Analytics', src: 'activities.png', data: 'Reports & Analytics' },
      { method: 'onClickLogout', src: 'logout.png', data: 'Logout' },
    ],
  };

  saveLoginData(resArray: any) {
    if (resArray) {
      localStorage.setItem('access_token', resArray.access_token);
      localStorage.setItem('userName', resArray.user_role.name);
      localStorage.setItem('csrf_token', resArray.csrf_token);
      localStorage.setItem('user-role', resArray.user_role.roleId);
      localStorage.setItem('designation', resArray.user_role.name);
      localStorage.setItem('userId', resArray.user_id);
    }
  }
  getFromLocalStorage() {
    this.userId = localStorage.getItem('userId') || '';
    this.userName = localStorage.getItem('userName') || '';
    this.acessToken = localStorage.getItem('access_token') || '';
    this.csrfToken = localStorage.getItem('csrf_token') || '';
    this.designation = localStorage.getItem('designation') || '';
    this.userRole = localStorage.getItem('user-role') || '';
    this.userData = localStorage.getItem('user_details') || '';
  }
  saveUserInLocalStorage(data: any) {
    localStorage.setItem('user_details', data);
  }
  public getRoleId() {
    return this.userRole;
  }
  public getDesignation() {
    return this.designation;
  }
  public clearOut() {
    delete this.userData;
    delete this.userId;
    delete this.userName;
    delete this.userRole;
    delete this.designation;
    delete this.acessToken;
    delete this.csrfToken;
  }
  public adminStatus(id: any) {
    if (id === '2') {
      this.adminStatus1 = false;
    } else {
      this.adminStatus1 = true;
    }
  }
}
