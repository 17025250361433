import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dateAndTime' })
export class DateAndTimePipe implements PipeTransform {
  transform(date: Date | string): string {
    date = new Date(date);  // if orginal type was a string
    const dates =  new DatePipe('en-US').transform(date, 'd/M/yyyy, h:mm a');
    return dates ? dates : ''
  }
}
