<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Accounts</div>
  <div class="px-5 py-5 minHeight80vh bg-white m-4 font-14">
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="row">
          <div class="col-8 col-xl-10 col-lg-9 col-md-8 col-sm-7">
            <div class="pb-2 font-14 pt-2">Manage Supervisor</div>
          </div>
          <div class="col-4 col-xl-2 col-lg-3 col-md-4 col-sm-5  text-right">
            <img alt="add" src="../../assets/img/plus.svg" title="Add Supervisor" class="addIcon w-100 pointer" (click)="addSupervisor()" />
          </div>
        </div>
        <form [formGroup]="searchForm">
          <div class="row">
            <div class="col-4 my-4 font-16 position-relative">
              <input type="text" placeholder="Search with Employee ID" formControlName="employeeIdName"
                class="manageUserSelectBox w-100 height-30 pl-5 pr-3 font-12 light darkGreyFont pointer" />
              <img alt="search" src="../../assets/img/magnifying-glass.svg" class="position-absolute searchIcon">
            </div>
            <div class="col-4 my-4 font-16 position-relative">
              <p-dropdown [options]="allSites" formControlName="site" [filter]="true" placeholder="Select a site"></p-dropdown>
            </div>
          </div>
        </form>
        <div class="pb-4 font-12">
          <div class="pb-5">
            <div class="row medium py-3">
              <div style="max-width: 80px;" class="col">Sl. No.</div>
              <div class="col">Employee Name</div>
              <div style="max-width: 150px;" class="col">Employee ID</div>
              <div class="col">Contact Number</div>
              <div class="col">Site Name</div>
              <div class="col">Balance - In hand</div>
            </div>
            <div (click)="accountDetails(employee.userCredentialId)"
              *ngFor="let employee of allSupervisors; let i = index" class="row py-2 light pointer">
              <div style="max-width: 80px;" class="col">{{i+1}}</div>
              <div class="col">{{employee.user.firstName}}</div>
              <div style="max-width: 150px;" class="col">{{employee.employeeId}}</div>
              <div class="col">{{employee.user.mobileNumber}}</div>
              <div class="col">{{employee.site.siteName}}</div>
              <div class="col">
                <div *ngIf="(employee.transactions.length) > 0">
                  &#x20B9;{{employee.transactions[employee.transactions.length-1].balance}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
