import { Injectable } from '@angular/core';
import { RestService } from '../core/rest.service';
import { Observable, throwError } from 'rxjs';
import { Environment } from 'src/environments/environment';
;
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AmountService {
  constructor(public restService: RestService) {}

  addSupervisor(data: any): Observable<any> {
    return this.restService.put(Environment.UrlPath + '/users/supervisors', data, false).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  manageSupervisor(): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/users/supervisors', null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  searchEmployee(data: any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/users/search/supervisor', data, false).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  searchByUser(): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/users/list', null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getSupervisorDetails(userId: any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/users/' + userId, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  supervisorTransaction(data: any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/transactions', data, false).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  downloadTransactionImage(data: any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/transactions/download/' + data, true, true, true).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  addTransaction(data: any, employeeId: any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/transactions/credit/' + employeeId, data, false).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  changeTransactionStatus(data: any): Observable<any> {
    return this.restService.put(Environment.UrlPath + '/transactions/updateStatus' , data, false).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  downloadtransactionHistory(employeeId: any, month: any, year: any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/transactions/history/download/' + employeeId + '?month=' + month + '&year=' + year, false, false, true).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getBalanceInHand(employeeId: any, month: any, year: any): Observable<any> {
    return this.restService.get(Environment.UrlPath + `/transactions/currentMonthBalance/${employeeId}?month=${month}&year=${year}` , null, false, ).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
}
