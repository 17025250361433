import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from '../validators/custom.validator';
import { ValidateAllFormFields } from '../utils/validateform';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {
  setPasswordForm!: FormGroup;
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.setPasswordForm = this.formBuilder.group({
      emailId: ['', Validators.compose([Validators.required, CustomValidators.email])],
      password: ['', Validators.compose([Validators.required, Validators.maxLength(20), Validators.minLength(5)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.maxLength(20), Validators.minLength(5)])]
      });
}
onSubmit() {
   if (this.setPasswordForm.valid) {
    this.authService.onSettingPassword('', this.setPasswordForm.value.emailId).subscribe((res: any) => {
        this.router.navigate(['/login']);
     });
  } else {
  ValidateAllFormFields(this.setPasswordForm);
}
}
}
