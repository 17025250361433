import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidateAllFormFields } from '../utils/validateform';
import { MasterService } from '../services/master.service';
import { Router } from '@angular/router';
import { RestResponse } from '../interface/RestResponse.interface';
import { IState } from '../interface/master.interface';
@Component({
  selector: 'app-add-zone',
  templateUrl: './add-zone.component.html',
  styleUrls: ['./add-zone.component.scss'],
})
export class AddZoneComponent implements OnInit {
  public addZoneForm!: FormGroup;
  stateList: IState[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private masterService: MasterService
  ) {}

  ngOnInit() {
    this.addZoneForm = this.formBuilder.group({
      zoneName: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9 s]*$')]),
      ],
      addressLine1: [''],
      addressLine2: [''],
      state: ['', [Validators.required]],
      district: ['', Validators.compose([Validators.pattern('^[a-zA-Z s]*$')])],
      location: ['', Validators.compose([Validators.pattern('^[a-zA-Z s]*$')])],
      pincode: [
        '',
        [Validators.pattern('^\\d+$'), Validators.minLength(6), Validators.maxLength(6)],
      ],
      contactName: [
        '',
        [Validators.pattern('^[a-zA-Z s]*$'), Validators.maxLength(30), Validators.minLength(3)],
      ],
      contactNumber: [
        '',
        [Validators.maxLength(10), Validators.minLength(10), Validators.pattern('^\\d+$')],
      ],
      unskilled: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9][0-9]*([.][0-9]{1,2}|)$'),
          Validators.maxLength(7),
          Validators.minLength(3),
        ],
      ],
      semiSkilled: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9][0-9]*([.][0-9]{1,2}|)$'),
          Validators.maxLength(7),
          Validators.minLength(3),
        ],
      ],
      skilled: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9][0-9]*([.][0-9]{1,2}|)$'),
          Validators.maxLength(7),
          Validators.minLength(3),
        ],
      ],
      isHra: [false],
      isConveyance: [false],
      isVhr: [false],
      isShoeAllowance: [false],
      isEpf: [false],
      isEsi: [false],
      isPt: [false],
    });
    this.getAllStates();
  }
  getAllStates(): void{
    this.masterService.getAllStates().subscribe((res: RestResponse<IState[]>) => {
      this.stateList = res.response;
    })
  }
  onClickSubmit() {
    if (this.addZoneForm.valid) {
      const data = {
        zoneName: this.addZoneForm.value.zoneName,
        addressLine1: this.addZoneForm.value.addressLine1,
        addressLine2: this.addZoneForm.value.addressLine2,
        state: this.addZoneForm.value.state,
        city: this.addZoneForm.value.district,
        location: this.addZoneForm.value.location,
        pincode: this.addZoneForm.value.pincode,
        contactPersonName: this.addZoneForm.value.contactName,
        contactPersonNumber: this.addZoneForm.value.contactNumber,
        unSkilledWage: this.addZoneForm.value.unskilled,
        leaveWage: this.addZoneForm.value.leaveWage,
        semiSkilledWage: this.addZoneForm.value.semiSkilled,
        skilledWage: this.addZoneForm.value.skilled,
        isHra: this.addZoneForm.value.isHra,
        isConveyance: this.addZoneForm.value.isConveyance,
        isVhr: this.addZoneForm.value.isVhr,
        isShoeAllowance: this.addZoneForm.value.isShoeAllowance,
        isEpf: this.addZoneForm.value.isEpf,
        isEsi: this.addZoneForm.value.isEsi,
        isPt: this.addZoneForm.value.isPt,
      };
      this.masterService.onCreateZone(data).subscribe((res: any) => {
        this.addZoneForm.reset();
        this.router.navigate(['/statutory']);
      });
    } else {
      ValidateAllFormFields(this.addZoneForm);
    }
  }
  onCancel() {
    this.addZoneForm.reset();
    this.router.navigate(['/statutory']);
  }
}
