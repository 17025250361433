<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Purchase Order & Invoice</div>
  <div class="px-5 py-4 minHeight80vh bg-white m-4 font-12">
    <div class="row">
      <div class="col-12">
        <div class="pb-4 font-14 pt-1">Add Customer</div>
      </div>
    </div>
    <div class="mb-3">
      <form [formGroup]="customerFormGroup"  (ngSubmit)="onSubmitCustomerForm(customerFormGroup.value)">
        <div class="row medium font-12 pb-3">
          <div class="col">
            <div class="medium pb-1">Customer Name *</div>
            <div class="mb-3">
              <input pInputText formControlName="companyName"  type="text" placeholder="Enter Customer Name" type="text"
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser font-12">
                <div class="error "
                *ngIf="customerFormGroup.controls['companyName'].invalid && customerFormGroup.controls['companyName'].dirty">
                <span *ngIf="customerFormGroup.controls['companyName'].errors && customerFormGroup.controls['companyName'].errors['required']">Customer Name is Required</span>
                <span *ngIf="customerFormGroup.controls['companyName'].errors && !customerFormGroup.controls['companyName'].errors['required'] && customerFormGroup.controls['companyName'].hasError('minlength')">Name too short</span>
                <span *ngIf="customerFormGroup.controls['companyName'].errors && !customerFormGroup.controls['companyName'].errors['required'] && customerFormGroup.controls['companyName'].hasError('maxlength')">Name too long</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="medium pb-1">GSTIN *</div>
            <div class="mb-3">
              <input pInputText formControlName="gstin"  type="text" placeholder="Enter GSTIN" type="text"
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser font-12">
                <div class="error "
                *ngIf="customerFormGroup.controls['gstin'].invalid && customerFormGroup.controls['gstin'].dirty">
                <span *ngIf="customerFormGroup.controls['gstin'].errors && customerFormGroup.controls['gstin'].errors['required']">GSTIN is Required</span>
                <span *ngIf="customerFormGroup.controls['gstin'].errors && !customerFormGroup.controls['gstin'].errors['required'] && customerFormGroup.controls['gstin'].hasError('minlength')">GSTIN is Invalid !</span>
                <span *ngIf="customerFormGroup.controls['gstin'].errors && !customerFormGroup.controls['gstin'].errors['required'] && customerFormGroup.controls['gstin'].hasError('maxlength')">GSTIN is Invalid !</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="medium pb-1">CIN </div>
            <div class="mb-3">
              <input pInputText formControlName="cin"  type="text" placeholder="Enter CIN" type="text"
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser font-12">
                <div class="error "
                *ngIf="customerFormGroup.controls['cin'].invalid && customerFormGroup.controls['cin'].dirty">
                <span *ngIf="customerFormGroup.controls['cin'].errors && !customerFormGroup.controls['cin'].errors['required'] && customerFormGroup.controls['cin'].hasError('minlength')">CIN is Invalid !</span>
                <span *ngIf="customerFormGroup.controls['cin'].errors && !customerFormGroup.controls['cin'].errors['required'] && customerFormGroup.controls['cin'].hasError('maxlength')">CIN is Invalid !</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="medium pb-1">PAN </div>
            <div class="mb-3">
              <input pInputText formControlName="pan"  type="text" placeholder="Enter PAN Number" type="text"
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser font-12">
                <div class="error "
                *ngIf="customerFormGroup.controls['pan'].invalid && customerFormGroup.controls['pan'].dirty">
                <span *ngIf="customerFormGroup.controls['pan'].errors && !customerFormGroup.controls['pan'].errors['required'] && customerFormGroup.controls['pan'].hasError('minlength')">PAN is Invalid !</span>
                <span *ngIf="customerFormGroup.controls['pan'].errors && !customerFormGroup.controls['pan'].errors['required'] && customerFormGroup.controls['pan'].hasError('maxlength')">PAN is Invalid !</span>
              </div>
              </div>
          </div>
        </div>

        <div class="row medium font-12 pt-4">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Address</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12">
                <div class="col">
                  <div class="medium pb-1">Address Line 1 *</div>
                  <div class="mb-3">
                    <input pInputText formControlName="addressLine1"  type="text" placeholder="Address Line 1" class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                    <div class="error "
                    *ngIf="customerFormGroup.controls['addressLine1'].invalid && customerFormGroup.controls['addressLine1'].dirty">
                    <span *ngIf="customerFormGroup.controls['addressLine1'].errors && customerFormGroup.controls['addressLine1'].errors['required']">Address is Required</span>
                    <span *ngIf="customerFormGroup.controls['addressLine1'].errors && !customerFormGroup.controls['addressLine1'].errors['required'] && customerFormGroup.controls['addressLine1'].hasError('minlength')">Address is Invalid !</span>
                    <span *ngIf="customerFormGroup.controls['addressLine1'].errors && !customerFormGroup.controls['addressLine1'].errors['required'] && customerFormGroup.controls['addressLine1'].hasError('maxlength')">Address is Invalid !</span>
                  </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Address Line 2 *</div>
                  <div class="mb-3">
                    <input pInputText formControlName="addressLine2"  type="text" placeholder="Address Line 2" class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                    <div class="error "
                    *ngIf="customerFormGroup.controls['addressLine2'].invalid && customerFormGroup.controls['addressLine2'].dirty">
                    <span *ngIf="customerFormGroup.controls['addressLine2'].errors && customerFormGroup.controls['addressLine2'].errors['required']">Address is Required</span>
                    <span *ngIf="customerFormGroup.controls['addressLine2'].errors && !customerFormGroup.controls['addressLine2'].errors['required'] && customerFormGroup.controls['addressLine2'].hasError('minlength')">Address is Invalid! </span>
                    <span *ngIf="customerFormGroup.controls['addressLine2'].errors && !customerFormGroup.controls['addressLine2'].errors['required'] && customerFormGroup.controls['addressLine2'].hasError('maxlength')">Address is Invalid !</span>
                  </div>
                  </div>
                </div>

                <div class="col">
                  <div class="medium pb-1">State *</div>
                  <div class="mb-3">
                    <select
                    class="w-100 light height-40 px-3 inputBoxAddUser font-12"
                     formControlName="state"
                   >
                   <option value="" selected>Select State</option>
                   <option *ngFor="let state of stateNames" [value]="state.stateName">
                     {{state.gstCode}} - {{state.stateName}}
                    </option>
                   </select>
                     <div class="error "
                     *ngIf="customerFormGroup.controls['state'].invalid && customerFormGroup.controls['state'].dirty">
                     <span *ngIf="customerFormGroup.controls['state'].errors && customerFormGroup.controls['state'].errors['required']">State is Required</span>
                   </div>
                  </div>
                </div>
              </div>

              <div class="row medium font-12">
                <div class="col">
                  <div class="medium pb-1">City/District *</div>
                  <div class="mb-3">
                    <input pInputText formControlName="city"  type="text" placeholder="Enter City/District"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                      <div class="error "
                      *ngIf="customerFormGroup.controls['city'].invalid && customerFormGroup.controls['city'].dirty">
                      <span *ngIf="customerFormGroup.controls['city'].errors && customerFormGroup.controls['city'].errors['required']">City is Required</span>
                      <span *ngIf="customerFormGroup.controls['city'].errors && !customerFormGroup.controls['city'].errors['required'] && customerFormGroup.controls['city'].hasError('minlength')">City is Invalid !</span>
                      <span *ngIf="customerFormGroup.controls['city'].errors && !customerFormGroup.controls['city'].errors['required'] && customerFormGroup.controls['city'].hasError('maxlength')">City is Invalid !</span>
                    </div>
                    </div>
                </div>

                <div class="col">
                  <div class="medium pb-1">Location *</div>
                  <div class="mb-3">
                    <input pInputText formControlName="locality"  type="text" placeholder="Enter Location" class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                    <div class="error "
                    *ngIf="customerFormGroup.controls['locality'].invalid && customerFormGroup.controls['locality'].dirty">
                    <span *ngIf="customerFormGroup.controls['locality'].errors && customerFormGroup.controls['locality'].errors['required']">Location is Required</span>
                    <span *ngIf="customerFormGroup.controls['locality'].errors && !customerFormGroup.controls['locality'].errors['required'] && customerFormGroup.controls['locality'].hasError('minlength')">Location is Invalid !</span>
                    <span *ngIf="customerFormGroup.controls['locality'].errors && !customerFormGroup.controls['locality'].errors['required'] && customerFormGroup.controls['locality'].hasError('maxlength')">Name too long</span>
                  </div>
                  </div>
                </div>

                <div class="col">
                  <div class="medium pb-1">Pincode *</div>
                  <div class="mb-3">
                    <input pInputText formControlName="pincode"  type="text" placeholder="Enter Pincode" class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                    <div class="error "
                    *ngIf="customerFormGroup.controls['pincode'].invalid && customerFormGroup.controls['pincode'].dirty">
                    <span *ngIf="customerFormGroup.controls['pincode'].errors && customerFormGroup.controls['pincode'].errors['required']">Pincode is Required</span>
                    <span *ngIf="customerFormGroup.controls['pincode'].errors && !customerFormGroup.controls['pincode'].errors['pattern'] && customerFormGroup.controls['pincode'].hasError('minlength')">Pincode is Invalid!</span>
                    <span *ngIf="customerFormGroup.controls['pincode'].errors && !customerFormGroup.controls['pincode'].errors['pattern'] && customerFormGroup.controls['pincode'].hasError('maxlength')">Pincode is Invalid!</span>
                    <span *ngIf="customerFormGroup.controls['pincode'].errors && customerFormGroup.controls['pincode'].errors['pattern']">Pincode consist only numbers!</span>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row medium font-12 pt-4">

          <div class="col-5">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Contact Info</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12 pb-1">
                <div class="col-6">
                  <div class="medium pb-1">Contact Person </div>
                  <div class="mb-3">
                    <input pInputText formControlName="contactPerson"  type="text" placeholder="Enter Contact Name"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                      <div class="error "
                      *ngIf="customerFormGroup.controls['contactPerson'].invalid && customerFormGroup.controls['contactPerson'].dirty">
                      <span *ngIf="customerFormGroup.controls['contactPerson'].errors && !customerFormGroup.controls['contactPerson'].errors['required'] && customerFormGroup.controls['contactPerson'].hasError('minlength')">Name too short</span>
                      <span *ngIf="customerFormGroup.controls['contactPerson'].errors && !customerFormGroup.controls['contactPerson'].errors['required'] && customerFormGroup.controls['contactPerson'].hasError('maxlength')">Name too long</span>
                    </div>
                    </div>
                </div>
                <div class="col-6">
                  <div class="medium pb-1">Mobile Number </div>
                  <div class="mb-3">
                    <input pInputText formControlName="mobileNumber"  type="text" placeholder="Enter Mobile Number"
                      class="w-100 light height-40 px-3 inputBoxAddUser font-12">
                      <div class="error "
                      *ngIf="customerFormGroup.controls['mobileNumber'].invalid && customerFormGroup.controls['mobileNumber'].dirty">
                      <span
                      *ngIf="customerFormGroup.controls['mobileNumber'].errors && !customerFormGroup.controls['mobileNumber'].errors['pattern'] &&
                      customerFormGroup.controls['mobileNumber'].hasError('minlength')"
                      >
                      Mobile Number is Invalid !
                      </span>
                      <span
                      *ngIf="customerFormGroup.controls['mobileNumber'].errors && !customerFormGroup.controls['mobileNumber'].errors['pattern'] &&
                      customerFormGroup.controls['mobileNumber'].hasError('maxlength')"
                      >
                      Mobile Number is Invalid !
                    </span>
                    <span
                      *ngIf="customerFormGroup.controls['mobileNumber'].errors &&
                      customerFormGroup.controls['mobileNumber'].errors['pattern']"
                      >
                      Mobile Number consist only numbers !
                    </span>
                    </div>
                    </div>
                </div>
              </div>

            </div>
          </div>
        </div>



        <div class="row medium font-12">
          <div class="col-12">
            <div class="text-right pt-5"><button type="button" (click)="onClickCancel()"
                class="font-12 border-0 mr-5 submitBtn darkGreyBG text-white pointer">Cancel</button><button
                type="submit"
                class="font-12 border-0 submitBtn redBG text-white pointer">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
