import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AmountService } from '../services/amount.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidateAllFormFields } from '../utils/validateform';

@Component({
  selector: 'app-add-amount',
  templateUrl: './add-amount.component.html',
  styleUrls: ['./add-amount.component.scss']
})
export class AddAmountComponent implements OnInit {

  addAmountFormGroup!: FormGroup;
  categories: any;
  employeeId: any;
  id: any;
  constructor(private amountService: AmountService,
              private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute) {
                this.employeeId = this.route.snapshot.queryParamMap.get('employeeId');
                this.id = this.route.snapshot.queryParamMap.get('id');
              }

  ngOnInit() {
    this.initAddAmountFormGroup();
  }
  initAddAmountFormGroup() {
    this.addAmountFormGroup = this.formBuilder.group({
      amountDate:  new FormControl(null, [
        Validators.required,
      ]),
      remarks: new FormControl('', [
          Validators.required
      ]),
      amount: ['', [
        Validators.required,
        Validators.pattern('^(?:[1-9][0-9]{0,6}(?:[.][0-9]{0,2})?|0|1000000|1000000.00)$'),
      ]],
    });
  }
  onClickCancel() {
    this.router.navigate(['account-details'], {queryParams: {id:  this.id } });
  }
  onSubmit() {
    if (this.addAmountFormGroup.valid) {
      const data = {
        remarks: this.addAmountFormGroup.value.remarks,
        amount: this.addAmountFormGroup.value.amount,
        transactionDate: this.addAmountFormGroup.value.amountDate,
      };
      this.amountService.addTransaction(data, this.employeeId).subscribe(res => {
        if (res.status.code === 0) {
          this.router.navigate(['account-details'], {queryParams: {id:  this.id } });
        }
      });
    } else {
      ValidateAllFormFields(this.addAmountFormGroup);
    }
  }
}
