import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidateAllFormFields } from '../utils/validateform';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CustomMessageService } from '../core/message/message.service';
import { Match } from '../validators/match.validator';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  setPasswordForm!: FormGroup;
  email: any;
   constructor(private formBuilder: FormBuilder,
               private authService: AuthService,
               private router: Router,
               public route: ActivatedRoute,
               private messageService: CustomMessageService) { }

  ngOnInit() {
    this.initSetPasswordForm();
    this.email = this.route.snapshot.queryParamMap.get('email');
}
initSetPasswordForm() {
  this.setPasswordForm = this.formBuilder.group({
    password: ['', Validators.compose([Validators.required, Validators.maxLength(20), Validators.minLength(5)])],
    confirmPassword: ['',
    Validators.compose(
      [Validators.required,
        Validators.maxLength(20),
        Validators.minLength(5)])]},
    {
      validator: Match('password', 'confirmPassword')
    },
    );
}
onSubmit() {
    if (this.setPasswordForm.valid) {
      const data = {
        password: this.setPasswordForm.value.password,
        confirmPassword: this.setPasswordForm.value.confirmPassword
      };
      this.authService.onSettingPassword(data, this.email).subscribe((res: any) => {
       if (res.status.code === 0) {
     this.router.navigate(['/login']);
       }
     });
   } else {
   ValidateAllFormFields(this.setPasswordForm);
 }

 }
}
