import { FormGroup, FormControl, FormArray } from '@angular/forms';

export function   ValidateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({
        onlySelf: true
      });
      control.markAsDirty({
        onlySelf: true
      });
    } else if (control instanceof FormGroup) {
      ValidateAllFormFields(control);
    } else if (control instanceof FormArray) {
      control.controls.forEach((ele: any) => {
        ValidateAllFormFields(ele);
      });
    }
  });
}
