import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { ValidateAllFormFields } from '../utils/validateform';
import { MasterService } from '../services/master.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from '../validators/custom.validator';

@Component({
  selector: 'app-edit-site',
  templateUrl: './edit-site.component.html',
  styleUrls: ['./edit-site.component.scss'],
})
export class EditSiteComponent implements OnInit {
  showSelected: boolean = false;
  public addSiteForm!: FormGroup;
  allZones: any;
  showSite = false;
  showZone = false;
  allSite :any;
  siteData: any;
  id;
  validatorType = false;
  constructor(
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private masterService: MasterService,
    private router: Router
  ) {
    this.id = this.route.snapshot.queryParamMap.get('id');
  }

  ngOnInit() {
    this.onInitForm();
    this.onAddForm();
    this.getData();
  }
  getData() {
    this.masterService.onGetSite(this.id).subscribe((res: any) => {
      this.siteData = res.response;
      this.addSiteForm.patchValue({
        siteName: this.siteData.siteName,
        employerName: this.siteData.employerName,
        addressLine1: this.siteData.siteAddress.addressLine1,
        addressLine2: this.siteData.siteAddress.addressLine2,
        state: this.siteData.siteAddress.state,
        district: this.siteData.siteAddress.city,
        leaveWage: this.siteData.leaveWage,
        bonusPercent: this.siteData.bonusPercent ? this.siteData.bonusPercent : '',
        location: this.siteData.siteAddress.landmark,
        pincode: this.siteData.siteAddress.pinCode,
        contactName: this.siteData.contactPersonName,
        contactNumber: this.siteData.contactPersonNumber,
        zone: (this.siteData.zone) ? this.siteData.zone.zoneId : 'Site',
        unskilled: (this.siteData.zone) ? '' : this.siteData.unSkilledWage,
        skilled: (this.siteData.zone) ? '' : this.siteData.skilledWage,
        semiSkilled: (this.siteData.zone) ? '' : this.siteData.semiSkilledWage,
        isHra: (this.siteData.zone) ? false : this.siteData.hra,
        isConveyance: (this.siteData.zone) ? false : this.siteData.conveyance,
        isVhr: (this.siteData.zone) ? false : this.siteData.vhr,
        isShoeAllowance: (this.siteData.zone) ? false : this.siteData.shoeAllowance,
        isEpf: (this.siteData.zone) ? false : this.siteData.epf,
        isEsi: (this.siteData.zone) ? false : this.siteData.esi,
        isPt: (this.siteData.zone) ? false : this.siteData.pt,
      });
      const type = (this.siteData.zone) ? this.siteData.zone.zoneId : 'Site';
      this.setDropdown(type);
    });
  }
  onClickAddSite() {
    this.validType();
    let data;
    if (this.addSiteForm.valid && !this.validatorType) {
      data = {
        siteName: this.addSiteForm.value.siteName,
        employerName: this.addSiteForm.value.employerName,
        addressLine1: this.addSiteForm.value.addressLine1,
        addressLine2: this.addSiteForm.value.addressLine2,
        state: this.addSiteForm.value.state,
        city: this.addSiteForm.value.district,
        location: this.addSiteForm.value.location,
        pincode: this.addSiteForm.value.pincode,
        contactPersonName: this.addSiteForm.value.contactName,
        contactPersonNumber: this.addSiteForm.value.contactNumber,
        leaveWage: this.addSiteForm.value.leaveWage,
        bonusPercent: this.addSiteForm.value.bonusPercent,
        zoneId: this.addSiteForm.value.zone,
        unSkilledWage: (this.addSiteForm.value.zone === 'Site') ? this.addSiteForm.value.unskilled : '',
        semiSkilledWage: (this.addSiteForm.value.zone === 'Site') ? this.addSiteForm.value.semiSkilled : '',
        skilledWage: (this.addSiteForm.value.zone === 'Site') ? this.addSiteForm.value.skilled : '',
        isHra: (this.addSiteForm.value.zone === 'Site') ? this.addSiteForm.value.isHra : false,
        isConveyance: (this.addSiteForm.value.zone === 'Site') ? this.addSiteForm.value.isConveyance : false,
        isVhr: (this.addSiteForm.value.zone === 'Site') ? this.addSiteForm.value.isVhr : false,
        isShoeAllowance: (this.addSiteForm.value.zone === 'Site') ? this.addSiteForm.value.isShoeAllowance : false,
        isEpf: (this.addSiteForm.value.zone === 'Site') ? this.addSiteForm.value.isEpf : false,
        isEsi: (this.addSiteForm.value.zone === 'Site') ? this.addSiteForm.value.isEsi : false,
        isPt: (this.addSiteForm.value.zone === 'Site') ? this.addSiteForm.value.isPt : false,
      };
      this.masterService.onUpdateSite(data, this.id).subscribe((res: any) => {
        this.addSiteForm.reset();
        this.router.navigate(['/manage-site']);
      });
    } else {
      debugger
       ValidateAllFormFields(this.addSiteForm);
     }
  }
onAddForm() {
    this.addSiteForm = this.formBuilder.group({
      siteName: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9 s]*$')]),
      ],
      employerName: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z s]*$')]),
      ],
      addressLine1: ['', Validators.compose([Validators.required])],
      addressLine2: ['', Validators.compose([Validators.required])],
      state: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z s]*$')])],
      district: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z s]*$')]),
      ],
      location: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z s]*$')]),
      ],
      pincode: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^\\d+$'),
          Validators.minLength(6),
          Validators.maxLength(6),
        ]),
      ],
      leaveWage:
        [
          '',
          [
            Validators.pattern('^[0-9][0-9]*([.][0-9]{1,3}|)$'),
            Validators.maxLength(5),
            CustomValidators.max,
          ]
        ],
        bonusPercent:
        [
          '',
        [
          Validators.pattern('^[0-9][0-9]*([.][0-9]{1,3}|)$'),
          CustomValidators.max,
        ],
        ],
      contactName: [
        '',
        Validators.compose([
          Validators.pattern('^[a-zA-Z s]*$'),
          Validators.maxLength(30),
          Validators.minLength(3),
        ]),
      ],
      contactNumber: [
        '',
        Validators.compose([,
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern('^\\d+$'),
        ]),
      ],
      zone: ['', Validators.compose([Validators.required])],
      unskilled: ['', Validators.compose([Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')])],
      semiSkilled: ['', Validators.compose([Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')])],
      skilled: ['', Validators.compose([Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')])],
      isHra: [false],
      isConveyance: [false],
      isVhr: [false],
      isShoeAllowance: [false],
      isEpf: [false],
      isEsi: [false],
      isPt: [false],
    });
  }
selectDropdown(event: any) {
    const type = event.target.value;

    if (type === 'Site') {
      this.showSite = true;
      this.showZone = false;
    } else {
      this.showZone = true;
      this.showSite = false;
    }
  }
setDropdown(type: any) {
    if (type === 'Site') {
      this.showSite = true;
      this.showZone = false;
    } else {
      this.showZone = true;
      this.showSite = false;
    }
  }

validType() {
    if (this.addSiteForm.value.zone !== 'Site' || this.addSiteForm.value.zone === '') {
      this.validatorType = false;
    } else {
      if (
        this.addSiteForm.value.zone === 'Site' &&
        (this.addSiteForm.value.unskilled === '' ||
          this.addSiteForm.value.skilled === '' ||
          this.addSiteForm.value.semiSkilled === '')
      ) {
        this.validatorType = true;
      } else {
        this.validatorType = false;
      }
    }
  }
onInitForm() {
    this.masterService.getAllZone(1, 1000).subscribe((res: any) => {
      const site = {
        zoneId: 'Site',
        zoneName: 'Site',
      };
      this.allZones = res.response.content;
      this.allZones = this.allZones.concat(site);
    });
  }
onCancel() {
    this.addSiteForm.reset();
    this.router.navigate(['/manage-site']);
  }
}
