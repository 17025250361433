import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmployeeService } from './../services/employee.service';
import { CustomMessageService } from '../core/message/message.service';

@Component({
  selector: 'app-manage-employee',
  templateUrl: './manage-employee.component.html',
  styleUrls: ['./manage-employee.component.scss'],
})
export class ManageEmployeeComponent implements OnInit {
  allEmployees: any;
  searchForm: any;
  totalElements = 0;
  currentPage = 1;
  pageSize = 50;
  activeEmployeeCount = 0;
  inactiveEmployeeCount = 0;
  constructor(
    private router: Router,
    private messagingService: CustomMessageService,
    private employeeService: EmployeeService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.toGetAllEmployeesDetails();
    this.onSearchForm();
  }
  toGetAllEmployeesDetails(): void{
    this.getActiveEmployeeCount();
    this.getInActiveEmployeeCount();
    this.getAllEmployees();
    
  }
  onclicAddIcon() {
    this.router.navigate(['/add-employee']);
  }
  getActiveEmployeeCount(): void{
    this.employeeService.getAllActiveEmployeesCount().subscribe((res) => {
      if (res) {
        this.activeEmployeeCount = res.response;
      }
    })
  }
  getInActiveEmployeeCount(): void{
    this.employeeService.getAllInActiveEmployees().subscribe((res) => {
      if (res) {
        this.inactiveEmployeeCount = res.response;
      }
    })
  } 
  getAllEmployees(): void{
    this.employeeService.getAllEmployees(this.currentPage, this.pageSize).subscribe(res => {
      if (res.status.code === 0) {
        this.allEmployees = res.response.content;
        this.totalElements = res.response.totalElements;
        this.currentPage = res.response.pageable.pageNumber;
      } else {
        this.totalElements = 0;
        this.messagingService.notify('error', 'Error', res.status.message);
      }
    });
  }
  onSearchForm() {
    this.searchForm = this.formBuilder.group({
      keyword: [''],
    });
  }
  searchEmployee() {
    this.allEmployees = null;
    if (this.searchForm.value.keyword === '') {
      this.getAllEmployees();
    } else {
      const keyword = this.searchForm.value.keyword;
      this.employeeService.employeeSearch(keyword, this.currentPage, this.pageSize).subscribe(res => {
        this.allEmployees = res.response.content;
        this.totalElements = res.response.totalElements;
        this.currentPage = res.response.pageable.pageNumber;
      });
    }
  }
  editEmployee(id: any) {
    this.router.navigate(['/edit-user'], { queryParams: { id } });
  }
  employeeStatusChange(status: string,userId:string): void{
    this.employeeService.employeeStatusChange(status, userId).subscribe((res) => {
      if (res) {
        this.toGetAllEmployeesDetails();
      }
    })
  }
  pageChanged(event: any) {
    this.currentPage = event.page;
    this.searchEmployee();
  }
}
