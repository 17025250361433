import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { PurchaseService } from '../services/purchase.service';
import { EmployeeService } from '../services/employee.service';
import * as Chart from 'chart.js';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  year: any;
  month: any;
  dashboardForm: any;
  selectedMonth: any;
  selectedYear: any;
  payrollData: any;
  invoiceData :any[]= [];
  purchaseOrderData:any[] = [];
  supervisorData : any[]= [];
  range :any[]= [];
  accountChartSetting!: Chart
  invoiceChartSetting!: Chart
  purchaseOrdersChartSetting!: Chart
  payrollChartSetting!: Chart
  constructor(private formBuilder: FormBuilder, public userService: UserService, public purchaseService: PurchaseService,
              public employeeService: EmployeeService, public route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.year = 2020;
    this.selectedYear = new Date().getFullYear();
    this.selectedMonth = new Date().getMonth() + 1;
    this.onDashboardForm();
    this.formChanges();
    this.setRage();
  }
  onDashboardForm() {
    this.dashboardForm = this.formBuilder.group({
      year: [this.selectedYear, Validators.compose([Validators.required])],
      month: [this.selectedMonth, Validators.compose([Validators.required])],
    });
  }
  setRage() {
    this.month = [
      { id: 1, name: 'January' },
      { id: 2, name: 'February' },
      { id: 3, name: 'March' },
      { id: 4, name: 'April' },
      { id: 5, name: 'May' },
      { id: 6, name: 'June' },
      { id: 7, name: 'July' },
      { id: 8, name: 'August' },
      { id: 9, name: 'September' },
      { id: 10, name: 'October' },
      { id: 11, name: 'November' },
      { id: 12, name: 'December' },
    ];
    for (let i = 0; i < 21; i++) {
      this.range.push({
        id: this.year + i,
        name: this.year + i,
      });
    }
    this.dashboardForm.patchValue({
      year: this.selectedYear,
      month:  this.selectedMonth,
    });
  }
   formChanges() {
    this.dashboardForm.valueChanges.subscribe((value: any) => {
        const month = this.month[value.month - 1].name;
        const year = value.year;
        this.searchPayroll(year, month);
        this.searchPoAndInvoice(year, month);
        this.searchSupervisors(year, month);
    });
  }
  searchPayroll(year: any, month: any) {
    this.userService.getPayroll(year, month).subscribe(res => {
      this.payrollData = res;
      const permanentTotalNetPay = Number(this.payrollData.response.permenantTotalNetPay);
      const labourerTotalNetPay = Number(this.payrollData.response.labourerTotalNetPay);
      const formatter = new Intl.NumberFormat('en-IN');
      const myChart = document.getElementById('pieChart') as HTMLCanvasElement;
      if(myChart){
        const ctx = myChart.getContext('2d');
        if(ctx){
          if (this.payrollChartSetting !== undefined) {
            this.payrollChartSetting.destroy();
          }
          this.payrollChartSetting = new Chart(ctx ? ctx : '', {
            type: 'pie',
            data: {
              labels: [`Permanent (${this.payrollData.response.permenantCount})`, `Labourer (${this.payrollData.response.labourerCount})`],
              datasets: [{
                data: [permanentTotalNetPay, labourerTotalNetPay],
                backgroundColor: [
                                  '#e8a09a',
                                  '#fbe29f',
                                ],
                borderWidth: 0,
                borderColor: '#fff',
                hoverBorderWidth: 7,
                hoverBorderColor: '#fff'
              }],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              aspectRatio: 1,
              hover: {
                onHover(e: any, a: any) {
                    const el: any = document.getElementById('pieChart');
                    el.style.cursor = a[0] ? 'pointer' : 'default';
                }
              },
              onClick: () => {
                this.router.navigate(['payslip'], {queryParams: {month, year} });
            },
              legend: {
                display: true,
                position: 'bottom',
                labels: {
                  fontColor: '#000',
                  boxWidth: 12,
                  padding: 25
                }
              },
              animation: {
                duration: 1000,
            },
              layout: {

                padding: {
                  left: 50,
                  right: 0,
                  bottom: 15,
                  top: 0
                },

              },
              tooltips: {
                enabled: true,
                mode: 'label',
                callbacks: {
                    label(tooltipItem: any, data: any) {
                      const dataLabel = data.labels[tooltipItem.index] as string[];
                      const value = dataLabel + ': INR ' + formatter.format(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] as number) + '/-';
                      return value;
                    }
                  }

                },
              },
          });
          this.payrollChartSetting.canvas ? this.payrollChartSetting.canvas.style.paddingRight = '25px' :'';
        }
      }
  });
  }
  searchPoAndInvoice(year: any, month: any) {
    const invoicesCustomerName : any[]= [];
    const invoicesGrantTotal : any[]= [];
    const purchaseOrdersCustomerName : any[]= [];
    const purchaseOrdersGrantTotal : any[]= [];
    const invoiceChart = document.getElementById('InvoiceBarChart') as HTMLCanvasElement;
    const invoiceChartCtx = invoiceChart.getContext('2d');
    const purchaseOrdersChart = document.getElementById('PurchaseBarChart') as HTMLCanvasElement;
    const purchaseOrdersCtx = purchaseOrdersChart.getContext('2d');
    const formatter = new Intl.NumberFormat('en-IN');
    if (this.invoiceChartSetting !== undefined) {
      this.invoiceChartSetting.destroy();
    }
    if (this.purchaseOrdersChartSetting !== undefined) {
      this.purchaseOrdersChartSetting.destroy();
    }
    this.purchaseService.getInvoice(year, month).subscribe((res) => {
      this.purchaseOrderData = res.response.purchaseOrders;
      this.invoiceData = res.response.invoices;
      this.invoiceData.forEach((element: any) => {
        invoicesCustomerName.push(element.customerName);
        invoicesGrantTotal.push(Number(element.grantTotal));
      });
      this.purchaseOrderData.forEach((element: any) => {
        purchaseOrdersCustomerName.push(element.vendorName);
        purchaseOrdersGrantTotal.push(Number(element.grantTotal));
      });
      this.invoiceChartSetting = new Chart(invoiceChartCtx? invoiceChartCtx: '', {
        type: 'horizontalBar',
        data: {
          labels: invoicesCustomerName,
          datasets: [
            {
              hoverBackgroundColor: '#f9c94f',
              backgroundColor: '#fbe29f',
              data: invoicesGrantTotal,
              maxBarThickness: 30,
            }
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1,
          hover: {
            onHover(e: any, a: any) {
                const el: any = document.getElementById('InvoiceBarChart');
                el.style.cursor = a[0] ? 'pointer' : 'default';
            }
          },
          onClick: () => {
            this.router.navigate(['invoice-list']);
        },
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              fontSize: 20,
              fontColor: '#000',
              padding: 25
            }
          },
          scales: {
            yAxes: [{
                ticks: {
                    callback(value: string) {
                      if (value.length > 11) {
                        return value.substr(0, 10) + '...';
                      }
                      return value.substr(0, 10);
                    },
                }
            }],
            xAxes: [{
              ticks: {
              beginAtZero: true,
          }}]
        },
        tooltips: {
            enabled: true,
            mode: 'label',
            callbacks: {
              title(tooltipItems: any, data: any) {
                return '';
              },
                label: (tooltipItem: any, chart:any) => {
                  const datasetLabel: any = (chart.labels[tooltipItem.index] as string).match(/(.*?\s){3}/g);
                  if ( datasetLabel.length > 1) {
                    return datasetLabel[0].slice(0, -1) + '...' + ' : INR ' + formatter.format(tooltipItem.xLabel as number) + '/-';
                  } else {
                    return datasetLabel[0] + ': INR ' + formatter.format(tooltipItem.xLabel as number) + '/-';
                  }
              },
            }
        },
          layout: {
            padding: {
              left: 0,
              right: 0,
              bottom: 0,
              top: 0
            },

          },
        }
      });
      this.purchaseOrdersChartSetting = new Chart(purchaseOrdersCtx?purchaseOrdersCtx:'', {
        type: 'horizontalBar',
        data: {
          labels: purchaseOrdersCustomerName,
          datasets: [
            {
              hoverBackgroundColor: '#f9c94f',
              backgroundColor: '#fbe29f',
              data: purchaseOrdersGrantTotal,
              maxBarThickness: 30
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1,
          hover: {
            onHover(e: any, a: any) {
                const el: any = document.getElementById('PurchaseBarChart');
                el.style.cursor = a[0] ? 'pointer' : 'default';
            }
          },
          onClick: () => {
            this.router.navigate(['purchase-orders']);
        },
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              fontColor: '#000'
            },
          },
          scales: {
            yAxes: [{
                ticks: {
                    callback(value: string) {
                      if (value.length > 11) {
                        return value.substr(0, 10) + '...';
                      }
                      return value.substr(0, 10);
                    },
                }
            }],
            xAxes: [{
              ticks: {
              beginAtZero: true
          }}]
        },

        tooltips: {
            enabled: true,
            mode: 'label',
            callbacks: {
              title(tooltipItems: any, data: any) {
                return '';
              },
              label: (tooltipItem: any, chart: any) => {
                const datasetLabel : any= (chart.labels[tooltipItem.index] as string).match(/(.*?\s){3}/g);
                if ( datasetLabel.length > 1) {
                  return datasetLabel[0].slice(0, -1) + '...' + ' : INR ' + formatter.format(tooltipItem.xLabel as number) + '/-';
                } else {
                  return datasetLabel[0] + ': INR ' + formatter.format(tooltipItem.xLabel as number) + '/-';
                }
            }
          }
        },
          layout: {
            padding: {
              left: 0,
              right: 0,
              bottom: 0,
              top: 0
            },

          },
        },
      });
      this.purchaseOrdersChartSetting && this.purchaseOrdersChartSetting.canvas ? this.purchaseOrdersChartSetting.canvas.style.paddingLeft = '25px' : '';
      this.invoiceChartSetting && this.invoiceChartSetting.canvas ? this.invoiceChartSetting.canvas.style.paddingLeft = '25px': '';
  });
  }
  searchSupervisors(year: any, month: any) {
    this.employeeService.getSupervisors(year, month).subscribe((res) => {
      this.supervisorData = res.response;
      const accountBalance :any[] = [];
      const accountCredit :any[] = [];
      const accountSpend :any[] = [];
      const formatter = new Intl.NumberFormat('en-IN');
      const label :any[] = [];
      if (this.accountChartSetting !== undefined) {
        this.accountChartSetting.destroy();
      }
      this.supervisorData.forEach((response: any) => {
        label.push(response.supervisorFirstName);
        accountBalance.push(Number(response.balance));
        accountCredit.push(Number(response.credit));
        accountSpend.push(Number(response.spend));
      });
      const myChart = document.getElementById('AccountBarChart') as HTMLCanvasElement;
      if(myChart){
        const ctx = myChart.getContext('2d');
        if(ctx){
          this.accountChartSetting = new Chart(ctx?ctx:'', {
            type: 'horizontalBar',
            data: {
              labels: label,
              datasets: [
          {
            label: 'Balance',
            data: accountBalance,
            backgroundColor: '#c6d68f',
            maxBarThickness: 30
          },
          {
            label: 'Credit',
            data: accountCredit,
            backgroundColor: '#9bbfe0',
            maxBarThickness: 30
          },
          {
            label: 'Spend',
            data: accountSpend,
            backgroundColor: '#e8908a',
            maxBarThickness: 30
          }],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              aspectRatio: 1,
              hover: {
                onHover(e: any, a: any) {
                    const el: any = document.getElementById('AccountBarChart');
                    el.style.cursor = a[0] ? 'pointer' : 'default';
                }
              },
                onClick: (e: any, item: any) => {
                  this.clickHandler(e, item, month, year);
                },
              legend: {
                display: true,
                position: 'bottom',
                labels: {
                  fontColor: '#000',
                  boxWidth: 12,
                },
              },
              scales: {
                xAxes: [{
                ticks: {
                beginAtZero: true
            },
                    stacked: true
                }],
                yAxes: [{id: 'Dominoes', ticks: {
                          callback(value: string) {
                            if (value.length > 11) {
                              return value.substr(0, 10) + '...';
                            }
                            return value.substr(0, 10);
                          },
                      },
                    stacked: true,
                }]
            },
            tooltips: {
              enabled: true,
              mode: 'label',
              callbacks: {
                label: (tooltipItem: any, chart: any) => {
                    const datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                    return datasetLabel + ': INR ' + formatter.format(tooltipItem.xLabel as number) + '/-';
                }
            }
          },
              layout: {
                padding: {
                  left: 0,
                  right: 0,
                  bottom: 0,
                  top: 0
                },

              },
            },
          });
          this.accountChartSetting && this.accountChartSetting.canvas? this.accountChartSetting.canvas.style.paddingLeft = '25px' : '';
        }
      }
  });
  }
  clickHandler(evt: any, items: any, month: any, year: any) {
    const eventFired = (this.accountChartSetting.getElementAtEvent(evt)[0]);
    let index = 0 ;
    const g = items.forEach((res: any) => {
      if ((res as {}) === eventFired) {
        index = res._index;
      }
    });
    if (eventFired) {
      this.router.navigate(['account-details'], {queryParams: {id: this.supervisorData[index].supervisorId, month, year} });
    }

}
}
