import { UserService } from './../services/user.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { CustomValidators } from '../validators/custom.validator';
import { ValidateAllFormFields } from '../utils/validateform';
import { DataService } from '../services/data.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CustomMessageService } from '../core/message/message.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [AuthService],
})
export class LoginComponent implements OnInit {
  modalRef!: BsModalRef;
  public loginform!: FormGroup;
  public modelForm!: FormGroup;
  constructor(
    private authService: AuthService,
    private formbuilder: FormBuilder,
    private router: Router,
    private modalService: BsModalService,
    private dataService: DataService,
    private userService: UserService,
    private customMessageService: CustomMessageService,
    public auth: AuthService,
  ) {}

  ngOnInit() {
    this.loginform = this.formbuilder.group({
      email: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        CustomValidators.email,
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(20),
      ]),
    });
    this.modelForm = this.formbuilder.group({
      emailAddress: ['', Validators.compose([Validators.required, CustomValidators.email])],
    });
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  onClickResetPassword() {
    if (this.modelForm.valid) {
      const emailId = {
        emailAddress: this.modelForm.value.emailAddress,
      };
      this.authService.forgotpassword(emailId.emailAddress).subscribe(res => {
          this.modalRef.hide();
      });
    }
  }

  onClickLogin() {
    if (this.loginform.valid) {
      const data = {
        username: this.loginform.value.email,
        password: this.loginform.value.password,
        grant_type: 'password',
      };
      this.authService.login(data).subscribe((res: any) => {
        if (res.user_role.authority === 'SUPER_ADMIN') {
          this.dataService.saveLoginData(res);
          this.dataService.getFromLocalStorage();
          this.userService.getUserById(res.user_id).subscribe(resArray => {
          this.dataService.userData = resArray.response;
          this.dataService.adminStatus(resArray.response.user.userId);
        });
          this.router.navigate(['/admin-dashboard']);
        } else if (res.user_role.authority === 'ADMIN') {
          this.dataService.saveLoginData(res);
          this.dataService.getFromLocalStorage();
          this.userService.getUserById(this.dataService.userId).subscribe(resArray => {
          this.dataService.userData = resArray.response;
          this.dataService.adminStatus(resArray.response.user.userId);
        });
          this.router.navigate(['/admin-dashboard']);
        } else {
          this.customMessageService.notify('error', 'Please Enter Valid Email id or Password', '');
          this.auth.forceLogout();
        }

      });
    } else {
      ValidateAllFormFields(this.loginform);
    }
  }
}
