<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Account</div>
  <div class="px-5 py-4 minHeight80vh bg-white m-4 font-14">
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="row">
          <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="pb-2 font-14 pt-2">Add Amount</div>
          </div>
        </div>
      </div>
    </div>
      <form [formGroup]="addAmountFormGroup">
        <div class="my-3">
          <div class="row medium font-12 pb-3">
            <div class="col">
              <div class="medium pb-1">Description *</div>
              <div class="mb-3">
                <input pInputText formControlName="remarks" placeholder="Enter Description" type="text"
                  class="font-12 w-100 light height-40 px-3 inputBoxAddUser">

                <div class="error "
                  *ngIf="addAmountFormGroup.get('remarks')?.invalid && addAmountFormGroup.get('remarks')?.dirty">
                  <span *ngIf="addAmountFormGroup.get('remarks')?.hasError('required')">Description is
                    Required</span> </div>
              </div>
            </div>
          <div class="col">
            <div class="medium pb-1">Amount *</div>
            <div class="mb-3">
              <input pInputText formControlName="amount" placeholder="Enter Amount" type="text"
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser">

              <div class="error "
                *ngIf="addAmountFormGroup.get('amount')?.invalid && addAmountFormGroup.get('amount')?.dirty">
                <span *ngIf="addAmountFormGroup.get('amount')?.hasError('required')">Amount is
                  Required</span>
                  <span *ngIf="!addAmountFormGroup.get('amount')?.hasError('required') && addAmountFormGroup.get('amount')?.hasError('pattern')">Amount is invalid
                  </span> </div>
            </div>
          </div>

          <div class="col">
            <div class="medium pb-1">Select date *</div>
            <div class="mb-3">
              <input bsDatepicker formControlName="amountDate" type="text" placeholder="Enter Date"
              type="text" class="font-12 w-100 light height-40 px-3 inputBoxAddUser" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY'}">

              <div class="error "
              *ngIf="addAmountFormGroup.get('amountDate')?.invalid && addAmountFormGroup.get('amountDate')?.dirty">
              <span *ngIf="addAmountFormGroup.get('amountDate')?.hasError('required')">Date
                Required</span>
            </div>
            </div>
          </div>

        </div>
      </div>

          <div class="row medium font-12">
            <div class="col-12">
              <div class="text-right pt-5"><button type="button" (click)="onClickCancel()"
                  class="font-12 border-0 mr-5 submitBtn darkGreyBG text-white pointer"(click)="onClickCancel()">Cancel</button><button
                  type="submit" class="font-12 border-0 submitBtn redBG text-white pointer"(click)="onSubmit()">Submit</button>
              </div>
            </div>
          </div>
      </form>


  </div>
</div>
