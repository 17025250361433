<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Payroll</div>
  <div class="px-5 py-4 minHeight80vh bg-white m-4 font-14">
    <form [formGroup]="searchForm" (ngSubmit)="searchEmployee()">
      <div class="row">
        <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="row">
            <div class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-5 p-0">
              <div class="pb-2 font-14 pt-2">Manage Employees</div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-3"></div>

            <div class="col-9 col-xl-4 col-lg-4 col-md-4 col-sm-9">
              <div class="pb-4 font-16 position-relative">
                <input
                  type="text"
                  placeholder="Search with Employee Name"
                  formControlName="keyword"
                  (input)="searchEmployee()"
                  class="manageUserSelectBox w-100 height-30 pl-5 pr-3 font-12 light darkGreyFont pointer"
                />
                <img
                  alt="search"
                  src="../../assets/img/magnifying-glass.svg"
                  class="position-absolute searchIcon"
                />
              </div>
            </div>
            <div class="col-3 col-xl-1 col-lg-1 col-md-2 col-sm-3 p-0 addIcon">
              <img
                alt="add"
                src="../../assets/img/plus.svg"
                class="addIcon w-100 pointer"
                (click)="onclicAddIcon()"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-5 p-0">
          <div class="pb-2 font-14 pt-2">Total Records : {{ totalElements }}</div>
        </div>
        <div class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-5 p-0">
          <div class="pb-2 font-14 pt-2">Active Records: {{activeEmployeeCount}}</div>
        </div>
        <div class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-5 p-0">
          <div class="pb-2 font-14 pt-2">Inactive Records: {{inactiveEmployeeCount}}</div>
        </div>
      </div>
    </form>
    <div class="pb-4 font-12">
      <div class="pb-5">
        <div class="row medium py-3">
          <div class="col">Name</div>
          <div class="col">Employee ID</div>
          <div class="col">Location</div>
          <div class="col">Employee Type</div>
          <div class="col">Contact Number</div>
          <div class="col">Status</div>
          <div class="col"></div>
        </div>
        <div class="row py-2 light" *ngFor="let employee of this.allEmployees; let i = index">
          <div class="col">{{ employee.user.firstName }} {{ employee.user.lastName }}</div>
          <div class="col">{{ employee.employeeId }}</div>
          <div class="col">{{ employee.address.city|customTitleCase }}</div>
          <div class="col">{{ employee.employeeType|customTitleCase }}</div>
          <div class="col">{{ employee.user.mobileNumber }}</div>
          <div class="col">{{ employee.userStatus|customTitleCase }}</div>
          <div class="col">
            <img alt="Edit" src="../../assets/img/pencil.svg" class="width-20 me-2 pointer" 
            (click)="editEmployee(employee.userCredentialId)"/>
            <img *ngIf="employee.userStatus === 'ACTIVE'" title="Active User" alt="Edit" src="../../assets/img/check.png" class="width-20 me-2 pointer" 
            (click)="employeeStatusChange('INACTIVE',employee.userCredentialId)"/>
            <img *ngIf="employee.userStatus === 'INACTIVE'" alt="Edit" src="../../assets/img/block.png" class="width-20 pointer" 
            (click)="employeeStatusChange('ACTIVE',employee.userCredentialId)"/>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="this.pageSize < this.totalElements">
      <pagination [totalItems]="totalElements" previousText="&laquo;" nextText="&raquo;" [itemsPerPage]="50"
      [maxSize]="10" (pageChanged)="pageChanged($event)"></pagination>
      </div>
  </div>
</div>
