import { Injectable } from '@angular/core';
import { RestService } from '../core/rest.service';
import { Observable, throwError } from 'rxjs';
import { Environment } from 'src/environments/environment';
;
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  constructor(public restService: RestService) {}

  searchCustomer(name: string): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/customers/search/' + name, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getCustomer(): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/customers' , null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getCustomerbyVendorId(vendorId: string): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/purchaseOrders/generate?vendorId=' + vendorId, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getCustomerById(id: string): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/customers/' + id, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  addCustomers(data: any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/customers', data).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  editCustomers(id: string, data: any): Observable<any> {
    return this.restService.put(Environment.UrlPath + '/customers/' + id, data).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
}
