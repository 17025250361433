<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Employee Management</div>
  <div class="px-5 py-4 minHeight80vh bg-white m-4 font-14">
    <div class="row">
      <div class="col-3">
        <div class="pb-4 font-18 pt-1">Add Employee</div>
      </div>
      <div class="col-9 text-right">
        <button
          (click)="uploadEmployee()"
          tooltip="Add Employee Through Excel"
          class="btn bg-white border-0 p-0"
        >
          <img alt="Upload" src="../../assets/img/cloud-backup-up-arrow.svg" style="width: 40px;" />
        </button>
      </div>
    </div>

    <div class="mb-3">
      <form [formGroup]="addEmployeeForm">
        <div class="row medium font-12 pb-3">
          <div class="col">
            <div class="medium pb-1">Employee Name *</div>
            <div class="mb-3">
              <input
                type="text"
                placeholder="Enter Employee Name"
                type="text"
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser"
                formControlName="employeeName"
              />
              <div
                *ngIf="
                  addEmployeeForm.controls['employeeName'].invalid &&
                  addEmployeeForm.controls['employeeName'].dirty
                "
              >
                <span class="error" *ngIf="addEmployeeForm.controls['employeeName'].errors && addEmployeeForm.controls['employeeName'].errors['required']"
                  >Employee name is required</span
                >
                <span class="error" *ngIf="addEmployeeForm.controls['employeeName'].errors && addEmployeeForm.controls['employeeName'].errors['pattern']"
                  >Employee name can only be in text</span
                >
              </div>
            </div>
          </div>
          <div class="col">
            <div class="medium pb-1">Employee Designation *</div>
            <div class="mb-3">
              <select
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser"
                formControlName="employeeDesignation"
              >
                <option value="" selected>Select Employee Designation</option>
                <option
                  *ngFor="let designation of Designations"
                  value="{{ designation.designationId }}"
                  >{{ designation.designationName }}</option
                >
              </select>
              <div
                *ngIf="
                  addEmployeeForm.controls['employeeDesignation'].invalid &&
                  addEmployeeForm.controls['employeeDesignation'].dirty
                "
              >
                <span
                  class="error"
                  *ngIf="addEmployeeForm.controls['employeeDesignation'].errors && addEmployeeForm.controls['employeeDesignation'].errors['required']"
                  >Employee Designation is required</span
                >

              </div>
            </div>
          </div>
          <div class="col">
            <div class="medium pb-1">Employee ID *</div>
            <div class="mb-3">
              <input
                type="text"
                placeholder="Enter Employee ID"
                type="text"
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser"
                formControlName="employeeId"
              />
              <div
                *ngIf="
                  addEmployeeForm.controls['employeeId'].invalid &&
                  addEmployeeForm.controls['employeeId'].dirty
                "
              >
                <span class="error" *ngIf="addEmployeeForm.controls['employeeId'].errors && addEmployeeForm.controls['employeeId'].errors['required']"
                  >Employee ID is required</span
                >
                <span class="error" *ngIf="addEmployeeForm.controls['employeeId'].errors && addEmployeeForm.controls['employeeId'].errors['pattern']"
                  >Employee ID can only be in text</span
                >
              </div>
            </div>
          </div>
          <div class="col">
            <div class="medium pb-1">Employee Type *</div>
            <div class="mb-3">
              <select
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser"
                formControlName="employeeType"
                (change)="actOnEmpType()"
              >
                <option value="" selected>Select Employee Type</option>
                <option value="PERMANENT">Permanent</option>
                <option value="LABOURER" selected>Labourer</option>
              </select>
              <div
                *ngIf="
                  addEmployeeForm.controls['employeeType'].invalid &&
                  addEmployeeForm.controls['employeeType'].dirty
                "
              >
                <span class="error" *ngIf="addEmployeeForm.controls['employeeType'].errors && addEmployeeForm.controls['employeeType'].errors['required']"
                  >Employee Type is required</span
                >
              </div>
            </div>
          </div>
          <div class="col" *ngIf="!this.showSalaryDetailsForPermanent">
            <div class="medium pb-1">Category *</div>
            <div class="mb-3">
              <select
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser"
                formControlName="employeeCategory"
              >
                <option value="" selected>Select Category</option>
                <option value="SKILLED">Skilled</option>
                <option value="UNSKILLED">UnSkilled</option>
                <option value="SEMISKILLED">Semi-Skilled</option>
              </select>
              <div
                *ngIf="
                  addEmployeeForm.controls['employeeCategory'].invalid &&
                  addEmployeeForm.controls['employeeCategory'].dirty
                "
              >
                <span
                  class="error"
                  *ngIf="addEmployeeForm.controls['employeeCategory'].errors && addEmployeeForm.controls['employeeCategory'].errors['required']"
                  >Employee Category is required</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row medium font-14">
          <div class="col-12">
            <span class="bold subHeading px-3 pt-2 pb-3">Basic Details</span>
          </div>
        </div>
        <div class="contentBelowSubHeading p-3">
          <div class="row medium font-12">
            <div class="col">
              <div class="medium pb-1">Contact Number *</div>
              <div class="mb-3">
                <input
                  type="text"
                  placeholder="Enter Contact Number"
                  class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="contactNumber"
                />
                <div
                  *ngIf="
                    addEmployeeForm.controls['contactNumber'].invalid &&
                    addEmployeeForm.controls['contactNumber'].dirty
                  "
                >
                  <span
                    class="error"
                    *ngIf="addEmployeeForm.controls['contactNumber'].errors&& addEmployeeForm.controls['contactNumber'].errors['required']"
                    >Employee Contact Number is required</span
                  >
                  <span
                    class="error"
                    *ngIf="addEmployeeForm.controls['contactNumber'].errors&& addEmployeeForm.controls['contactNumber'].errors['pattern']"
                    >Employee Contact Number can only be number</span
                  >
                  <span
                    class="error"
                    *ngIf="addEmployeeForm.controls['contactNumber'].errors &&
                      !addEmployeeForm.controls['contactNumber'].errors['required'] &&
                      !addEmployeeForm.controls['contactNumber'].errors['pattern'] &&
                      addEmployeeForm.controls['contactNumber'].errors['minlength']
                    "
                    >Invalid mobile number.</span
                  >
                  <span
                    class="error"
                    *ngIf="addEmployeeForm.controls['contactNumber'].errors &&
                      !addEmployeeForm.controls['contactNumber'].errors['required'] &&
                      !addEmployeeForm.controls['contactNumber'].errors['pattern'] &&
                      addEmployeeForm.controls['contactNumber'].errors['maxlength']
                    "
                    >Invalid mobile number.</span
                  >
                </div>
              </div>
            </div>
            <div class="col siteMultiselect">
              <div class="medium pb-1">Site *</div>
              <div class="light mb-3">
              <p-dropdown [options]="allSites" formControlName="site" [filter]="true" placeholder="select a site"></p-dropdown>
                <div
                  *ngIf="addEmployeeForm.controls['site'].invalid && addEmployeeForm.controls['site'].dirty"
                >
                  <span class="error" *ngIf="addEmployeeForm.controls['site'].errors && addEmployeeForm.controls['site'].errors['required']"
                    >Site is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">DOB *</div>
              <div class="mb-3">
                <input
                  class="form-control pointer w-100 font-12 light height-40 px-3 font-12 inputBoxAddUser"
                  placeholder="Date of Birth"
                  #datepickerMDY="bsDatepicker"
                  bsDatepicker
                  formControlName="dateOfBirth"
                  [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', maxDate: get18YearsFromToday() }"
                />
                <div
                  *ngIf="
                    addEmployeeForm.controls['dateOfBirth'].invalid &&
                    addEmployeeForm.controls['dateOfBirth'].dirty
                  "
                >
                  <span
                    class="error"
                    *ngIf="addEmployeeForm.controls['dateOfBirth'].errors && addEmployeeForm.controls['dateOfBirth'].errors['required']"
                    >Date of birth is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">UAN No</div>
              <div class="mb-3">
                <input
                  type="text"
                  placeholder="Enter your UAN Number"
                  class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="uan"
                />
                <div *ngIf="addEmployeeForm.controls['uan'].invalid && addEmployeeForm.controls['uan'].dirty">
                  <span class="error" *ngIf="addEmployeeForm.controls['uan'].errors && addEmployeeForm.controls['uan'].errors['pattern']"
                    >Employee UAN can only be in text</span
                  >
                  <span
                    class="error"
                    *ngIf="addEmployeeForm.controls['uan'].errors &&
                      !addEmployeeForm.controls['uan'].errors['pattern'] &&
                      !addEmployeeForm.controls['uan'].errors['required'] &&
                      addEmployeeForm.controls['uan'].errors['maxlength']
                    "
                    >Employee UAN is too long.</span
                  >
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">Blood Group</div>
              <div class="mb-3">
                <select
                  class="font-12 w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="bloodGroup"
                >
                  <option value="" selected>Select Blood Group</option>
                  <option value="OPositive">O+</option>
                  <option value="ONegative">O-</option>
                  <option value="APositive">A+</option>
                  <option value="ANegative">A-</option>
                  <option value="BPositive">B+</option>
                  <option value="BNegative">B-</option>
                  <option value="ABPositive">AB+</option>
                  <option value="ABNegative">AB-</option>
                </select>
                <!-- <div
                  *ngIf="
                    addEmployeeForm.get('bloodGroup').invalid &&
                    addEmployeeForm.get('bloodGroup').dirty
                  "
                ></div> -->
              </div>
            </div>
          </div>

          <div class="row medium font-12">
            <div class="col">
              <div class="medium pb-1">Address *</div>
              <div class="mb-3">
                <input
                  type="text"
                  placeholder="Enter Address"
                  class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="address"
                />
                <div
                  *ngIf="
                    addEmployeeForm.controls['address'].invalid && addEmployeeForm.controls['address'].dirty
                  "
                >
                  <span class="error" *ngIf="addEmployeeForm.controls['address'].errors && addEmployeeForm.controls['address'].errors['required']"
                    >Employee Address is required</span
                  >
                  <span class="error" *ngIf="addEmployeeForm.controls['address'].errors && addEmployeeForm.controls['address'].errors['pattern']"
                    >Employee Address can only be in text</span
                  >
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">City *</div>
              <div class="mb-3">
                <input
                  type="text"
                  placeholder="Enter City"
                  class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="city"
                />
                <div
                  *ngIf="addEmployeeForm.controls['city'].invalid && addEmployeeForm.controls['city'].dirty"
                >
                  <span class="error" *ngIf="addEmployeeForm.controls['city'].errors && addEmployeeForm.controls['city'].errors['required']"
                    >Employee city is required</span
                  >
                  <span class="error" *ngIf="addEmployeeForm.controls['city'].errors && addEmployeeForm.controls['city'].errors['pattern']"
                    >Employee city can only be in text</span
                  >
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">State *</div>
              <div class="mb-3">
                <input
                  type="text"
                  placeholder="Enter State"
                  class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="state"
                />
                <div
                  *ngIf="addEmployeeForm.controls['state'].invalid && addEmployeeForm.controls['state'].dirty"
                >
                  <span class="error" *ngIf="addEmployeeForm.controls['state'].errors && addEmployeeForm.controls['state'].errors['required']"
                    >Employee state is required</span
                  >
                  <span class="error" *ngIf="addEmployeeForm.controls['state'].errors && addEmployeeForm.controls['state'].errors['pattern']"
                    >Employee state can only be in text</span
                  >
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">Country *</div>
              <div class="mb-3">
                <input
                  type="text"
                  placeholder="Enter Country Name"
                  class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="country"
                />
                <div
                  *ngIf="
                    addEmployeeForm.controls['country'].invalid && addEmployeeForm.controls['country'].dirty
                  "
                >
                  <span class="error" *ngIf="addEmployeeForm.controls['country'].errors && addEmployeeForm.controls['country'].errors['required']"
                    >Employee country is required</span
                  >
                  <span class="error" *ngIf="addEmployeeForm.controls['country'].errors && addEmployeeForm.controls['country'].errors['pattern']"
                    >Employee country can only be in text</span
                  >
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">Pincode *</div>
              <div class="mb-3">
                <input
                  placeholder="Enter Pincode"
                  class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="pincode"
                />
                <div
                  *ngIf="
                    addEmployeeForm.controls['pincode'].invalid && addEmployeeForm.controls['pincode'].dirty
                  "
                >
                  <span class="error" *ngIf="addEmployeeForm.controls['pincode'].errors && addEmployeeForm.controls['pincode'].errors['required']"
                    >Employee pincode is required</span
                  >
                  <span class="error" *ngIf="addEmployeeForm.controls['pincode'].errors && addEmployeeForm.controls['pincode'].errors['pattern']"
                    >Employee pincode can only be in numbers</span
                  >
                  <span
                    class="error"
                    *ngIf="addEmployeeForm.controls['pincode'].errors &&
                      !addEmployeeForm.controls['pincode'].errors['pattern'] &&
                      !addEmployeeForm.controls['pincode'].errors['required'] &&
                      addEmployeeForm.controls['pincode'].errors['minlength']
                    "
                    >Invalid pincode.</span
                  >
                  <span
                    class="error"
                    *ngIf="addEmployeeForm.controls['pincode'].errors &&
                      !addEmployeeForm.controls['pincode'].errors['pattern'] &&
                      !addEmployeeForm.controls['pincode'].errors['required'] &&
                      addEmployeeForm.controls['pincode'].errors['maxlength']
                    "
                    >Invalid pincode.</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row medium font-12">
            <div class="col">
              <div class="medium pb-1">ESI NO</div>
              <div class="mb-3">
                <input
                  placeholder="Enter ESI"
                  class="light height-40 px-3 inputBoxAddUser"
                  style="width: 180px;"
                  formControlName="esiNo"
                />
                <div
                  *ngIf="addEmployeeForm.controls['esiNo'].invalid && addEmployeeForm.controls['esiNo'].dirty"
                >
                  <span class="error" *ngIf="addEmployeeForm.controls['esiNo'].errors && addEmployeeForm.controls['esiNo'].errors['required']"
                    >ESI is required</span
                  >
                  <!-- <span class="error" *ngIf="addEmployeeForm.get('esi').errors['pattern']"
                    >ESI can only be numbers</span
                  > -->
                </div>
              </div>
            </div>

            <div class="col">
              <div class="medium pb-1">Gender *</div>
              <div class="mb-3">
                <select
                  class="font-12 w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="gender"
                >
                  <option value="" selected>Select Gender</option>
                  <option value="MALE">MALE</option>
                  <option value="FEMALE">FEMALE</option>
                </select>
                <div
                  *ngIf="
                    addEmployeeForm.controls['gender'].invalid && addEmployeeForm.controls['gender'].dirty
                  "
                >
                  <span class="error" *ngIf="addEmployeeForm.controls['gender'].errors && addEmployeeForm.controls['gender'].errors['required']"
                    >Gender required</span
                  >
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">Aadhar Number</div>
              <div class="mb-3">
                <input
                  placeholder="Enter Aadhar Number"
                  class="light height-40 px-3 inputBoxAddUser"
                  style="width: 180px;"
                  formControlName="aadhar"
                />
                <div
                  *ngIf="addEmployeeForm.controls['aadhar'].invalid && addEmployeeForm.controls['aadhar'].dirty"
                >
                  <span class="error" *ngIf="addEmployeeForm.controls['aadhar'].errors && addEmployeeForm.controls['aadhar'].errors['pattern']"
                    >Aadhar number is invalid.</span
                  >
                  <!-- <span class="error" *ngIf="addEmployeeForm.get('esi').errors['pattern']"
                    >ESI can only be numbers</span
                  > -->
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">Joining Date</div>
              <div class="mb-3">
                <input
                  class="form-control pointer w-100 font-12 light height-40 px-3 font-12 inputBoxAddUser"
                  placeholder="Date of Birth"
                  #datepickerMDY="bsDatepicker"
                  bsDatepicker
                  formControlName="dateOfJoining"
                  [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                />
              </div>
            </div>
            <div class="col"></div>
          </div>
        </div>
        <div class="row medium font-14 pt-4">
          <div class="col-7">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Savings Details</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12">
                <div class="col" *ngIf="this.showSalaryDetailsForPermanent" >
                  <div class="medium pb-1">Basic *</div>
                  <div class="mb-3">
                    <input
                      placeholder="Enter Basic"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="basic"
                    />
                    <div
                      *ngIf="
                        addEmployeeForm.controls['basic'].invalid && addEmployeeForm.controls['basic'].dirty
                      "
                    >
                      <span class="error" *ngIf="addEmployeeForm.controls['basic'].errors && addEmployeeForm.controls['basic'].errors['required']"
                        >Basic Pay is required</span
                      >
                      <span class="error" *ngIf="addEmployeeForm.controls['basic'].errors && addEmployeeForm.controls['basic'].errors['pattern']"
                        >Basic Pay can only be numbers</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col" *ngIf="this.showSalaryDetailsForPermanent" >
                  <div class="medium pb-1">DA *</div>
                  <div class="mb-3">
                    <input
                      placeholder="Enter DA"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="da"
                    />
                    <div
                      *ngIf="addEmployeeForm.controls['da'].invalid && addEmployeeForm.controls['da'].dirty"
                    >
                      <span class="error" *ngIf="addEmployeeForm.controls['da'].errors && addEmployeeForm.controls['da'].errors['required']"
                        >DA is required</span
                      >
                      <span class="error" *ngIf="addEmployeeForm.controls['da'].errors && addEmployeeForm.controls['da'].errors['pattern']"
                        >DA can only be numbers</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Other Allowance *</div>
                  <div class="mb-3">
                    <input
                      placeholder="Enter Other Allowance"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="otherAllowance"
                    />
                    <div
                      *ngIf="
                        addEmployeeForm.controls['otherAllowance'].invalid &&
                        addEmployeeForm.controls['otherAllowance'].dirty
                      "
                    >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['otherAllowance'].errors && addEmployeeForm.controls['otherAllowance'].errors['required']"
                        >Other Allowance is required</span
                      >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['otherAllowance'].errors && addEmployeeForm.controls['otherAllowance'].errors['pattern']"
                        >Other Allowance can only be numbers</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row medium font-12">
                <div class="col-4" *ngIf="this.showSalaryDetailsForPermanent">
                  <div class="medium pb-1">PPE's Allowance *</div>
                  <div class="mb-3">
                    <input
                      placeholder="Enter PPE's Allowance"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="ppeAllowance"
                    />
                    <div
                      *ngIf="
                        addEmployeeForm.controls['ppeAllowance'].invalid &&
                        addEmployeeForm.controls['ppeAllowance'].dirty
                      "
                    >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['ppeAllowance'].errors && addEmployeeForm.controls['ppeAllowance'].errors['required']"
                        >PPE Allowance is required</span
                      >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['ppeAllowance'].errors && addEmployeeForm.controls['ppeAllowance'].errors['pattern']"
                        >PPE Allowance can only be numbers</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-4" *ngIf="this.showSalaryDetailsForPermanent">
                  <div class="medium pb-1">Food Allowance *</div>
                  <div class="mb-3">
                    <input
                      placeholder="Enter Food Allowance"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="foodAllowance"
                    />
                    <div
                      *ngIf="
                        addEmployeeForm.controls['foodAllowance'].invalid &&
                        addEmployeeForm.controls['foodAllowance'].dirty
                      "
                    >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['foodAllowance'].errors && addEmployeeForm.controls['foodAllowance'].errors['required']"
                        >Food Allowance is required</span
                      >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['foodAllowance'].errors && addEmployeeForm.controls['foodAllowance'].errors['pattern']"
                        >Food Allowance can only be numbers</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-4">
                  <div class="medium pb-1">Mobile Allowance</div>
                  <div class="mb-3 max-width-270">
                    <input
                      type="text"
                      placeholder="Enter mobile Allowance"
                      name="mobile"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="mobileAllowance"
                    />

                    <div
                      *ngIf="
                        addEmployeeForm.controls['mobileAllowance'].invalid &&
                        addEmployeeForm.controls['mobileAllowance'].dirty
                      "
                    >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['mobileAllowance'].errors && addEmployeeForm.controls['mobileAllowance'].errors['pattern']"
                        >Please enter a valid mobile allowance.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="medium pb-1">&nbsp;</div>
                  <div class="mb-3 max-width-270">
                    <ul class="tg-list">
                      <li class="tg-list-item w-100 m-0 py-2">
                        <span class="d-inline-block">EPF Required</span>
                        <input class="tgl tgl-light" value="true" id="cb1"
                        formControlName="epf" type="checkbox"
                        />
                        <label class="tgl-btn" for="cb1"></label>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-4">
                  <div class="medium pb-1">&nbsp;</div>
                  <div class="mb-3 max-width-270">
                    <ul class="tg-list">
                      <li class="tg-list-item w-100 m-0 py-2">
                        <span class="d-inline-block">ESI Required</span>
                        <input class="tgl tgl-light" type="checkbox" value="true"
                        formControlName="esi"
                        id="cb2"
                       >
                        <label class="tgl-btn" for="cb2"></label>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-5" *ngIf="this.showSalaryDetailsForPermanent">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Deductions</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12 pb-1">
                <div class="col-6">
                  <div class="medium pb-1">Income Tax</div>
                  <div class="mb-3">
                    <input
                      placeholder="Income Tax"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="incomeTax"
                    />
                    <div
                      *ngIf="
                        addEmployeeForm.controls['incomeTax'].invalid &&
                        addEmployeeForm.controls['incomeTax'].dirty
                      "
                    >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['incomeTax'].errors && addEmployeeForm.controls['incomeTax'].errors['required']"
                        >Income Tax is required</span
                      >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['incomeTax'].errors && addEmployeeForm.controls['incomeTax'].errors['pattern']"
                        >Income Tax can only be numbers</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row medium font-14 pt-4">
          <div class="col-7">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Bank Details</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12">
                <div class="col">
                  <div class="medium pb-1">Account Number *</div>
                  <div class="mb-3">
                    <input
                      placeholder="Enter Account Number"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="accountNumber"
                    />
                    <div
                      *ngIf="
                        addEmployeeForm.controls['accountNumber'].invalid &&
                        addEmployeeForm.controls['accountNumber'].dirty
                      "
                    >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['accountNumber'].errors && addEmployeeForm.controls['accountNumber'].errors['required']"
                        >Account Number is required</span
                      >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['accountNumber'].errors && addEmployeeForm.controls['accountNumber'].errors['pattern']"
                        >Account Number can only be numbers</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Account Holder Name *</div>
                  <div class="mb-3">
                    <input
                      type="text"
                      placeholder="Enter Account Holder Name"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="accountName"
                    />
                    <div
                      *ngIf="
                        addEmployeeForm.controls['accountName'].invalid &&
                        addEmployeeForm.controls['accountName'].dirty
                      "
                    >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['accountName'].errors && addEmployeeForm.controls['accountName'].errors['required']"
                        >Account Holder Name is required</span
                      >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['accountName'].errors && addEmployeeForm.controls['accountName'].errors['pattern']"
                        >Account Holder Name can only be text</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row medium font-12">
                <div class="col">
                  <div class="medium pb-1">Bank Name *</div>
                  <div class="mb-3">
                    <select
                      class="font-12 w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="bankName"
                    >
                      <option value="" selected>Select Bank Name</option>
                      <option *ngFor="let bank of BankNames" [value]="bank.bankId">{{
                        bank.bankName
                      }}</option>
                    </select>
                    <div
                      *ngIf="
                        addEmployeeForm.controls['bankName'].invalid &&
                        addEmployeeForm.controls['bankName'].dirty
                      "
                    >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['bankName'].errors && addEmployeeForm.controls['bankName'].errors['required']"
                        >Bank Name is required</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Branch Name *</div>
                  <div class="mb-3">
                    <input
                      type="text"
                      placeholder="Enter Branch Name"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="branchName"
                    />
                    <div
                      *ngIf="
                        addEmployeeForm.controls['branchName'].invalid &&
                        addEmployeeForm.controls['branchName'].dirty
                      "
                    >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['branchName'].errors && addEmployeeForm.controls['branchName'].errors['required']"
                        >Bank Branch is required</span
                      >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['branchName'].errors && addEmployeeForm.controls['branchName'].errors['pattern']"
                        >Bank Branch can only be text</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">IFSC Code *</div>
                  <div class="mb-3">
                    <input
                      type="text"
                      placeholder="Enter IFSC Code"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="ifscCode"
                    />
                    <div
                      *ngIf="
                        addEmployeeForm.controls['ifscCode'].invalid &&
                        addEmployeeForm.controls['ifscCode'].dirty
                      "
                    >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['ifscCode'].errors && addEmployeeForm.controls['ifscCode'].errors['required']"
                        >IFSC Code is required</span
                      >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['ifscCode'].errors && addEmployeeForm.controls['ifscCode'].errors['pattern']"
                        >IFSC Code can only be text</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-5">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Emergency Contact</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12 pb-1">
                <div class="col-6">
                  <div class="medium pb-1">Contact Name</div>
                  <div class="mb-3">
                    <input
                      type="text"
                      placeholder="Enter Contact Name"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="emergencyContactName"
                    />
                    <div
                      *ngIf="
                        addEmployeeForm.controls['emergencyContactName'].invalid &&
                        addEmployeeForm.controls['emergencyContactName'].dirty
                      "
                    >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['emergencyContactName'].errors && addEmployeeForm.controls['emergencyContactName'].errors['pattern']"
                        >Emergency Contact Name can only be text</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="medium pb-1">Contact Number</div>
                  <div class="mb-3">
                    <input
                      type="text"
                      placeholder="Enter Contact Number"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="emergencyContactNumber"
                    />
                    <div
                      *ngIf="
                        addEmployeeForm.controls['emergencyContactNumber'].invalid &&
                        addEmployeeForm.controls['emergencyContactNumber'].dirty
                      "
                    >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['emergencyContactNumber'].errors && addEmployeeForm.controls['emergencyContactNumber'].errors['pattern']"
                        >Emergency Contact number can only be numbers.</span
                      >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['emergencyContactNumber'].errors &&
                          !addEmployeeForm.controls['emergencyContactNumber'].errors['pattern'] &&
                          addEmployeeForm.controls['emergencyContactNumber'].errors['minlength']
                        "
                        >Invalid mobile number.</span
                      >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['emergencyContactNumber'].errors &&
                          !addEmployeeForm.controls['emergencyContactNumber'].errors['required'] &&
                          !addEmployeeForm.controls['emergencyContactNumber'].errors['pattern'] &&
                          addEmployeeForm.controls['emergencyContactNumber'].errors['maxlength']
                        "
                        >Invalid mobile number.</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row medium font-12">
                <div class="col-6">
                  <div class="medium pb-1">Relation</div>
                  <div class="mb-3">
                    <input
                      type="text"
                      placeholder="Enter Relationship"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="emergencyContactRelation"
                    />
                    <div
                      *ngIf="
                        addEmployeeForm.controls['emergencyContactRelation'].invalid &&
                        addEmployeeForm.controls['emergencyContactRelation'].dirty
                      "
                    >
                      <span
                        class="error"
                        *ngIf="addEmployeeForm.controls['emergencyContactRelation'].errors && addEmployeeForm.controls['emergencyContactRelation'].errors['pattern']"
                        >Relationship can only be text</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row medium font-14">
          <div class="col-12">
            <div class="text-right pt-5">
              <button
                class="font-14 border-0 mr-5 submitBtn darkGreyBG text-white pointer"
                (click)="cancelRegistration()"
              >
                Cancel</button
              ><button
                class="font-14 border-0 submitBtn redBG text-white pointer"
                (click)="registerEmployee()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
