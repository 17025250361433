import { RestService } from '../core/rest.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Environment } from 'src/environments/environment';
;
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class UserService {
  constructor(public restService: RestService) {}

  getUserById(id: string): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/users/' + id, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  genaratePayslip(data: any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/payroll', data, false, true).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  genaratePayslipForPermanent(year: any, month: any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/payroll/generatePayslip/permanent?year=' + year + '&month=' + month, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );

  }
  getPayslip(pageNumber: any, pageSize: any): Observable<any> {
    return this.restService
      .get(Environment.UrlPath + '/payroll?pageNumber=' + pageNumber + '&pageSize=' + pageSize, null)
      .pipe(
        map(res => res),
        catchError(error => throwError(() => error))
      );
  }
  getZone(): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/masters/zones', null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  payslipSearch(data: any, pageNumber: any, pageSize: any): Observable<any> {
    return this.restService
      .get(
        Environment.UrlPath + '/payroll/search?pageNumber=' + pageNumber + '&pageSize=' + pageSize,
        data
      )
      .pipe(
        map(res => res),
        catchError(error => throwError(() => error))
      );
  }
  getPayroll(year: any, month: any) {
    return this.restService.get(Environment.UrlPath + '/dashboard/payroll?year=' + year + '&month=' + month, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
}
