import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { EmployeeService } from './../services/employee.service';
import { CustomMessageService } from '../core/message/message.service';
import { ValidateAllFormFields } from '../utils/validateform';
import { AmountService } from '../services/amount.service';
import { CustomValidators } from '../validators/custom.validator';
@Component({
  selector: 'app-add-supervisor',
  templateUrl: './add-supervisor.component.html',
  styleUrls: ['./add-supervisor.component.scss']
})
export class AddSupervisorComponent implements OnInit {
  allEmployeeData: any;
  totalElements: number  =0;
  supervisorForm!: FormGroup;
  searchForm!: FormGroup;
  currentPage = 1;
  pageSize = 50;
  constructor(
    private router: Router,
    private messagingService: CustomMessageService,
    private employeeService: EmployeeService,
    private formBuilder: FormBuilder,
    private amountService: AmountService,
  ) {}

  ngOnInit() {
    this.initSupervisorForm();
    this.onSearchForm();
    this.getEmployeeDetail();
    this.searchForm.valueChanges.subscribe(() => {
      this.searchEmployee();
    });
    this.resetUncheckedData();
  }
  onSearchForm() {
    this.searchForm = this.formBuilder.group({
      keyword:  new FormControl('', [
      ]),
    });
  }
  searchEmployee() {
    this.allEmployeeData = null;
    this.initSupervisorForm();
    if (this.searchForm.value.keyword === '') {
      this.getEmployeeDetail();
    } else {
      const keyword = this.searchForm.value.keyword;
      this.employeeService.supervisorEmployeeSearch(keyword, this.currentPage, this.pageSize).subscribe(res => {
        this.allEmployeeData = res.response.content;
        this.totalElements = res.response.totalElements;
        this.allEmployeeData.forEach((element: any) => {
          this.addSupervisorFields(element);
        });
      });
    }
  }
  getEmployeeDetail() {
    this.employeeService.getSupervisorEmployee(this.currentPage, this.pageSize).subscribe(res => {
      if (res.status.code === 0) {
        this.totalElements = res.response.totalElements;
        this.allEmployeeData = res.response.content;
        this.allEmployeeData.forEach((element: any) => {
          this.addSupervisorFields(element);
        });
      } else {
        this.totalElements = 0;
        this.messagingService.notify('error', 'Error', res.status.message);
      }
    });
  }
  pageChanged(event: any) {
    this.currentPage = event.page;
    this.searchEmployee();
  }
  addSupervisorFields(data: any) {
  const supervisor = this.supervisorFormArray;
  supervisor.push(this.formBuilder.group({
      checkbox: new FormControl('', [
      ]),
      employeeName: new FormControl(data.user.firstName, [
      ]),
      employeeId: new FormControl(data.employeeId, [
      ]),
      emailId: new FormControl('', [
        Validators.required,
        CustomValidators.email
       ]),
     }));
  }
  initSupervisorForm() {
    this.supervisorForm = this.formBuilder.group({
      supervisor: new FormArray([])});
  }
  resetUncheckedData() {
    this.supervisorFormArray.valueChanges.subscribe(res => {
    const control = this.supervisorFormArray.controls.filter(el => el.value.checkbox === false );
    res.forEach((element: any) => {
      if (element.checkbox === false) {
        control.forEach((data: any) => {
          data.reset({
            employeeName: element.employeeName,
            employeeId: element.employeeId,
            emailId: '',
            checkbox: ''
          });
        });
      }
    });
     }
    );
  }
  onSubmitSupervisor() {
    const control = this.supervisorFormArray.controls.filter(el => el.value.checkbox === true);
    if (control.length > 0) {
    const isFormInvalid = control.some(el => el.valid === false);
    if (isFormInvalid === false ) {
    const data = {
      supervisorRequestlist: this.supervisorFormArray.value.
        filter((el: any) => el.checkbox === true)
        .map((ele: any) => ({employeeId: ele.employeeId, emailId: ele.emailId}))
    };
    this.amountService.addSupervisor(data).subscribe((res) => {
      if (res.status.code === 0) {
        this.router.navigate(['manage-supervisor']);
      }
           });
    } else {
    control.forEach(ele => {
      ValidateAllFormFields( ele as FormGroup);
    });
    }
     } else {
      this.messagingService.notify('error', 'Error', 'Please Select Employee');
     }
   }
  get supervisorFormArray() {
    return this.supervisorForm.controls['supervisor'] as FormArray;
  }
  onCancel() {
    this.router.navigate(['manage-supervisor']);
  }

}
