import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MasterService } from '../services/master.service';

@Component({
  selector: 'app-manage-site',
  templateUrl: './manage-site.component.html',
  styleUrls: ['./manage-site.component.scss'],
})
export class ManageSiteComponent implements OnInit {
  allSites: any;
  searchForm: any;
  totalElements = 0;
  currentPage = 1;
  pageSize = 50;
  constructor(
    private router: Router,
    private masterService: MasterService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.onInitForm();
    this.onSearchForm();
  }
  onclicAddIcon() {
    this.router.navigate(['/add-site']);
  }
  onInitForm() {
    this.masterService.getAllSites(this.currentPage,this.pageSize).subscribe(res => {
      this.allSites = res.response.content;
      this.totalElements = res.response.totalElements;
    });
  }
  onSearchForm() {
    this.searchForm = this.formBuilder.group({
      keyword: [''],
    });
  }
  searchSite() {
    this.allSites = null;
    if (this.searchForm.value.keyword === '') {
      this.onInitForm();
    } else {
      const keyword = this.searchForm.value.keyword;
      this.masterService.siteSearch(keyword, this.currentPage,this.pageSize).subscribe(res => {
        this.allSites = res.response.content;
        this.totalElements = res.response.totalElements;
      });
    }
  }
  editSite(id: string) {
    this.router.navigate(['/edit-site'], { queryParams: { id } });
  }
  pageChanged(event: any) {
    this.currentPage = event.page ;
    this.searchSite();
  }
}
