import { Component, OnInit } from '@angular/core';
import { DashBoardData } from '../interface/dashboard.interface';
import { PurchaseService } from '../services/purchase.service';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  receivedTableData :DashBoardData[]= [];
  OutstandingTableData :DashBoardData[] = [];
  allInvoiceData: any;
  searchForm!: FormGroup;
  constructor(private purchaseService: PurchaseService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initSearchForm();
    this.getAllInvoiceData();
    this.searchOutstandingInvoice();

  }
  initSearchForm() {
    this.searchForm = this.formBuilder.group({
      keyword : new FormControl('')
    });
  }
  getAllInvoiceData() {
    this.purchaseService.searchInvoice().subscribe((ele: any) => {
      this.allInvoiceData = ele.response.content;
      this.receivedTableData = this.allInvoiceData.filter((el: any) => el.paymentRecievedDate);
      this.OutstandingTableData = this.allInvoiceData.filter((el: any) => !el.paymentRecievedDate);
    });
  }
  searchOutstandingInvoice() {
      this.searchForm.controls['keyword'].valueChanges.subscribe((data) => {
        const res = {
          keyword : this.searchForm.controls['keyword'].value
        }
        this.purchaseService.searchInvoice(res).subscribe(ele => {
          this.allInvoiceData = ele.response.content;
          this.OutstandingTableData = this.allInvoiceData.filter((el: any) => !el.paymentRecievedDate);
     });
      });

  }
  downloadReceivedTable(data: any) {
           const fileName = `Received.xlsx`;
           const request =  {
            paymentStatus: data,
           };
           this.purchaseService.downloadDashboardData(request).subscribe(ele => {
              if (ele) {
                const file = new File(
                    [ele],
                    fileName,
                    { type: 'application/vnd.ms.excel' }
                  );
                saveAs(file);
          }
            });
  }
  downloadOutstandingData(data: any) {
    const fileName = `Outstanding.xlsx`;
    const request =  {
      paymentStatus: data,
      keyword : this.searchForm.controls['keyword'].value
     };
    this.purchaseService.downloadDashboardData(request).subscribe(ele => {
              if (ele) {
                const file = new File(
                    [ele],
                    fileName,
                    { type: 'application/vnd.ms.excel' }
                  );
                saveAs(file);
          }
            });
  }
}
