import { RestService } from '../core/rest.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Environment } from 'src/environments/environment';
;
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class EmployeeService {
  constructor(public restService: RestService) {}

  addEmployee(data:any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/users', data).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getSupervisorEmployee(pageNumber?: number, pageSize?: number): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/users/list?pageNumber=' + pageNumber + '&pageSize=' + pageSize, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getAllEmployees(pageNumber?: number, pageSize?: number): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/users?pageNumber=' + pageNumber + '&pageSize=' + pageSize, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getAllActiveEmployeesCount(): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/users/ACTIVE/count', null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getAllInActiveEmployees(): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/users/INACTIVE/count', null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  employeeSearch(keword: string, pageNumber?: number, pageSize?: number): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/users/search/' + keword + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  employeeStatusChange(status:string, userId:string): Observable<any> {
    return this.restService.put(Environment.UrlPath + `/users/${userId}/status/${status}`, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  supervisorEmployeeSearch(keyword: string, pageNumber?: number, pageSize?: number): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/users/list/search/' + keyword+ '?pageNumber=' + pageNumber + '&pageSize=' + pageSize, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getEmployee(id: string): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/users/' + id, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  editEmployee(data:any, id: string): Observable<any> {
    return this.restService.put(Environment.UrlPath + '/users/' + id, data).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  UploadEmployee(data:any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/users/excelUpload', data, false, true).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getSupervisors(year:any, month:any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/dashboard/supervisors?year=' + year + '&month=' + month, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
}
