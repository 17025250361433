import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidateAllFormFields } from '../utils/validateform';
import { VendorService } from '../services/vendor.service';
import { MasterService } from '../services/master.service';

@Component({
  selector: 'app-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.scss']
})
export class AddVendorComponent implements OnInit {
  vendorFormGroup!: FormGroup;
  userId: any;
  userEdit = false;
  BankNames: any;
  stateNames: any;
  constructor(private route: ActivatedRoute, private masterService: MasterService,  private router: Router, private vendorService: VendorService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initForm();
    this.checkAndPrefillForm();
    this.getAllBanks();
    this.getAllStates();
  }
  initForm() {
    this.vendorFormGroup = this.formBuilder.group({
      vendorName: new FormControl(null, [
        Validators.required,


      ]),
      accountNumber: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]{0,2})?$'),
        Validators.maxLength(20),

      ]),
      accountName: new FormControl(null, [
        Validators.required,


      ]),
      accountType: new FormControl(null, [
        Validators.required,


      ]),
      bankName: new FormControl('', [
        Validators.required,
      ]),
      branchName: new FormControl(null, [
        Validators.required,


      ]),
      ifscCode: new FormControl(null, [
        Validators.required,


      ]),
      faxNumber: new FormControl(null, [
        Validators.minLength(3), Validators.maxLength(18)
      ]),
      daysPendingFromBillingDate: new FormControl(null, [
        Validators.required
      ]),
    gstin: ['',[Validators.maxLength(15)]],
cin: ['',[Validators.minLength(10), Validators.maxLength(21)]],
pan: new FormControl(null, [
  Validators.maxLength(10)
  ]),
addressLine1: new FormControl(null, [
    Validators.required,


  ]),
addressLine2: new FormControl(null, [
    Validators.required,


  ]),
state: new FormControl('', [
    Validators.required,
  ]),
city: new FormControl(null, [
    Validators.required,


  ]),
  location: new FormControl(null, [
    Validators.required,


  ]),
  pincode: ['',[Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^\\d+$')]],
  telephoneNumber: ['',
    [Validators.minLength(3), Validators.maxLength(18), Validators.pattern('^[0-9\+\-\]+$')]
  ],
  mobileNumber: ['',
  [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^\\d+$')]
  ],
    });
  }
  checkAndPrefillForm() {
    this.userId = this.route.snapshot.queryParamMap.get('id');
    if (this.userId) {
      this.userEdit = true;
      this.vendorService.getVendorById(this.userId).subscribe((ele) => {
      this.patchFormValue(ele.response);
    });
  }
  }
  patchFormValue(data: any) {
this.vendorFormGroup.patchValue({
vendorName: data.vendorName,
accountNumber: data.accountDetails.accountNumber,
accountName: data.accountDetails.accountName,
accountType: data.accountDetails.accountType,
bankName: data.accountDetails.bankId.bankId,
branchName: data.accountDetails.branchName,
ifscCode: data.accountDetails.ifscCode,
faxNumber: data.faxNumber,
daysPendingFromBillingDate: data.daysPendingFromBillingDate,
gstin: data.gstin,
cin: data.cin,
pan: data.pan,
addressLine1: data.address.addressLine1,
addressLine2: data.address.addressLine2,
state: data.address.state,
city: data.address.city,
location: data.address.landmark,
pincode: data.address.pinCode,
telephoneNumber: data.telephoneNumber,
mobileNumber: data.mobileNumber
});
  }
  onSubmitVendorForm(value: any) {
    if (this.vendorFormGroup.valid) {
    if (this.userEdit) {
      this.onSubmitEditedVendor(value);
      return;
    }
    this.vendorService.addVendors(value).subscribe((ele) => {
      this.router.navigate(['/vendor-invoice'], { queryParams: { type: '1' } });
    });
  } else {
    ValidateAllFormFields(this.vendorFormGroup);
  }
  }
  onSubmitEditedVendor(value: any) {
    this.vendorService.editVendors(this.userId , value).subscribe((ele) => {
      this.router.navigate(['/vendor-invoice'], { queryParams: { type: '1' } });
    });
  }
  onClickCancel() {
    this.router.navigate(['/vendor-invoice'], { queryParams: { type: '1' } }) ;
  }
  getAllBanks() {
    this.masterService.getAllBanks().subscribe(res => {
      this.BankNames = res.response;
    });
  }
  getAllStates() {
    this.masterService.getAllStates().subscribe(res => {
      this.stateNames = res.response;
    });
  }
}
