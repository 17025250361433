import { RestService } from '../core/rest.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Environment } from 'src/environments/environment';
;
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(public restService: RestService) {}

  getBobReport(reportType: any, data: any): Observable<any> {
    return this.restService
      .get(
        Environment.UrlPath + '/payroll/' + reportType , data,
        false
      )
      .pipe(
        map(res => res),
        catchError(error => throwError(() => error))
      );
  }
  getOtherBankReport(reportType: any, data: any): Observable<any> {
    return this.restService
      .get(
        Environment.UrlPath + '/payroll/' + reportType , data,
        false
      )
      .pipe(
        map(res => res),
        catchError(error => throwError(() => error))
      );
  }

 getBobReportDownload(reportType: any, data: any): Observable<any> {
    return this.restService
      .get(
        Environment.UrlPath +
          '/payroll/' + reportType + '/download', data,
        false,
        true
      )
      .pipe(
        map(res => res),
        catchError(error => throwError(() => error))
      );
  }
  downloadPayslip(data: any): Observable<any> {
    return this.restService
      .get(
        Environment.UrlPath +
          '/payroll/excelDownload' , data,
        false,
        true
      )
      .pipe(
        map(res => res),
        catchError(error => throwError(() => error))
      );
  }
  downloadPayslipPDFByZones(data: any): Observable<any> {
    return this.restService
      .get(
        Environment.UrlPath +
          '/payroll/zipDownload' , data,
        false,
        true
      )
      .pipe(
        map(res => res),
        catchError(error => throwError(() => error))
      );
  }
getOtherReportDownload(reportType: any ,data: any): Observable<any> {
    return this.restService
      .get(
        Environment.UrlPath +
          '/payroll/' + reportType + '/download' , data,
        false,
        true
      )
      .pipe(
        map(res => res),
        catchError(error => throwError(() => error))
      );
  }
  getBonusReport(data: any): Observable<any> {
    return this.restService
      .get(Environment.UrlPath + '/payroll/bonusReport/excelDownload' , data, false, true)
      .pipe(map((res: any) => res),
        catchError(error => throwError(() => error))
      );
  }
}
