import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../services/customer.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidateAllFormFields } from '../utils/validateform';
import { Router, ActivatedRoute } from '@angular/router';
import { MasterService } from '../services/master.service';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit {
customerFormGroup!: FormGroup;
  userId: any;
  userEdit = false;
  stateNames: any;
  constructor(private route: ActivatedRoute ,private masterService: MasterService, private router: Router, private customerService: CustomerService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initForm();
    this.getAllStates();
    this.checkAndPrefillForm();

  }
  checkAndPrefillForm() {
    this.userId = this.route.snapshot.queryParamMap.get('id');
    if (this.userId) {
      this.userEdit = true;
      this.customerService.getCustomerById(this.userId).subscribe((ele) => {
      this.patchFormValue(ele.response);
    });
  }
  }
  patchFormValue(data: any) {
this.customerFormGroup.patchValue({
  companyName : data.companyName,
  gstin : data.gstin,
  cin : data.cin,
  pan : data.pan,
  addressLine1 : data.address.addressLine1,
  addressLine2 : data.address.addressLine2,
  state : data.address.state,
  city : data.address.city,
  locality : data.address.landmark,
  pincode : data.address.pinCode,
  contactPerson : data.contactPerson,
  mobileNumber : data.mobileNumber,
});
  }
  initForm() {
    this.customerFormGroup = this.formBuilder.group({
      companyName: new FormControl(null, [
        Validators.required,
      ]),
    gstin: ['',
      [Validators.required, Validators.minLength(15), Validators.maxLength(15)]
  ],
cin: ['',
  [Validators.minLength(10), Validators.maxLength(21)]
  ],
pan: new FormControl(null, [
  Validators.minLength(10),
  Validators.maxLength(10)
  ]),
addressLine1: new FormControl(null, [
    Validators.required
  ]),
addressLine2: new FormControl(null, [
    Validators.required
  ]),
state: new FormControl('', [
    Validators.required,
  ]),
city: new FormControl(null, [
    Validators.required
  ]),
  locality: new FormControl(null, [
    Validators.required
  ]),
pincode: new FormControl(null, [
    Validators.required,
    Validators.pattern('^\\d+$'),
    Validators.minLength(6),
    Validators.maxLength(6),
  ]),
  contactPerson: new FormControl(null, [
    Validators.minLength(3),
    Validators.maxLength(25),
  ]),
mobileNumber: new FormControl(null, [
    Validators.pattern('^\\d+$'),
    Validators.minLength(3),
    Validators.maxLength(10),
  ]),
    });
  }
  onSubmitCustomerForm(value: any) {
  if (this.customerFormGroup.valid) {
    if (this.userEdit) {
      this.submitEditedUserData();
      return;
    }
    this.customerService.addCustomers(value).subscribe((ele) => {
      this.router.navigate(['/vendor-invoice'], { queryParams: { type: '2' } });

    });
  } else {
    ValidateAllFormFields(this.customerFormGroup);
  }
  }
  onClickCancel() {
    this.router.navigate(['/vendor-invoice'], {queryParams: { type: '2' }});
  }
  submitEditedUserData() {
const data = this.customerFormGroup.getRawValue();
this.customerService.editCustomers(this.userId, data).subscribe((ele) => {
  this.router.navigate(['/vendor-invoice'], { queryParams: { type: '2' } });
});
  }
  getAllStates() {
    this.masterService.getAllStates().subscribe(res => {
      this.stateNames = res.response;
    });
  }
}
