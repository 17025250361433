<div class="container-fluid reportsTab">
  <div class="font-16 pt-4 px-4">Payroll</div>
  <div class="px-5 py-4 minHeight80vh bg-white m-4 font-14">
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="pb-4 font-16 pt-1 medium">Reports</div>
      </div>
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <tabset type="pills">
          <tab heading="BOB Report" id="tab1" (selectTab)="bobReportTab()">
            <div class="py-3">
              <form [formGroup]="bobReportForm">
                <div class="row pt-4">
                  <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12">
                    <select class="w-100 height-30 px-3 font-12 medium darkGreyFont pointer" formControlName="year">
                      <option value="" selected>Select Year</option>
                      <option class="pointer" *ngFor="let status of range; let i = index" [value]="status.id">
                        {{ status.name }}
                      </option>
                    </select>
                    <div class="" *ngIf="
                        bobReportForm.controls['year'].invalid && bobReportForm.controls['year'].dirty
                      ">
                      <span class="error" *ngIf="bobReportForm.controls['year'].errors && bobReportForm.controls['year'].errors['required']">Please select
                        year.</span>
                    </div>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12">
                    <select class="w-100 height-30 px-3 font-12 medium darkGreyFont pointer" formControlName="month">
                      <option value="" disabled selected>Select Month</option>
                      <option class="pointer" *ngFor="let status of month; let i = index" [value]="status.id">
                        {{ status.name }}
                      </option>
                    </select>
                    <div class="" *ngIf="
                        bobReportForm.controls['month'].invalid && bobReportForm.controls['month'].dirty
                      ">
                      <span class="error" *ngIf="bobReportForm.controls['month'].errors && bobReportForm.controls['month'].errors['required']">Please select
                        month.</span>
                    </div>
                  </div>

                  <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12">
                    <select class="w-100 height-30 px-3 font-12 medium darkGreyFont pointer"
                      formControlName="reportType">
                      <option value="bobCombinedReport" selected>Select Report Type</option>
                      <option value="bobBasicPayReport">Basic</option>
                      <option value="bobOtherAllowanceReport">Other Allowance</option>
                    </select>
                  </div>
                  <div class="ol-xl-2 col-lg-2 col-md-3 col-sm-4 col-12 font-12">
                    <ng-multiselect-dropdown [placeholder]="'Select Site'" [data]="allSites"
                      [settings]="multipleSitesSettings" formControlName="multipleSites">
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="ol-xl-2 col-lg-2 col-md-3 col-sm-4 col-12 font-12">
                    <ng-multiselect-dropdown [placeholder]="'Select Zone'" [data]="zoneList"
                      [settings]="multipleZonesSettings" formControlName="multipleZones">
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12 text-right">
                    <img (click)="downloadBOBReport()" class="width-30 pointer" alt="download"
                      tooltip="Download"
                      src="../../assets/img/down-arrow.svg" />
                  </div>


                </div>
              </form>
              <div class="py-4 font-12">
                <div class="pb-5">
                  <div class="row medium py-3">
                    <div class="col-2">SL No</div>
                    <div class="col">Name</div>
                    <div class="col">Account Number</div>
                    <div class="col">{{bobAmountDisplayName}}</div>
                  </div>
                  <div class="row py-2 light" *ngFor="let items of bobData; let i = index">
                    <div class="col-2">{{ i + 1 }}</div>
                    <div class="col">{{ items.employeeName }}</div>
                    <div class="col">{{ items.accountNumber }}</div>
                    <div class="col">{{ items.amount }}</div>
                  </div>
                </div>
              </div>
            </div>
          </tab>

          <tab heading="Other Banks Report" id="tab2" (selectTab)="otherBankReportTab()">
            <div class="py-3">
              <form [formGroup]="otherBankReportForm">
                <div class="row pt-4">
                  <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12">
                    <select class="w-100 height-30 px-3 font-12 medium darkGreyFont pointer" formControlName="year">
                      <option value="" selected>Select Year</option>
                      <option class="pointer" *ngFor="let status of range; let i = index" [value]="status.id">
                        {{ status.name }}
                      </option>
                    </select>
                    <div class="" *ngIf="
                        otherBankReportForm.controls['year'].invalid &&
                        otherBankReportForm.controls['year'].dirty
                      ">
                      <span class="error" *ngIf="otherBankReportForm.controls['year'] && otherBankReportForm.controls['year'].errors && otherBankReportForm.controls['year'].errors['required']">Please select
                        year.</span>
                    </div>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12">
                    <select class="w-100 height-30 px-3 font-12 medium darkGreyFont pointer" formControlName="month">
                      <option value="" disabled selected>Select Month</option>
                      <option class="pointer" *ngFor="let status of month; let i = index" [value]="status.id">
                        {{ status.name }}
                      </option>
                    </select>
                    <div class="" *ngIf="
                        otherBankReportForm.controls['month'].invalid &&
                        otherBankReportForm.controls['month'].dirty
                      ">
                      <span class="error" *ngIf="otherBankReportForm.controls['month'].errors && otherBankReportForm.controls['month'].errors['required']">Please select
                        month.</span>
                    </div>
                  </div>


                  <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12">
                    <select class="w-100 height-30 px-3 font-12 medium darkGreyFont pointer font-12"
                      formControlName="reportType">
                      <option value="combinedReport" selected>Select Report Type</option> -->
                      <option value="basicPayReport">Basic</option>
                      <option value="otherAllowanceReport">Other Allowance</option>

                    </select>
                  </div>

                  <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12 font-12">
                    <ng-multiselect-dropdown [placeholder]="'Select Site'" [data]="allSites"
                      [settings]="multipleSitesSettings" formControlName="multipleSites">
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12 font-12">
                    <ng-multiselect-dropdown [placeholder]="'Select Zone'" [data]="zoneList"
                      [settings]="multipleZonesSettings" formControlName="multipleZones">
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12 text-right">
                    <img (click)="downloadOtherBankReport()" class="width-30 pointer" alt="download"
                      tooltip="Download"
                      src="../../assets/img/down-arrow.svg" />
                  </div>

                </div>
              </form>
              <div class="py-4 font-12">
                <div class="pb-5">
                  <div class="row medium py-3">
                    <div class="col-1">SL No</div>
                    <div class="col">Emp.Name</div>
                    <div class="col">Bank Name</div>
                    <div class="col-3">Account Number</div>
                    <div class="col">IFSC Code</div>
                    <div class="col-2">{{otherBankAmountDisplayname}}</div>
                  </div>
                  <div class="row py-2 light" *ngFor="let data of otherBankData; let i = index">
                    <div class="col-1">{{ i + 1 }}</div>
                    <div class="col">{{ data.employeeName }}</div>
                    <div class="col">{{ data.bankName }}</div>
                    <div class="col-3">{{ data.accountNumber }}</div>
                    <div class="col">{{ data.ifscCode }}</div>
                    <div class="col-2">{{ data.amount }}</div>
                  </div>
                </div>
              </div>
            </div>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</div>
