<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Site Management</div>
  <div class="px-5 py-4 minHeight80vh bg-white m-4 font-14">
    <div class="row">
      <div class="col-12">
        <div class="pb-4 font-14 pt-1">Add Site</div>
      </div>
    </div>
    <div class="mb-3">
      <form [formGroup]="addSiteForm" (ngSubmit)="onClickAddSite()">
        <div class="row medium font-12 pb-3">
          <div class="col-3">
            <div class="medium pb-1">Site Name *</div>
            <div class="mb-3">
              <input
                type="text"
                placeholder="Enter Site Name"
                type="text"
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser"
                formControlName="siteName"
              />
              <div
                *ngIf="
                  addSiteForm.controls['siteName'].invalid && addSiteForm.controls['siteName'].dirty
                "
              >
                <span class="error" *ngIf="addSiteForm.controls['siteName'].errors && addSiteForm.controls['siteName'].errors['required']"
                  >Site name is required</span
                >
                <span class="error" *ngIf="addSiteForm.controls['siteName'].errors && addSiteForm.controls['siteName'].errors['pattern']"
                  >Site name can only be in text</span
                >
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="medium pb-1">Principal Employer *</div>
            <div class="mb-3">
              <input
                type="text"
                placeholder="Employer Name"
                type="text"
                class="font-12 w-100 light height-40 px-3 inputBoxAddUser"
                formControlName="employerName"
              />
              <div
                *ngIf="
                  addSiteForm.controls['employerName'].invalid &&
                  addSiteForm.controls['employerName'].dirty
                "
              >
                <span class="error" *ngIf="addSiteForm.controls['employerName'].errors && addSiteForm.controls['employerName'].errors['required']"
                  >Employer name is required</span
                >
                <span class="error" *ngIf="addSiteForm.controls['employerName'].errors && addSiteForm.controls['employerName'].errors['pattern']"
                  >Employer name can only be in text</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row medium font-14">
          <div class="col-12">
            <span class="bold subHeading px-3 pt-2 pb-3">Address</span>
          </div>
        </div>
        <div class="contentBelowSubHeading p-3">
          <div class="row medium font-12">
            <div class="col">
              <div class="medium pb-1">Address Line 1 *</div>
              <div class="mb-3">
                <input
                  type="text"
                  placeholder="Enter Address"
                  class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="addressLine1"
                />
                <div
                  *ngIf="
                    addSiteForm.controls['addressLine1'].invalid &&
                    addSiteForm.controls['addressLine1'].dirty
                  "
                >
                  <span class="error" *ngIf="addSiteForm.controls['addressLine1'].errors && addSiteForm.controls['addressLine1'].errors['required']"
                    >Address is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">Address Line 2 *</div>
              <div class="mb-3">
                <input
                  type="text"
                  placeholder="Enter Address"
                  class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="addressLine2"
                />
                <div
                  *ngIf="
                    addSiteForm.controls['addressLine2'].invalid &&
                    addSiteForm.controls['addressLine2'].dirty
                  "
                >
                  <span class="error" *ngIf="addSiteForm.controls['addressLine2'].errors && addSiteForm.controls['addressLine2'].errors['required']"
                    >Address is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">State *</div>
              <div class="mb-3">
                <input
                  type="text"
                  placeholder="Enter State Name"
                  class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="state"
                />
                <div
                  *ngIf="
                    addSiteForm.controls['state'].invalid && addSiteForm.controls['state'].dirty
                  "
                >
                  <span class="error" *ngIf="addSiteForm.controls['state'].errors && addSiteForm.controls['state'].errors['required']"
                    >State is required</span
                  >
                  <span class="error" *ngIf="addSiteForm.controls['state'].errors && addSiteForm.controls['state'].errors['pattern']"
                    >State can only be in text</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row medium font-12">
            <div class="col">
              <div class="medium pb-1">City/District *</div>
              <div class="mb-3">
                <input
                  type="text"
                  placeholder="Enter City Or District"
                  class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="district"
                />
                <div
                  *ngIf="
                    addSiteForm.controls['district'].invalid &&
                    addSiteForm.controls['district'].dirty
                  "
                >
                  <span class="error" *ngIf="addSiteForm.controls['district'].errors && addSiteForm.controls['district'].errors['required']"
                    >District is required</span
                  >
                  <span class="error" *ngIf="addSiteForm.controls['district'].errors && addSiteForm.controls['district'].errors['pattern']"
                    >District name can only be in text</span
                  >
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">Location *</div>
              <div class="mb-3">
                <input
                  type="text"
                  placeholder="Enter Location"
                  class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="location"
                />
                <div
                  *ngIf="
                    addSiteForm.controls['location'].invalid &&
                    addSiteForm.controls['location'].dirty
                  "
                >
                  <span class="error" *ngIf="addSiteForm.controls['location'].errors && addSiteForm.controls['location'].errors['required']"
                    >Location is required</span
                  >
                  <span class="error" *ngIf="addSiteForm.controls['location'].errors && addSiteForm.controls['location'].errors['pattern']"
                    >Location can only be in text</span
                  >
                </div>
              </div>
            </div>
            <div class="col">
              <div class="medium pb-1">Pincode *</div>
              <div class="mb-3">
                <input
                  type="text"
                  placeholder="Enter Pincode"
                  class="w-100 light height-40 px-3 inputBoxAddUser"
                  formControlName="pincode"
                />
                <div
                  *ngIf="
                    addSiteForm.controls['pincode'].invalid && addSiteForm.controls['pincode'].dirty
                  "
                >
                  <span class="error" *ngIf="addSiteForm.controls['pincode'].errors && addSiteForm.controls['pincode'].errors['required']"
                    >Pincode is required</span
                  >
                  <span class="error" *ngIf="addSiteForm.controls['pincode'].errors && addSiteForm.controls['pincode'].errors['pattern']"
                    >Pincode should be in number</span
                  >
                  <span
                    class="error"
                    *ngIf="addSiteForm.controls['pincode'].errors &&
                      !addSiteForm.controls['pincode'].errors['required'] &&
                      !addSiteForm.controls['pincode'].errors['pattern'] &&
                      addSiteForm.controls['pincode'].errors['max']length
                    "
                    >Invalid pincode.</span
                  >
                  <span
                    class="error"
                    *ngIf="addSiteForm.controls['pincode'].errors &&
                      !addSiteForm.controls['pincode'].errors['required'] &&
                      !addSiteForm.controls['pincode'].errors['pattern'] &&
                      addSiteForm.controls['pincode'].errors['minlength']
                    "
                    >Invalid pincode.</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row medium font-14">
          <div class="col-6 mt-4">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Contact Person</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12">
                <div class="col">
                  <div class="medium pb-1">Contact Name</div>
                  <div class="mb-3">
                    <input
                      type="text"
                      placeholder="Enter Contact Name"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="contactName"
                    />
                    <div
                      *ngIf="
                        addSiteForm.controls['contactName'].invalid &&
                        addSiteForm.controls['contactName'].dirty
                      "
                    >
                      <span
                        class="error"
                        *ngIf="addSiteForm.controls['contactName'].errors &&
                          addSiteForm.controls['contactName'].errors['minlength'] &&
                          !addSiteForm.controls['contactName'].errors['pattern']
                        "
                        >Contact name is too short</span
                      >
                      <span
                        class="error"
                        *ngIf="addSiteForm.controls['contactName'].errors && addSiteForm.controls['contactName'].errors['max']length"
                        >Contact name is too long</span
                      >
                      <span class="error" *ngIf="addSiteForm.controls['contactName'].errors && addSiteForm.controls['contactName'].errors['pattern']"
                        >Contact name can only be in text</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Contact Number</div>
                  <div class="mb-3">
                    <input
                      type="text"
                      placeholder="Enter Number"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="contactNumber"
                    />
                    <div
                      *ngIf="
                        addSiteForm.controls['contactNumber'].invalid &&
                        addSiteForm.controls['contactNumber'].dirty
                      "
                    >
                      <span
                        class="error"
                        *ngIf="addSiteForm.controls['contactNumber'].errors &&
                          addSiteForm.controls['contactNumber'].errors['maxlength'] &&
                          !addSiteForm.controls['contactNumber'].errors['pattern']
                        "
                        >Invalid mobile number.</span
                      >
                      <span
                        class="error"
                        *ngIf="addSiteForm.controls['contactNumber'].errors &&
                          addSiteForm.controls['contactNumber'].errors['minlength'] &&
                          !addSiteForm.controls['contactNumber'].errors['pattern']
                        "
                        >Invalid mobile number.</span
                      >
                      <span
                        class="error"
                        *ngIf="addSiteForm.controls['contactNumber'].errors && addSiteForm.controls['contactNumber'].errors['pattern']"
                        >Contact number can only be in numbers.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1">Site/Zone *</div>
                  <div class="mb-3">
                    <select
                      class="w-100 height-40 inputBoxAddUser px-3 font-12 light darkGreyFont pointer"
                      formControlName="zone"
                      (change)="selectDropdown($event)"
                    >
                      <option value="" disabled selected>Zone</option>
                      <option
                        class="pointer"
                        *ngFor="let zone of this.allZones; let i = index"
                        [value]="zone.zoneId"
                      >
                        {{ zone.zoneName }}</option
                      >
                    </select>

                    <div
                      *ngIf="
                        addSiteForm.controls['zone'].invalid && addSiteForm.controls['zone'].dirty
                      "
                    >
                      <span class="error" *ngIf="addSiteForm.controls['zone'].errors && addSiteForm.controls['zone'].errors['required']"
                        >Site/Zone is required</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row medium font-12" *ngIf="showSite">
                <div class="col">
                  <div class="medium pb-1">Daily Wages</div>
                  <div class="mb-3">
                    <input
                      type="text"
                      placeholder="Enter Unskilled "
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="unskilled"
                    />

                    <div>
                      <span
                        class="error"
                        *ngIf="addSiteForm.controls['unskilled'].value === '' && validatorType"
                        >Unskilled is required</span
                      >
                      <span
                        class="error"
                        *ngIf="
                          addSiteForm.controls['unskilled'].invalid &&
                          addSiteForm.controls['unskilled'].dirty &&
                          addSiteForm.controls['unskilled'].errors && addSiteForm.controls['unskilled'].errors['pattern']
                        "
                        >Unskilled should be in number</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1 transparentText">*</div>
                  <div class="mb-3">
                    <input
                      type="text"
                      placeholder="Enter Semi Skilled"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="semiSkilled"
                    />
                    <div>
                      <span
                        class="error"
                        *ngIf="addSiteForm.controls['semiSkilled'].value === '' && validatorType"
                      >
                        Semi skilled is required</span
                      >
                      <span
                        class="error"
                        *ngIf="
                          addSiteForm.controls['semiSkilled'].invalid &&
                          addSiteForm.controls['semiSkilled'].dirty &&
                          addSiteForm.controls['semiSkilled'].errors && addSiteForm.controls['semiSkilled'].errors['pattern']
                        "
                        >Semi skilled should be in number</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="medium pb-1 transparentText">*</div>
                  <div class="mb-3">
                    <input
                      type="text"
                      placeholder="Enter Skilled"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="skilled"
                    />

                    <div>
                      <span
                        class="error"
                        *ngIf="addSiteForm.controls['skilled'].value === '' && validatorType"
                        >Skilled is required</span
                      >
                      <span
                        class="error"
                        *ngIf="
                          addSiteForm.controls['skilled'].invalid &&
                          addSiteForm.controls['skilled'].dirty &&
                          addSiteForm.controls['skilled'].errors && addSiteForm.controls['skilled'].errors['pattern']
                        "
                        >Skilled should be in number</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6 mt-4" *ngIf="showSite">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Earnings and Deductions</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12 pb-1">
                <div class="col-12">
                  <div class="medium pb-1">Additions</div>
                </div>
                <div class="col-12 light">
                  <label class="radio  float-left pr-3">
                    <input type="checkbox" name="hra" formControlName="isHra" />
                    <span>HRA</span>
                  </label>

                  <label class="radio  float-left pr-3">
                    <input type="checkbox" name="conveyance" formControlName="isConveyance" />
                    <span>Conveyance</span>
                  </label>

                  <label class="radio  float-left pr-3">
                    <input type="checkbox" name="vhr" formControlName="isVhr" />
                    <span>VHR</span>
                  </label>


                  <label class="radio float-left pr-3">
                    <input type="checkbox" name="shoe" formControlName="isShoeAllowance" />
                    <span>Shoe</span>
                  </label>
                </div>
              </div>

              <div class="row medium font-12">
                <div class="col-12">
                  <div class="medium pb-1 pt-4">Deductions</div>
                </div>
                <div class="col-12 light mb-2">
                  <label class="radio  float-left pr-3">
                    <input type="checkbox" name="epf" formControlName="isEpf" />
                    <span>EPF</span>
                  </label>

                  <label class="radio  float-left pr-3">
                    <input type="checkbox" name="esi" formControlName="isEsi" />
                    <span>ESI</span>
                  </label>

                  <label class="radio  float-left pr-3">
                    <input type="checkbox" name="pt" formControlName="isPt" />
                    <span>Professional Tax</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6 mt-4">
            <div class="row">
              <div class="col-12">
                <span class="bold subHeading px-3 pt-2 pb-3">Leaves Wage</span>
              </div>
            </div>
            <div class="contentBelowSubHeading p-3">
              <div class="row medium font-12">
                <div class="col-5">
                  <div class="medium pb-1">Leaves Wage</div>
                  <div class="mb-3">
                    <input
                      type="text"
                      placeholder="Enter Leave wage" style="max-width: 140px;"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="leaveWage"
                    />&nbsp;&nbsp;%
                    <div
                            *ngIf="
                            addSiteForm.controls['leaveWage'].invalid &&
                            addSiteForm.controls['leaveWage'].dirty
                            "
                          >
                            <span
                              class="error"
                              *ngIf="addSiteForm.controls['leaveWage'].errors && !addSiteForm.controls['leaveWage'].errors['max'] && !addSiteForm.controls['leaveWage'].errors['pattern'] && addSiteForm.controls['leaveWage'].errors['max']length"
                              >Leave wage is too long.</span
                            >
                            <span
                              class="error"
                              *ngIf="addSiteForm.controls['leaveWage'].errors && !addSiteForm.controls['leaveWage'].errors['max'] && !addSiteForm.controls['leaveWage'].errors['pattern'] && addSiteForm.controls['leaveWage'].errors['minlength']"
                              >Leave wage is too short.</span
                            >
                            <span
                              class="error"
                              *ngIf="addSiteForm.controls['leaveWage'].errors && addSiteForm.controls['leaveWage'].errors['pattern']"
                              >Please enter the leave wage correctly.</span
                            >
                            <span
                              class="error"
                              *ngIf="addSiteForm.controls['leaveWage'].errors &&
                                !addSiteForm.controls['leaveWage'].errors['pattern'] &&
                                addSiteForm.controls['leaveWage'].errors['max']
                              "
                              >Please enter the leave wage correctly.</span
                            >
                          </div>
                  </div>
                </div>
                <div class="col-7">
                  <div class="medium pb-1">Monthly Bonus</div>
                  <div class="mb-3">
                    <input
                      type="text"
                      placeholder="Enter Monthly Bonus" style="max-width: 140px;"
                      class="w-100 light height-40 px-3 inputBoxAddUser"
                      formControlName="bonusPercent"
                    />&nbsp;&nbsp;%
                    <div
                            *ngIf="
                            addSiteForm.controls['bonusPercent'].invalid &&
                            addSiteForm.controls['bonusPercent'].dirty
                            "
                          >
                            <span
                              class="error"
                              *ngIf="addSiteForm.controls['bonusPercent'].errors &&
                                !addSiteForm.controls['bonusPercent'].errors['pattern'] &&
                                addSiteForm.controls['bonusPercent'].errors['max']
                              "
                              >Please enter the Monthly Bonus correctly.</span
                            >
                          </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row medium font-14">
          <div class="col-12">
            <div class="text-right pt-5">
              <button
                class="font-14 border-0 mr-5 submitBtn darkGreyBG text-white pointer"
                (click)="onCancel()"
              >
                Cancel</button
              ><button class="font-14 border-0 submitBtn redBG text-white pointer" type="submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
