<div class="container-fluid">
  <div class="font-16 pt-4">Dashboard</div>
  <div class="px-lg-5 py-lg-5 py-5 minHeight80vh bg-white m-lg-3 font-14">
    <form [formGroup]="dashboardForm" class="px-lg-0 px-2 py-lg-0 py-2">
      <div class="row my-2 myl-lg-0 mx-lg-0 mx-2">
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
          <select class="w-100 height-30 px-3 font-12 medium darkGreyFont pointer" formControlName="year">
            <option value="" selected>Select Year</option>
            <option class="pointer" *ngFor="let status of range; let i = index" [value]="status.id">
              {{ status.name }}
            </option>
          </select>
          <div class="" *ngIf="
        dashboardForm.controls['year'].invalid && dashboardForm.controls['year'].dirty
      ">
            <span class="error" *ngIf="dashboardForm.controls['year'].errors.required">Please select year.</span>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
          <select class="w-100 height-30 px-3 font-12 medium darkGreyFont pointer" formControlName="month">
            <option value="" disabled selected>Select Month</option>
            <option class="pointer" *ngFor="let status of month; let i = index" [value]="status.id">
              {{ status.name }}
            </option>
          </select>
          <div class="" *ngIf="
        dashboardForm.controls['month'].invalid && dashboardForm.controls['month'].dirty
      ">
            <span class="error" *ngIf="dashboardForm.controls['month'].errors.required">Please select month.</span>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" style="border-right: 1px solid #ececec;
      border-bottom: 1px solid #ececec;">
        <div class="font-16 pt-5 pb-2 bold ">
          <p class="px-4 px-sm-4 p-md-0">Payroll</p></div>
        <div style="display: block;" class="py-4 w-100">
          <!-- pie chart -->
          <canvas id="pieChart" class="pie-chart"></canvas>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" style="border-bottom: 1px solid #ececec;">
        <div class="font-16 pt-5 pb-2 bold"><p class="px-4 px-sm-4 p-md-0">Accounts</p></div>
        <div style="display: block;" class="py-4">
          <!-- horizontal bar chart -->
          <canvas id="AccountBarChart"></canvas>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" style=" border-right: 1px solid #ececec;">
        <div class="font-16 pt-5 pb-2 bold"><p class="px-4 px-sm-4 p-md-0">Purchase Order</p></div>
        <div style="display: block;" class="py-4">
          <!-- horizontal bar chart -->
          <canvas id="PurchaseBarChart"></canvas>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
        <div class="font-16 pt-5 pb-2 bold"><p class="px-4 px-sm-4 p-md-0">Invoice</p></div>
        <div style="display: block;" class="py-4">
          <!-- horizontal bar chart -->
          <canvas id="InvoiceBarChart"></canvas>
        </div>
      </div>
    </div>
  </div>
</div>