<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Payroll</div>
  <div class="px-lg-5 py-5 px-4 minHeight80vh bg-white m-lg-3 font-14">
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="pb-4 font-16 pt-1 medium">Payslip</div>
      </div>
    </div>

    <div class="row" *ngIf="dataService.adminStatus1 === true">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 payslipTab">
        <tabset type="pills" >
          <tab heading="Generate">
            <div>
              <form [formGroup]="payslipForm">
                <div class="row w-100">
                  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6">
                    <select
                      class="w-100 height-30 px-3 font-12 medium darkGreyFont pointer"
                      formControlName="year"
                    >
                      <option value="" selected>Select Year</option>
                      <option
                        class="pointer"
                        *ngFor="let status of range; let i = index"
                        [value]="status.id"
                      >
                        {{ status.name }}
                      </option>
                    </select>
                    <div
                      class=""
                      *ngIf="
                        payslipForm.controls['year'].invalid && payslipForm.controls['year'].dirty
                      "
                    >
                      <span class="error" *ngIf="payslipForm.controls['year'].errors.required"
                        >Please select year.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6">
                    <select
                      class="w-100 height-30 px-3 font-12 medium darkGreyFont pointer"
                      formControlName="month"
                    >
                      <option value="" disabled selected>Select Month</option>
                      <option
                        class="pointer"
                        *ngFor="let status of month; let i = index"
                        [value]="status.id"
                      >
                        {{ status.name }}
                      </option>
                    </select>
                    <div
                      class=""
                      *ngIf="
                        payslipForm.controls['month'].invalid && payslipForm.controls['month'].dirty
                      "
                    >
                      <span class="error" *ngIf="payslipForm.controls['month'].errors.required"
                        >Please select month.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row w-100">
                  <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1 col-12"></div>
                  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 col-12">
                    <div class="text-center uploadBox my-5 px-5">
                      <h3 class="font-24 regular pt-5 pb-4">Upload Your File</h3>
                      <div class="font-12 light pb-3">Only Excel Files :)</div>
                      <div>
                        <img
                          alt="Excel Only"
                          src="../../assets/img/1excel-icon.png"
                          class="width-60"
                        />
                      </div>
                      <div class="my-4 pointer">
                        <label for="uploadexcel" class="excelUpload w-100 py-4 pointer"
                          >Drag a file here or&nbsp;<span class="orangeFont">Browse</span>&nbsp;for
                          a file to upload</label
                        >
                        <input
                          type="file"
                          class="pointer"
                          id="uploadexcel"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          formControlName="file"
                          (change)="onSelectedFile($event)"
                        />
                        <div *ngIf="this.file">
                          <span>{{ file.name }}</span>
                        </div>
                        <div
                          class=""
                          *ngIf="
                            payslipForm.controls['file'].invalid &&
                            payslipForm.controls['file'].dirty
                          "
                        >
                          <span class="error" *ngIf="payslipForm.controls['file'].errors.required"
                            >Please select file.</span
                          >
                        </div>
                        <div>
                        <span class="error" *ngIf="showFiletypeError === true"
                            >Please upload valid format file.</span
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <button
                            class="font-14 border-0 submitBtn darkGreyBG text-white mb-5 pointer"
                            type="button"
                            (click)="onClear()"
                          >
                            Cancel
                          </button>
                        </div>
                        <div class="col-6">
                          <button
                            class="font-14 border-0 submitBtn redBG text-white mb-5 pointer"
                            (click)="onSubmitedForm()"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-2 col-sm-1 col-12"></div>
                </div>
              </form>
            </div>
          </tab>

          <tab heading="Payslip" [active]="dashboardMonth ? true : false"(selectTab)="onSearch()">
            <div>
              <form [formGroup]="genarateForm">
                <div class="row w-100">
                  <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
                    <select
                      class="w-100 height-30 px-3 font-12 medium darkGreyFont pointer"
                      formControlName="year"
                      (change)="onSearch()"
                    >
                      <option value="" selected>Select Year</option>
                      <option
                        class="pointer"
                        *ngFor="let status of range; let i = index"
                        [value]="status.id"
                      >
                        {{ status.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
                    <select
                      class="w-100 height-30 px-3 font-12 medium darkGreyFont pointer"
                      formControlName="month"
                      (change)="onSearch()"
                    >
                      <option value="" selected>Select Month</option>
                      <option
                        class="pointer"
                        *ngFor="let status of month; let i = index"
                        [value]="status.id"
                      >
                        {{ status.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="my-4 position-relative">
                      <input
                        type="text"
                        placeholder="Search with employee ID / Site"
                        type="text"
                        class="font-12 w-100 light  px-4 manageUserSelectBox height-30"
                        formControlName="keyword"

                      />
                      <img
                        alt="search"
                        src="../../assets/img/search.svg"
                        class="position-absolute searchhIcon"
                      />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-6">
                    <div class="my-4">
                      <select
                        class="w-100 height-30 px-3 font-12 manageUserSelectBox pointer light"
                        formControlName="zone"
                        (change)="onSearch()"
                      >
                        <option value="" selected>Select Zone</option>
                        <option
                          class="pointer"
                          *ngFor="let item of zoneList; let i = index"
                          [value]="item.zoneName"
                        >
                          {{ item.zoneName }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div *ngIf="totalElements !== 0"class="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12 ">
                    <div class="row">
                  <div class="col text-right">
                    <img
                    (click)="downloadAllPayslipByZone()"
                    class="width-30 pointer my-4" alt="Download Payslips"
                    title="Download Payslips"
                    src="../../assets/img/pdf.svg"
                  />
                  </div>
                  <div>
                    <img
                    (click)="downloadAllPayslip()"
                    class="width-30 pointer my-4" alt="Download Excel"
                    title="Download Excel"
                    src="../../assets/img/excel.svg"
                  />
                  </div>
                </div>
                </div>
                </div>
                <div class="font-16 medium">Total Records : {{ totalElements }}</div>
                <div class="row font-12 regular py-4">
                  <div
                    *ngFor="let item of paySlipData"
                    class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 borderRightEmployee"
                  >
                  <a [href]="openPdf(item.payslipId)" target="_blank" class="text-dark">
                    <div class="row" (click)="openPdf()">
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div class="text-left py-1">{{ item.employee?.employeeId }}</div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div class="text-left py-1">

                          {{ item.employee.user?.firstName }} {{ item.employee.user?.lastName }}

                        </div>
                      </div>
                    </div>
                  </a>
                  </div>
                </div>
              </form>
            </div>
            <div *ngIf="this.pageSize < this.totalElements">
            <pagination
            [totalItems]="totalElements"
            previousText="&laquo;"
            nextText="&raquo;"
            [itemsPerPage]="50"
            [maxSize]="10"
            (pageChanged)="pageChanged($event)"
            ></pagination>
            </div>
          </tab>
        </tabset>
      </div>
    </div>
    <div class="row" *ngIf="dataService.adminStatus1 === false">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 payslipTab">
      <div>
        <form [formGroup]="genarateForm">
          <div class="row w-100">
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
              <select
                class="w-100 height-30 px-3 font-12 medium darkGreyFont pointer"
                formControlName="year"
                (change)="onSearch()"
              >
                <option value="" selected>Select Year</option>
                <option
                  class="pointer"
                  *ngFor="let status of range; let i = index"
                  [value]="status.id"
                >
                  {{ status.name }}
                </option>
              </select>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
              <select
                class="w-100 height-30 px-3 font-12 medium darkGreyFont pointer"
                formControlName="month"
                (change)="onSearch()"
              >
                <option value="" selected>Select Month</option>
                <option
                  class="pointer"
                  *ngFor="let status of month; let i = index"
                  [value]="status.id"
                >
                  {{ status.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <div class="my-4 position-relative">
                <input
                  type="text"
                  placeholder="Search with employee ID / Site"
                  type="text"
                  class="font-12 w-100 light  px-4 manageUserSelectBox height-30"
                  formControlName="keyword"
                  (input)="onSearch()"
                />
                <img
                  alt="search"
                  src="../../assets/img/search.svg"
                  class="position-absolute searchhIcon"
                />
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12">
              <div class="my-4">
                <select
                  class="w-100 height-30 px-3 font-12 manageUserSelectBox pointer light"
                  formControlName="zone"
                  (change)="onSearch()"
                >
                  <option value="" selected>Select Zone</option>
                  <option
                    class="pointer"
                    *ngFor="let item of zoneList; let i = index"
                    [value]="item.zoneName"
                  >
                    {{ item.zoneName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12 text-right">
              <img
                (click)="downloadAllPayslip()"
                class="width-30 pointer my-4" alt="download"
                tooltip="Download"
                src="../../assets/img/down-arrow.svg"
              />
            </div>
          </div>
          <div class="font-16 medium">Total Records : {{ totalElements }}</div>
          <div class="row font-12 regular py-4">
            <div
              *ngFor="let item of paySlipData"
              class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 borderRightEmployee"
            >
            <a [href]="openPdf(item.payslipId)" target="_blank" class="text-dark">
              <div class="row" (click)="openPdf()">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div class="text-left py-1">{{ item.employee?.employeeId }}</div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div class="text-left py-1">

                    {{ item.employee.user?.firstName }} {{ item.employee.user?.lastName }}

                  </div>
                </div>
              </div>
            </a>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="this.pageSize < this.totalElements">
      <pagination
      [totalItems]="totalElements"
      previousText="&laquo;"
      nextText="&raquo;"
      [itemsPerPage]="50"
      [maxSize]="10"
      (pageChanged)="pageChanged($event)"
      ></pagination>
      </div>
      </div>
    </div>
  </div>
</div>
