import { RestService } from '../core/rest.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Environment } from 'src/environments/environment';
;
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class MasterService {
  constructor(public restService: RestService) {}

  onCreateZone(data: any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/masters/zones', data).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getAllZone(pageNumber?:number, pageSize?: number): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/masters/zones?pageNumber=' + pageNumber + '&pageSize=' + pageSize, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  onCreateStatutory(data: any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/masters/statutory', data).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  onGetStatutory(): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/masters/statutory', null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  onCreateSite(data: any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/masters/sites', data).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  onUpdateSite(data: any, id: any): Observable<any> {
    return this.restService.put(Environment.UrlPath + '/masters/sites/' + id, data).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  onGetSite(id:any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/masters/sites/' + id, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getAllSites(pageNumber?: number, pageSize?: number): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/masters/sites?pageNumber=' + pageNumber + '&pageSize=' + pageSize, null, false).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  siteSearch(data: any, pageNumber?: any, pageSize?: any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/masters/sites/search/' + data + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize,null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getZone(id: any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/masters/zones/' + id, null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  onUpdateZone(data: any, id: any): Observable<any> {
    return this.restService.put(Environment.UrlPath + '/masters/zones/' + id, data).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getAllEmployeeDesignation(): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/masters/designations', null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getAllBanks(): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/masters/banks', null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  getAllStates(): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/masters/states', null).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
}
