<div class="container-fluid">
  <div class="font-16 pt-4 px-4">Payroll</div>
  <div class="px-5 py-4 minHeight80vh bg-white m-4 font-14">
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <form [formGroup]="searchForm" (ngSubmit)="searchSite()">
          <div class="row">
            <div class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-5 p-0">
              <div class="pb-2 font-14 pt-2">Manage Sites</div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-3"></div>

            <div class="col-9 col-xl-4 col-lg-4 col-md-4 col-sm-9">
              <div class="pb-4 font-16 position-relative">
                <input
                  type="text"
                  placeholder="Search with Site Name"
                  formControlName="keyword"
                  class="manageUserSelectBox w-100 height-30 pl-5 pr-3 font-12 light darkGreyFont pointer"
                  (input)="searchSite()"
                />
                <img
                  alt="search"
                  src="../../assets/img/magnifying-glass.svg"
                  class="position-absolute searchIcon"
                />
              </div>
            </div>

            <div class="col-3 col-xl-1 col-lg-1 col-md-2 col-sm-3 p-0 addIcon">
              <img
                alt="add"
                src="../../assets/img/plus.svg"
                class="addIcon w-100 pointer"
                (click)="onclicAddIcon()"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="pb-4 font-12">
      <div class="pb-5">
        <div class="row medium py-3">
          <div class="col">Name</div>
          <div class="col">Location</div>
          <div class="col-4">Address</div>
          <div class="col">Contact Person</div>
          <div class="col">Contact Number</div>
          <div class="col"></div>
        </div>
        <div class="row py-2 light" *ngFor="let site of this.allSites; let i = index">
          <div class="col">{{ site.siteName }}</div>
          <div class="col">{{ site.siteAddress.landmark }}</div>
          <div class="col-4">
            {{ site.siteAddress.addressLine1 }} , {{ site.siteAddress.state }}
          </div>
          <div class="col">{{ site.contactPersonName }}</div>
          <div class="col">{{ site.contactPersonNumber }}</div>
          <div class="col"><img alt="Edit" src="../../assets/img/pencil.svg" class="width-20 pointer" (click)="editSite(site.siteId)"/></div>

        </div>
        <div *ngIf="this.pageSize < this.totalElements">
          <pagination 
          [totalItems]="totalElements" 
          previousText="&laquo;" 
          nextText="&raquo;" 
          [itemsPerPage]="50"
          [maxSize]="10" 
          (pageChanged)="pageChanged($event)"
          ></pagination>
          </div>
      </div>
    </div>
  </div>
</div>
