import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { AmountService } from '../services/amount.service';
import { Router } from '@angular/router';
import { MasterService } from '../services/master.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-manage-supervisor',
  templateUrl: './manage-supervisor.component.html',
  styleUrls: ['./manage-supervisor.component.scss']
})
export class ManageSupervisorComponent implements OnInit {
  searchForm!: FormGroup;
  allSupervisors: any;
  allSites: any;
  constructor(private amountService: AmountService,
              private formBuilder: FormBuilder,
              private router: Router,
              private masterService: MasterService) { }

  ngOnInit() {
    this.initManageSupervisorForm();
    this.getManageSupervisorData();
    this.onSearch();
    this.getAllSites();
  }
  initManageSupervisorForm() {
    this.searchForm = this.formBuilder.group({
      employeeIdName: new FormControl('', [
      ]),
      site: new FormControl('', [
      ]),
    });
  }
  accountDetails(employeeid: string) {
    this.router.navigate(['account-details'], {queryParams: {id: employeeid} });
  }
  getManageSupervisorData() {
    this.amountService.manageSupervisor().subscribe(res => {
      this.allSupervisors = res.response.content;
    });
  }
  onSearch() {
    this.searchForm.valueChanges.pipe(debounceTime(200)).subscribe(data => {
      if (data.employeeIdName === '' && (data.site === '')) {
        this.getManageSupervisorData();
      } else {
        this.allSupervisors = null;
        const request = {
        keyword: data.employeeIdName,
        site: data.site,
      };
        this.amountService.searchEmployee(request).subscribe(res => {
        this.allSupervisors = res.response.content;
      });
      }

    }

    );
   }
   getAllSites() {
    this.masterService.getAllSites(1, 1000).subscribe(res => {
      this.allSites = res.response.content.map((el: any) => ({label: el.siteName , value: el.siteName}));
    });
  }
  addSupervisor() {
    this.router.navigate(['add-supervisor']);
  }
}
