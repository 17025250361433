<div class="loginBackground">
  <div class="container">
    <div class="row">
      <div class="col-xs-4 col-lg-4 col-md-3 col-sm-2 col-12"></div>
      <div class="col-xs-4 col-lg-4 col-md-6 col-sm-8 col-12">
        <div class="loginPad">
          <div class="text-center"><img alt="logo" src="../../assets/img/set-password-bg.png" class="loginLogo my-3"></div>
          <div class="orangeBG p-4 borderRadius10">
            <div class="text-white pt-1 pb-3 text-center bold font-18">Set Password</div>
            <div class="text-white">
              <form [formGroup]="setPasswordForm" (ngSubmit)="onSubmit()">

                <div class="row">
                  <div class="col-md-12 mb-2">
                    {{email}}
                  </div>
                  <div class="col-md-12">
                    <div class="font-12">
                      Password
                    </div>
                    <div class="">
                      <input class="form-control w-100 bg-white my-2 light font-12" formControlName="password"
                        type="password" placeholder="Enter New Password">
                      <div
                        *ngIf="setPasswordForm.controls['password'].invalid && setPasswordForm.controls['password'].dirty">
                        <span class="error" *ngIf="setPasswordForm.controls['password'].errors && setPasswordForm.controls['password'].errors['required']">Password is
                          required</span>
                        <span class="error" *ngIf="setPasswordForm.controls['password'].errors && setPasswordForm.controls['password'].errors['minlength']">Password
                          needs to be atleast 5
                          characters long</span>
                        <span class="error" *ngIf="setPasswordForm.controls['password'].errors && setPasswordForm.controls['password'].errors['maxlength']">Password is
                          too long.</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 my-2">
                    <div class="font-12">
                      Confirm Password
                    </div>
                    <div>
                      <input pInputText maxlength="50" formControlName="confirmPassword" type="password"
                        class="form-control w-100 bg-white light font-12" name="" placeholder="Confirm Password">
                      <div
                        *ngIf="setPasswordForm.controls['confirmPassword'].invalid && setPasswordForm.controls['confirmPassword'].dirty">
                        <span class="error" *ngIf="setPasswordForm.controls['confirmPassword'].errors && setPasswordForm.controls['confirmPassword'].errors['required']">Confirm
                          Password is required</span>
                        <span class="error" *ngIf="setPasswordForm.controls['confirmPassword'].errors && setPasswordForm.controls['confirmPassword'].errors['minlength']">Confirm
                          Password needs to be atleast 5
                          characters long</span>
                        <span class="error" *ngIf="setPasswordForm.controls['confirmPassword'].errors && setPasswordForm.controls['confirmPassword'].errors['maxlength']">Confirm
                          Password is too long.</span>
                        <span class="error" *ngIf="setPasswordForm.controls['confirmPassword'].errors && setPasswordForm.controls['confirmPassword'].errors['match']">Passwords
                          didn't match. Try again</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <button class="border-0 px-4 py-1 mt-4 borderRadius5 bg-black text-white bold pointer"
                      type="submit">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-4 col-lg-4 col-md-3 col-sm-2 col-12"></div>
    </div>
  </div>
