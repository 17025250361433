import { MasterService } from './../services/master.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidateAllFormFields } from '../utils/validateform';
import { Component, OnInit } from '@angular/core';
import { IState } from '../interface/master.interface';
import { RestResponse } from '../interface/RestResponse.interface';

@Component({
  selector: 'app-edit-statutory',
  templateUrl: './edit-statutory.component.html',
  styleUrls: ['./edit-statutory.component.scss'],
})
export class EditStatutoryComponent implements OnInit {
  id;
  zoneData: any;
  addZoneForm!: FormGroup;
  stateList: IState[] = [];
  constructor(
    public route: ActivatedRoute,
    private masterService: MasterService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.id = this.route.snapshot.queryParamMap.get('id');
  }

  ngOnInit() {
    this.zoneForm();
    this.getData();
    this.getAllStates();
  }
  getData() {
    this.masterService.getZone(this.id).subscribe((res: any) => {
      this.zoneData = res.response;
      this.addZoneForm.patchValue({
        zoneName: this.zoneData.zoneName,
        addressLine1: this.zoneData.zoneAddress.addressLine1,
        addressLine2: this.zoneData.zoneAddress.addressLine2,
        state: this.zoneData.zoneAddress.state,
        district: this.zoneData.zoneAddress.city,
        location: this.zoneData.zoneAddress.landmark,
        pincode: this.zoneData.zoneAddress.pinCode,
        contactName: this.zoneData.contactPersonName,
        contactNumber: this.zoneData.contactPersonNumber,
        leaveWage:  this.zoneData.leaveWage,
        unskilled: this.zoneData.unSkilledWage,
        skilled: this.zoneData.skilledWage,
        semiSkilled: this.zoneData.semiSkilledWage,
        isHra: this.zoneData.hra,
        isConveyance: this.zoneData.conveyance,
        isVhr: this.zoneData.vhr,
        isShoeAllowance: this.zoneData.shoeAllowance,
        isEpf: this.zoneData.epf,
        isEsi: this.zoneData.esi,
        isPt: this.zoneData.pt,
        //ptValue: this.zoneData.ptValue
      });
    });
  }
  zoneForm() {
    this.addZoneForm = this.formBuilder.group({
      zoneName: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9 s]*$')]),
      ],
      addressLine1: [''],
      addressLine2: [''],
      state: ['', [Validators.required]],
      district: [
        '',
        Validators.compose([Validators.pattern('^[a-zA-Z s]*$')]),
      ],
      location: [
        '',
        Validators.compose([Validators.pattern('^[a-zA-Z s]*$')]),
      ],
      pincode: [
        '',
        [
          Validators.pattern('^\\d+$'),
          Validators.minLength(6),
          Validators.maxLength(6),
        ],
      ],
      contactName: [
        '',
        [
          Validators.pattern('^[a-zA-Z s]*$'),
          Validators.maxLength(30),
          Validators.minLength(3),
        ],
      ],
      contactNumber: [
        '',
        [
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern('^\\d+$'),
        ],
      ],
      unskilled: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9][0-9]*([.][0-9]{1,2}|)$'),
          Validators.maxLength(7),
          Validators.minLength(3),
        ],
      ],
      semiSkilled: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9][0-9]*([.][0-9]{1,2}|)$'),
          Validators.maxLength(7),
          Validators.minLength(3),
        ],
      ],
      skilled: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9][0-9]*([.][0-9]{1,2}|)$'),
          Validators.maxLength(7),
          Validators.minLength(3),
        ],
      ],
      isHra: [false],
      isConveyance: [false],
      isVhr: [false],
      isShoeAllowance: [false],
      isEpf: [false],
      isEsi: [false],
      isPt: [false],
    });
    //this.professionalCheckboxChange();
  }
  getAllStates(): void{
    this.masterService.getAllStates().subscribe((res: RestResponse<IState[]>) => {
      this.stateList = res.response;
    })
  }
  professionalCheckboxChange(): void{
    this.addZoneForm.controls['isPt'].valueChanges.subscribe((res) => {
      if (res === true) {
        this.addZoneForm.addControl('ptValue', new FormControl('', [Validators.pattern('^[0-9][0-9]*([.][0-9]{1,2}|)$')]));
      }else {
        this.addZoneForm.removeControl('ptValue');
      }
    })
  }
  onCancel() {
    this.addZoneForm.reset();
    this.router.navigate(['/statutory']);
  }
  onClickSubmit() {
    if (this.addZoneForm.valid) {
      const data = {
        zoneName: this.addZoneForm.value.zoneName,
        addressLine1: this.addZoneForm.value.addressLine1,
        addressLine2: this.addZoneForm.value.addressLine2,
        state: this.addZoneForm.value.state,
        city: this.addZoneForm.value.district,
        location: this.addZoneForm.value.location,
        pincode: this.addZoneForm.value.pincode,
        contactPersonName: this.addZoneForm.value.contactName,
        contactPersonNumber: this.addZoneForm.value.contactNumber,
        leaveWage: this.addZoneForm.value.leaveWage,
        unSkilledWage: this.addZoneForm.value.unskilled,
        semiSkilledWage: this.addZoneForm.value.semiSkilled,
        skilledWage: this.addZoneForm.value.skilled,
        isHra: this.addZoneForm.value.isHra,
        isConveyance: this.addZoneForm.value.isConveyance,
        isVhr: this.addZoneForm.value.isVhr,
        isShoeAllowance: this.addZoneForm.value.isShoeAllowance,
        isEpf: this.addZoneForm.value.isEpf,
        isEsi: this.addZoneForm.value.isEsi,
        isPt: this.addZoneForm.value.isPt,
        //ptValue: +this.addZoneForm.value.ptValue
      };
      this.masterService.onUpdateZone(data, this.id).subscribe((res: any) => {
        this.addZoneForm.reset();
        this.router.navigate(['/statutory']);
      });
    } else {
      ValidateAllFormFields(this.addZoneForm);
    }
  }
}
