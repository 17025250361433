import { ReportsComponent } from './reports/reports.component';
import { EditStatutoryComponent } from './edit-statutory/edit-statutory.component';
import { ManageEmployeeComponent } from './manage-employee/manage-employee.component';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { Routes, RouterModule } from '@angular/router';
import { NocontentComponent } from './nocontent/nocontent.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { StatutoryComponent } from './statutory/statutory.component';
import { AddZoneComponent } from './add-zone/add-zone.component';
import { ManageSiteComponent } from './manage-site/manage-site.component';
import { AddSiteComponent } from './add-site/add-site.component';
import { AddEmployeeComponent } from './add-employee/add-employee.component';
import { PayslipComponent } from './payslip/payslip.component';
import { VendorInvoiceComponent } from './vendor-invoice/vendor-invoice.component';
import { AddVendorComponent } from './add-vendor/add-vendor.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { PurchaseOrderListComponent } from './purchase-order-list/purchase-order-list.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { EditSiteComponent } from './edit-site/edit-site.component';
import { EditEmployeeComponent } from './edit-employee/edit-employee.component';
import { ExcelUploadComponent } from './excel-upload/excel-upload.component';
import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { AddSupervisorComponent } from './add-supervisor/add-supervisor.component';
import { ManageSupervisorComponent } from './manage-supervisor/manage-supervisor.component';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { AddAmountComponent } from './add-amount/add-amount.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AuthGuardService, LoginAuthGuardService } from './services/auth.guard.service';
import { BonusReportComponent } from './bonus-report/bonus-report.component';

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [LoginAuthGuardService] },
  { path: 'login', component: LoginComponent, canActivate: [LoginAuthGuardService] },
  { path: 'home', component: HomeComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'set-password', component: SetPasswordComponent},
  { path: 'statutory', component: StatutoryComponent , canActivate: [AuthGuardService]},
  { path: 'add-zone', component: AddZoneComponent, canActivate: [AuthGuardService]},
  { path: 'manage-site', component: ManageSiteComponent , canActivate: [AuthGuardService]},
  { path: 'add-site', component: AddSiteComponent , canActivate: [AuthGuardService]},
  { path: 'add-employee', component: AddEmployeeComponent , canActivate: [AuthGuardService]},
  { path: 'payslip', component: PayslipComponent , canActivate: [AuthGuardService]},
  { path: 'list-employee', component: ManageEmployeeComponent , canActivate: [AuthGuardService]},
  { path: 'vendor-invoice', component: VendorInvoiceComponent , canActivate: [AuthGuardService]},
  { path: 'add-vendor', component: AddVendorComponent , canActivate: [AuthGuardService]},
  { path: 'add-customer', component: AddCustomerComponent , canActivate: [AuthGuardService]},
  { path: 'purchase', component: PurchaseComponent , canActivate: [AuthGuardService]},
  { path: 'purchase-orders', component: PurchaseOrderListComponent , canActivate: [AuthGuardService]},
  { path: 'invoice', component: InvoiceComponent , canActivate: [AuthGuardService]},
  { path: 'invoice-list', component: InvoiceListComponent , canActivate: [AuthGuardService]},
  { path: 'edit-invoice', component: EditInvoiceComponent , canActivate: [AuthGuardService]},
  { path: 'edit-statutory', component: EditStatutoryComponent, canActivate: [AuthGuardService] },
  { path: 'edit-site', component: EditSiteComponent, canActivate: [AuthGuardService] },
  { path: 'employee-upload', component: ExcelUploadComponent, canActivate: [AuthGuardService]},
  { path: 'edit-user', component: EditEmployeeComponent, canActivate: [AuthGuardService]},
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuardService]},
  { path: 'add-supervisor', component: AddSupervisorComponent , canActivate: [AuthGuardService]},
  { path: 'manage-supervisor', component: ManageSupervisorComponent , canActivate: [AuthGuardService]},
  { path: 'account-details', component: AccountDetailsComponent , canActivate: [AuthGuardService]},
  { path: 'add-amount', component: AddAmountComponent , canActivate: [AuthGuardService]},
  { path: 'dashboard', component: DashboardComponent , canActivate: [AuthGuardService]},
  { path: 'admin-dashboard', component: AdminDashboardComponent, canActivate: [AuthGuardService] },
  { path: 'bonus-report', component: BonusReportComponent, canActivate: [AuthGuardService] },
  { path: '**', component: NocontentComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
