import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterService } from '../services/master.service';
import { ReportService } from '../services/report.service';
import { UserService } from '../services/user.service';
import { ValidateAllFormFields } from '../utils/validateform';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { CustomMessageService } from '../core/message/message.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-bonus-report',
  templateUrl: './bonus-report.component.html',
  styleUrls: ['./bonus-report.component.scss']
})
export class BonusReportComponent implements OnInit {
  bonusReportForm!: FormGroup;
  zoneList: any;
  allSites: any;
  currentPage = 1;
  pageSize = 1000;
  downloadStatus = false;
  file: File | null = null;
  multipleZonesSettings: IDropdownSettings = {};
  constructor(public userService: UserService,
              public masterService: MasterService,
              public formBuilder: FormBuilder,
              public reportService: ReportService,
              public messageService: CustomMessageService) { }

  ngOnInit() {
    this.getAllSites();
    this.getZone();
    this.initBonusReportForm();
  }
  initBonusReportForm() {
    this.bonusReportForm = this.formBuilder.group({
      fromDate: new FormControl('', [
        Validators.required,
      ]),
      toDate: new FormControl('', [
        Validators.required,
      ]),
      site: new FormControl('', [
        Validators.required,
      ]),
      zone: new FormControl('', [
        Validators.required,
      ]),
    });
    this.onSiteSelect();
    this.onZoneSelect();
  }
  onSubmitBonusReportForm() {
    if (this.bonusReportForm.valid) {
      const convertedFromDate = moment(this.bonusReportForm.value.fromDate).format(
        'YYYY-MM-DD');
      const convertedToDate = moment(this.bonusReportForm.value.toDate).format(
        'YYYY-MM-DD');
      const fileName = `${moment(this.bonusReportForm.value.fromDate).format(
        'MMMYYYY')}-${moment(this.bonusReportForm.value.toDate).format(
          'MMMYYYY')}.xlsx`;
      const zones = this.bonusReportForm.controls['zone'].value.length > 0 ?(this.bonusReportForm.controls['zone'].value as {zoneName: string,zoneId:string}[]).map((res) => {return  res.zoneName}): [];
      const request = {
        fromDate: convertedFromDate,
        toDate: convertedToDate,
        site: this.bonusReportForm.controls['site'].value,
        zone: zones.toString(),
      };
      this.reportService.getBonusReport(request).subscribe((res) => {
        if (res) {
          this.file = new File(
              [res],
              fileName,
              { type: 'application/vnd.ms.excel' }
            );
          this.downloadStatus = true;
          this.messageService.notify('success', '', 'Report generated successfully, Click on download to get the report');
    }
      });
    } else {
      ValidateAllFormFields(this.bonusReportForm);
    }
  }
  onClickCancel() {
    this.downloadStatus = false;
    this.bonusReportForm.controls['zone'].enable({emitEvent: false});
    this.bonusReportForm.controls['site'].enable({emitEvent: false});
    this.initBonusReportForm();
  }

  getZone() {
    this.userService.getZone().subscribe(res => {
      if (res.status.code === 0) {
        this.zoneList = res.response.content;

      }
    });
    this.multipleZonesSettings = {
      singleSelection: false,
      idField: 'zoneId',
      textField: 'zoneName',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
  }
  getAllSites() {
    this.masterService.getAllSites(this.currentPage, this.pageSize).subscribe(res => {
      this.allSites = res.response.content;
    });
  }
  onDownloadBonusReport() {
    saveAs(this.file? this.file :'', '');
    this.downloadStatus = false;
  }
  onSiteSelect() {
    this.bonusReportForm.controls['site'].valueChanges.subscribe((res) => {
      if (res !== '') {
        this.bonusReportForm.controls['zone']?.reset('', { emitEvent: false });
        this.bonusReportForm.controls['zone'].disable({emitEvent: false});
      } else {
        this.bonusReportForm.controls['zone'].enable({emitEvent: false});
      }

    });
  }
  onZoneSelect() {
    this.bonusReportForm.controls['zone'].valueChanges.subscribe((res) => {
      if (res.length !== 0) {
        this.bonusReportForm.controls['site']?.reset('', { emitEvent: false });
        this.bonusReportForm.controls['site'].disable({emitEvent: false});
      } else {
        this.bonusReportForm.controls['site'].enable({emitEvent: false});
      }

    });
  }
}
