import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormControl, FormGroup, FormArray, FormArrayName } from '@angular/forms';
import { ValidateAllFormFields } from '../utils/validateform';
import { VendorService } from '../services/vendor.service';
import { PurchaseService } from '../services/purchase.service';
import { CustomerService } from '../services/customer.service';
var writtenNumber = require('written-number');
import * as moment from 'moment';



@Component({
  selector: 'app-edit-invoice',
  templateUrl: './edit-invoice.component.html',
  styleUrls: ['./edit-invoice.component.scss']
})

export class EditInvoiceComponent implements OnInit {

  id;
  invoiceForm!: FormGroup;
  allCustomerData: any;
  allVendorData: any;
  invoiceNumber: any;
  selectedPurchaseData: any;
  allInvoiceData: any;
  convertedInvoiceDate: string = '';
  convertedPurchaseOrderDate: string = '';
  subTotal: any;
  totalAmount: any;
  amountInWords: any;
  quantityChange = [];
  unitRateChange = [];
  cgst = '0';
  igst = '0';
  sgst = '0';
  cess: any;
  state1 = true;
  state2 = true;
  state3: any;
  constructor(
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private purchaseService: PurchaseService,
    private customerService: CustomerService ,
    private vendorService: VendorService,
  ) {
    this.id = this.route.snapshot.queryParamMap.get('id');
  }

  ngOnInit() {
    this.getCustomerList();
    this.initGenerateForm();
    this.getAllInvoicedata();
    this.amountChanges();
  }
  calculateTotal() {
    if ( this.allInvoiceData.vendor.address.state === this.allInvoiceData.customer.address.state) {
      this.sgst = Number((this.subTotal * 9) / 100).toFixed(0);
      this.cgst = Number((this.subTotal * 9) / 100).toFixed(0);
    } else {
      this.igst = Number((this.subTotal * 18) / 100).toFixed(0);
    }
    if (this.invoiceForm.controls['cess'].touched) {
    this.cess = this.invoiceForm.controls['cess'].value;
  }
    this.totalAmount = (Number(this.igst) + Number(this.sgst) + Number(this.cgst) + Number(this.cess) + Number(this.subTotal)).toFixed(0);
    this.amountInWords = writtenNumber(this.totalAmount);
  }
  amountChanges() {
    this.invoicesFormArray.valueChanges.subscribe(ele => {
      this.subTotal = 0;
      ele.forEach((elem: any, index: number) => {
        this.invoicesFormArray.controls[index]?.get('amount')?.patchValue((elem.quantity * elem.unitRate).toFixed(2) , {emitEvent: false});
        this.subTotal =  (Number(elem.amount) + Number(this.subTotal)).toFixed(2);
      });
      this.calculateTotal();
    }
 );
  }
  getCustomerList() {
    this.customerService.getCustomer().subscribe((ele) => {
      this.allCustomerData = ele.response.content;
    });
  }
  getVendorList(customerId: string) {
    this.vendorService.getVendorsbycustomerId(customerId).subscribe((ele) => {
    this.invoiceNumber = ele.response.invoiceNumber;
    this.allVendorData = ele.response.vendorId;
    });
  }
  getAllInvoicedata() {
    this.purchaseService.editInvoiceList(this.id).subscribe((res) => {
      this.allInvoiceData = res.response;
      this.cess = this.allInvoiceData.cess;
      this.allInvoiceData.invoiceItems.forEach((element: any) => {
        this.invoiceItems(element);
      });
      this.invoiceForm.patchValue({
        purchaseOrderNumber: this.allInvoiceData.purchaseOrderNumber,
        InvoiceReferanceNumber: this.allInvoiceData.referenceNumber,
        shipToAddress: this.allInvoiceData.shipTo.addressLine1,
        shipToCity: this.allInvoiceData.shipTo.city,
        shipToState: this.allInvoiceData.shipTo.state,
        shipToCountry: this.allInvoiceData.shipTo.country,
        shipToPincode: this.allInvoiceData.shipTo.pinCode,
        cess: this.allInvoiceData.cess,
        amountInWords: this.allInvoiceData.grantTotalInWords,
        invoiceDate: moment(this.allInvoiceData.invoiceDate).format('DD/MM/YYYY'),
        invoicePurchaseOrderDate: moment(this.allInvoiceData.purchaseOrderDate).format('DD/MM/YYYY'),
        });

  });
}
  initGenerateForm() {
    this.invoiceForm = this.formBuilder.group({
      invoiceDate: new FormControl(null, [
        Validators.required,
      ]),
      purchaseOrderNumber: new FormControl('', [
        Validators.required
      ]),
      invoicePurchaseOrderDate:  new FormControl(null, [
        Validators.required
      ]),
      InvoiceReferanceNumber:  new FormControl(null, [
        Validators.required
      ]),
      cess: new FormControl(0, [
        Validators.required
      ]),
      shipToAddress: new FormControl(null, []),
      shipToCity: new FormControl(null, []),
      shipToState: new FormControl(null, []),
      shipToCountry: new FormControl(null, [
      ]),
      invoices: new FormArray([]),
      shipToPincode: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^\\d+$'),
          Validators.minLength(6),
          Validators.maxLength(6),
        ]),
      ],
    });
  }
  onSubmitInvoiceForm(value: any) {
    if (this.invoiceForm.valid) {
    this.convertedInvoiceDate = moment(this.allInvoiceData.invoiceDate).format('DD/MM/YYYY');
    this.convertedPurchaseOrderDate = moment(this.allInvoiceData.purchaseOrderDate).format('DD/MM/YYYY');

    if (value.invoicePurchaseOrderDate !== this.convertedPurchaseOrderDate) {
      value.invoicePurchaseOrderDate =  moment(value.invoicePurchaseOrderDate).format('DD/MM/YYYY');
    }
    if (value.invoiceDate !== this.convertedInvoiceDate) {
      value.invoiceDate =   moment( value.invoiceDate).format('DD/MM/YYYY');
    }

    const request = {
      invoiceDate : value.invoiceDate,
      purchaseOrderNumber : value.purchaseOrderNumber,
      purchaseOrderDate:  value.invoicePurchaseOrderDate,
      referenceNumber : value.InvoiceReferanceNumber,
      shipToAddress: value.shipToAddress ,
      shipToCity: value.shipToCity ,
      shipToState: value.shipToState ,
      shipToCountry: value.shipToCountry ,
      shipToPincode: value.shipToPincode ,
      totalAmountInWords: this.amountInWords ,
      subTotal: this.subTotal ,
      igst: this.igst ,
      sgst: this.sgst ,
      cgst: this.cgst ,
      cess: value.cess ,
      grandTotal: this.totalAmount ,
      itemSpecification: value.invoices,
        };
    this.purchaseService.submitEditInvoice(request, this.id).subscribe((ele) => {
       if (ele.status.code === 0) {
          this.router.navigate(['invoice-list']);
          }
  });
} else {
  ValidateAllFormFields(this.invoiceForm);
}
}
  get totalInWords() {
return writtenNumber(this.selectedPurchaseData.grandTotal);
   }

  get invoicesFormArray() {
    return this.invoiceForm.controls['invoices'] as FormArray;
  }
  onClickCancel() {
    this.router.navigate(['invoice-list']);
  }
  onRemoveFormGroup(i: any) {
    const invoices = this.invoiceForm.controls['invoices'] as FormArray;
    invoices.removeAt(i);
    }
  addNewInvoice() {
    const isInvoicesFormArray = this.invoiceForm.controls['invoices'].valid;
    if (isInvoicesFormArray) {
      const ivoices = this.invoicesFormArray;
      ivoices.push(this.formBuilder.group({
      description: new FormControl(null, [
        Validators.required
      ]),
      uam: new FormControl(null, [
        Validators.required
      ]),
      hsnCode: new FormControl(null, [
        Validators.required
      ]),
      quantity: ['', [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      unitRate: ['', [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      amount: new FormControl(null, []),
    }));
  } else {
    ValidateAllFormFields(this.invoiceForm);
  }
  }
  invoiceItems(data: any) {
    const ivoices = this.invoicesFormArray;
    ivoices.push(this.formBuilder.group({
       description: new FormControl(data.description, [
         Validators.required
       ]),
       rsvn: new FormControl(data.rsvn, [
       ]),
       uam: new FormControl(data.uam, [
         Validators.required
       ]),
       hsnCode: new FormControl(data.hsnCode, [
         Validators.required
       ]),
       quantity: [data.quantity ? data.quantity : '', [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
       unitRate: [data.unitRate ? data.unitRate : '', [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
       amount: new FormControl(data.quantity * data.unitRate, []),
     }));
   }
}
