import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidateAllFormFields } from '../utils/validateform';
import { Router } from '@angular/router';
import { MasterService } from '../services/master.service';
import { CustomValidators } from '../validators/custom.validator';

@Component({
  selector: 'app-statutory',
  templateUrl: './statutory.component.html',
  styleUrls: ['./statutory.component.scss'],
})
export class StatutoryComponent implements OnInit {
  public masterZoneForm!: FormGroup;
  public masterStatutoryForm!: FormGroup;
  statutoryData: any;
  zoneData: any;
  currentPage = 1;
  pageSize = 50;
  totalElements = 0;
  constructor(
    private formBuilder: FormBuilder,
    private masterService: MasterService,
    private router: Router
  ) {}

  ngOnInit() {
    this.masterStatutoryForm = this.formBuilder.group({
      hra: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9][0-9]*([.][0-9]{1,3}|)$'),
          Validators.maxLength(5),
          CustomValidators.max,
        ]),
      ],
      conveyance: ['', Validators.compose([Validators.pattern('^\\d+$')])],
      shoes: ['', Validators.compose([Validators.pattern('^\\d+$')])],
      vhr: ['', Validators.compose([Validators.pattern('^\\d+$')])],
      epf: [
        '',
        [
          Validators.pattern('^[0-9][0-9]*([.][0-9]{1,3}|)$'),
          Validators.maxLength(5),
          CustomValidators.max,
        ],
      ],
      esi: [
        '',
        [
          Validators.pattern('^[0-9][0-9]*([.][0-9]{1,3}|)$'),
          Validators.maxLength(5),
          CustomValidators.max,
        ],
      ],
      epfCealingAmount:[
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9][0-9]*([.][0-9]{1,3}|)$'),
        ],
      ],
      esiCealingAmount:[
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9][0-9]*([.][0-9]{1,3}|)$'),
        ],
      ],
    });
    this.onClickZones();
  }

  onSubmitStatutory() {
    let statutoryId: string = '';

    if (this.statutoryData != null) {
      statutoryId = this.statutoryData.statutoryId;
    }
    if (this.masterStatutoryForm.valid) {
      const data = {
        statutoryId,
        hra: this.masterStatutoryForm.value.hra,
        mobileAllowance: this.masterStatutoryForm.value.mobile,
        conveyance: this.masterStatutoryForm.value.conveyance,
        shoesAllowance: this.masterStatutoryForm.value.shoes,
        vhr: this.masterStatutoryForm.value.vhr,
        epf: this.masterStatutoryForm.value.epf,
        esi: this.masterStatutoryForm.value.esi,
        esiCelingAmount: +Number(+this.masterStatutoryForm.value.esiCealingAmount).toFixed(2),
        epfCelingAmount: +Number(+this.masterStatutoryForm.value.epfCealingAmount).toFixed(2),
      };
      this.masterService.onCreateStatutory(data).subscribe((res: any) => {});
    } else {
      ValidateAllFormFields(this.masterStatutoryForm);
    }
  }

  onClickZones(event?: any) {
    this.masterService.getAllZone(this.currentPage, this.pageSize).subscribe((res: any) => {
      this.zoneData = res.response.content;
      this.totalElements = res.response.totalElements;
    });
  }

  onClickStatutory(event: any) {
    this.masterService.onGetStatutory().subscribe((res: any) => {
      this.statutoryData = res.response;

      if (this.statutoryData != null) {
        this.masterStatutoryForm.patchValue({
          hra: this.statutoryData.hra,
          mobile: this.statutoryData.mobileAllowance,
          conveyance: this.statutoryData.conveyance,
          shoes: this.statutoryData.shoesAllowance,
          vhr: this.statutoryData.vhr,
          epf: this.statutoryData.epf,
          esi: this.statutoryData.esi,
          epfCealingAmount: this.statutoryData.epfCealingAmount,
          esiCealingAmount: this.statutoryData.esiCealingAmount,
        });
      }
    });
  }
  onclickPlusImage() {
    this.router.navigate(['/add-zone']);
  }

  onCancel() {
    this.masterStatutoryForm.reset();
    this.router.navigate(['/statutory']);
  }
  editStatutory(id: string) {
    this.router.navigate(['/edit-statutory'], { queryParams: { id } });
  }
  pageChanged(event: any) {
    this.currentPage = event.page ;
    this.onClickZones();
  }
}
