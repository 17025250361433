import { Injectable } from '@angular/core';
import { CanActivate} from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {

  canActivate() {
      if (localStorage.getItem('user-role')) {
        return true;
      }
      return false
  }
}
export class LoginAuthGuardService implements CanActivate {

    canActivate() {
        if (!localStorage.getItem('user-role')) {
          return true;
        }
        return false
    }
  }
