import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidateAllFormFields } from '../utils/validateform';
import { EmployeeService } from '../services/employee.service';

@Component({
  selector: 'app-excel-upload',
  templateUrl: './excel-upload.component.html',
  styleUrls: ['./excel-upload.component.scss']
})
export class ExcelUploadComponent implements OnInit {
  uploadEmployeeForm: any;
  fileForm: any;
  file: File|null = null;
  showFiletypeError: boolean = false;
  constructor(private formBuilder: FormBuilder, private employeeService: EmployeeService, private router: Router) {}

  ngOnInit() {
    this.uploadEmployee();
  }

  uploadEmployee() {
    this.uploadEmployeeForm = this.formBuilder.group({
      file: ['', Validators.compose([Validators.required])],
    });
  }
  onSubmitedForm() {
    if (this.uploadEmployeeForm.valid && !this.showFiletypeError) {

      this.employeeService.UploadEmployee(this.fileForm).subscribe(res => {
        if (res) {
          if (res.status.code === 0) {
            this.onClear();
          }
          this.router.navigate(['/list-employee']);
        }
      });
    } else {
      ValidateAllFormFields(this.uploadEmployeeForm);
    }
  }
  onSelectedFile(event: any) {
    this.deleteForm();
    if (event.target.files && event.target.files[0]) {
      const  filedata = event.target.files[0];
      if (filedata.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || filedata.type === 'application/vnd.ms-excel') {
        this.showFiletypeError = false;
        this.file = event.target.files[0];
        this.fileForm.append('file', this.file, this.file ? this.file.name : '');
      } else {
        this.showFiletypeError = true;
      }
    }
  }
  onClear() {
    this.deleteForm();
    this.showFiletypeError = false;
    this.uploadEmployeeForm.reset();
  }
  deleteForm() {
    this.file = null;
    delete this.fileForm;
    this.fileForm = new FormData();
  }
  gotoback() {
    this.router.navigate(['/add-employee']);
  }
}
