<div class="py-2 minHeight80vh m-4 font-14">
  <div class="row">
    <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div class="pt-3 pb-4 px-4 bg-white">
        <div class="row py-2">
          <div class="col-10 col-xl-8 col-lg-8 col-md-9 col-sm-10">
            <div class="pb-2 font-16 pt-2 medium">Received</div>
          </div>
          <div class="col-2 col-xl-4 col-lg-4 col-md-3 col-sm-2 text-right">
            <img alt="Download" (click)="downloadReceivedTable('RECEIVED')" src="../../assets/img/excel.svg"
              title="Download Excel" class="addIcon w-100 pointer" />
          </div>
        </div>

        <div class="font-12 pb-4 contentBelowSubHeading px-4 mt-4" *ngIf="receivedTableData && receivedTableData.length > 0">
          <div class="row medium py-3">
            <div style="max-width: 70px;" class="col">Sl No.</div>
            <div class="col">Invoice No.</div>
            <div class="col">Invoice Date</div>
            <div class="col">Total Amount</div>
          </div>
          <div *ngFor="let detail of receivedTableData;let i = index" class="row py-2 light">
            <div style="max-width: 70px;" class="col">{{i+1}}</div>
            <div class="col">{{detail?.invoiceNumber}}</div>
            <div class="col"> {{detail.invoiceDate ? (detail.invoiceDate | date: 'dd/MM/yyyy'):''}}</div>
            <div class="col">{{detail?.grandTotal}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div class="pt-3 px-4 bg-white pb-4">
        <div class="row py-2">
          <div class="col-6 col-xl-4 col-lg-4 col-md-4 col-sm-6">
            <div class="pb-2 font-16 pt-2 medium">Outstanding</div>
          </div>

          <div class="col-4 col-xl-6 col-lg-6 col-md-5 col-sm-4">
            <form [formGroup]="searchForm">
              <div class="py-1 font-16 position-relative">
                <input type="text" placeholder="Search by Customer Name" formControlName="keyword"
                  class="manageUserSelectBox w-100 height-30 pl-5 pr-3 font-12 light darkGreyFont pointer" />
                <img alt="search" src="../../assets/img/magnifying-glass.svg" class="position-absolute searchIcon" />
              </div>
            </form>
          </div>

          <div class="col-2 col-xl-2 col-lg-2 col-md-3 col-sm-2 text-right">
            <img alt="Download" (click)="downloadOutstandingData('OUTSTANDING')" src="../../assets/img/excel.svg"
              title="Download Excel" class="addIcon w-100 pointer" />
          </div>
        </div>

        <div class="font-12 pb-4 contentBelowSubHeading px-4 mt-4" *ngIf="OutstandingTableData && OutstandingTableData.length > 0">
          <div class="row medium py-3">
            <div style="max-width: 70px;" class="col">Sl No.</div>
            <div class="col">Invoice No.</div>
            <div class="col">Invoice Date</div>
            <div class="col">Total Amount</div>
          </div>
          <div *ngFor="let detail of OutstandingTableData;let i = index" class="row py-2 light">
            <div style="max-width: 70px;" class="col">{{i+1}}</div>
            <div class="col">{{detail.invoiceNumber}}</div>
            <div class="col">{{detail.invoiceDate ? (detail.invoiceDate | date: 'dd/MM/yyyy'): ''}}</div>
            <div class="col">{{detail.grandTotal}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
