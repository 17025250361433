import { ReportsComponent } from './reports/reports.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import {MessageModule} from 'primeng/message'
import {MessagesModule} from 'primeng/messages'
import {MessageService} from 'primeng/api';
import {InputTextareaModule} from 'primeng/inputtextarea'
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';


import { MessageComponent } from './core/message';
import { LoaderComponent } from './core/loader/loader.component';
import { MenuModule } from './menu/menu.module';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NocontentComponent } from './nocontent/nocontent.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserService } from './services/user.service';
import { RestService } from './core/rest.service';
import { PurchaseService } from './services/purchase.service';
import { LoaderService } from './core/loader/loader.service';
import { CustomMessageService } from './core/message/message.service';
import { ErrorInterceptor } from './interceptors/http.interceptor';
import { MasterService } from './services/master.service';
import { EmployeeService } from './services/employee.service';
import { DataService } from './services/data.service';
import { CustomerService } from './services/customer.service';
import { VendorService } from './services/vendor.service';

import { DateAndTimePipe } from './pipes/date-and-time.pipe';
import { TransformPipe } from './pipes/transform.pipe';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MenuComponent } from './menu/menu/menu.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { DropdownModule } from 'primeng/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { StatutoryComponent } from './statutory/statutory.component';
import { AddZoneComponent } from './add-zone/add-zone.component';
import { ManageSiteComponent } from './manage-site/manage-site.component';
import { AddSiteComponent } from './add-site/add-site.component';
import { AddEmployeeComponent } from './add-employee/add-employee.component';
import { PayslipComponent } from './payslip/payslip.component';
import {InputTextModule} from 'primeng/inputtext';
import { ManageEmployeeComponent } from './manage-employee/manage-employee.component';
import { VendorInvoiceComponent } from './vendor-invoice/vendor-invoice.component';
import { AddVendorComponent } from './add-vendor/add-vendor.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { PurchaseOrderListComponent } from './purchase-order-list/purchase-order-list.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { EditStatutoryComponent } from './edit-statutory/edit-statutory.component';
import { EditSiteComponent } from './edit-site/edit-site.component';
import { EditEmployeeComponent } from './edit-employee/edit-employee.component';
import { ExcelUploadComponent } from './excel-upload/excel-upload.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { AddSupervisorComponent } from './add-supervisor/add-supervisor.component';
import { ManageSupervisorComponent } from './manage-supervisor/manage-supervisor.component';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { AddAmountComponent } from './add-amount/add-amount.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ChartsModule } from 'ng2-charts';
import { AuthGuardService, LoginAuthGuardService } from './services/auth.guard.service';
import { BonusReportComponent } from './bonus-report/bonus-report.component';
import { CustomDateTimePipe } from './pipes/custom-date-time.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { CustomTitleCasePipe } from './pipes/custom-title-case.pipe';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    MessageComponent,
    LoaderComponent,
    NocontentComponent,
    ReportsComponent,
    DateAndTimePipe,
    TransformPipe,
    CustomDateTimePipe,
    CustomDatePipe,
    CustomTitleCasePipe,
    ForgotpasswordComponent,
    MenuComponent,
    SetPasswordComponent,
    StatutoryComponent,
    ManageSiteComponent,
    AddZoneComponent,
    AddSiteComponent,
    AddEmployeeComponent,
    PayslipComponent,
    ManageEmployeeComponent,
    VendorInvoiceComponent,
    AddVendorComponent,
    AddCustomerComponent,
    PurchaseComponent,
    InvoiceComponent,
    PurchaseOrderListComponent,
    InvoiceListComponent,
    EditStatutoryComponent,
    EditSiteComponent,
    EditEmployeeComponent,
    ExcelUploadComponent,
    EditInvoiceComponent,
    AddSupervisorComponent,
    ManageSupervisorComponent,
    AccountDetailsComponent,
    AddAmountComponent,
    DashboardComponent,
    AdminDashboardComponent,
    BonusReportComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MenuModule,
    AppRoutingModule,
    HttpClientModule,
    MessageModule,
    BrowserAnimationsModule,
    MessagesModule,
    ToastModule,
    DropdownModule,
    ChartsModule,
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BsDatepickerModule.forRoot(),
    InputTextModule,
    InputTextareaModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [DateAndTimePipe],
  providers: [
    UserService,
    RestService,
    MasterService,
    LoaderService,
    CustomMessageService,
    MessageService,
    DataService,
    CustomerService,
    VendorService,
    EmployeeService,
    PurchaseService,
    AuthGuardService,
    LoginAuthGuardService,
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorInterceptor,
        multi: true,
      },
    ],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
