import { UserService } from './user.service';
import { DataService } from './data.service';
import { AppConfig } from '../../config';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { RestService } from '../core/rest.service';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private restService: RestService,
    private dataService: DataService,
    private router: Router
  ) {}
  public login(data: any): Observable<{}> {
    return this.restService.oauthLogin(Environment.baseUrl + `/oauth/token`, data).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  public forceLogout() {
    this.dataService.clearOut();
    localStorage.clear();
    this.router.navigate(['/login']);
  }
  public forgotpassword(emailAddress: any): Observable<{}> {
    return this.restService
      .post(Environment.UrlPath + '/auth/forgotPassword?email=' + emailAddress, null)
      .pipe(
        map(res => res),
        catchError(error => throwError(() => error))
      );
  }
  onSettingPassword(data: any, email: any): Observable<any> {
    return this.restService.put(Environment.UrlPath + '/auth/setPassword/' + email, data, false).pipe(
      map(res => res),
      catchError(error => throwError(() => error))
    );
  }
  public isLoggedIn() {
    if (localStorage.getItem('userName') !== null && localStorage.getItem('userName') !== 'null') {
      return true;
    }
    return false;
  }
  public setUserData() {}
}
