import { ValidatorFn, Validators, FormControl } from '@angular/forms';

export class CustomValidators {
  public static onlyText(): ValidatorFn {
    const pattern = '^[a-zA-Z s]*$';
    return Validators.pattern(pattern);
  }
  public static onlyNumber(): ValidatorFn {
    const pattern = '^\\d+$';
    return Validators.pattern(pattern);
  }
  public static currency(c: FormControl) {
    const regex = /^(?:\d+|\d{1,2},(?:\d{2},)*\d{3})(?:\.\d{2})?$/;
    if (regex.test(c.value)) {
      return null;
    } else {
      return { pattern: true };
    }
  }
  public static onlyAlphaNumeric(): ValidatorFn {
    const pattern = '^[a-zA-Z0-9]*$';
    return Validators.pattern(pattern);
  }
  public static lowercaseValidator(c: FormControl) {
    const regex = /(?=.*?[a-z])/;
    if (regex.test(c.value)) {
      return null;
    } else {
      return { lowercase: true };
    }
  }
  public static uppercaseValidator(c: FormControl) {
    const regex = /(?=.*?[A-Z])/;
    if (regex.test(c.value)) {
      return null;
    } else {
      return { uppercase: true };
    }
  }
  public static oneDigitValidator(c: FormControl) {
    const regex = /(?=.*?[0-9])/;
    if (regex.test(c.value)) {
      return null;
    } else {
      return { onedigit: true };
    }
  }
  public static oneSpecialCharValidator(c: FormControl) {
    const regex = /(?=.*?[#?!@$%^&*-])/;
    if (regex.test(c.value)) {
      return null;
    } else {
      return { specialChar: true };
    }
  }
  public static email(c: FormControl) {
    // angular validator does not validate (dot) https://github.com/angular/angular/issues/16544#issuecomment-299199211
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(c.value)) {
      return null;
    } else {
      return { email: true };
    }
  }

  public static dateRangeValidator(control: FormControl) {
    const today = new Date();
    if (new Date(control.value).getTime() < today.getTime()) {
      return null;
    } else {
      return { dateError: true };
    }
  }
  public static threePlaceDecimalValidator(c: FormControl) {
    const regex = /^[0-9][0-9]*([.][0-9]{1,3}|)$/;
    if (regex.test(c.value)) {
      return null;
    } else {
      return { threePlaceDecimal: true };
    }
  }
  public static max(c: FormControl) {
    const max = c.value;
    if (max < 100) {
      return null;
    } else if (max === '') {
      return null;
    }else {
      return { max: true };
    }
  }
}
