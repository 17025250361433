import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'customDateTime' })
export class CustomDateTimePipe implements PipeTransform {
    transform(date: Date | string): any {
        date = new Date(date);  // if original type was a string
        return new DatePipe('en-US').transform(date, 'dd-MM-YYYY hh:mm a', 'GMT+0530');
    }
}
